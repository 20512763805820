import React from 'react';

const SecSolution = ({solutions}) => {
  console.log(solutions);
  return (
    <section className="solution-section">
    <div className="solution-list">
      {
        solutions.length > 0 &&
        solutions.map((item, index) =>
        <div className="solution-item" key={index}>
        <div className="container">
          <div className="row align-items-center">
            <div className="solution-content">
              <div className="title">{item?.name}</div>
              <div className="description" dangerouslySetInnerHTML={{__html:item?.description}}></div>
            </div>
            <div className="solution-img">
              <img src={item?.image} alt='' />
            </div>
          </div>
        </div>
      </div>
        ) 
      }
      
    </div>
  </section>
  );
};

export default SecSolution;
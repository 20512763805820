import React from 'react';


const SecWhy = ({whyus}) => {
  
  return (
    <section className="section-whyus">
    <div className="pre-footer"><img src="assets/img/wave.svg" alt=""/></div>
    <div className="container">
      <div className="whyus-content">
        <div className="whyus-info">
          <h2 className="whyus-title">Why us</h2>
          {/* <div className="whyus-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem error animi reiciendis labore nobis adipisci delectus, ad ratione culpa iusto.</div> */}
          <div className="whyus-image"><img src="assets/img/bg-whyus.svg" alt=""/></div>
        </div>
        <div className="whyus">
          <div className="whyus-list">
            {
              whyus.length > 0 &&
              whyus.map((item, index) => 
                <div className="whyus-item" key={index}>
                  <div className="item-icon"><i className={item?.icon}></i></div>
                  <div className="item-title">{item?.name}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default SecWhy;
import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";
import { LOGGED_IN, LOG_OUT} from "./type";
// import { useNavigate } from "react-router";
// const navigate = useNavigate();

export const login = body => async dispatch => {
    try {
      const res = await fetchData('POST', `${BASE_URL}user/login`, body);
      const resData = await res.json();  
      if(resData.status === 200 && !resData.data) {
        dispatch({ type: LOGGED_IN, payload: resData.data });
        //localStorage.setItem('userId', resData.data._id);
        localStorage.setItem('access_token', resData.access_token);
        return resData;
      }else if(resData.status === 200 && resData.data) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}
export const signup =  async body => {
    try {
      const res = await fetchData('POST', `${BASE_URL}signup`, body);
      const resData = await res.json();  
      if(resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}

export const loginas = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}/user/loginas`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      localStorage.setItem('access_token', data.access_token);
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const role =  async body => {
    try {
      const res = await fetchData('POST', `${BASE_URL}role/list`, body);
      const resData = await res.json();  
      if(resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}
export const verifyotp = async body =>  {
    try {
      const res = await fetchData('POST', `${BASE_URL}user/verify`, body);
      const resData = await res.json();  
     
      if(resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}

export const autoSignup = body => async dispatch =>  {
  try {
    const res = await fetchData('POST', `${BASE_URL}signup-auto-login`, body);
    const resData = await res.json();     
    if(resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem('access_token', resData.access_token);
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyLogin = body => async dispatch =>  {
  try {
    const res = await fetchData('POST', `${BASE_URL}verify-login`, body);
    const resData = await res.json();     
    if(resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem('access_token', resData.access_token);
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const forgetPassword = async body =>  {
    try {
      const res = await fetchData('POST', `${BASE_URL}forget-password`, body);
      const resData = await res.json();  
     
      if(resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}
export const forgetPasswordVerify = async body =>  {
    try {
      const res = await fetchData('POST', `${BASE_URL}forget-password-verify`, body);
      const resData = await res.json();  
     
      if(resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}
export const updatePassword = async body =>  {
    try {
      const res = await fetchData('POST', `${BASE_URL}forget-update-password`, body);
      const resData = await res.json();  
     
      if(resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}

export const getUser = (signal = null) => async dispatch => {
  try {
    const res = await fetchData('POST', `${BASE_URL}profile-details`, {
      id: localStorage.getItem('userId'),
    }, signal);
    const resData = await res.json();  
    if(resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const sendOtp = async body =>  {
  try {
    const res = await fetchData('POST', `${BASE_URL}send-otp`, body);
    const resData = await res.json();  
   
    if(resData.status === 200) {
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const resendOtp = async body =>  {
  try {
    const res = await fetchData('POST', `${BASE_URL}resend-otp`, body);
    const resData = await res.json();  
    if(resData.status === 200) {
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const logout = () => dispatch => {
    dispatch({ type: LOG_OUT, payload: null });
    localStorage.removeItem('userId');  
    localStorage.removeItem('access_token');   
    window.location.href = '/';
}
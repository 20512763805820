import React, { useState, useEffect } from "react";
import SecProfile from "../components/UserProfile/SecProfile";
import UserSidepanel from "../shared/userSidepanel";

import { getProfileData } from "../actions/site";

const UserProfile = () => {
  const [userprofile, setUserProfile] = useState({});
  const [isloading, setIsloading] = useState(true);
  useEffect(() => {
    // Profile Data
    const loadProfileData = async () => {
      try {
        const data = await getProfileData({
          id: localStorage.getItem("userId"),
        });
        if (data.status == 200) {
          setUserProfile(data.data);
          setIsloading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadProfileData();
  }, []);

  return (
    <section className="sec-panel login-part-pages">
      <div className="container">
        <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className="right-sidebar">
            <SecProfile userprofile={userprofile} isloading={isloading}></SecProfile>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;

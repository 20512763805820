import React, { useState, useEffect } from "react";
import SecEditDriverManagement from "../components/DriverManagement/SecEditDriverManagement";
import UserSidepanel from "../shared/userSidepanel";
import { useParams } from "react-router";
import { getStates, getCities, checkDriverVehicle } from "../actions/site";
import { getDriverDetails } from "../actions/driver";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
const EditDriver = () => {
  const [states, setStates] = useState({});
  const [cities, setCities] = useState({});
  const { id } = useParams();
  const [driver, setDriver] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // State List
    const loadStates = async () => {
      try {
        const data = await getStates({
          country_code: "US",
        });
        if (data.status == 200) {
          setStates(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStates();

    const loadCities = async () => {
      try {
        const data = await getCities({});
        if (data.status == 200) {
          setCities(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCities();
  }, []);

  useEffect(() => {
    // State List
    const loadDriver = async () => {
      try {
        const data = await getDriverDetails({
          id: id,
        });
        if (data.status == 200) {
          setDriver(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDriver();
  }, [id]);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(async () => {
        try {
          const data = await checkDriverVehicle();
          if (data.status == 200) {
            if (data.step == 1) {
              return navigate("/add-driver");
            } else if (data.step == 2) {
              return navigate("/add-plate");
            }
          } else {
            return navigate("/add-driver");
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);
  return (
    <section className="sec-ticket login-part-pages">
      <div className="container">
        <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className="right-sidebar">
            <SecEditDriverManagement states={states} cities={cities} id={id} driver={driver} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditDriver;

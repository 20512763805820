import React, { useState, useEffect } from "react";
//import SecAddDriverManagement from '../components/DriverManagement/SecAddDriverManagement';
import SecDriverManagement from "../components/DriverManagement/SecDriverManagement";
import UserSidepanel from "../shared/userSidepanel";
import { checkDriverVehicle } from "../actions/site";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const DriverManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const segment = pathname.substring(pathname.lastIndexOf("/") + 1);
  console.log(segment);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(async () => {
        try {
          const data = await checkDriverVehicle();
          if (data.status == 200) {
            if (data.step === 1) {
              return navigate("/add-driver");
            } else if (data.step === 2 && segment != "driver-management") {
              return navigate("/add-plate");
            }
          } else {
            return navigate("/add-driver");
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);
  return (
    <section className="sec-ticket login-part-pages">
      <div className="container">
        <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className="right-sidebar">
            <SecDriverManagement />
            {/* <SecAddDriverManagement/> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DriverManagement;

import React, { useRef,useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const ImageShowModal = ({photo,isOpen,setViewLightBox}) => {   
    return (
    <>{
        isOpen && (
        <Lightbox
            mainSrc={photo}
            animationDisabled ={true}
            onCloseRequest={() => setViewLightBox({ isOpen: false })}
        />
        )
    }</>
    )
}

export default ImageShowModal;
import React,{ useEffect, useState,useRef} from 'react';
import { connect } from "react-redux";
import toastr from 'toastr';
import {
  login,
  signup,
  verifyotp,
  role,
  forgetPassword,
  forgetPasswordVerify,
  updatePassword,  
  getUser,
  resendOtp,
  autoSignup
} from '../../actions/login';
import InputMask from 'react-input-mask';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import AuthenticationModal from '../../shared/AuthenticationModal';
import { getStates } from '../../actions/site';
import { NavLink } from 'react-router-dom';


const SecSignup = ({pages,autoSignup}) => {
  const navigate = useNavigate();
  const modalRef=useRef();
  const [referred_signup_code, setReferredSignupCode] = useState();
  const [showPassword,setShowPassword] = useState('fa-regular fa-fw fa-eye-slash');
  const [type,setType] = useState('password');
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [signupType,setSignupType] = useState(2);
  const [phone,setPhone] = useState("");
  const [name,setName] = useState("");
  const [otp,setOtp] = useState("");
  const [active,setActive] = useState('signup');
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSignupEnabled,setSignupEnabled] = useState(false);
  const [isSignupVerifyEnabled,setIsSignupVerifyEnabled] = useState(false);
  const [countDown,setCountDown] = useState('(00:00)');
  const [isResentOtp,setIsResentOtp] =  useState(false);
  const [isEnabledResentOtp,setIsEnabledResentOtp] =  useState(false);
  const [isBack,setIsBack] = useState(false);

  const [states,setStates] = useState([]);
  const [searchState, setSearchState] = useState([]);
  const [plate_no, setPlateNo] = useState('');
  const [state, setState] = useState('');
  const [displayState,setDisplayState] =  useState('');
  const [vehicleInfo,setVehicleInfo] =  useState({});
  const [goToDashboard,setGoToDashboard] = useState(false);
  const [userId,setUserId] = useState('');
  const [isAccecpt,setIsAccecpted] = useState(false);

 var referral= new URL(window.location.href).searchParams.get("ref");

 
 useEffect(() => {
  if (
    typeof referral != "undefined" &&
    referral != "" &&
    referral != null
  ) {
    setReferredSignupCode(referral);
  }
}, [referral ]); 

 const hadelAccecpt = (e) => {
    if (e.target.checked) {    
      setIsAccecpted(true);
    } else {
      setIsAccecpted(false);
    }
  }

const handleChangeState = (event) => {
  let searchStates = states.filter((item) => { 
      if(item.state_code){ 
          return item.state_code.toLowerCase().includes(event.target.value.toLowerCase()) || item.name.toLowerCase().includes(event.target.value.toLowerCase())
      }
  })
  setSearchState(searchStates);
}

const getState = (id,code) => {
  setState(id);
  setDisplayState(code);
}

useEffect(()=>{ 
// State List
const loadStates = async () => {
  try {
    const data = await getStates({
      country_code: "US",
    });
    if (data.status == 200) {
      setStates(data.data);
      setSearchState(data.data);
    }
  } catch (err) {
    console.log(err.message);
  }
};
loadStates();
},[]);

  const handlePShowPassword = () => {
    if(type == 'password'){
      setShowPassword('fa-regular fa-fw fa-eye');
      setType('text');    
    }else{
      setShowPassword('fa-regular fa-fw fa-eye-slash');
      setType('password');
    }
  }
  const onSignup = async (e) => { 
    setError("");
    e.preventDefault();   
    setSignupEnabled(true);
    try {
        let msg = await  signup({  
          name : name,
          email : email,
          phone : (phone)?phone.replace(/[(|)|-]/g, "").replace(/\s/g,''):'',
          password : password,
          referred_signup_code:referred_signup_code,
          type:signupType,
          plate_no: plate_no.toUpperCase().replace(/ /g,''),
          state: state,
          is_accecpt:isAccecpt
        });         
        if(msg.status === 200){      
          toastr.success(msg.message);
          setActive('otp');
          setEmail(msg.data?.email)
          setSignupType(msg.type)
          setSignupEnabled(false);
        }else{
          toastr.error(msg.message);
          setSignupEnabled(false);
        }       
    } catch(err) {
      toastr.error(err.message);
      setSignupEnabled(false);
    }
  }

  const onVerifyotp = async (e) => { 
    setError("");
    e.preventDefault();   
    setIsSignupVerifyEnabled(true);
    try {
      let msg = await  verifyotp({  
        email : email,
        phone : (phone)?phone.replace(/[(|)|-]/g, "").replace(/\s/g,''):'',
        otp : otp,
        type:signupType,
        plate_no: plate_no.toUpperCase().replace(/ /g,''),
        state: state,
        displayState:displayState
      }); 
      if(msg.status === 200){      
        toastr.success(msg.message);
        setEmail(msg.data?.email)
        setPhone(msg.data?.phone)
        setIsSignupVerifyEnabled(false)
        setActive('plate-info');
        setUserId(msg.data.userInfo?._id);
        setVehicleInfo(msg.data.vehicleInfo)
        //modalRef.current.click()
      }else{
        setIsSignupVerifyEnabled(false)
        toastr.error(msg.message);
      }      
    } catch(err) {
      setIsSignupVerifyEnabled(false)
      toastr.error(err.message);
    }
  }

  const onDashboard = async (e) => { 
    setError("");
    e.preventDefault();
    setGoToDashboard(true);
    try {
      let msg = await autoSignup({  
        user_id :  userId,
        plate_no: plate_no.toUpperCase().replace(/ /g,''),
        state: state,
        displayState:displayState
      });
  
      if(msg.status === 200 && !msg.data){      
        toastr.success(msg.message);
        return navigate("/dashboard");
      }else if(msg.status === 200 && msg.data){
        setGoToDashboard(false);
      }
      else{
        toastr.error(msg.message);
        setGoToDashboard(false);
      }      
    } catch(err) {
      toastr.error(err.message);
      setGoToDashboard(false);
    }
  }

  const resendOtpFun = async (type) => {
    setIsEnabledResentOtp(true);
    if(type === 1){
      try {
        let msg = await resendOtp({ 
          email : email,
          type:type,
        });
        if(msg.status === 200){ 
          otpCounter();
          setIsEnabledResentOtp(false);
          toastr.success(msg.message);
        }else{
          setIsEnabledResentOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsEnabledResentOtp(false);
        toastr.error(err.message);
      }
    }else{
      try {
        let msg = await resendOtp({ 
          phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
          type:type,
        });
        if(msg.status === 200){ 
          otpCounter();
          setIsEnabledResentOtp(false);
          toastr.success(msg.message);
        }else{
          setIsEnabledResentOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsEnabledResentOtp(false);
        toastr.error(err.message);
      }
    }
  }

  const otpCounter = () => {
    setIsResentOtp(true);
    const count_time5 = add_minutes(new Date(), 0.5).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const count_time20 = new Date(count_time5).getTime();
    const x20 = setInterval(function() {
      const timezone20 = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const now20 = new Date(timezone20).getTime();
      const distance20 = count_time20 - now20;
      const days20 = Math.floor(distance20 / (1000 * 60 * 60 * 24));
      const hours20 = Math.floor((distance20 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes20 = Math.floor((distance20 % (1000 * 60 * 60)) / (1000 * 60));
      const seconds20 = Math.floor((distance20 % (1000 * 60)) / 1000);
      setCountDown('('+ ('0'+minutes20).slice(-2) + ":" + ('0'+seconds20).slice(-2) +')');
      if (distance20 < 0) {
          clearInterval(x20);
          setCountDown('(00:00)');
          setIsResentOtp(false);
      }
    }, 1000);
  } 
  
  const add_minutes = (dt2, minutes2) => {
    return new Date(dt2.getTime() + minutes2*60000);
  }


  return (
    <>
    <section className="career-section">
    <div className="pre-footer"><img src="assets/img/wave.svg" alt="" /></div>
    <button className="btn-login d-none" type="button" data-toggle="modal" data-target="#authenticationModal"  ref={modalRef}>Sign / Signup</button>
    <div className="container">
      <div className="career-content">
        <div className="career-info">
          <h2 className="title">{pages?.subtitle}</h2>
          <div className="career-text" dangerouslySetInnerHTML={{__html:pages?.description}}></div>
          <div className="career-image"><img src={pages?.image} alt="" /></div>
        </div>
        <div className="career-list authenticationModal ">
          <div className={`signup-content ${active == 'signup'?'active' : ''}`}>
              <h2 className="auth-title">Signup</h2>
              <div className="auth-text"></div>
              <form className="form-content">
              <div className="commercial-inner login-type">
                  <div className="radio-group">
                    <div className="custom-radio">
                      <input 
                      type="radio" 
                      className={`custom-radio-input ` } 
                      onChange={(e) => {
                        setSignupType(1);
                        setPhone('')
                      }} 
                      checked={signupType==1?'checked' : ''}
                      name="type" 
                      value="1"/>
                      <div className="custom-radio-label">With Email</div>
                    </div>
                    <div className="custom-radio">
                      <input 
                      type="radio" 
                      className={`custom-radio-input `}  
                      checked={signupType==2?'checked' : ''}
                      onChange={(e) =>{ 
                        setSignupType(2);
                        setPassword('');
                        setEmail('')}} 
                      name="type" 
                      value="2"/>
                        <div className="custom-radio-label">With  Phone</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-style">
                    <div className="input-icon"><i className="fa-regular fa-fw fa-user"></i></div>
                    <input 
                    className="input" 
                    type="text" 
                    placeholder="Name"
                    value={name}
                    autoComplete='off'
                    onChange={(e) => setName(e.target.value)}/>
                  </div>
                </div>
                <div className={`form-group ${signupType== 1?'' : 'd-none'}`}>
                  <div className="input-style">
                    <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                    <input  
                    className="input" 
                    type="text" 
                    placeholder="Email Address"
                    value={email}
                    autoComplete='off'
                    onChange={(e) => setEmail(e.target.value)}/>
                  </div>
                </div>
                <div className={`form-group ${signupType== 1?'' : 'd-none'}`}>
                  <div className="input-style">
                    <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                    <input  
                    className="input" 
                    type={type} 
                    placeholder="Password"
                    autoComplete='off'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
                    <button type="button" className="btn-show-password" onClick={handlePShowPassword} ><i className={showPassword}></i></button>
                  </div>
                </div>
                <div className={`phone form-row ${signupType== 2?'' : 'd-none'}`}>
                    {/* <div className="form-group col-md-5">
                      <div className='dropdown state-dropdown'>
                      <button className="dropdown-toggle" type="button" data-toggle="dropdown">{displayPhoneCode}</button>
                        <div className="dropdown-menu">
                          <div className="dropdown-search">
                            <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search" 
                            onChange={handleChangePhoneCode}
                            />
                          </div>
                          <div className="dropdown-scroll" id="search-state">
                                  {
                              searchPhoneCode.length > 0 &&
                              searchPhoneCode.map((item,index) => (
                                <button 
                                key={index}
                                type="button" 
                                className="dropdown-item" 
                                data-value={item?.phone_code}  
                                data-name={item?.phone_code}
                                onClick={() => {setPhoneCode(item?.phone_code); setDisplayPhoneCode(item?.phone_code)}} 
                                >{item?.phone_code} </button>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-md-12">
                      <div className="input-style">
                        <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                        <InputMask  
                        mask="(999) 999-9999"
                        className="input" 
                        type="text" 
                        placeholder="Phone"
                        autoComplete='off'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}/>
                        
                      </div>
                    </div>
                </div>
                <div className="form-group">
                  <div className="input-style">
                    <div className="input-icon"><i className="fa fa-ticket"></i></div>
                    <input  
                    className="input" 
                    type="text" 
                    placeholder="Enter Plate no"
                    value={plate_no}
                    onChange={(e) => setPlateNo(e.target.value)}/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="state">
                      <div className='ticket-dropdown'>
                        <div className='dropdown'>
                            <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{(displayState)?displayState:'Select State'}</button>
                            <div className='dropdown-menu'>
                            <div className='dropdown-search'>
                                <input 
                                type="text" 
                                className='form-control' 
                                placeholder="Search" 
                                id="search-state-input"
                                onChange={handleChangeState}
                                />
                            </div>
                            <div className='dropdown-scroll'>
                                {
                                    searchState.length > 0 &&
                                    searchState.map((item,index) => (
                                        <button  
                                        key={index} 
                                        type="button" 
                                        className='dropdown-item' 
                                        data-value={item?._id} 
                                        onClick={() => getState(item?._id,item?.state_code,item?.name)}                                           
                                        >{item?.state_code} - {item?.name}</button>
                                    ))
                                }                                    
                            </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-style">
                    <div className="input-icon"><i className="fa fa-user-plus"></i></div>
                    <input  
                    className="input" 
                    type="text" 
                    placeholder="Referred code"
                    value={referred_signup_code}
                    onChange={(e) => setReferredSignupCode(e.target.value)}/>
                  </div>
                </div>
                {
                  (signupType === 2)?
                  <div className="form-option">
                    <div className="custom-checkbox alt">
                      <input 
                      type="checkbox" 
                      className="custom-checkbox-input"
                      valie={isAccecpt}
                      onChange={hadelAccecpt}
                      />
                      <div className="custom-checkbox-label">Signing up using cell phone you agree to receive automated transactional messages. Terms and Privacy police can be found at <NavLink to="/terms-of-service" target="_blank" className="link">Terms of service</NavLink></div>
                    </div>
                  </div>:''
                }
                <div className="form-action">
                  <button type="button" className="btn-action" onClick={onSignup} disabled={isSignupEnabled}><i className={(isSignupEnabled)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-user-plus mr-2'}></i >Signup</button>
                </div>
              </form>
          </div>
          <div className={`forgot-content ${active=='otp'?'active' : ''}`}>
            <h2 className="auth-title">Verification code</h2>
            <div className="auth-text"></div>
            <form className="form-content sign-up">
              <div className="form-group ">
                <div className="input-style">
                  <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                  <InputMask
                  mask="999999"   
                  className="input"  
                  type="text" 
                  placeholder={`Enter ${(signupType === 1)?'Email':'SMS'} Verification Code`}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)} />
                </div>
                <div className='timer' dangerouslySetInnerHTML={{__html:countDown}}></div> 
              </div>
              <div className="form-action">
                <button type="button" className="btn-action" onClick={onVerifyotp} disabled={isSignupVerifyEnabled}><i className={(isSignupVerifyEnabled)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-key mr-2'}></i>Verify code</button>
                <button type="button" className="btn-action" onClick={() => resendOtpFun(signupType)} disabled={isResentOtp}><i className={(isEnabledResentOtp)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-clock mr-2"}></i>Resend OTP</button>
                <button type="button" className="btn-action" onClick={() => setActive('signup')} disabled={isBack}><i className={(isBack)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-arrow-left-to-bracket mr-2"}></i>Back</button>
              </div>
            </form>                
          </div>
          <div className={`forgot-content plate-info-modal ${active==='plate-info'?'active' : ''}`}>
              <h2 className="auth-title">Plate Info </h2>
              <div className='details'>
                    {
                        (vehicleInfo && Object.keys(vehicleInfo).length>0) ?(
                        <div className='details-card'>
                            <div className='vehicle-name'>{vehicleInfo?.name}</div>
                            <div className='vehicle-info'>
                                <div className='vehicle-number'>{vehicleInfo?.plate_no}</div>
                            </div>
                            {
                                vehicleInfo &&
                                <div className='vehicle-metalist'>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Make</div>
                                    <div className='meta-data'>{vehicleInfo?.make}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Model</div>
                                    <div className='meta-data'>{vehicleInfo?.model}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Year</div>
                                    <div className='meta-data'>{vehicleInfo?.year}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Color</div>
                                    <div className='meta-data'>{(vehicleInfo)?vehicleInfo?.color:'N/A'}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Drive Type</div>
                                    <div className='meta-data'>{vehicleInfo?.driveType}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Engine</div>
                                    <div className='meta-data'>{vehicleInfo?.engine}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Fuel</div>
                                    <div className='meta-data'>{vehicleInfo?.fuel}</div>
                                    </div>
                                    <div className='meta-item item-full'>
                                    <div className='meta-label'>VIN/SN</div>
                                    <div className='meta-data'>{vehicleInfo?.vin}</div>
                                    </div>                       
                                    <div className='meta-item'>
                                    <div className='meta-label'>Style</div>
                                    <div className='meta-data'>{vehicleInfo?.style}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Transmission</div>
                                    <div className='meta-data'>{vehicleInfo?.transmission}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Trim</div>
                                    <div className='meta-data'>{vehicleInfo?.trim}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        ):''
                    } 
                    <div className='submit-action mt-3'>
                        <button type="button" className='btn-submit-action' disabled={goToDashboard} onClick={onDashboard}>
                        <i className={(goToDashboard)?'fa-regular fa-spinner fa-spin mr-2':"fa-solid fa-save mr-2"}></i> Go To Dashboard
                        </button>
                    </div>
                </div>
              </div>
        </div>
      </div>
    </div>

    
  </section>
  <AuthenticationModal />
</>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, { autoSignup })(SecSignup);
import React, { useState, useEffect } from "react";
import UserSidepanel from "../shared/userSidepanel";
import SecAddNewTicket from "../components/TicketManagement/SecAddNewTicket";
import { getSiteSettings } from "../actions/site";

const SubmitTicket = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [siteSettings, setSiteSettings] = useState({});

  useEffect(() => {
    // Settings
    const loadSiteSettingsData = async () => {
      try {
        const data = await getSiteSettings({});
        if (data.status == 200) {
          setSiteSettings(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadSiteSettingsData();
  }, []);

  return (
    <section className="sec-ticket login-part-pages">
      <div className="container">
        <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className="right-sidebar">
            <SecAddNewTicket type={params.get("type")} settings={siteSettings} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubmitTicket;

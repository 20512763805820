import React, { useRef, useState, useEffect } from "react";
import "react-image-lightbox/style.css";
import Searching from "../../shared/searching";
import NothingFound from "../../shared/nothingFound";
import { getPlateDetails } from "../../actions/vehicle";

const SecPlateDetailsGlobal = ({ state, plate_no }) => {
  const [plate, setPlate] = useState({});
  const [status, setStatus] = useState(100);
  const [apijson, setApijson] = useState({});
  const [viewLightBox, setViewLightBox] = useState({
    photo: null,
    isOpen: false,
  });
  const { photo, isOpen } = viewLightBox;
  useEffect(() => {
    // Plate Details
    const loadPlateData = async () => {
      try {
        const data = await getPlateDetails({
          state,
          plate_no,
        });
        if (data.status == 200) {
          setPlate(data.data);
          
        }
        setStatus(data.status);
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlateData();
  }, [state, plate_no]);

  return (
    <section className="sec-plate-details">
      <div className="body-layout">
        <div className="body-content">
          <div className="user-search-part">
            <div className="title">Plate Details</div>
          </div>
          <div className="row">
            {status == 100 || status == 400 ? (
              status == 400 ? (
                <NothingFound />
              ) : (
                <Searching />
              )
            ) : (
              <div className="col-lg-12">
                <div className="details-content">
                  <div className="details">
                    <div className="details-card">
                      <div className="vehicle-name">
                       
                      </div>
                      <div className="vehicle-info">
                        <div className="vehicle-number">{plate?.plate_no}</div>
                        <div className="report-details">
                          {/* <button type="button" className='report-btn'>report</button> */}
                        </div>
                      </div>
                      <div className="vehicle-metalist">
                        <div className="meta-item">
                          <div className="meta-label">Make</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.make}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Model</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.model}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Year</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.year}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Color</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.color?.name}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Vehicle Style</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.style}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Vehicle Name</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.name}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">VIN/SN</div>
                          <div className="meta-data">
                            {plate?.licensePlateLookup?.vin}
                          </div>
                        </div>

                        <div className="meta-item">
                          <div className="meta-label">Trim</div>
                          <div className="meta-data">
                          {plate?.licensePlateLookup?.trim}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Engine</div>
                          <div className="meta-data">
                          {plate?.licensePlateLookup?.engine}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Transmission</div>
                          <div className="meta-data">
                          {plate?.licensePlateLookup?.transmission}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Fuel</div>
                          <div className="meta-data">
                          {plate?.licensePlateLookup?.fuel}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">DriveType</div>
                          <div className="meta-data">
                          {plate?.licensePlateLookup?.driveType}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecPlateDetailsGlobal;

import React from 'react';
const NothingFound = () => {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="nothing-inner">
              <div className="nothing-icon"><i className="fal fa-print-search"></i></div>
              <div className="nothing-content">
                <div className="nothing-text">Nothing Found</div>
                <div className="nothing-sub-text">It seem no data found in this page</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
export default NothingFound;
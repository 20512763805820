import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const getTicketList = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { 
        message: data.message, 
        data: data.data,
        status:data.status,
        count : data.count,
       };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTicketDetails = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/details`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTicketDetailsGlobal = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/global-details`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status,type:resData.type };
    } else {
      return { message: resData.message, status:resData.status };
    }
  } catch(err) {
    throw new Error(err.message);
  }
}
export const getSearchTicket = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}search-ticket`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const addTicket = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/add`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const importTicket = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/import`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

// For Plate 
export const storeTicket = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/create`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getSearchVehicle = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}search-vehicle`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getPlateList = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}vehicle/list`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { 
        message: resData.message, 
        data: resData.data,
        count : resData.count,
        status:resData.status
       };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getPlateDetails = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}vehicle/details`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deletePlate = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}vehicle/remove`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updatePlate = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}plate/update`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deletePlateGallery = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket-gallery/remove`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

// Others 
export const checkVehicle = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}check-vehicle`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getDashboardData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}dashboard`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { 
        message: resData.message, 
        data: resData.data,
        countOpenTicket:resData.countOpenTicket,
        countCloseTicket:resData.countCloseTicket,
        countDmvTicket:resData.countDmvTicket,
        count:resData.count,
        status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeAuthorizationFrom = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}authorization-form/store`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const ticketAssigneeDetails = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket_assignee/details`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTicketAssignStatusDetails = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket_assignee/status-documents`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}
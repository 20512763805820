import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const getDriverList = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}driver/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { 
        message: data.message, 
        data: data.data,
        status:data.status,
        count : data.count,
       };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getDriverDetails = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}driver/details`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeDriver = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}driver/create`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const updateDriver = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}driver/edit`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const deleteDriver = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}driver/remove`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}
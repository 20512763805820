
import React, { useState, useEffect } from 'react';
import SecBanner from '../components/Career/SecBanner';
import SecCareer from '../components/Career/SecCareer';

import {
  getPageDetails,
  getBanners,
  getJobs,
  getSeo
} from '../actions/site';
import {Helmet} from "react-helmet";
const Career = () => {

  const [seo,setSeo] = useState({});
  const [pageId,setPageId] = useState({});
  const [pages,setPages] = useState({});
  const [banners,setBanners] = useState({});
  const [jobs, setJobs] = useState({});
  
  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'careers'
        });  
        if(data.status == 200) {
         
          setPageId(data.data._id)
          setPages(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();

    // Jobs
 
  }, [ ]); 
 
  useEffect(() => {
    const loadJobsData = async () => {
      try {            
        const data = await getJobs({"status":"active"});  
        if(data.status == 200) {
            setJobs(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
  }
  loadJobsData();
  },[]);
  useEffect(() => {
    //Banners
    const loadBannersData = async () => {
        try {            
          const data = await getBanners({
            type:'page',
            type_id:pageId
          });  
          if(data.status == 200) {
              setBanners(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadBannersData();
  },[pageId]);

  useEffect(() => {
    //Seo
    const loadSeoData = async () => {
        try {            
          const data = await getSeo({
            type:'page',
            typeId:pageId,
          });  
          if(data.status == 200) {
              setSeo(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSeoData();
  },[pageId]);
  return (
    <><Helmet>
    <meta charSet="utf-8" />
    <meta name="title" content={seo?.meta_title}></meta>
    <meta name="author" content={seo?.author}></meta>
    <meta name="keywords" content={seo?.keywords}></meta>
    <meta name="description" content={seo?.description}></meta>
    </Helmet>
      <SecBanner banner={banners[0]}/>
      <SecCareer jobs={jobs} pages={pages} />
    </>
  );
};

export default Career;
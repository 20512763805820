import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { 
    manageContact
} from '../../actions/site';

const SecNewsletter = () => {
  const [ email,setEmail ] = useState(''); 
  const onSubmit = async () => {    
    try {
      let msg = await manageContact({
        type : 'newslatter', 
        email : email,
      }); 
      if(msg.status === 200){ 
        setEmail('');
        toastr.success(msg.message);
      }else{
        toastr.error(msg.message);
      }      
    } catch(err) {
      toastr.error(err.message);
    }
  }  

  return (
    <section className="section-newsletter">
    <div className="pre-footer"><img src="assets/img/wave.svg" alt="" /></div>
    <div className="container">
        <div className="news-letter-content">
            <div className="newsletter-image">
                <img src="assets/img/newsletter.svg" alt='' />
            </div>
            <div className="newsletter-form-section">
            <div className="news-letter-title">Join Our newsletter</div>
            <div className="newsletter-des">
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam quas autem illum nulla facere, dicta esse blanditiis? Porro temporibus repellat soluta modi neque quae molestiae.</p> */}
            </div>
                <form className="form-content" action='#'>
                    <div className="form-group">
                        <div className="input-style">
                            <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                            <input 
                            className="input" 
                            type="text" 
                            placeholder="Email Address" 
                            value ={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-action">
                        <button type="button" className="btn-action" onClick={onSubmit}><i
                                className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
  );
};

export default SecNewsletter;
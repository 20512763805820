import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import DefaultFooter from '../shared/defaultFooter';
import DefaultHeader from '../shared/defaultHeader';
import { getSiteSettings } from '../actions/site';

const DefaultLayout = ({socket}) => {
    const [siteSettings, setSiteSettings] = useState({});

    useEffect(() => {    
        const loadSiteSettingsData = async () => {
          try {            
            const data = await getSiteSettings({});  
            if(data.status == 200) {
                setSiteSettings(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadSiteSettingsData();        
      }, [ ]);    

    return (
        <>
        <DefaultHeader settings={siteSettings} socket={socket}/>
        <Outlet />
        <DefaultFooter settings={siteSettings}/>
        </>
    );
};

export default DefaultLayout;
import React, { useRef,useState, useEffect } from 'react';
import { connect } from "react-redux";
import toastr from 'toastr';
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import {storeCart} from '../actions/cart';
import {addTicket} from '../actions/ticket';
import NothingFound from '../shared/nothingFound';
import Searching from '../shared/searching';

const TicketDetailsModal = ({ticketInfo,storeCart,isSearchTicket}) => {
    const navigate = useNavigate();
    const closeRef = useRef(null);
    const [ticket_ids, setTicketsIds] = useState([]);
    const [cart_id, setCartId] = useState(localStorage.getItem('cartId'));

    const dateFormat = issue_date => {
        let d = new Date(issue_date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d); //short
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${(mo<=9)?'0'+mo:mo}/${(da<=9)?'0'+da:da}/${ye}`;
    }    

    const onAddTocart = async () => {
        try {
            let msg = await addTicket({
              user_id:localStorage.getItem('userId'),
              summons_number: ticketInfo?.summons_number,        
              ticket_type: 'dmv',
              fine_amount: ticketInfo?.fine_amount
            });
            if (msg.status === 200) {   
                setTicketsIds([
                    {
                      id: msg.data?._id,
                      plate_no: msg.data?.plate_no,
                      ticket_id: msg.data.summons_number,
                      amount: msg.data?.due_amount,
                    },
                ]);     
              toastr.success(msg.message);
            } else {
              toastr.error(msg.message);
            }
        } catch (err) {
            toastr.error(err.message);
        }
    }

    useEffect(() => {
        if(localStorage.getItem('cartId')){
          setCartId(localStorage.getItem('cartId'))
        }else{
          localStorage.setItem('cartId', uuidv4());
          setCartId(localStorage.getItem('cartId'))
        }
    }, []); 

    const  closeOneModal = () => {
        const modal = document.getElementById('ModalTicketDetails');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
    }

    useEffect(() => {
        const insertCart = async () => {
            try {
                let msg = await storeCart({
                cartId : cart_id,    
                ticket_ids: ticket_ids
                });
                if (msg.status === 200) {
                setTicketsIds([]); 
                closeOneModal();
                return navigate("/checkout");
                } else {
                toastr.error(msg.message);
                }
            } catch (err) {
                toastr.error(err.message);
            }
        }
        if(ticket_ids.length>0){
            insertCart();
        }
    }, [ticket_ids]); 

    return (
    <div className="modal fade career-apply-modal" id="ModalTicketDetails" tabIndex="-1" data-backdrop="static"
    data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="header-text">
                        <h5 className="modal-title" id="ModalCenterTitle">Ticket Information</h5>
                    </div>
                    <button type="button" className="modal-close" data-dismiss="modal"><i
                        className="fa-light fa-fw fa-times"></i></button>
                </div>
                <div className="modal-body">                    
                    <div className='details'>
                        <div className='details-card'>
                            <div className='vehicle-name'>{ticketInfo?.summons_number}</div>
                            <div className='vehicle-info'>
                                <div className='vehicle-number'>{ticketInfo?.plate}</div>
                            </div>
                            {
                                (ticketInfo && Object.keys(ticketInfo).length>0)?(
                                <div className='vehicle-metalist'>
                                    <div className='meta-item'>
                                    <div className='meta-label'>State</div>
                                    <div className='meta-data'>{ticketInfo?.state}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>License Type</div>
                                    <div className='meta-data'>{ticketInfo?.license_type}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Issue Date</div>
                                    <div className='meta-data'>{ticketInfo?.issue_date ? dateFormat(ticketInfo?.issue_date) : ''}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Violation Time</div>
                                    <div className='meta-data'>{ticketInfo?.violation_time}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Violation</div>
                                    <div className='meta-data'>{(ticketInfo?.violation)?ticketInfo?.violation:ticketInfo?.violation_description}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Fine Amount</div>
                                    <div className='meta-data'>{ticketInfo?.fine_amount}</div>
                                    </div>
                                    <div className='meta-item item-full'>
                                    <div className='meta-label'>Penalty Amount</div>
                                    <div className='meta-data'>{ticketInfo?.penalty_amount}</div>
                                    </div>                       
                                    <div className='meta-item'>
                                    <div className='meta-label'>Interest Amount</div>
                                    <div className='meta-data'>{ticketInfo?.interest_amount}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Reduction Amount</div>
                                    <div className='meta-data'>{ticketInfo?.reduction_amount}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Payment Amount</div>
                                    <div className='meta-data'>{ticketInfo?.payment_amount}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Amount Due</div>
                                    {
                                        ticketInfo.is_trans &&
                                        <div className='meta-data'>0</div>
                                    }
                                    {
                                        !ticketInfo.is_trans &&
                                        <div className='meta-data'>{(ticketInfo?.amount_due)?ticketInfo?.amount_due:ticketInfo?.due_amount}</div>
                                    }
                                    </div>
                                    {
                                    ticketInfo?.summons_image &&
                                    <div className='assigned-drivers mb-3 mt-3'>
                                        <div className='driver'>
                                            <div className='driver-image'>
                                            <iframe 
                                            src={ticketInfo?.summons_image} 
                                            //title={ticketInfo?.summons_image.description}
                                            >
                                            </iframe>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                ):((isSearchTicket)?<Searching/>:<NothingFound/>)
                            }
                        </div>
                    </div>
                </div>
                {
                    (ticketInfo?.amount_due > 0 && !ticketInfo.is_trans)  &&
                    <div className="modal-footer justify-content-center">
                        <button 
                        type="button"
                        className="btn-action"
                        onClick={onAddTocart}
                        ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Add & Pay Now
                        </button>
                    </div>
                }
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = state => {
    return {
      auth: state.auth
    }
}
export default connect(mapStateToProps, { storeCart })(TicketDetailsModal);
import React, { useState, useEffect } from "react";
import { useLocation,NavLink,Link } from "react-router-dom";

const UserSidepanel = () => {  
  const location = useLocation();
  const pathname = location.pathname;
  const segment = pathname.substring(pathname.lastIndexOf("/") + 1);
  const [isOpen, setIsOpen] = useState("");
  var [sidenavigationShow, setSidenavigationShow] = useState(false);
  const [activeMenu,setActiveMenu] = useState(false);
  const parems = new URLSearchParams(window.location.search).get("payment");


  useEffect(() => {
    setIsOpen(segment);
  }, [segment]);

  useEffect(() => {
    if(parems){
      setActiveMenu(true)
    }else if(segment === 'tlc-tickets'){
      setActiveMenu(true)
    }
  }, [parems]);

  const openMenu = () => {
    if(activeMenu){
      setActiveMenu(false)
    }else{
      setActiveMenu(true)
    }
  }

  return (
    <>
      <div className= "desktop-view" >
        <div className="side-panel">
          <div className="side-menu">
            <NavLink to="/dashboard" className={isOpen && (isOpen === "dashboard" || isOpen === "tickets") ? "menu-link active" : "menu-link"}>
              <div className="link-icon" title="Dashboard">
                <i className="fa-regular fa-fw fa-tachometer-alt-fast"></i>
              </div>
              <div className="link-text">Dashboard</div>
            </NavLink>

            <div className="ticket-menus">
              <a href='#'  className={isOpen && ((isOpen === "ticket-management" && (parems === 'all' || parems === 'pending'  || parems === 'paid') ) || isOpen === "submit-ticket" || isOpen === "tlc-tickets") ? "menu-link active" : "menu-link"} onClick={()=> openMenu()}>
                <div className="link-icon" title="Ticket Management">
                  <i className="fa-regular fa-fw fa-ticket"></i>
                </div>
                <div className="link-text">Ticket Management</div>
              </a>
              {/* to="/ticket-management?payment=all" */}
              <div className={`other-menus ${(activeMenu)?'active' : ''}`}>
                <NavLink to="/tlc-tickets" className={`sub-menu ${isOpen && isOpen === "tlc-tickets" ? "selected" : ""}`}>
                  <div className="link-icon" title="Ticket Management">
                    <i className="fal fa-clipboard-list-check mr-2"></i>
                  </div>
                  <div className="link-text">TLC</div>
                </NavLink>
                <NavLink to="/ticket-management?payment=pending" className={`sub-menu ${isOpen && (isOpen === "ticket-management" && parems === 'pending') ? "selected" : ""}`}>
                  <div className="link-icon" title="Ticket Management">
                    <i className="fal fa-clipboard-list-check mr-2"></i>
                  </div>
                  <div className="link-text">DMV (Pending)</div>
                </NavLink>
                <NavLink to="/ticket-management?payment=paid" className={`sub-menu ${isOpen && (isOpen === "ticket-management"  && parems === 'paid') ? "selected" : ""}`}>
                  <div className="link-icon" title="Ticket Management">
                    <i className="fal fa-clipboard-list-check mr-2"></i>
                  </div>
                  <div className="link-text">DMV (Paid)</div>
                </NavLink>
                <NavLink to="/ticket-management?payment=all" className={`sub-menu ${isOpen && (isOpen === "ticket-management"  && parems === 'all') ? "selected" : ""}`}>
                  <div className="link-icon" title="Ticket Management">
                    <i className="fal fa-clipboard-list-check mr-2"></i>
                  </div>
                  <div className="link-text">DMV (All)</div>
                </NavLink>
              </div>
            </div>
{/* 
            <div className="ticket-menus">
              <a className="btn btn-primary" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <div className="link-icon" title="Ticket Management">
                  <i className="fa-regular fa-fw fa-ticket"></i>
                </div>
                <div className="link-text">Ticket Management</div>
              </a>
             
              <div className="collapse" id="collapseExample">
                <NavLink to="/ticket-management?payment=pending" className={`sub-menu ${isOpen && (isOpen === "ticket-management" && parems === 'pending') ? "selected" : ""}`}>
                  <div className="link-icon" title="Ticket Management">
                    <i className="fal fa-clipboard-list-check mr-2"></i>
                  </div>
                  <div className="link-text">DMV Pending</div>
                </NavLink>
                <NavLink to="/ticket-management?payment=paid" className={`sub-menu ${isOpen && (isOpen === "ticket-management"  && parems === 'paid') ? "selected" : ""}`}>
                  <div className="link-icon" title="Ticket Management">
                    <i className="fal fa-clipboard-list-check mr-2"></i>
                  </div>
                  <div className="link-text">DMV Paid</div>
                </NavLink>
              </div>
            </div> */}

            <NavLink to="/driver-management" className={isOpen && (isOpen === "driver-management" || isOpen === "add-driver") ? "menu-link active" : "menu-link"}>
              <div className="link-icon" title="Driver Management">
                <i className="fa-regular fa-fw fa-user-vneck"></i>
              </div>
              <div className="link-text">Driver Management</div>
            </NavLink>
            <NavLink to="/plates" className={isOpen && (isOpen === "plates" || isOpen === "add-plate") ? "menu-link active" : "menu-link"}>
              <div className="link-icon" title="Plate Management">
                <i className="fa-regular fa-fw fa-cars"></i>
              </div>
              <div className="link-text">Plate Management</div>
            </NavLink>
            <NavLink to="/billings" className={isOpen && isOpen === "billings" ? "menu-link active" : "menu-link"}>
              <div className="link-icon" title="Billings">
                <i className="fa-regular fa-fw fa-file-invoice-dollar"></i>
              </div>
              <div className="link-text">Billings</div>
            </NavLink>
            <NavLink to="/referral" className={isOpen && isOpen === "referral" ? "menu-link active" : "menu-link"}>
              <div className="link-icon" title="Billings">
                <i className="fa-regular fa-fw fa-user-group"></i>
              </div>
              <div className="link-text">Referrals</div>
            </NavLink>
            <NavLink to="/user-profile" className={isOpen && isOpen === "user-profile" ? "menu-link active" : "menu-link"}>
              <div className="link-icon" title="Profile">
                <i className="fa-regular fa-circle-user"></i>
              </div>
              <div
                className="link-text"
                onClick={() => {
                  setIsOpen("user-profile");
                }}
              >
                Profile
              </div>
            </NavLink>
            <a href="#!" data-toggle="modal" data-target="#logoutModal" className="menu-link">
              <div className="link-icon" title="Log Out">
                <i className="fa-regular fa-arrow-right-from-bracket"></i>
              </div>
              <div className="link-text">Log out</div>
            </a>
          </div>
        </div>
      </div>
      {/* <div className={`side-overlay${sidenavigationShow ? " show" : ""}`} onClick={() => setSidenavigationShow(!sidenavigationShow)}></div> */}
    </>
  );
};

export default UserSidepanel;

import React, { useState, useEffect } from 'react';
import SecDriverDetails from '../components/DriverDetails/SecDriverDetails';
import UserSidepanel from '../shared/userSidepanel';
import { useParams } from 'react-router';
import {getDriverDetails} from '../actions/driver';
import {checkDriverVehicle} from '../actions/site';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import toastr from 'toastr';
const DriverDetails = () => {
  const { id } = useParams();
  const dispatch=useDispatch();
  const [driver,setDriver] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const loadDriver = async () => {
      try {            
        const data = await getDriverDetails({
            id:id
        });  
        if(data.status == 200) {
          setDriver(data.data)
        }else{
            toastr.error(data.message);
            navigate('/driver-management');
        }    
      } catch(err) {
        toastr.error(err.message);
        navigate('/driver-management');
      }
    }
    loadDriver();        
}, [id]);
useEffect(() => {
  if(localStorage.getItem('access_token')){
  dispatch(  async () => {
    try {            
      const data = await checkDriverVehicle();
      if(data.status == 200) {
        if(data.step==1){
          return navigate("/add-driver");
        }else if(data.step==2){
          return navigate("/add-plate");
        }
       
      }else{
        return navigate("/add-driver");
      }
      
    } catch(err) {
      console.log(err.message);
    }
  })
  }
 
}, [dispatch,window.location.pathname]); 
    return (
        <section className='sec-panel login-part-pages'>
        <div className='container'>
        <div className="row user-panel-menus">
          <div className="left-sidebar">
                <UserSidepanel/>
            </div>
            <div className='right-sidebar'>
                <SecDriverDetails id={id} driver={driver} />
            </div>
          </div>
        </div>
    </section>
    );
};

export default DriverDetails;
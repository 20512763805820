import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { useStripe, useElements,CardCvcElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate } from "react-router";
import {
    stripePaymentIntentWithCvc,
    storeCvcStripeData,
    storeCvcTlcStripeData
} from '../../actions/payment';

const CvcForm=({
    type,
    price,
    ticket_id,
    payment_for,
    invoice_no,
    coupon,
    couponPrice,
    taxs,
    reward_amount,
    payment_type,
    email,
    user_info,
    isHome,
    phone,
    payment_method,
    cartInfo,
}) => { 
const navigate = useNavigate(); 
const stripe = useStripe();
const elements = useElements();
const [isPayment,setIsPayment] = useState(false);

const handleCvcSubmit = (stripe, elements) => async (event) => {
    setIsPayment(true);
    const cvcElement = elements.getElement(CardCvcElement)     
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //     type: 'card',
    //     card: cvcElement,
    // });
    // if (error) {
    //       console.log('[error]', error);
    //       setIsPayment(false);
    // }else{
    //     console.log('Success'); 
    // }  
    try { 
        if(cvcElement){
            const data = await stripePaymentIntentWithCvc({
                price: price,
                cartId:localStorage.getItem('cartId'),
                payment_for:payment_for,
                ticket_no:ticket_id,
                couponPrice:couponPrice,
                payment_type:payment_type,
            });  
            if (data.status === 200) {
                const cvcSetup = await stripe.confirmCardPayment(data.data.client_secret, {
                    payment_method: payment_method,
                    payment_method_options: {
                        card: {
                            cvc: cvcElement
                        }
                    },
                });
                if(cvcSetup.error){
                  setIsPayment(false)
                  toastr.error(cvcSetup.error.message);
                }else{
                    if (cvcSetup.paymentIntent.status === 'succeeded') {
                        if(payment_for){
                           try {
                            const data = await storeCvcTlcStripeData({
                                charge:cvcSetup,
                                price: price,
                                ticket_no:ticket_id,
                                payment_for:payment_for,
                                payment_type:payment_type,
                                user_info:user_info,
                                couponPrice:couponPrice,
                                coupon:coupon
                            });  
                            if (data.status === 200) {
                                setIsPayment(false)
                                if(payment_for ==='assignee'){
                                  if(email || isHome){
                                    closeOneModal3();
                                  }else{
                                    //closeOneModal();
                                  }
                                  return navigate("/authorization-form/"+ticket_id);                 
                                }
                                if(payment_for ==='reduce' || payment_for === 'settlement' || payment_for === 'dismiss'){
                                  //closeOneModal2();
                                  return navigate("/success?txn="+ data?.data?.transaction_id);
                                }
                            } else {
                                toastr.error(data.message);
                                setIsPayment(false)
                            }
                           } catch (err) {
                            toastr.error(err.message);
                            setIsPayment(false)
                           } 
                        }else{
                            try {            
                                const cust = await storeCvcStripeData({
                                    charge:cvcSetup,
                                    userId:localStorage.getItem('userId'),
                                    cartId:localStorage.getItem('cartId'),
                                    price: price,
                                    coupon:coupon,
                                    couponPrice:couponPrice,
                                    taxs:taxs,
                                    reward_amount:reward_amount,
                                    user_info:user_info,
                                    cartInfo:cartInfo
                                });  
                                if (cust.status === 200) {
                                    setIsPayment(false)
                                    return navigate("/success?txn="+ cust?.data?.transaction_id);
                                } else {
                                    toastr.error(data.message);
                                    setIsPayment(false)
                                }    
                            } catch(err) {
                                toastr.error(err.message);
                                setIsPayment(false)
                            }
                        }
                    }
                }
            } else {
                setIsPayment(false)
                toastr.error(data.message);
            }    
        } 
    } catch(err) {
      setIsPayment(false)
      toastr.error(err.message);
    }
  };  
  
  const  closeOneModal = () => {
        const modal = document.getElementById('ModalTicketDetails');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
    }

    const  closeOneModal2 = () => {
    const modal = document.getElementById('ModalTLCPaymentDetails');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
    }

    const  closeOneModal3 = () => {
    const modal = document.getElementById('ModalTlcTicketDetails');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
    } 

    return (
        <>
            <CardCvcElement
            id="cardCvc"
            />
            <button 
            onClick={ handleCvcSubmit(stripe, elements) } 
            className='payment-card-btn pay' 
            disabled={isPayment}
            ><i className={(isPayment)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i> Submit</button>
        </>
    );

};

export default CvcForm;
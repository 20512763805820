import React, { useState, useEffect } from 'react';
import Searching from '../../shared/searching';
import NothingFound from '../../shared/nothingFound';
import toastr from 'toastr';
import {cartList,cartDelete} from '../../actions/cart';
import { getSiteSettings } from '../../actions/site';
import { NavLink } from 'react-router-dom';

const SecCart = () => {
  const [loading,setLoading] = useState(true);
  const [cartInfo,setCartInfo] = useState([]);
  const [totalDataCount,setTotalDataCount] = useState(0);
  const [searchCart, setSearchCart] = useState({});
  const [total,setTotal] = useState(0);
  const [totalPrice,setTotalPrice] = useState(0);
  const [isUser,setIsUser] = useState('');
  const [taxs,setTaxes] = useState(0);
  const [fine_amount,setFineAmount] = useState([]);
  const [amounts,setAmounts] = useState([]);
  const [settings, setSiteSettings] = useState({});
  const [isDelete,setIsDelete] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [isError,setIsError] = useState(false);
  const [errorCheck,setErrorCheck] = useState(0);

  useEffect(() => {
    if(localStorage.getItem('access_token')){
        setIsUser(localStorage.getItem('access_token'))
    }else{
        setIsUser('')
    }
  }, []);

  useEffect(() => {
    // Cart List
    const loadCartList = async () => {
        try {            
            const data = await cartList({
                cartId:localStorage.getItem('cartId'),
            });  
            if(data.status == 200) {
                setCartInfo(data.data);  
                setSearchCart(data.data);
                setTotalPrice((data.total)); 
                setTotal((data.total)); 
                setTaxes(data.stateTax);
            } 
            setTotalDataCount(data.count);
            setAmounts(data.amounts);
            setFineAmount(data.fine_amount);
            setLoading(false); 
        } catch(err) {
            console.log(err.message);
            setLoading(false); 
        }
    }
    loadCartList();  
    
    // Settings
    const loadSiteSettingsData = async () => {
        try {            
          const data = await getSiteSettings({});  
          if(data.status == 200) {
              setSiteSettings(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSiteSettingsData();
  }, []);

  useEffect(() => {
      // Cart Delete
      const loadCartDelete = async () => {
          setIsDelete(true)
          try {            
              const data = await cartDelete({
                  cartId:localStorage.getItem('cartId'),
                  id:currentId
              });  
              if(data.status == 200) {
                  setCartInfo(data.data);  
                  setSearchCart(data.data);
                  setTotal((data.total)); 
                  setTotalPrice((data.total));
                  setTaxes(data.stateTax);
                  setCurrentId('');
                  toastr.success(data.message); 
              } 
              setTotalDataCount(data.count);
              setAmounts(data.amounts);
              setFineAmount(data.fine_amount);
              setIsDelete(false);
          } catch(err) {
              console.log(err.message);
              setIsDelete(false);
          }
      }
      if(currentId){
        loadCartDelete();
      }      
  }, [currentId]);

  useEffect(() => {
      setTotalPrice((parseFloat(total)+parseFloat((settings?.creadit_fee)?settings?.creadit_fee:0)+parseFloat((settings?.processing_fee)?settings?.processing_fee:0)+parseFloat(taxs)).toFixed(2));
      
      let errorCheck = amounts.filter(item => item === null);
      if(errorCheck && errorCheck.length>0){
        setIsError(true);
        setErrorCheck(errorCheck.length);
      }else{
        setIsError(false);
        setErrorCheck(0);
      } 
  }, [cartInfo,settings,total,taxs]);  

  const dateFormat = issue_date => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${(mo<=9)?'0'+mo:mo},${da} ${ye}`;
  }

 

  return (
    <section className="cart-section">
      <div className="container">
        <div className='body-layout'>
            <div className='body-content'>
                <div className='user-search-part'>
                    <div className='title'>Cart List</div>
                </div>
            </div>
        </div>
        <div className="row">
         <div className='col-lg-8'>
          <div className="tickets cartlist">
            <div className="ticket-inner">
              {
                totalDataCount >0 ? (
                  searchCart.length > 0 &&
                  searchCart.map((item, index) => (
                    <div className="ticket-item" key={index}>
                      <div className="ticket-start">
                        <div className="ticket-id">
                          <div className="ticket-label">Ticket ID</div>
                          <div className="ticket-data">#{item?.ticket_no}</div>
                        </div>
                        <div className="ticket-date">{item?.created_at ? dateFormat(item?.created_at) : ''}</div>
                        <div className="ticket-meta">
                          <div className="meta-item">
                            <div className="meta-label">Fine</div>
                            <div className="meta-data">${(fine_amount)?fine_amount[index]:'--'}</div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Due</div>
                            <div className="meta-data">${(amounts)?amounts[index]:'--'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="ticket-end">
                        <div className="ticket-status">
                          <button className="status" onClick={() => {setCurrentId(item?._id)}}>
                            <i className={isDelete?"far fa-spinner fa-spin":"far fa-trash-alt"}></i> Delete</button>
                        </div>
                        {
                          (item && item.plate_no) &&
                          <div className="ticket-plate">
                            <div className="plate-label">Plate No.</div>
                            <div className="plate-data">{item?.plate_no}</div>
                          </div>
                        }
                      </div>
                    </div>
                  ))
                ):(loading?<Searching/>:<NothingFound/>)
              }
            </div>
          </div>
        </div>
        <div className='col-lg-4'>
            <div className='payment-option'>
              <div className="title">Total Summary</div>
              <div className="payment-summary">
                  <div className="summary-list">
                      <div className="item">
                          <div className="item-label">Total Tickets:</div>
                          <div className="item-data">${total}</div>
                      </div>
                      {
                          settings?.creadit_fee >0 &&
                          <div className="item">
                              <div className="item-label">Credit Card Fee:</div>
                              <div className="item-data">${settings?.creadit_fee}</div>
                          </div>
                      }
                      {
                          settings?.processing_fee >0 &&  
                          <div className="item">
                              <div className="item-label">Processing Fee:</div>
                              <div className="item-data">${settings?.processing_fee}</div>
                          </div>
                      }
                      {
                          taxs >0 &&  
                          <div className="item">
                              <div className="item-label">State Fee:</div>
                              <div className="item-data">${taxs}</div>
                          </div>
                      }
                  </div>
                  <div className="summary-total">
                      <div className="label">Total Amount</div>
                      <div className="data">${totalPrice}</div>
                  </div>
              </div>
            </div>
          </div>
          {  
            searchCart.length > 0 &&
            isError ? 
            <div className='col-lg-8 mt-3'>
              <div className='dashboard-action'>
                <div className="alert alert-warning" role="alert">
                    <h4 className="alert-heading">{errorCheck} tickets have no due amount in your cart</h4>
                    Please remove those tickets to continue the process
                </div>
              </div>
            </div>
            :
            <div className='col-lg-3 offset-lg-5'>
              <div className="checkout-action">
                <NavLink to="/checkout" className="btn-action">Proceed To Checkout <i className="far fa-arrow-right ml-2"></i></NavLink>
              </div>
            </div>
            
          }
        </div>
      </div>
    </section>
  );
};

export default SecCart;
import React, {useState, useEffect} from 'react';
import SecBillings from '../components/Billings/SecBillings';
import UserSidepanel from '../shared/userSidepanel';
const Billing = () => {
  

    return (
        <section className='sec-panel login-part-pages'>
            <div className='container'>
            <div className="row user-panel-menus">
          <div className="left-sidebar">
                        <UserSidepanel/>
                    </div>
            <div className='right-sidebar'>
                    <SecBillings/>
                    {/* <SecBillingsDetails/> */}
            </div>
          </div>
            </div>
        </section>
    );
};

export default Billing;
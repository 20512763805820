import React, { useState, useEffect } from 'react';
import UserSidepanel from '../shared/userSidepanel';
import SecAuthorizationForm from '../components/Dashboard/SecAuthorizationForm';
import { useParams } from 'react-router';

const AuthorizationForm = () => {
    const { summons_number } = useParams();
    return (
        <section className='sec-ticket login-part-pages'>
            <div className='container'>
                <div className='row user-panel-menus'>
                {
                    localStorage.getItem('access_token') && 
                    <div className='left-sidebar'>
                        <UserSidepanel/>
                    </div>
                }                
                <div className={localStorage.getItem('access_token')?' right-sidebar':'col-xl-12'}>
                   <SecAuthorizationForm 
                   summons_number={summons_number}
                   />
                </div>
          </div>
            </div>
        </section>
    );
}
export default AuthorizationForm;
import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import Searching from '../../shared/searching';
import NothingFound from '../../shared/nothingFound';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router";
import { getDashboardData } from '../../actions/ticket';
import { getTicketList } from "../../actions/ticket";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../../components/Checkout/CheckoutForm';
import CvcFrom from '../../components/Checkout/CvcFrom';
import { PayPalButton } from "react-paypal-button-v2";
import {storeTlcPaypalData,stripeCardList,stripeCardDelete,ticketStatus,acceptTlcStatus} from '../../actions/payment';
import ReactPaginate from 'react-paginate';
import ImageShowModal from '../ImageShowModal';
import { stripePublishKey,paypalClientId } from '../../helpers';

const SecTlcTickets = ({settings,keyword}) => {
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({});
    const [count, setCount] = useState(0);
    const [currentTicketId,setCurrentTicketId] = useState('');
    const [currentStatus,setCurrentStatus] = useState('');
    const [currentPrice,setCurrentPrice] = useState(0);
    const [currentPoint,setCurrentPoint] = useState(0);
    const [totalprice,setTotalPrice] =  useState(0);

    const [totalDataCount,setTotalDataCount] = useState(0);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [noOfPage, setNoOfPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [signature,setSignature] = useState([]);
    const [viewLightBox, setViewLightBox] = useState({photo: null,isOpen: false})
    const { photo, isOpen } = viewLightBox;
    //Paypal & stripe
    const stripePromise = loadStripe(stripePublishKey());

    const [cards,setCards] = useState([]);
    const [isExisting,setIsExisting] = useState(false);
    const [cardId,setCardId] = useState('');

    const [isDeleteCard,setIsDeleteCard] = useState(false);
    const [deleteCardId,setDeleteCardId] = useState('');
    const [totalCard,setTotalCard] =  useState(0);   
    const [tlcStatus,setTlcStatus] = useState([]);
    const [isAccecpt,setIsAccecpt] = useState(false);

    const [isModalTLCPaymentDetails, setIsModalTLCPaymentDetails] = useState(false);
    
    const status = [
        { label: "waiting", value: "Waiting" },
        { label: "assigned", value: "Assigned" },
        { label: "inprogress", value: "In progress" },
        { label: "reduce", value: "Reduce" },
        { label: "dismiss", value: "Dismiss" }
    ]; 
    
    //Fees Calculation 
    const [credit_card_fees,setCreditCardFees] = useState('');
    const [platform_fees,setPlatformFees] = useState('');

    const closeModalTLCPaymentDetails = () => {
      setIsModalTLCPaymentDetails(false);
    }

    useEffect(() => {    
        // For Credit card
        let cc_fees = 0;
        if(settings?.creadit_fee){            
            if(settings?.creadit_fee_type ==='percentage'){
                cc_fees = parseFloat(currentPrice)*parseFloat(settings?.creadit_fee)/100;
            }else{
                cc_fees = settings?.creadit_fee
            }
        }
        // For Platform
        let platform_fees = 0;
        if(settings?.processing_fee){            
            if(settings?.processing_fee_type ==='percentage'){
                platform_fees = parseFloat(currentPrice)*parseFloat(settings?.processing_fee)/100;
            }else{
                platform_fees = settings?.processing_fee
            }
        }
        setCreditCardFees(cc_fees.toFixed(2));
        setPlatformFees(platform_fees.toFixed(2));
        setTotalPrice((parseFloat(currentPrice)+parseFloat(platform_fees)+parseFloat(cc_fees)).toFixed(2));         
    }, [settings,currentPrice]); 

    
    const handleStatusActive = async (ticket_no,status) => {        
        setIsAccecpt(true);
        try {            
            const data = await acceptTlcStatus({
                ticket_no:ticket_no,
                payment_for:status,
            });  
            if(data.status === 200) {
                setIsAccecpt(false);
                setTotalCard(data.data.length);
                try {            
                    const data = await getDashboardData({
                      keyword: keyword,
                      ticket_type:'tlc',
                      limit:limit,
                      offset:offset
                    });  
                    if(data.status == 200) {
                      setDashboard(data.data)              
                      setCount(data.count);
                      //toastr.success(data.message);
                    } 
                    setTotalDataCount(data.count);
                    setLoading(false);    
                } catch(err) {
                console.log(err.message);
                setLoading(false); 
                }    
            }else{
                setIsAccecpt(false);
                toastr.error(data.message);
            }
        } catch(err) {
            toastr.error(err.message);
            setIsAccecpt(false);
        }
    };
    
      useEffect(() => {
          // Dashboard 
          const loadDashboardData = async () => {
              try {            
                const data = await getDashboardData({
                  keyword: keyword,
                  ticket_type:'tlc',
                  limit:limit,
                  offset:offset
                });  
                if(data.status == 200) {
                  setDashboard(data.data)              
                  setCount(data.count);
                } 
                setTotalDataCount(data.count);
                setLoading(false);    
              } catch(err) {
                console.log(err.message);
                setLoading(false); 
              }
            }
          loadDashboardData();      
      },[keyword,limit, offset]); 
    
      useEffect(() => {
          if(cards && cards.length>0){
              setIsExisting(true)
          }else{
              setIsExisting(false)
          }
      }, [cards]);
    
      const handleExistChange = (e) => {
          const { name, value } = e.target;  
          if(value === 'existing'){
              setIsExisting(true);
          }else{
              setIsExisting(false);
          }
      };
    
      const handleCardChange = (e) => {
          const { name, value } = e.target;    
          setCardId(value);
      }
    
      useEffect(() => {
        // Card List
        const loadCardList = async () => {
          try {            
            const data = await stripeCardList({});  
            if(data.status == 200) {
                setCards(data.data);   
                setTotalCard(data.data.length);       
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadCardList();  
        
         // Ticket Status List
         const loadTicketStatusList = async () => {
            try {            
              const data = await ticketStatus({
                status_for : 'user'
              });  
              if(data.status == 200) {
                setTlcStatus(data.data);                   
              }    
            } catch(err) {
              console.log(err.message);
            }
          }
          loadTicketStatusList();  
        
        
      }, []); 

    // Card Delete
    const onStripeCardDelete = async () => {
        setIsDeleteCard(true);
        try {            
            const data = await stripeCardDelete({
                cardId:deleteCardId,
                total:totalCard
            });  
            if(data.status === 200) {
                closeOneModal2();
                setDeleteCardId('');
                setIsDeleteCard(false);
                setCards(data.data);
                setTotalCard(data.data.length);    
            }else{
                setIsDeleteCard(false);
                setDeleteCardId('');
                toastr.error(data.message);
            }
        } catch(err) {
            toastr.error(err.message);
            setIsDeleteCard(false);
            setDeleteCardId('');
        }
    }

      const closeOneModal2 = () => {
        const modal = document.getElementById('ModalCardDelete');
        modal.classList.remove('show');
        modal.removeAttribute("role");
        modal.removeAttribute("aria-modal");
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
        document.body.classList.remove('modal-open');
        document.body.style = '';       
      }
    
      const  closeOneModal = () => {
        const modal = document.getElementById('ModalTLCPaymentDetails');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
    }
    
    useEffect(() => {
      setNoOfPage(Math.ceil(totalDataCount / limit))
    }, [limit, totalDataCount]);
    
    const handlePageClick = (event) => {
      const newOffset = (event.selected * limit) % totalDataCount;
      console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setOffset(newOffset);
    };

    const dateFormat = issue_date => {
        let d = new Date(issue_date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${(mo<=9)?'0'+mo:mo} ${da}, ${ye}`;
    }    

    return (
        <>
        <div className='dashboard-list'>
        <div className='dashboard-list-inner'>
            <div className='ticket-list'>
            <div className='ticket-table tlc-tickets-list'>
                <div className='table-head'>
                    <div className='table-row header-row'>
                        <div className='table-cell cell-image'>
                        <div className='table-title'>Tickets</div>
                        </div>
                        <div className="table-cell cell-name">
                        <div className="table-title">Issue Date</div>
                        </div> 
                        <div className='table-cell cell-email'>
                        <div className='table-title'>Type</div>
                        </div>
                        <div className='table-cell cell-licence'>
                        <div className='table-title'>Fees</div>
                        </div>
                        <div className='table-cell cell-charge'>
                        <div className='table-title'>Amount</div>
                        </div>
                        <div className='table-cell cell-charge'>
                        <div className='table-title'>Point</div>
                        </div>
                        <div className='table-cell cell-view'>
                        <div className='table-title'>Manager</div>
                        </div>
                        <div className='table-cell cell-status'>
                            <div className='table-title'>Status</div>
                        </div>
                        <div className='table-cell cell-action'>
                            <div className='table-title'></div>
                            </div>
                        </div>
                    </div>
                    <div className='table-body'>
                    {
                        count >0 ? (
                        dashboard.length > 0 &&
                        dashboard.map((item, index) => (
                        <div className='table-row' key={index}>
                        <div className='table-cell cell-image'>
                            <div className='driver-content'>
                            <span className='driver-list-title'>
                                <NavLink to={'/ticket/'+item?._id}>
                                <strong>{item?.summons_number}</strong>
                                </NavLink>
                            </span>
                            </div>
                        </div>
                        <div className='table-cell cell-name'>
                            <div className='dashbord-date'>
                            {(item.issue_date && item.issue_date !=null)? dateFormat(item?.issue_date):(item.violation_date && item.violation_date !=null)?dateFormat(item?.violation_date):'--'}
                            </div>
                        </div>
                        <div className='table-cell cell-email'>
                            <div className='driver-list-title'>{(item.transaction && item.transaction.payment_type !=null)?item.transaction?.payment_type:'--'}</div>
                        </div>
                        <div className='table-cell cell-licence'>
                            <div className='driver-list-title'>${(item.transaction)?item.transaction?.amount:''}</div>
                        </div>                                    
                        <div className='table-cell cell-charge'>
                            <div className='driver-list-title'>{(item.ticket_assignee?.amount)?'$'+item.ticket_assignee?.amount:'--'}</div>
                        </div>
                        <div className='table-cell cell-charge'>
                            <div className='driver-list-title'>{(item.ticket_assignee?.point)?item.ticket_assignee?.point:'--'}</div>
                        </div>
                        <div className='table-cell cell-view mobile-view-part-image'>
                        <div className='mobile-title'>Manager</div>                              
                            {
                                ((item.manager_details) && (item.manager_details.image)) &&
                                ((item.ticket_assignee) && (item.ticket_assignee.status !== 'wating')) &&
                                <a 
                                href='javascript:void(0)'
                                className='driver-list-image'
                                data-toggle="tooltip"
                                data-placement="top"
                                title={item.manager_details.name}
                                >
                                <img src={item.manager_details.image} alt={item.manager_details.image}  onClick={() => setViewLightBox({ photo:item.manager_details.image, isOpen: true })}/>
                                <div className='manager-name'>{item.manager_details.name}</div>
                                </a>
                            }
                        </div>
                        <div className='table-cell cell-status'>
                            <div className='mobile-title'>Status</div>
                            <div className='cell-data'>
                            {
                                (tlcStatus && tlcStatus.length>0) &&
                                tlcStatus.map(item1 => (
                                    ((item.ticket_assignee) && (item.ticket_assignee.status === item1?.code)) &&
                                    <div className={`status-text ${
                                        (item.ticket_assignee.status === 'e-sign')?'guilty':
                                        (item.ticket_assignee.status === 'settlement' || item.ticket_assignee.status === 'settled-inprogress' || item.ticket_assignee.status === 'reduction-inprogress' || item.ticket_assignee.status === 'dismissed-inprogress' || item.ticket_assignee.status === 'settled-closed' || item.ticket_assignee.status === 'dismissed-closed' || item.ticket_assignee.status === 'reduction-complete' )?'inprogress':
                                        item1?.code}`}>{item1?.name}</div>
                                ))
                            }
                            {/* {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'e-sign')) && 
                                <div className='status-text guilty'>E sign</div>
                            }
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'waiting')) && 
                                <div className='status-text waiting'>Waiting</div>
                            }
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'assigned')) && 
                                <div className='status-text assigned'>Assigned</div>
                            }
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'inprogress')) && 
                                <div className='status-text inprogress'>In progress</div>
                            }
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'reduce')) && 
                                <div className='status-text reduce'>Reduce</div>
                            }
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'dismiss')) && 
                                <div className='status-text dismiss'>Dismiss</div>
                            } */}
                            </div>
                        </div>
                        <div className='table-cell cell-action'>
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'settlement' || item.ticket_assignee.status === 'reduce' || item.ticket_assignee.status === 'dismiss')) ? 
                                (item.ticket_assignee?.amount && item.ticket_assignee?.amount>0)?
                                <button 
                                type='button' 
                                className='view-btn' 
                                onClick={() => {
                                setCurrentTicketId(item?.summons_number); 
                                setCurrentStatus(item.ticket_assignee?.status);
                                setCurrentPrice(item.ticket_assignee?.amount);
                                setCurrentPoint(item.ticket_assignee?.point);
                                setIsModalTLCPaymentDetails(true);
                                }}
                                >{(item.ticket_assignee?.amount)?'Accept & Pay $'+item.ticket_assignee?.amount:'Pay Now'}</button>:
                                (item.ticket_assignee && item.ticket_assignee?.amount === 0 && item.ticket_assignee?.point >0)?
                                <button 
                                type='button' 
                                className='view-btn' 
                                onClick={() => {
                                setCurrentTicketId(item?.summons_number); 
                                setCurrentStatus(item.ticket_assignee?.status);
                                setCurrentPrice(item.ticket_assignee?.amount);
                                setCurrentPoint(item.ticket_assignee?.point); 
                                setTimeout( handleStatusActive(item?.summons_number,item.ticket_assignee?.status), 5000)                                    
                                }}
                                ><i className={(isAccecpt)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-check mr-2"}></i> Accept</button>:''
                                :''
                            }
                            {/* {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'reduce'))? 
                                (item.ticket_assignee?.amount && item.ticket_assignee?.amount>0 && item.ticket_assignee?.point)?
                                <button 
                                type='button' 
                                className='view-btn' 
                                onClick={() => {
                                setCurrentTicketId(item?.summons_number); 
                                setCurrentStatus(item.ticket_assignee?.status);
                                setCurrentPrice(item.ticket_assignee?.amount);
                                setCurrentPoint(item.ticket_assignee?.point);                                    
                                }}
                                data-toggle="modal" 
                                data-target="#ModalTLCPaymentDetails" 
                                >{(item.ticket_assignee?.amount)?'Accept & Pay $'+item.ticket_assignee?.amount:'Pay Now'}</button>:
                                (item.ticket_assignee && item.ticket_assignee?.amount === 0 && item.ticket_assignee?.point >0)?
                                <button 
                                type='button' 
                                className='view-btn' 
                                onClick={() => {
                                setCurrentTicketId(item?.summons_number); 
                                setCurrentStatus(item.ticket_assignee?.status);
                                setCurrentPrice(item.ticket_assignee?.amount);
                                setCurrentPoint(item.ticket_assignee?.point); 
                                setTimeout( handleStatusActive(), 2000)
                                }}
                                ><i className={(isAccecpt)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-check mr-2"}></i> Accept</button>:''
                                :''
                            } */}
                            
                            {
                                ((item.ticket_assignee) && (item.ticket_assignee.status === 'e-sign')) &&
                                <NavLink 
                                to={"/authorization-form/"+item?.summons_number}
                                type='button' 
                                className='view-btn'
                                ><i className="fa-solid fa-plus mr-2"></i> Add</NavLink>
                            }
                        </div>
                        </div>
                        ))
                        ):(loading?<Searching/>:<NothingFound/>)
                    }                                       
                    </div>
            </div>
            {
                noOfPage > 1 &&
                <div className='pagination-inner'>
                    <nav aria-label="Page navigation example">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={noOfPage}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                    </nav>
                </div>
            }
            </div>
        </div>
        </div>
        { isModalTLCPaymentDetails &&
          <div className="modal fade career-apply-modal show d-block" id="ModalTLCPaymentDetails" tabIndex="-1" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="header-text">
                            <h5 className="modal-title" id="ModalCenterTitle">Payment Details</h5>
                        </div>
                        <button type="button" className="modal-close" data-dismiss="modal" onClick={closeModalTLCPaymentDetails}><i
                            className="fa-light fa-fw fa-times"></i></button>
                    </div>
                    <div className="modal-body payment-modal-part">
                        <>
                        <div className='container-fluid'>
                        <div className='row'>
                        <div className='col-lg-12'>
                        <div className='payment-heading'>
                        <div className="payment-option">
                        <div className="title">Coupon</div>
                        <div className="payment-summary">
                        <div className="summary-list">
                        <div className="item ticket-info">
                        <div className="ticket-input d-flex gap-3 align-items-center">
                        <input
                        className="input"
                        type="text"
                        placeholder="Coupon"
                        defaultValue=""
                        style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 16px" }}
                        />
                        <button type="button" className="btn btn-success px-4 py-6 ml-4">
                        Apply
                        </button>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>

                        <div className='payment-option'>
                        <div className="title">Payment Summary</div>
                        <div className="payment-summary">
                        <div className="summary-list">
                        <div className="item">
                        <div className="item-label">Sub total:</div>
                        <div className="item-data">${currentPrice}</div>
                        </div>
                        {
                        credit_card_fees >0 &&
                        <div className="item">
                        <div className="item-label">Credit Card Fee:</div>
                        <div className="item-data">${credit_card_fees}</div>
                        </div>
                        }
                        {
                        platform_fees >0 &&  
                        <div className="item">
                        <div className="item-label">Platform Fee:</div>
                        <div className="item-data">${platform_fees}</div>
                        </div>
                        }                                                           
                        </div>
                        <div className="summary-total">
                        <div className="label">Total Amount</div>
                        <div className="data">${totalprice}</div>
                        </div>
                        </div>
                        </div>
                        {/* <p>**Please Pay ${totalprice} Amount for Dismiss ticket </p> */}
                        </div>
                        </div>
                        <div className='col-lg-5'>
                        {
                        cards && cards.length>0 && 
                        <div className="payment-select">
                        <div className="radio-group">
                        <div className="radio">
                        <input 
                        type="radio" 
                        className="radio-input" 
                        name="card-type"
                        value="existing"
                        checked={isExisting}
                        disabled={cards && cards.length === 0}
                        onChange={ handleExistChange }
                        />
                        <div className="radio-label">
                        <div className="text">Existing Card</div>
                        <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                        </div>
                        </div>
                        <div className="radio">
                        <input 
                        type="radio" 
                        className="radio-input" 
                        name="card-type"
                        value="new"
                        checked={!isExisting}
                        onChange={ handleExistChange }
                        />
                        <div className="radio-label">
                        <div className="text">New Card</div>
                        <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                        </div>
                        </div>
                        </div>
                        </div>
                        }
                        {
                        isExisting?
                        <div className="saved-cards">
                        <div className="title">Saved Cards</div>
                        <div className="card-list">
                        <div className="card-item">
                        {
                        cards.length > 0 &&
                        cards.map((item, index) =>
                        <div className="radio" key={index}>
                        <input 
                        type="radio" 
                        className="radio-input" 
                        name="saved-cards" 
                        value={item?.id}
                        checked={cardId === item?.id}
                        onChange={ handleCardChange }
                        />
                        <div className="radio-label">
                        <div className="label-icon">
                        {
                        (item.card?.brand && item.card?.brand === 'visa') &&
                        <div className="icon"><i className="fa-brands fa-fw fa-cc-visa"></i></div>
                        }
                        {
                        (item.card?.brand && item.card?.brand === 'amex') &&
                        <div className="icon"><i className="fa-brands fa-fw fa-cc-amex"></i></div>
                        }
                        {
                        (item.card?.brand && item.card?.brand === 'mastercard') &&
                        <div className="icon"><i className="fa-brands fa-fw fa-cc-mastercard"></i></div>
                        }
                        </div>
                        <div className="card-content">
                        <div className="card-number">
                        <div className="data">**** **** **** {item?.card?.last4}</div>
                        </div>
                        <div className="card-details">
                        <div className="expiry-date">
                        <div className="card-text">Expires on</div>
                        <div className="data">{item?.card?.exp_month}/{item?.card?.exp_year}</div>
                        </div>
                        </div>
                        </div>
                        <button 
                        className='btn-delete-action' 
                        type='button'
                        data-toggle="modal" 
                        data-target="#ModalCardDelete"
                        onClick={() => { setDeleteCardId(item?.id)} } 
                        ><i class="far fa-trash-alt"></i>
                        </button>
                        </div>
                        </div>
                        )
                        }
                        </div>
                        {
                        cardId &&
                        <Elements stripe={stripePromise}>
                        <CvcFrom
                        type="3" 
                        price={totalprice}
                        ticket_id={currentTicketId}
                        payment_for={currentStatus}
                        payment_method={cardId}
                        />
                        </Elements>
                        }                                                  
                        </div>
                        </div>
                        :''
                        }
                        {
                        !isExisting?
                        <div className='payment-card'>
                        <Elements stripe={stripePromise}>
                        <CheckoutForm 
                        type="3" 
                        price={totalprice}
                        ticket_id={currentTicketId}
                        payment_for={currentStatus}
                        />
                        </Elements>
                        </div>:''
                        }
                        </div>
                        <div className='col-lg-2'>
                        <div className='pay-or'>
                        or
                        </div>
                        </div>
                        <div className='col-lg-5'>
                        <div className='payment-mode'>
                        <PayPalButton
                        amount={totalprice}
                        onSuccess={(details, data) => {
                        const loadPaypalData = async () => {
                        try {            
                        const data1 = await storeTlcPaypalData({
                        ticket_no:currentTicketId,
                        paymentData:data,
                        paymentDetails : details,
                        payment_for: currentStatus
                        });  
                        if(data1.status == 200) {
                        //closeOneModal();
                        return navigate("/success?txn="+ data1?.data?.transaction_id);         
                        }    
                        } catch(err) {
                        console.log(err.message);
                        }
                        }
                        loadPaypalData();
                        }}
                        options={{
                        clientId: paypalClientId()
                        }}
                        />
                        </div>
                        </div>
                        </div>                          
                        </div>
                        </>                     
                    </div>
                </div>
            </div>
          </div>
        }
        {  isOpen && (<ImageShowModal photo={photo} isOpen={isOpen} setViewLightBox={setViewLightBox}/>)}
        <div className="modal fade career-apply-modal delete-modal " id="ModalCardDelete" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content delete">
                <div className="modal-header">
                    <h5 className="modal-title">Card Delete</h5>
                    <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                    <i className="fal fa-fw fa-times"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-text">Are you want to delete this card, Click "Confirm" button.</div>
                </div>
                <div className="modal-footer justify-content-center">
                    <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                    Cancel
                    </button>
                    <button className="confirm-btn" disabled={isDeleteCard} onClick={onStripeCardDelete}>
                    <i className={(isDeleteCard)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                    Confirm
                    </button>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default SecTlcTickets;
import React from 'react';

const HeroSection = ({settings,banners}) => {
  const scrollToBottom = () => {
    const element = document.getElementById('searchDiv').scrollIntoView({
      behavior: 'smooth'
    });
    //element.scrollTop = element.scrollHeight;
  }
  return (
    <section className="hero">
    <div className="container">
      <div className="row justify-content-between align-items-center">       
        <div className="col-md-7">
          <h1 className="hero-title">{banners?.title}</h1>
          {/* <div className="hero-text"></div> */}
          <div className="banner-content-list" dangerouslySetInnerHTML={{__html:banners?.description}}>
            {/* <ul className="list">
              <li className="item">Settle or dispute TLC summons.</li>
              <li className="item">Pay your DMV ticket.</li>
              <li className="item">Monitor your vehicles and licenses.</li>
            </ul> */}
          </div>          
          <div className="banner-download">
            {
              settings?.playstore_link &&
              <a className="download-item" target="_blank" href={settings?.playstore_link}>
                <div className="icon"><img src="assets/img/google.svg" alt=""/></div>
                <div className="title">Google Play</div>
              </a>
            }
            {
              settings?.appstore_link && 
              <a className="download-item" target="_blank" href={settings?.appstore_link}>
                <div className="icon"><img src="assets/img/apple.svg" alt=''/></div>
                <div className="title">Apple Store</div>
              </a>
            }
            
          </div>
          <div className='hero-action'>
              <button type="button" className="btn-action" onClick={()=> scrollToBottom()}>Pay Ticket Now</button>
          </div>
        </div>
        <div className="col-md-5">
          <div className="banner-image">
            <div className="img-one-inner"><img src={banners?.image} alt=''/></div>
            <div className="img-two-inner"><img src={banners?.image2} alt=''/></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default HeroSection;
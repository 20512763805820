import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getDashboardData, ticketAssigneeDetails } from '../../actions/ticket';

const SecDashboardCount = ({ }) => {
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const [countOpenTicket, setCountOpenTicket] = useState(0);
  const [countCloseTicket, setCountCloseTicket] = useState(0);
  const [countDmvTicket, setCountDmvTicket] = useState(0);
  const [count, setCount] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [signature, setSignature] = useState([]);

  useEffect(() => {
    // Dashboard 
    const loadDashboardData = async () => {
      try {
        const data = await getDashboardData({
          ticket_type: 'tlc',
          limit: limit,
          offset: offset
        });
        if (data.status == 200) {
          setDashboard(data.data)
          setCountOpenTicket(data.countOpenTicket);
          setCountCloseTicket(data.countCloseTicket);
          setCountDmvTicket(data.countDmvTicket);
          setCount(data.count);
        }
        setTotalDataCount(data.count);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    }
    loadDashboardData();

    // Ticket signature
    const loadTicketSignatureData = async () => {
      try {
        const data = await ticketAssigneeDetails({});
        if (data.status == 200) {
          setSignature(data.data)
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    loadTicketSignatureData();
  }, [limit, offset]);


  return (
    <>
      <div className="dashboard-stats">

        <div className='dashboard-item blue'>
          <div className='dashboard-icon'>
            <i className="fa-light fa-ticket"></i>
          </div>
          <div className='dashboard-content'>
            <div className='dashboard-title'>Open Tickets</div>
            <div className='dashboard-text'>{countOpenTicket}</div>
          </div>
        </div>
        <div className='dashboard-item red'>
          <div className='dashboard-icon'>
            <i className="fa-light fa-rectangle-xmark"></i>
          </div>
          <div className='dashboard-content'>
            <div className='dashboard-title'>Closed Tickets</div>
            <div className='dashboard-text'>{countCloseTicket}</div>
          </div>
        </div>
        <div className='dashboard-item yellow'>
          <div className='dashboard-icon'>
            <i className="fa-light fa-ban-parking"></i>
          </div>
          <div className='dashboard-content'>
            <div className='dashboard-title'>DMV Tickets</div>
            <div className='dashboard-text'>{countDmvTicket}</div>
          </div>
        </div>
        <div className='dashboard-item green'>
          <div className='dashboard-icon'>
            <i className="fa-light fa-traffic-light-stop"></i>
          </div>
          <div className='dashboard-content'>
            <div className='dashboard-title'>TLC Tickets </div>
            <div className='dashboard-text'>{count}</div>
          </div>
        </div>
      </div>
      {/* <div className='col-lg-12'>
                  <div className='dashboard-action'>
                  <a href="/" className="driving-btn">Sign Up for Defensive Driving Course</a> 
                  <a href="/" className="driving-btn">Request Motor Vehicle Report</a> 
                  </div>
              </div> */}
      <div className="dashboard-stats">
        {
          (signature && signature.length > 0) &&
          <div className='dashboard-action'>
            <div className="alert alert-warning" role="alert">
              <h4 className="alert-heading">You have {signature.length} pending tickets</h4>
              {
                signature.map((item, index) => (
                  <>
                    <NavLink
                      key={index}
                      to={"/authorization-form/" + item?.ticket_id}
                      class="alert-link"
                    ><strong>{item?.ticket_id}</strong></NavLink>
                    &nbsp;&nbsp;
                  </>
                ))
              }
              &nbsp; To continue click on the ticket no
            </div>
          </div>
        }
      </div>
    </>
  )
};

export default SecDashboardCount;
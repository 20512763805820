import React from 'react';
import SecCart from '../components/Cart/SecCart';

const Cart = () => {
  return (
    <section className='sec-ticket'>
        <div className='container'>
          <div className='row'>                
            <div className='col-lg-12 col-xl-12'>
              <SecCart/>
            </div>
          </div>
        </div>
    </section>
  );
};

export default Cart;
import { CART_LIST, CART_DELETE } from "../actions/type";

const cartReducer = (state = {}, action) => {
  switch(action.type) {
    case CART_LIST:
      return { status: true, count: action.payload };
    case CART_DELETE:
      return { status: true, count: action.payload }
    default:
      return state;
  }
}

export default cartReducer;
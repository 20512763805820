import React ,{useEffect}from 'react';
import SecPlateList from '../components/Plates/SecPlateList';
//import SecPlateAdd from '../components/Plates/SecPlateAdd';
//import SecPlateEdit from '../components/Plates/SecPlateEdit';
import UserSidepanel from '../shared/userSidepanel';
import { 
  checkDriverVehicle
 } from '../actions/site';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
const Plates = () => {
  const dispatch=useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem('access_token')){
    dispatch(  async () => {
      try {            
        const data = await checkDriverVehicle();
        if(data.status == 200) {
          if(data.step==1){
            return navigate("/add-driver");
          }else if(data.step==2){
            return navigate("/add-plate");
          }
         
        }else{
          return navigate("/add-driver");
        }
        
      } catch(err) {
        console.log(err.message);
      }
    })
    }
   
  }, [dispatch,window.location.pathname]); 
  return (
    <section className='sec-panel login-part-pages'>
    <div className='container'>
      <div className='row user-panel-menus'>
      <div className='left-sidebar'>
        <UserSidepanel/>
        </div>
        <div className='right-sidebar'>
            <SecPlateList/>
            {/* <SecPlateAdd/>
            <SecPlateEdit/> */}
        </div>
      </div>
    </div>
    </section>
   
  );
};

export default Plates;
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  getPageDetails,
  getSeo
} from '../actions/site';
import {Helmet} from "react-helmet";
const TermsOfService = () => {
  const [seo,setSeo] = useState({});
  const [page,setPages] = useState({});
  const [pageId,setPageId] = useState({});
  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'terms-of-service'
        });  
        if(data.status == 200) {
          setPages(data.data)
          setPageId(data.data._id)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();
  },[]);
  useEffect(() => {
    //Seo
    const loadSeoData = async () => {
        try {            
          const data = await getSeo({
            type:'page',
            typeId:pageId,
          });  
          if(data.status == 200) {
              setSeo(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSeoData();
  },[pageId])
  return (<>
    <Helmet>
    <meta charSet="utf-8" />
    <meta name="title" content={seo?.meta_title}></meta>
    <meta name="author" content={seo?.author}></meta>
    <meta name="keywords" content={seo?.keywords}></meta>
    <meta name="description" content={seo?.description}></meta>
    </Helmet>
    <section className="terms-section">
      <div className="container">
        <div className="title-part">
          <h2 className="title">{page?page.title:'Terms & Conditions'}</h2>
        </div>
        <div className="row">
          <div className="col-md-12" dangerouslySetInnerHTML={{__html:page?.description}}>
            {/* <h6 className="terms-sub-head">General Terms and Conditions</h6>
            <div className="content-part">
              <div className="points">
                <h6 className="text">1. ACCEPTANCE OF TERMS</h6>
                <div className="description">
                  <p>Welcome to the Defendit, Inc. (“Defendit,” “Company,” “we,” “us,” and “our”) website (the “Website”). These General Terms and Conditions are governed by our Terms of Service. Our Terms of Service take precedence over any conflicting General Terms and Conditions provision. By using Defendit’s services and website, you consent to the terms and conditions of these general terms and conditions. If you do not agree to these general terms and conditions please do not use the website and/or services.</p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">2. ACCURACY AND COMPLETENESS OF INFORMATION</h6>
                <div className="description">
                  <p>While Defendit strives to ensure that the information contained on the Website is accurate and reliable, Defendit makes no warranties as to the accuracy, correctness or completeness of any such information and assumes no liability or responsibility for any omissions or errors in the content of the Website. Defendit reserves the right to revise the information contained on the Website at any time, in its sole discretion, without any obligation to notify past, current or prospective visitors.</p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">3. YOUR USE OF THE WEBSITE</h6>
                <div className="description">
                  <p>You may download certain content that appears on the Website for your personal use, provided you do not remove or modify any copyright, trademark or other proprietary notices. You expressly agree that no right, title or interest in any downloaded materials is transferred to you as a result of such downloading or copying. There are inherent dangers in downloading materials and information from the Internet, and Defendit cautions you to make sure that you completely understand the potential risks before downloading any such content. You are solely responsible for adequate protection and backup of the data and equipment used in connection with any information downloaded from the Website, and Defendit will not be liable for any damages that you may suffer as a result of such download.</p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">4. CHANGES TO THE GENERAL TERMS AND CONDITIONS</h6>
                <div className="description">
                  <p>We reserve the right to change these General Terms and Conditions from time to time without notice by posting the changes to the Website. When we do, we will also revise the “last update” date of these General Terms and Conditions.</p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">4. CHANGES TO THE GENERAL TERMS AND CONDITIONS</h6>
                <div className="description">
                  <p>We reserve the right to change these General Terms and Conditions from time to time without notice by posting the changes to the Website. When we do, we will also revise the “last update” date of these General Terms and Conditions.</p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">5. PRIVACY POLICY</h6>
                <div className="description">
                  <p>Defendit’s use of any personally identifiable information you submit to the Website is governed by the Website’s ,<NavLink to="/privacy-policy">Privacy Policy</NavLink>.</p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">6. DISCLAIMERS</h6>
                <div className="description">
                  <p>The information on the website is provided “as is” without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</p>
                  <p>
                    No advice or information, whether oral or written, obtained by a user from Defendit, shall create any warranty not expressly made herein.
                  </p>
                  <p>
                    If you download or copy any material from the Website, you do so at your sole discretion and risk. Consequently, you will be responsible for any damage to your computer system or loss of data that results from the download or copying of any material or software.
                  </p>

                  <p>
                    Some of Defendit’s Services require the use of third party services, products or networks. Defendit will make commercially reasonable efforts to communicate any policies, requirements or guidelines Of those third parties to you. You agree to follow those policies, requirements or guidelines. Any actual or alleged violation of a third party policy, requirement or guideline by you is your responsibility. Defendit makes no warranty or representation that its website or services will be uninterrupted, timely, secure or error-free if any violation of a third party policy, requirement or guideline by you is alleged by a third party.
                  </p>
                </div>
              </div>
              <div className="points">
                <h6 className="text">7. PRODUCTS AND SERVICES</h6>
                <div className="description">
                  <p>All products (including software) and services of Defendit may only be accessed and used pursuant to a separate product or service agreement. If you have accessed such products or services prior to entering into a separate product or service agreement, such access is in violation of Defendit’s General Terms and Conditions, and you shall immediately cease using such services or delete any such products from your computer or server until such time as you become an authorized user of such products or services.</p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">8. LIMITATION OF LIABILITY</h6>
                <div className="description">
                  <p>In no event will Defendit be liable for any direct, indirect, punitive, special, incidental or consequential damages however they may arise, whether in an action of contract, negligence or other tortious action, arising out of or in connection with the: (i) use of the information contained on the website; (ii) use of software downloaded or linked to from the website; or (iii) for the failure to provide services or information available from the website, even if Defendit has been previously advised of the possibility of such damages.</p>
                  <p>
                    In the event you have any dispute with one or more third parties as a result of your use of the website, content or services, or are in any way damaged as a result of any third party action in connection therewith, you hereby release and covenant not to sue or otherwise make a claim, demand or file any legal action or institute any legal or regulatory proceedings against Defendit, its affiliates, officers, directors, employees, agents, representatives and suppliers, for any claims, actions, demands or damages (whether direct, indirect, special, incidental or consequential), of whatever kind or nature, known or unknown, suspected or unsuspected, whether foreseeable or not, disclosed or undisclosed.
                  </p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">9. INDEMNIFICATION</h6>
                <div className="description">
                  <p>You agree to defend, indemnify and hold Defendit harmless from and against any and all third party claims, damages, costs and expenses, including reasonable attorney’s fees, arising from or related to your use of the Website.</p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">10. LINKS TO THIRD PARTY WEBSITES</h6>
                <div className="description">
                  <p>Any links to third party sites are provided as a convenience to you, and such sites are neither owned nor operated by Defendit. Defendit has no control over these linked sites, is not responsible for the content of these sites, makes no representations or warranties with respect to these linked sites, and shall not be liable for any damages or injury arising from the contents of these linked sites. Your viewing and use of any third party sites is at your sole discretion and risk.</p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">11. INTELLECTUAL PROPERTY NOTICE</h6>
                <div className="description">
                  <p>Unless otherwise noted, the graphic images, buttons and text contained in the Website are the exclusive property of Defendit. All rights reserved. Except for your personal use as permitted herein, these items may not be copied, displayed, transmitted or reproduced in any form without the express written permission of Defendit.</p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">12. SUBMISSIONS, SUGGESTIONS & COMMUNITY PARTICIPATION</h6>
                <div className="description">
                  <p>In the event you elect to communicate to us suggestions for improvements to the Website or any of Defendit’s services or properties (collectively, “Feedback”), we shall own all right, title, and interest in and to the same, even if you have designated the Feedback as confidential, and we shall be entitled to use the Feedback without restriction. Furthermore, any other content or information you post or provide to Defendit via comments, forums, emails and the like (collectively, “Communications”) shall be considered the property of Defendit. You hereby irrevocably assign all right, title and interest in and to the Feedback and Communications to us and agree to provide us such assistance as we may require to document, perfect and maintain our rights to the Feedback and Communications.</p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">13. TRADEMARK NOTICE</h6>
                <div className="description">
                  <p>Defendit and the Defendit logo are trademarks of Defendit.com, Inc.</p>
                  <p>Other company, product, and service names mentioned in these documents may be trademarks and/or service marks of others.</p>

                </div>
              </div>
              <div className="points">
                <h6 className="text">14. LAST UPDATED</h6>
                <div className="description">
                  <p>February 23, 2022</p>
                </div>
              </div>
            </div>
            <h6 className="terms-sub-head">Defendit Terms of Service</h6>
            <div className="content-part">
              <p>
                In order to use the Defendit.com, Inc. (“Defendit,” “Company,” “we,” “us,” and “our”), access the information contained on the Defendit website (the “Website”) or make use of the services provided by Defendit (the “Service” or “Services”), you (hereinafter referred to as “You,” “Your,” and ‘Yours”) must first read this agreement (the “Agreement”) and accept it. You may not avail Yourself of the Defendit Website or Services if You do not accept this Agreement and the terms therein. You accept the terms of the Agreement by clicking to accept, by agreeing to the terms of the Agreement in the user interface for any Defendit Service. In the latter case, you understand and agree that we will treat your use of the services as acceptance of the terms from that point onwards. By using the services or the website, you consent to the terms and conditions of these terms of service. If you do not agree to these terms of service please do not use the website, api and/or services.
              </p>
              <p>
                By submitting a ticket to Defendit, You represent that You have read and agree to the terms and conditions of this Agreement, which also include and incorporate Defendit’s Acceptable Use Policy, General Terms and Conditions and <NavLink to="/privacy-policy">Privacy Policy </NavLink>. These terms and conditions will remain in effect throughout Your use of the Services. These terms and conditions are legally binding should You choose to register for the Service. You may not use the Services and may not accept the terms if (a) You are not of legal age to form a binding contract with us, or (b) You are a person barred from receiving the Services under the laws of the United States or other countries including the country in which You are resident or from which You use the Services. You agree to incorporate these Defendit policies into Your own products and policies and ensure that Your customers adhere to Defendit’s policies.
              </p>
              <p>
                If You are accepting on behalf of Your employer or another entity, You represent and warrant that: (i) You have full legal authority to bind your employer, or the applicable entity, to these terms of service; (ii) You have read and understand this Agreement; and (iii) You agree, on behalf of the party that You represent, to this Agreement. If You don’t have the legal authority to bind Your employer or the applicable entity, please do not submit a ticket or use Defendit’s Services. We reserve the right to change these Terms of Service from time to time without notice by posting them to the Website. When we do, we will also revise the “last update” date of these Terms of Service. Your continued use of the Service after such posting will constitute acceptance by You of such amendments.
              </p>
              <div className="points">
                <h6 className="text">1. GRANT OF RIGHTS TO USE SERVICES</h6>
                <div className="description">
                  <p><span>1.1</span>  Defendit is a SaAs firm, not a Law Firm, does not practice law, and does not give legal advice. Therefore, we:</p>
                  <ul>
                    <li>
                      don’t act as your attorneys;
                    </li>
                    <li>are not a substitute for an attorney’s advice;</li>
                    <li>don’t have an attorney-client relationship with you; and,</li>
                    <li>all information you provide us, while protected by our Privacy Policy, is not protected by attorney-client privilege or as attorney work product.</li>
                  </ul>
                  <p>
                    We do not review any information you provide us for legal accuracy or sufficiency, draw legal conclusions, provide opinions about your answers on the online questionnaire, or apply the law to the facts of your situation.
                  </p>
                  <p>
                    Defendit is not an attorney, may not perform legal services that an attorney performs, and is not permitted to engage in the practice of law, including providing any kind of advice, explanation, opinion, or recommendation about possible legal rights, remedies, defenses, options, selection of forms, or strategies.
                  </p>
                  <p>
                    Defendit does not guarantee that your ticket will be dismissed by using the Defendit service, because, ultimately the decision is that of the particular Administrator reviewing your case, not Defendit.
                  </p>
                  <p><span>1.2</span>Subject to Your acceptance of and compliance with this Agreement and with the payment requirements for the Services, Defendit hereby grants You a limited, non-exclusive, non-transferable, non-sublicensable, revocable right and license during the Term of this Agreement under our intellectual property rights, to access and use the Services, solely in accordance with the terms and conditions of this Agreement. Unless explicitly stated otherwise, any new features provided by Defendit that augment or enhance the current Services shall also constitute “Services” and shall be subject to these terms and conditions. You may not, nor allow any third party to, copy, distribute, sell, disclose, lend, transfer, convey, modify, decompile, disassemble or reverse engineer the Services for any purpose whatsoever. You may not allow any unauthorized third party to access the Services for any purpose whatsoever. All rights not expressly granted under this Agreement are retained by Defendit.
                  </p>
                  <p><span>1.3</span>
                    You may not remove, obscure or alter any notice of any Defendit trademark, service mark or other intellectual property or proprietary right appearing on the Website or contained within the Services.
                  </p>
                  <p><span>1.4</span>We may make available to You, for Your installation, copying and/or use in connection with the Services, from time to time, a variety of software, data and other content and printed and electronic documentation (the “Defendit Properties”). Subject to Your acceptance of and compliance with this Agreement, ongoing compliance with its terms and conditions with respect to the subject Service and payment if and as required for Your right to use the subject Service, Defendit hereby grants to You a limited, non-exclusive, non-transferable, non-sublicensable, revocable license during the Term of this Agreement under our intellectual property rights in the Defendit Properties only to install, copy and use the Defendit Properties solely in connection with and as necessary for Your use of such Services, solely in accordance with the terms and conditions of this Agreement.</p>
                  <p>
                    <span>1.5</span>You may not, and may not attempt to, reverse engineer, disassemble, or decompile the Defendit Properties or the Services or apply any other process or procedure to derive the source code of any software included in the Defendit Properties.
                  </p>
                  <p>
                    <span>1.6</span>For purposes of this Agreement, Defendit hereby grants to You a limited, non-exclusive, non-transferable, non-sub-licensable, revocable license during the Term of this Agreement to display the trade names, trademarks, service marks, logos, domain names of Defendit (each, a “Defendit Mark”) for the purpose of promoting or advertising that You use the Services, solely in accordance with the terms and conditions of this Agreement. In return You hereby grant Defendit a limited, non-exclusive, non-transferable, non-sublicensable license during the Term of this Agreement to display Your trade names, trademarks, service marks, logos, domain names and the like for the purpose of promoting or advertising that You use the Services. In using Defendit Marks, You may not: (i) display a Defendit Mark in any manner that implies a relationship or affiliation with, sponsorship or endorsement by Defendit; (ii) use Defendit’s Marks to disparage Defendit or its products or services; or (iii) display a Defendit Mark on a site that violates any law or regulation. Notwithstanding the above, Defendit may determine in its sole discretion whether its marks may be used in connection with Your Application. Furthermore, we may modify any Defendit Marks provided to You at any time, and upon notice, You will use only the modified Defendit Marks and not the old Defendit Marks. Other than as specified in this Agreement, You may not use any Defendit Mark unless You obtain our prior written consent. All uses of the Defendit Trademarks and goodwill associated therewith shall inure to the benefit of Defendit.
                  </p>
                  <div className="points">
                    <h6 className="text">2. TERM, TERMINATION AND SUSPENSION</h6>
                    <div className="description">
                      <p>
                        <span>2.1</span>The term (“Term”) of this Agreement will commence once You accept this Agreement as provided above. The Agreement will remain in effect until terminated by You or Defendit in accordance with this Section 2.
                      </p>
                      <p>
                        <span>2.2</span>You may terminate this Agreement for any reason or no reason at all, at Your convenience, by closing Your account for any Service for which we provide an account closing mechanism.
                      </p>
                      <p>
                        <span>2.3</span>We may suspend Your right and license to use any or all Services or terminate this Agreement in its entirety (and, accordingly, cease providing all Services to You), for any reason or for no reason, at our discretion at any time by providing You thirty (30) days advance notice in accordance with the notice provisions set forth in Section 9 below. If Defendit determines that providing advance notice would negatively impact Defendit’s ability to provide Services, Defendit may suspend Your right and license to use any or all Services or terminate this Agreement in its entirety (and, accordingly, cease providing all Services to You), with no notice.
                      </p>
                      <p>
                        <span>2.4</span>We may suspend Your right and license to use the Service or terminate this Agreement in its entirety (and, accordingly, your right to use the Service), for cause effective as set forth below:
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span>2.4.1</span>Immediately upon our notice to You in accordance with the notice provisions set forth in Section 9 below if: (i) You violate any provision of the Acceptable Use Policy or we have reason to believe that You have violated the Acceptable Use Policy; (ii) we determine, in our sole discretion, that our provision of any of the Services to You is prohibited by applicable law or has become impractical or unfeasible for any legal or regulatory reason; or (iii) subject to applicable law, upon Your liquidation, commencement of dissolution proceedings, disposal of Your assets, failure to continue Your business, assignment for the benefit of creditors or if You become the subject of a voluntary or involuntary bankruptcy or similar proceeding.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>2.4.2</span>Immediately and without notice if You are in default of any payment obligation with respect to any of the Services or if any payment mechanism You have provided to us is invalid or charges are refused for such payment mechanism.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>2.4.3</span>Ten (10) days following our provision of notice to you in accordance with the notice provisions set forth in Section 9 below if You breach any other provision of this Agreement and fail, as determined by us, in our sole discretion, to cure such breach within such 10-day period.
                          </p>
                        </li>
                      </ul>
                      <p>
                        <span>2.5</span>Effect of Suspension or Termination.
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span>2.5.1</span>Upon our suspension of your use of any Services, in whole or in part, for any reason: (i) fees will continue to accrue for any Services that are still in use by You, notwithstanding the suspension; (ii) You will remain liable for all fees, charges and any other obligations You have incurred through the date of suspension with respect to the Services; and (iii) all of Your rights with respect to the Services shall be terminated during the period of the suspension.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>2.5.2</span>Upon termination of this Agreement for any reason: (i) You will remain liable for all fees, charges and any other obligations You have incurred through the date of termination with respect to the Services; and (ii) all of Your rights under this Agreement shall immediately terminate.
                          </p>
                        </li>
                      </ul>
                      <p>
                        <span>2.6</span>In the event this Agreement expires or is cancelled or terminated for any reason, Sections 1.3, 1.6, 2.5, 6, 7, 8, 9 and 10 and any applicable definitions will survive any such expiration, cancellation or termination.
                      </p>

                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">3. TERM, TERMINATION AND SUSPENSION</h6>
                    <div className="description">
                      <p>
                        <span>3.1</span>In addition to our rights to terminate or suspend Services to You as described in Section 2 above, You acknowledge that: (i) Your access to and use of the Services may be suspended for the duration of any unanticipated or unscheduled downtime or unavailability of any portion or all of the Services for any reason, including as a result of power outages, system failures or other interruptions; and (ii) we shall also be entitled, without any liability to You, to suspend access to any portion of, or all of, the Services at any time, on a Service-wide basis: (a) for scheduled downtime to permit us to conduct maintenance or make modifications to any Service; (b) in the event of a denial of service attack or other attack on the Service or other event that we determine, in our sole discretion, may create a risk to the applicable Service, to You or to any of our other customers if the Service were not suspended; or © in the event that we determine that any Service is prohibited by applicable law or we otherwise determine that it is necessary or prudent to do so for legal or regulatory reasons (collectively, “Service Suspensions”). Without limitation to Section 6, we shall have no liability whatsoever for any damage, liabilities, losses (including any loss of data or profits) or any other consequences that You may incur as a result of any Service Suspension. To the extent we are able, we will endeavor to provide You notice of any Service Suspension in accordance with the notice provisions set forth in Section 9 below.
                      </p>
                      <p>
                        <span>3.2</span>We strive to keep information You provide to us secure, but cannot guarantee that we will be successful at doing so. Accordingly, without limitation to Section 6 below, You acknowledge that You bear sole responsibility for adequate security, protection and backup of Your content. We strongly encourage You, where available and appropriate, to use encryption technology to protect Your content from unauthorized access and to routinely archive Your content. We will have no liability to You for any unauthorized access or use, corruption, deletion, destruction or loss of any of Your content.
                      </p>
                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">4. FEES</h6>
                    <div className="description">
                      <p>
                        <span>4.1</span>To the extent the Services or any portion thereof are made available for any fee, you agree to pay all applicable fees as set forth here. We may increase or add new fees for any existing Service or Service feature in accordance with the notice provisions set forth in Section 9 below. All fees payable by You are exclusive of applicable taxes and duties.
                      </p>
                      <p>
                        <span>4.2</span>We may specify the manner in which You will pay any fees, and any such payment shall be subject to our general accounts receivable policies from time to time in effect. All amounts payable by You under this Agreement will be made without setoff or counterclaim and without deduction or withholding. If any deduction or withholding is required by applicable law, You shall notify us and shall pay such additional amounts to us as necessary to ensure that the net amount that we receive, after such deduction and withholding, equals the amount we would have received if no such deduction or withholding had been required. Additionally, You shall provide us with documentation that the withholding and deducted amounts have been paid to the relevant taxing authority.
                      </p>
                      <p>
                        <span>4.3</span>Should You have any dispute as to fees associated with Your account, please contact us at hello@Defendit.com within 90 days of the date of the activity that generated such dispute, and we will attempt to resolve the matter. Any and all refunds issued to resolve such a dispute shall be issued as credits to Your account, but in no event shall there be any cash refunds. Disputes older than 90 days shall not be entitled to any refunds or credits.
                      </p>
                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">5. INTELLECTUAL PROPERTY</h6>
                    <div className="description">
                      <p>
                        <span>5.1</span>Other than the limited use and access rights and licenses expressly set forth in this Agreement, we reserve all right, title and interest (including all intellectual property and proprietary rights) in and to: (i) the Services; (ii) the Defendit Properties; (iii) the Defendit Marks; and (iv) any other technology and software that we provide or use to provide the Services and the Defendit Properties. You do not, by virtue of this Agreement or otherwise, acquire any ownership interest or rights in the Services, the Defendit Properties, the Defendit Marks or such other technology and software, except for the limited use and access rights described in this Agreement.
                      </p>
                      <p>
                        <span>5.2</span>Other than the rights and interests expressly set forth in this Agreement and excluding any and all works derived from Defendit Properties, You reserve all right, title and interest (including all intellectual property and proprietary rights) in and to: (i) content and data You may send to us or use as part of Your use of any Services (“Your Content”); and (ii) Your Applications.
                      </p>
                      <p>
                        <span>5.3</span>In the event You elect, in connection with any of the Services, to communicate to us suggestions for improvements to the Services, the Defendit Properties or the Defendit Marks (collectively, “Feedback”), we shall own all right, title and interest in and to the same, even if You have designated the Feedback as confidential, and we shall be entitled to use the Feedback without restriction. Furthermore, any other content or information You post or provide to Defendit via comments, forums, emails and the like (collectively, “Communications”) shall be considered the property of Defendit. You hereby irrevocably assign all right, title and interest in and to the Feedback and Communications to us and agree to provide us such assistance as we may require documenting, perfect, and maintain our rights to the Feedback and Communications.
                      </p>
                      <p>
                        <span>5.4</span>During and after the Term of the Agreement, with respect to any of the Services that You elect to use, You will not assert, nor will You authorize, assist or encourage any third party to assert, against us or any of our customers, end users, vendors, business partners (including third party sellers on websites operated by or on behalf of us), sub-licensees or transferees, any patent infringement or other intellectual property infringement claim with respect to such Services.
                      </p>
                      <p>
                        <span>5.5</span>Defendit respects the intellectual property of others, and we ask our users to do the same. If You believe that Your work has been copied in a way that constitutes copyright infringement, or that Your intellectual property rights have been otherwise violated, you should notify Defendit of Your infringement claim in accordance with the procedure set forth below. Defendit will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws with respect to any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to Defendit’s Copyright Agent at info@defendit.com for (Subject line: “DMCA Takedown Request”). You may also contact us by mail at:
                      </p>
                      <p>36-13 32ndStreet, Astoria NY 11106</p>
                      <p>To be effective, the notification must be in writing and contain the following information:</p>
                      <ul>
                        <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
                        <li>a description of the copyrighted work or other intellectual property that You claim has been infringed;</li>
                        <li>a description of where the material that You claim is infringing is located on the Website, with enough detail that we may find it on the Website;
                        </li>
                        <li>	Your address, telephone number and email address;</li>
                        <li>a statement by You that You have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent or the law;</li>
                        <li>a statement by You, made under penalty of perjury, that the above information in Your Notice is accurate and that You are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
                      </ul>

                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">6. REPRESENTATIONS AND WARRANTIES; DISCLAIMERS; LIMITATIONS OF LIABILITY</h6>
                    <div className="description">
                      <p>
                        <span>6.1</span>You represent and warrant that You will not use or permit third parties, such as Your customers, to use the Website, Services, Defendit Properties, Defendit Marks, Your Application or Your Content in a manner that violates the Acceptable Use Policy. To this effect, we ask that You take reasonable precautions to promote best practices. Although Defendit does not assume the duty or obligation to monitor any materials created, posted or uploaded by You or any third parties, Defendit reserves the right, in its sole and absolute discretion, to monitor any and all materials posted or uploaded by You or any third parties at any time without prior notice to ensure that they conform to any usage guidelines or policies (including our Acceptable Use Policy) relating to our Website or Services.
                      </p>
                      <p>
                        <span>6.2</span>You represent and warrant: (i) that You are solely responsible for the development, operation, and maintenance of Your Application and for Your Content, including without limitation, the accuracy, appropriateness and completeness of Your Content and all product-related materials and descriptions; (ii) that You have the necessary rights and licenses, consents, permissions, waivers and releases to use and display Your Application and Your Content; (iii) that neither Your Application nor Your Content (a) violates, misappropriates or infringes any rights of us or any third party, (b) constitutes defamation, invasion of privacy or publicity, or otherwise violates any rights of any third party, or © is designed for use in any illegal activity or promotes illegal activities, including, without limitation, in a manner that might be libelous or defamatory or otherwise malicious, illegal or harmful to any person or entity, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation or age; (iv) that neither Your Application nor Your Content contains any harmful components; and (v) to the extent to which You use any of the Defendit Marks, that You will conduct Your business in a professional manner and in a way that reflects favorably on the goodwill and reputation of Defendit. You also represent and warrant that You are responsible for any charges incurred by virtue of Your use of the Application, no matter whether the Application acted in error.
                      </p>
                      <p>
                        <span>6.3</span>You represent and warrant that You have read and understood the Acceptable Use Policy, Privacy Policy and General Terms and Conditions, and You agree to abide by their terms, where applicable, including as incorporated by reference herein. You further agree to abide by all applicable local, state, national, foreign and international laws and regulations and that You will be solely responsible for all acts or omissions that occur under Your account or password. You agree not to violate any provisions of the Acceptable Use Policy. If You make the Services available to third parties, such as Your Customers, you must require such parties to agree to Terms of Use consistent with these Terms and to agree to abide by all applicable laws and regulations and to be solely responsible for all acts or omissions that occur under such party’s account or password.
                      </p>
                      <p>
                        <span>6.4</span>You represent and warrant that without Defendit’s express written consent You will not use, and will not authorize any third party to use, any Public Software (as defined below) in connection with the Services in any manner that requires, pursuant to the license applicable to such Public Software, that any Defendit Properties or Services be (a) disclosed or distributed in source code form, (b) made available free of charge to recipients, or © modifiable without restriction by recipients. With respect to any Feedback or Communications, You represent and warrant that such Feedback and Communications, in whole or in part, contributed by or through You, (i) is legally distributable by You, either because You own the copyright or because You have fully complied with any copyright terms associated with the software or content, (ii) contains no third party software or any software that may be considered Public Software and (iii) does not violate, misappropriate or infringe any intellectual property rights of any third party. “Public Software” means any software, documentation or other material that contains, or is derived (in whole or in part) from, any software, documentation or other material that is distributed as free software, open source software (e.g., Linux) or similar licensing or distribution models, including, but not limited to software, documentation or other material licensed or distributed under any of the following licenses or distribution models, or licenses or distribution models similar to any of the following: (i) GNU’s General Public License (GPL), Lesser/Library GPL (LGPL), or Free Documentation License, (ii) The Artistic License (e.g., PERL), (iii) the Mozilla Public License, (iv) the Netscape Public License, (v) the Sun Community Source License (SCSL), (vi) the Sun Industry Standards License (SISL), (vii) the BSD License and (viii) the Apache License.
                      </p>
                      <p>
                        <span>6.5</span>You represent and warrant that: (i) the information You provide in connection with Your registration for the Services is accurate and complete; (ii) You are duly authorized to do business in the jurisdiction where You operate; and (iii) You are an authorized representative of Your entity duly authorized to access the Services and to legally bind You to this Agreement and all transactions conducted under Your account.
                      </p>
                      <p>
                        <span>6.6</span>Defendit properties, the Defendit marks, the services and all technology, software, functions, content, images, materials and other data or information provided by us or our licensors in connection therewith (collectively the “service offerings”) are provided “as is.” We and our licensors make no representations or warranties of any kind, whether express, implied, statutory or otherwise with respect to the Defendit properties, the Defendit marks, the services or promotional credits. Except to the extent prohibited by applicable law, we and our licensors disclaim all warranties, including, without limitation, any implied warranties of merchantability, satisfactory quality, fitness for a particular purpose, non-infringement, title, quiet enjoyment and any warranties arising out of any course of dealing or usage of trade. We and our licensors do not warrant that the services or website will function as described, will be uninterrupted or error free, free of harmful components or that the data you store within the services will be secure or not otherwise lost or damaged. We and our licensors shall not be responsible for any service or website interruptions, including, without limitation, power outages, system failures or other interruptions. No advice or information obtained by you from us or from any third party or through the services shall create any warranty not expressly stated in this agreement.
                      </p>
                      <p>
                        <span>6.7</span>Neither we nor any of our licensors shall be liable to you for any direct, indirect, incidental, special, punitive, consequential or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other losses (even if we have been advised of the possibility of such damages) in connection with this agreement, including, without limitation, any such damages resulting from: (i) the use or the inability to use Defendit properties, the Defendit marks, the services or promotional credits; (ii) the cost of procurement of substitute goods and services; or (iii) unauthorized access to or alteration of your content. In any case, our aggregate liability under this agreement shall be limited to the amount actually paid by you to us hereunder for the services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some or all of the above exclusions or limitations may not apply to you and you may have additional rights.
                      </p>
                      <p>
                        <span>6.8</span>Third Party Materials: Certain content, products and services available via the Defendit website (or links contained therein) may include materials, software, plug-ins, applications and other resources from third parties and access to third party websites (collectively “Third Party Materials”). You acknowledge and agree that Defendit is not responsible for examining or evaluating the content or accuracy of any such Third Party Materials and that Defendit does not warrant or endorse and does not assume (and will not have) any liability or responsibility for any Third Party Materials or any damage or loss resulting therefrom. The availability of Third Party Materials is provided solely as a convenience to you. You agree that you must evaluate, and bear all risks associated with, the use of any Third Party Materials, including any reliance on the accuracy, completeness or usefulness thereof. Please also remember that all use of the Defendit website and service is subject to the Defendit Terms of Service.
                      </p>
                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">7. INDEMNIFICATION</h6>
                    <div className="description">
                      <p>
                        <span>7.1</span>You agree to indemnify, defend and hold us, our affiliates and licensors, each of our and their business partners (including third party sellers on websites operated by or on behalf of us) and each of our and their respective employees, officers, directors and representatives, harmless from and against any and all claims, losses, damages, liabilities, judgments, penalties, fines, costs and expenses (including reasonable attorney’s fees), arising out of or in connection with any claim arising out of: (i) Your use of the Services, Defendit Properties and/or Defendit Marks in a manner not authorized by this Agreement and/or in violation of the applicable restrictions, the Acceptable Use Policy and/or applicable law; (ii) Your Application, Your Content or the combination of either with other Applications, content or processes, including but not limited to any claim involving infringement or misappropriation of third-party rights and/or the use, development, design, manufacture, production, advertising, promotion and/or marketing of Your Application and/or Your Content; (iii) Your violation of any term or condition of this Agreement, the Acceptable Use Policy or any applicable additional policies, including without limitation, Your representations and warranties;, or (iv) You or Your employees’ or personnel’s negligence or willful misconduct.
                      </p>
                      <p>
                        <span>7.2</span>We agree to promptly notify You of any claim subject to indemnification; provided that our failure to promptly notify You shall not affect Your obligations hereunder except to the extent that our failure to promptly notify You delays or prejudices Your ability to defend the claim. At our option, You will have the right to defend against any such claim with counsel of Your own choosing (subject to our written consent) and to settle such claim as You deem appropriate, provided that You shall not enter into any settlement without our prior written consent and provided that we may, at any time, elect to take over control of the defense and settlement of the claim.
                      </p>
                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">8. DISPUTES</h6>
                    <div className="description">
                      <p>
                        <span>8.1</span>Notwithstanding anything to the contrary, we may seek injunctive or other relief in any state, federal or national court of competent jurisdiction for any actual or alleged infringement of Defendit’s or any third party’s intellectual property rights and/or proprietary rights. You further acknowledge that our rights in the Defendit Services, Defendit Properties and the Defendit Marks are of a special, unique, extraordinary character, giving them peculiar value, the loss of which cannot be readily estimated and may not be adequately compensated for in monetary damages.
                      </p>
                      <p>
                        <span>8.2</span>By using the Services, you agree that the laws of the State of New York, without regard to principles of conflicts of laws, will govern this Agreement and any dispute of any sort that might arise between You and us.
                      </p>
                      <p>
                        <span>8.3</span>Arbitration. You and Defendit both agree to resolve disputes only by arbitration. There is no judge or jury in arbitration, and the procedures may be different, but an arbitrator can award you the same damages and relief, and must honor the same terms in this agreement, as a court would. If the law allows for an award of attorney’s fees, an arbitrator can award them too. You and Defendit also both agree that:
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span>8.3.1</span>The federal arbitration act applies to this agreement. Any dispute that in any way relates to or arises out of this agreement or from any services you receive from us will be resolved by one or more neutral arbitrators before the american arbitration association (“AAA”). You can also bring any issues you may have to the attention of federal, state or local government agencies, and if the law allows, they can seek relief against us for you.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>8.3.2</span>Unless you and Defendit agree otherwise, the arbitration will take place in New York City, NY.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>8.3.3</span>If either you or Defendit intends to seek arbitration under this agreement, the party seeking arbitration must first notify the other party of the dispute in writing at least 30 days in advance of initiating the arbitration. Notice to Defendit should be sent as required by this agreement. The notice must describe the nature of the claim and the relief being sought. If you and Defendit are unable to resolve our dispute within thirty (30) days, either party may then proceed to file a claim for arbitration.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>8.3.4</span>An arbitration award and any judgment confirming it apply only to that specific case; it can’t be used in any other case except to enforce the award itself.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>8.3.5</span>If for some reason the prohibition on class arbitrations set forth in subsection 8.3.3 Cannot be enforced, then the agreement to arbitrate will not apply.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span>8.3.6</span>If for any reason a claim proceeds in court rather than through arbitration, you and Defendit agree that there will not be a jury trial. You and Defendit unconditionally waive any right to trial by jury in any action, proceeding or counterclaim arising out of or relating to this agreement in any way. In the event of litigation, this paragraph may be filed to show a written consent to a trial by the court. You and Defendit also agree that any dispute hereunder shall be adjudicated in any state or federal court in New York City, NY, and you consent to exclusive jurisdiction and venue in such courts.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">9. NOTICES</h6>
                    <div className="description">
                      <p>
                        <span>9.1</span>Notices made by us under this Agreement for You or Your account specifically (e.g., notices of breach and/or suspension) will be provided to You via the email address provided to us in Your registration for the Services or in any updated email address You provide to us. It is Your responsibility to keep Your email address current and You will be deemed to have received any email sent to any such email address, upon our sending of the email, whether or not You actually receive the email.
                      </p>
                      <p>
                        <span>9.2</span>For notices made by You to us under this Agreement and for questions regarding this Agreement or the Services, You may contact Defendit as follows: by US Postal Mail at
                      </p>
                      <p>
                        527 Howard Street, #200, San Francisco, CA 94105 or by contacting us at hello@Defendit.com.
                      </p>

                    </div>
                  </div>
                  <div className="points">
                    <h6 className="text">10. MISCELLANEOUS PROVISIONS</h6>
                    <div className="description">
                    <p>
                      <span>10.1</span><b>Responsibility.</b> If You authorize, assist, encourage or facilitate another person or entity to take any action related to the subject matter of this Agreement, You shall be deemed to have taken the action Yourself.
                    </p>
                    <p>
                      <span>10.2</span>
                      <b>Severability-</b> If any portion of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, the remaining portions of this Agreement will remain in full force and effect, and any invalid or unenforceable portions shall be construed in a manner that most closely reflects the effect and intent of the original language. If such construction is not possible, the provision will be severed from this Agreement and the rest of the Agreement shall remain in full force and effect.
                      
                    </p>
                    <p>
                      <span>10.3</span><b>Waivers.</b>The failure by us to enforce any provision of this Agreement shall in no way be construed to be a present or future waiver of such provision nor in any way affect our right to enforce such provision thereafter. All waivers by us must be in writing to be effective.
                    </p>
                    <p>
                      <span>10.4</span><b>Successors and Assigns. </b>This Agreement will be binding upon, and inure to the benefit of the parties and their respective successors and assigns.
                    </p>
                    <p>
                      <span>10.5</span><b>Entire Agreement</b>This Agreement incorporates by reference all policies and guidelines posted on the Defendit Website and as may be modified thereafter (including the Acceptable Use Policy, the General Terms and Conditions and the Privacy Policy) and constitutes the entire agreement between You and us regarding the subject matter hereof and supersedes any and all prior or contemporaneous representation, understanding, agreement or communication between You and us, whether written or oral, regarding such subject matter.
                    </p>
                    <p>
                      <span>10.6</span><b>No Endorsement. </b>You understand and acknowledge that we are not certifying nor endorsing, and have no obligation to certify or endorse, any of Your Applications or Your Content.
                    </p>
                    <p>
                      <span>10.7</span><b>Export and Import Control Laws and Regulations. </b>Services, content and product derived or obtained from Defendit’s Services may be subject to the U.S. export laws and the export or import laws of other countries. You agree to comply strictly with all such laws and, in particular, shall: (a) obtain any export, reexport or import authorizations required by U.S. or your local laws; (b) not use Services, content or direct product from Defendit’s Services to design, develop or produce missile, chemical/biological or nuclear weaponry; and © not provide Services, content or direct product from Defendit’s Services to prohibited countries and entities identified in the U.S. export regulations
                    </p>
                    <p>
                      <span>10.8</span><b>No Agency. </b>Nothing in this Agreement shall be construed as creating a partnership, contract of employment, agency, joint venture or franchise relationship between Defendit with you.
                    </p>
                    <p>
                      <span>10.9</span><b>No Third Party Beneficiary</b>You acknowledge and agree that, except as otherwise expressly provided in the Terms, there shall be no third party beneficiary to this agreement.
                    </p>
                    </div>
                  </div> 

                </div>
              </div>
            </div>*/}

          </div>
        </div>
      </div>
    </section>
     </>
  );
};

export default TermsOfService;
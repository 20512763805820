import React,{ useState} from 'react';
import toastr from 'toastr';
import {
  jobApply
} from '../../actions/site';
import {
  convertToBase64 
} from "../../helpers/base64FileHelper"
const SecCareer = ({pages,jobs}) => {
  const allowedExts = ['jpg', 'jpeg', 'png','svg','svg+xml','pdf'];
  const [showExperience,setShowExperience] = useState('Experiences');
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [job_id,setJobId] = useState('');
  const [phone,setPhone] = useState('');
  const [qualification,setQualification] = useState('');
  const [cvUpload,setCvUpload] = useState('');
  const [willingToRelocate,setWillingToRelocate] = useState(false);
  function handleShow(datas) {
    setData(datas)
    setJobId(datas?._id)

  }
  function SelectExperience(val){
    setShowExperience(val);
  }
console.log(willingToRelocate)
  const onFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];

      if (![...allowedExts].includes(ext)) {   
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById('file-input');
          output.src = URL.createObjectURL(e.target.files[0]);
          output.onload = function() {
            URL.revokeObjectURL(output.src) // free memory
          }
        convertToBase64(e.target.files[0], setCvUpload);
  
      }
    }
  };
  const [data, setData] = useState(null);
  const  closeOneModal = () => {
    const modal = document.getElementById('ModalCenter');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
  
  }
  const onSubmit = async (e) => { 
    console.log(e)
    e.preventDefault();   
    try {
      let msg = await jobApply({  
        job_id : job_id, 
        email : email,
        full_name : name, 
        contact_number : phone,
        qualification : qualification, 
        experiences : showExperience,
        cv_upload : cvUpload, 
        willing_to_relocate : willingToRelocate,
      }); 
      
      if(msg.status === 200){      
        toastr.success(msg.message);
        closeOneModal();
      }else{
        toastr.error(msg.message);
      }      
    } catch(err) {
      toastr.error(err.message);
    }
  }
  return (
    <>
    <section className="career-section">
    <div className="pre-footer"><img src="assets/img/wave.svg" alt="" /></div>
    <div className="container">
      <div className="career-content">
        <div className="career-info">
          <h2 className="title">{pages?.subtitle}</h2>
          <div className="career-text" dangerouslySetInnerHTML={{__html:pages?.description}}></div>
          <div className="career-image"><img src={pages?.image} alt="" /></div>
        </div>
        <div className="career-list">
        {
        jobs.length > 0 &&
        jobs.map((item, index) =>
          <div className="career-item"key={index}>
            <div className="job-title">{item?.title}</div>
            <div className="job-description"dangerouslySetInnerHTML={{__html:item?.short_description}} >
            
            </div>
            <div className="job-detail">
              <div className="job-detail-list">
                <div className="job-detail-item">
                  <div className="icon"><i className="fa-regular fa-fw fa-user-graduate"></i></div>
                  <div className="text">{item?.degree}</div>
                </div>
                <div className="job-detail-item">
                  <div className="icon"><i className="fa-regular fa-fw fa-briefcase"></i></div>
                  <div className="text">{item?.experience} experience</div>
                </div>
                <div className="job-detail-item">
                  <div className="icon"><i className="fa-regular fa-fw fa-user"></i></div>
                  <div className="text">{item?.vacancy} vacancies</div>
                </div>
                <div className="job-detail-item">
                  <div className="icon"><i className="fa-regular fa-fw fa-sack-dollar"></i></div>
                  <div className="text">{item?.salary}</div>
                </div>
                <div className="job-detail-item">
                  <div className="icon"><i className="fa-regular fa-fw fa-map-marker-alt"></i></div>
                  <div className="text">{item?.address}</div>
                </div>
              </div>
              <div className="apply-action">
                <a href="" className="apply-btn" data-toggle="modal" data-target="#ModalCenter" onClick={() => handleShow(item)}>Apply Now </a>  
              </div>
            </div>
          </div>
        )
        }
          {/* <div className="pagination">
            <button type="button" className="pagination-nav"><i className="fa-regular fa-arrow-left-long"></i></button>
            <div className="pagination-content">
              Page
              <input type="number" className="pagination-input" value="1" min="1" name="" />
              of 78
            </div>
            <button type="button" className="pagination-nav"><i className="fa-regular fa-arrow-right-long"></i></button>
          </div> */}
        </div>
      </div>
    </div>

    
  </section>
  <div className="modal fade career-apply-modal" id="ModalCenter" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <div className="header-text">
          <h5 className="modal-title" id="ModalCenterTitle">{data?.title}</h5>
          <div className="job-detail">
            <div className="job-detail-list">
              <div className="job-detail-item">
                <div className="icon"><i className="fa-regular fa-fw fa-user-graduate"></i></div>
                <div className="text">{data?.title}</div>
              </div>
              <div className="job-detail-item">
                <div className="icon"><i className="fa-regular fa-fw fa-briefcase"></i></div>
                <div className="text">{data?.experience} experience</div>
              </div>
              <div className="job-detail-item">
                <div className="icon"><i className="fa-regular fa-fw fa-user"></i></div>
                <div className="text">{data?.vacancy} vacancies</div>
              </div>
              <div className="job-detail-item">
                <div className="icon"><i className="fa-regular fa-fw fa-sack-dollar"></i></div>
                <div className="text">{data?.salary}</div>
              </div>
              <div className="job-detail-item">
                <div className="icon"><i className="fa-regular fa-fw fa-map-marker-alt"></i></div>
                <div className="text">{data?.address}</div>
              </div>
            </div>
          
          </div>
      <div className="vacancy-text"><span>{data?.vacancy}</span>Vacancies</div> 
        </div>

        <button type="button" className="modal-close" data-dismiss="modal"><i
            className="fa-light fa-fw fa-times"></i></button>
      </div>
      <div className="modal-body">
        <form className="form-content">
        <input type="hidden"  name="job_id"  value={data?._id} />
          <div className="form-group">
            <div className="input-style">
              <div className="input-icon"><i className="fa-regular fa-fw fa-user"></i></div>
              <input className="input" type="text"  name="full_name" onChange={e => setName(e.target.value) } placeholder="Full Name" />
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-style">
                  <div className="input-icon"><i className="fa-regular fa-fw fa-phone"></i></div>
                  <input className="input" type="text"  name="contact_number"  onChange={e => setPhone(e.target.value) }  placeholder="Contact number" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="input-style">
                  <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                  <input className="input" type="text"  name="email" onChange={e => setEmail(e.target.value) }  placeholder="E-mail" />
                </div>
              </div>
            </div>

          </div>
          <div className="form-group">
            <div className="dropdown input-style experience-dropdown">

              <button className="dropdown-toggle" type="button" data-toggle="dropdown">
                <div className="input-icon"><i className="fa-regular fa-fw fa-briefcase"></i></div>
                <div className="text">{showExperience}</div>
                <input  type="hidden"  name="experiences"   value={showExperience} />
              </button>
              <div className="dropdown-menu">
                {/* <div className="dropdown-search">
                  <input type="text" className="form-control" placeholder="Search" onkeyup="search_experience()"
                    id="search-experience-input" />
                </div> */}
                <div className="dropdown-scroll" id="search-experience">
                  <button type="button" className="dropdown-item" onClick={()=>SelectExperience("Less than 6 months")}>Less Than 6 months</button>
                  <button type="button" className="dropdown-item"onClick={()=>SelectExperience("Less than 1 year")}>Less Than 1 year</button>
                  <button type="button" className="dropdown-item"onClick={()=>SelectExperience("1-2 Years")}>1-2 Years</button>
                  <button type="button" className="dropdown-item" onClick={()=>SelectExperience("2-3 Years")}>2-3 Years</button>
                  <button type="button" className="dropdown-item" onClick={()=>SelectExperience("3-4 Years")}>3-4 Years</button>
                  <button type="button" className="dropdown-item"onClick={()=>SelectExperience("4-5 Years")}>4-5 Years</button>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="input-style">
              <div className="input-icon"><i className="fa-regular fa-fw fa-graduation-cap"></i></div>
              <input className="input" type="text"  name="qualification" onChange={e => setQualification(e.target.value) }  placeholder="Qualification" />
            </div>
          </div>

          <div className="form-group">
            <div className="input-style">
             <div className="input-icon"><i className="fa-regular fa-fw fa-memo-circle-check"></i></div>
              {/* <input className="input" type="text" placeholder="Upload CV or Resume" readonly/> */}
              <div className="input-file">
                <input name="cv_upload"  onChange={onFileChange}  type="file" id="file-input" className="feedback-input"/>
                <label className="file-input-label" for="file-input"><i className="fa-regular fa-upload mr-2"></i>Upload File</label>
              </div>
            </div>
          </div>
          <div className="upload-file">
            <div className="media-item-add">
              {/* <div className="btn-add"><input type="file" title="" accept="image/*" className="btn-add-file">
                <i className="fal fa-fw fa-plus"></i>
              </div> */}
            </div>
          </div> 

          <div className="custom-checkbox">
            <input type="checkbox" className="custom-checkbox-input"  name="willing_to_relocate" onChange={e => setWillingToRelocate(e.target.checked) }  />
            <div className="custom-checkbox-label">Willing to relocate</div>
          </div>
          <div className="apply-action">
            <button type="button" className="btn-submit-action" onClick={onSubmit}>Apply</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</>
  );
};

export default SecCareer;
import React, { useState, useEffect } from 'react';
import SecClient from '../components/Home/SecClient';
import SecHero from '../components/Home/SecHero';
import SecSearch from '../components/Home/SecSearch';
import SecServices from '../components/Home/SecServices';
import SecStep from '../components/Home/SecStep';
import SecTicket from '../components/Home/SecTicket';
import SecWhy from '../components/Home/SecWhy';
import { 
  getPageDetails,
  getWhyus,
  getSolutions,
  getClients,
  getBanners,
  getSteps,
  getSiteSettings,
  getTickets,
  getStates,
  getSeo,
 } from '../actions/site';
 import {Helmet} from "react-helmet";

const Home = () => {
    const [pageId,setPageId] = useState({});
    const [whyus, setWhyus] = useState({});
    const [solutions, setSolutions] = useState({});
    const [clients,setClients] = useState({});
    const [banners,setBanners] = useState({});
    const [seo,setSeo] = useState({});
    const [steps,setSteps] = useState({});
    const [siteSettings, setSiteSettings] = useState({});
    const [tickets,setTickets] = useState({});
    const [states,setStates] = useState({});
    useEffect(() => {
        // Page
        const loadPageData = async () => {
          try {            
            const data = await getPageDetails({
              slug:'home'
            });  
            if(data.status == 200) {
              setPageId(data.data._id)
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadPageData();

        // Settings
        const loadSiteSettingsData = async () => {
          try {            
            const data = await getSiteSettings({});  
            if(data.status == 200) {
                setSiteSettings(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadSiteSettingsData();

        // Why Us     
        const loadWhyusData = async () => {
          try {            
            const data = await getWhyus({
              status:'active'
            });  
            if(data.status == 200) {
                setWhyus(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadWhyusData();    
        
        // Solutions
        const loadSolutionsData = async () => {
            try {            
              const data = await getSolutions({
                status:'active'
              });  
              if(data.status == 200) {
                  setSolutions(data.data)
              }    
            } catch(err) {
              console.log(err.message);
            }
        }
        loadSolutionsData();  

        // Cliants
        const loadClientsData = async () => {
            try {            
              const data = await getClients({
                status:'active'
              });  
              if(data.status == 200) {
                  setClients(data.data)
              }    
            } catch(err) {
              console.log(err.message);
            }
        }
        loadClientsData();                

      // How it works
      const loadStepsData = async () => {
          try {            
            const data = await getSteps({
              status:'active',
              type:1
            });  
            if(data.status == 200) {
                setSteps(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
      }
      loadStepsData();

      // Tickets
      const loadTicketsData = async () => {
        try {            
          const data = await getTickets({});  
          if(data.status === 200) {
              setTickets(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadTicketsData();

     // State List
     const loadStates = async () => {
      try {            
        const data = await getStates({country_code:"US"});  
        if(data.status == 200) {
            setStates(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadStates();

    }, [ ]); 

    

    useEffect(() => {
      //Banners
      const loadBannersData = async () => {
          try {            
            const data = await getBanners({
              type:'page',
              type_id:pageId,
              //status:'active'
            });  
            if(data.status == 200) {
                setBanners(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
      }
      loadBannersData();
    },[pageId]);

    useEffect(() => {
      //Seo
      const loadSeoData = async () => {
          try {            
            const data = await getSeo({
              type:'page',
              typeId:pageId,
            });  
            if(data.status == 200) {
                setSeo(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
      }
      loadSeoData();
    },[pageId]); 
    

    return (
        <>
            <Helmet>
              <meta charSet="utf-8" />
              <meta name="title" content={seo?.meta_title}></meta>
              <meta name="author" content={seo?.author}></meta>
              <meta name="keywords" content={seo?.keywords}></meta>
              <meta name="description" content={seo?.description}></meta>
            </Helmet>
            <SecHero settings={siteSettings} banners={banners[0]}/>
            <SecStep steps={steps} settings={siteSettings}/>
            <SecSearch states={states} settings={siteSettings} />
            <SecServices solutions={solutions} />
            <SecTicket tickets={tickets} />
            <SecClient clients={clients} />
            <SecWhy whyus={ whyus } />
        </>
    );
};

export default Home;
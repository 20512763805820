import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const stripeSetupIntents = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/set-payment-intent`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const stripeCustomer = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/customer`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const stripeCardList = async (body, signal = null)  => {
    try {
      const res = await fetchData('POST', `${BASE_URL}stripe/card-list`, body,signal);
      const resData = await res.json();
      if(resData.status === 200) {
        return { message: resData.message, data: resData.data,status:resData.status };
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}

export const stripeCardDelete = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/card-delete`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const stripePaymentIntentWithCvc = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/payment-intent-create-cvc`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const stripeCardAdd = async (body, signal = null)  => {
    try {
      const res = await fetchData('POST', `${BASE_URL}stripe/card-add`, body,signal);
      const resData = await res.json();
      if(resData.status === 200) {
        return { message: resData.message, data: resData.data,status:resData.status };
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}

export const stripeChargeCreate = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/charge-create`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storePaypalData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}paypal/payment`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeCvcStripeData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/store-cvc-data`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

// for TLC
export const storeTlcPaypalData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}paypal/tlc-payment`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeTlcStripeData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/tlc-payment`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeCvcTlcStripeData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/store-tlc-cvc-data`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const checkInvoice = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}check/invoice`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeInvoiceStripeData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}stripe/invoice`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const storeInvoicePaypalData = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}paypal/invoice`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getApplyCoupon = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}apply/coupon`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTLCApplyCoupon = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}apply/tlc-coupon`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
     return { message: resData.message,status:400 };
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const totalReferralAmount = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}transaction/total-referral`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { 
        message: resData.message, 
        total: resData.total,
        totalEarning: resData.totalEarning,
        totalSpend: resData.totalSpend,
        status:resData.status,
      };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


export const payUsingWallet = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}pay-using-wallet`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTransCheckTicket = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}transaction/check-ticket`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getEmailToUser = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}email-to-user`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const checkEmail = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}check-email`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status};
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const checkPhone = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}check-phone`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status};
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const ticketStatus = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket-status/list`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status};
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const acceptTlcStatus = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}accept-tlc-status`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status};
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


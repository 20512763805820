import React, { useState, useEffect,Fragment } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { 
    getReferralList
   } from '../../actions/referral';
import ReferredModal from '../ReferredModal';
import {getProfileData} from '../../actions/site';
import { FRONT_URL } from "../../config/host";
import NothingFound from '../../shared/nothingFound';
import Searching from '../../shared/searching';
import { NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ImageShowModal from '../ImageShowModal';


const SecReferral = () => {
    const dateFormat = issue_date => {
        let d = new Date(issue_date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
        let da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);
        return `${(da<=9)?'0'+da:da} ${(mo<=9)?'0'+mo:mo} ${ye}`;
      }
      const [loading,setLoading] = useState(true);
    const [keyword,setKeyword] = useState('');
    const [offset,setOffset] = useState(0);
    const [totalDataCount,setTotalDataCount] = useState(0);
    const [referral, setReferral] = useState({});
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [noOfPage, setNoOfPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [setReferredCode, setSetReferredCode] = useState();
    const [viewLightBox, setViewLightBox] = useState({photo: null,isOpen: false})
    const { photo, isOpen } = viewLightBox;
    const [isCopy,setIsCopy] = useState(false);

  useEffect(() => {
      const loadReferral = async () => {
        try {            
          const data = await getReferralList({
              keyword:keyword,
              referred_signup_id:localStorage.getItem('userId'),
              sortByField:'created_at',
              sortByValue:'DESC',
              limit:limit,
              offset:offset
          });  
          if(data.status == 200) {
            setReferral(data.data);
            setCount(data.count);
            setTotalDataCount(data.count);   
            setTotal(data.total)
          }  else{
            setCount(0);
            setTotalDataCount(0);  
          }
          setLoading(false); 
        } catch(err) {
          console.log(err.message);
        }
      }
      loadReferral();
  }, [keyword, offset]); 
  const [userprofile, setUserProfile] = useState({});
  
  useEffect(() => {
      // Profile Data    
      const loadProfileData = async () => {
          try {            
            const data = await getProfileData({
              id : localStorage.getItem('userId')
            });  
            if(data.status == 200) {
              setUserProfile(data.data)
              
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadProfileData(); 
      
  }, [ ]);
useEffect(()=>{
  setSetReferredCode(userprofile?.referred_code);
},[userprofile])

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit))
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

    return (
        <section className='sec-referral'>
                <div className='body-layout'>
                    <div className='body-content'>
                        <div className='user-search-part'>
                        <div className='d-flex justify-content-between mb-5'>
                        <div className='title'>Referral
                                <span>({count})</span>
                            </div>
                            <div className='title'>Total Earning
                                <span>${(total && total>0)?total.toFixed(2):'0'}</span>
                            </div>
                        </div>
                           
                          
                              <div className="submit-new-ticket">
                                <div className="search">
                                    <button type="button" className="search-btn"><i className="fa-regular fa-fw fa-magnifying-glass"></i></button>
                                    <input type="text" className='search-input' value={keyword} onChange={(e) => setKeyword(e.target.value)}  />
                                </div>
                               </div>
                           
                        </div>
                        <div className='row'>
                        <div className='col-lg-12 col-xl-8'>
                                <div className='referral-inner'>
                                    { count >0 ?(
                                       referral.length > 0 &&
                                       referral.map((item, index) => (
                                     
                                      <div className='referral-item' key={index}>
                                          <div className='referral-img input-file cursor'>
                                              <img src={item?.user_details[0].image} onClick={() => setViewLightBox({ photo:item?.user_details[0].image, isOpen: true })} alt="" />
                                          </div>
                                          <div className='referral-content'>
                                              <div className='referral-heading'>
                                                <div className='referral-name'>{item?.user_details[0]?.name}</div>
                                                </div>
                                                <div className='referral-details'>
                                                    <div className='name'>
                                                        <div className='referral-date'>Signup date</div>
                                                        <div className='referral-date-no'>{item?.user_details[0]?dateFormat(item.user_details[0].created_at):""}</div>
                                                    </div>
                                                    {/* <div className='name'>
                                                        <div className='referral-date'>Amount</div>
                                                        <div className='referral-date-no'>${item?.referred_amount||"0.00"}</div>
                                                    </div> */}
                                                </div>
                                          </div>
                                      </div>
                                     
                                      ))):(loading?<Searching/>:<NothingFound/>)
                                    }
                                </div>
                            </div>
                            <div className='col-lg-12 col-xl-4'>
                                { userprofile ? (
                                <div className="referral">
                                    <div className="group group-action">
                                        <div className="group-label">Referral Code</div>
                                        {/* <div className="icon-referral"><button type="button" className="btn" data-toggle="modal" data-target="#ModalReferredDetails"><i className="fas fa-edit"> </i></button>  </div> */}
                                        
                                     
                                        <div className="group-data alt">{setReferredCode} </div>
                                        {/* <button type="button" className="btn-action" data-toggle="modal" data-target="#ModalReferredDetails"   >Edit </button> */}
                                    </div>
                                    <div className="group">
                                        <div className="group-label">Refer By Friend's</div>
                                        <div className="group-data" id="referralcode">{FRONT_URL+'signup?ref='+setReferredCode}</div>
                                    </div>
                                    <div className="group-action">
                                        <button 
                                        type="button" 
                                        className="btn-action"
                                        onClick={() => navigator.clipboard.writeText(FRONT_URL+'signup?ref='+setReferredCode)}>Copy</button>
                                    </div>
                                </div>):''
                                }
                               
                            </div>
                        </div>
                        {
                          noOfPage > 1 &&
                          <div className='pagination-inner'>
                              <nav aria-label="Page navigation example">
                                <ReactPaginate
                                  breakLabel="..."
                                  nextLabel=">"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  pageCount={noOfPage}
                                  previousLabel="<"
                                  renderOnZeroPageCount={null}
                                />
                              </nav>
                          </div>
                        }
                        {/* {
                          noOfPage > 1 &&
                          <div className='pagination-inner'>
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                { 
                                  Array(noOfPage).fill().map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                    { 
                                      (offset > 0 && i === 0) &&
                                      <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => {setOffset(offset - limit); setCurrentPage(currentPage - 1)}}>
                                          <i className="fa-regular fa-arrow-left"></i>
                                        </a>
                                      </li>
                                    }
                                    {
                                      currentPage === (i + 1) &&                              
                                      <li className="page-item active">
                                        <a className="page-link" href="#">{ i + 1 }</a>
                                      </li>
                                    }
                                    {
                                      currentPage !== (i + 1) &&
                                      <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => {setOffset(limit*i); setCurrentPage(i + 1)}}> 
                                        { i + 1 }</a>
                                      </li>
                                    }
                                    { 
                                      (currentPage !== noOfPage && (i + 1) === noOfPage) &&
                                      <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => {setOffset(offset + limit); setCurrentPage(currentPage + 1)}}>
                                          <i className="fa-regular fa-arrow-right"></i>
                                        </a>
                                      </li>
                                    }
                                    </Fragment>
                                  )
                                  })
                                }
                              </ul>
                            </nav>
                          </div>
                        } */}
                    </div>
                </div>
                { isOpen && ( <ImageShowModal photo={photo} isOpen={isOpen} setViewLightBox={setViewLightBox}/>)}
                <ReferredModal  setReferredCode={setSetReferredCode}/>
        </section>

    );
};

export default SecReferral;
import React, { useState, useEffect } from "react";
import Searching from '../../shared/searching';
import NothingFound from '../../shared/nothingFound';
const SecBillingsDetails = ({ id, transaction,count,isloading }) => {
  useEffect(() => {}, [transaction]);
 
  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d); //numeric
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${mo <= 9 ? "0" + mo : mo} ${da}, ${ye}`;
  };

  const sum = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum = sum + parseFloat(data[i]?.amount ? data[i]?.amount : 0);
    }
    return sum;
  };
  return (
    <section className="sec-billings-details">
     
     <div className="body-layout">
        <div className="body-content">
          <div className="user-search-part">
            <div className="title">Transaction Details</div>
            <div className="invoice-inner">
              {/* <button type="button" className="invoice-btn">
                <i className="fa-light fa-file-lines"></i>Invoice
              </button> */}
            </div>
          </div>
          <div className="details-inner">
            <div className="row">
              
              {  isloading ?(<Searching/>): (count != 0 ? <><div className="col-lg-12 col-xl-8">
                <div className="details-item">
                  <div className="details-heading">
                    <div className="details-id">
                      <div className="id-title">Transaction ID</div>
                      <div className="id-sub-title">{(transaction?.details?.payment_transaction)?transaction?.details?.payment_transaction:''}</div>
                    </div>
                    <div className="billings-status">
                    <div className={"status-title " + transaction?.details?.transaction_payment_status}>{transaction?.details?.transaction_payment_status}</div>
                    </div>
                  </div>
                  {
                  transaction?.tickets?.length > 0 &&
                  transaction?.tickets?.map((item,index)=> (
                  <div className="details-body-content" key={index}>
                    {
                      (transaction?.details?.transaction_payment_mode === 'manual')?'':
                      (transaction?.details?.invoice_id)?
                      <div className="details-content">
                      <div className="details-ticket-content">
                        <div className="details-ticket-title">
                          Invoice Number
                        </div>
                        <div className="details-ticket-sub-title">
                        {transaction?.details?.invoice_id}
                        </div>
                      </div>
                      <div className="details-ticket-date">
                        <div className="date-title">Date</div>
                        <div className="date-sub-title">{item?.created_at?dateFormat(item?.created_at):"N/A"}</div>
                      </div>
                    </div>:
                     <div className="details-content">
                      <div className="details-ticket-content">
                        <div className="details-ticket-title">
                          Ticket Number
                        </div>
                        <div className="details-ticket-sub-title">
                        {item?.ticket_number}
                        </div>
                        <div className="details-ticket-text">
                        {item?.ticket_name}
                        </div>
                      </div>
                      <div className="details-ticket-date">
                        <div className="date-title">Issue Date</div>
                        <div className="date-sub-title">{item?.issue_date?dateFormat(item?.issue_date):"N/A"}</div>
                      </div>
                    </div>
                    }
                    <div className="details-footer">
                      <div className="plate-inner">
                        {                          
                          (item?.ticket_details?.ticket_type && item?.ticket_details?.ticket_type === 'tlc')?
                          <div className="plate-content">                                                    
                            <div className="plate-item">
                              <div className="plate-title">Violation Amount</div>
                              <div className="plate-sub-title">{(item?.ticket_details?.total_violation_amount)?'$'+item?.ticket_details?.total_violation_amount:"N/A"}</div>
                            </div>
                          </div>
                          :
                          (transaction?.details?.transaction_payment_mode === 'manual')?'':
                          (transaction?.details?.invoice_id)?'':
                          <>
                          <div className="plate-content">
                            <div className="plate-item">
                              <div className="plate-title">Plate No.</div>
                              <div className="plate-sub-title"> {(item?.plate_no)?item?.plate_no:'N/A'}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">State</div>
                              <div className="plate-sub-title">{(item?.state)?item?.state:'N/A'}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Fine</div>
                              <div className="plate-sub-title">{(item?.fine_amount)?'$'+item?.fine_amount:"N/A"}</div>
                            </div>
                          </div>
                          <div className="plate-content">
                            <div className="plate-item">
                              <div className="plate-title">Interest.</div>
                              <div className="plate-sub-title">{(item?.interest_amount)?'$'+item?.interest_amount:"N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Penalty</div>
                              <div className="plate-sub-title">{(item?.penalty_amount)?'$'+item?.penalty_amount:"N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Reduction</div>
                              <div className="plate-sub-title">{(item?.reduction_amount)?'$'+item?.reduction_amount:"N/A"}</div>
                            </div>                          
                          </div>
                          </>
                        }
                      </div>
                      <div className="details-price cancel">${item.amount?item.amount:"0.00"}</div>
                    </div>
                  </div>
                   ))
                }
                </div>
              </div>
              <div className="col-lg-12 col-xl-4">
                
                 {transaction?.details?.transaction_coupon_code &&
                  <div className="transaction-bill-summary">
                  <div className="coupon-inner">
                    <div className="coupon-title">Coupon Code</div>
                    <div className="coupon-text">#{transaction?.details?.transaction_coupon_code}</div>
                  </div>
                  </div>
                }
                  
             
                <div className="payment-inner">
                  <div className="payment-title">Payment Summary 
                  {
                    (transaction?.details?.transaction_total_ticket && transaction?.details?.transaction_total_ticket>0) &&
                    <span class="badge badge-primary ml-2">{transaction?.details?.transaction_total_ticket}</span>
                  }
                  </div>
                  <div className="payment-item">
                    <div className="payment-option">
                      {/* <div className="payment-content">
                        <div className="payment-text">Total tickets:</div>
                        <div className="payment-price">{transaction?.details?.transaction_total_ticket}</div>
                      </div> */}
                      <div className="payment-content">
                        <div className="payment-text">Sub Total:</div>
                        <div className="payment-price">{(transaction?.tickets && transaction?.tickets.length>0)?'$'+sum(transaction?.tickets):'$0'}</div>
                      </div>
                      {/* <div className="payment-content">
                        <div className="payment-text">Ticket Fee:</div>
                        <div className="payment-price">${transaction?.details?.transaction_ticket_fee? transaction?.details?.transaction_ticket_fee:'0.00'}</div>
                      </div> */}
                      <div className="payment-content">
                        <div className="payment-text">Credit Card Fee</div>
                        <div className="payment-price">${transaction?.details?.transaction_credit_card_fee? transaction?.details?.transaction_credit_card_fee:'0.00'}</div>
                      </div>
                      <div className="payment-content">
                        <div className="payment-text">Processing Fee:</div>
                        <div className="payment-price">${transaction?.details?.transaction_processing_fee? transaction?.details?.transaction_processing_fee:'0.00'}</div>
                      </div>
                      {
                        (transaction?.details?.state_fee && transaction?.details?.state_fee >0) &&
                        <div className="payment-content">
                          <div className="payment-text">State Fee:</div>
                          <div className="payment-price">${transaction?.details?.state_fee? transaction?.details?.state_fee:'0.00'}</div>
                        </div>
                      }                      
                      {
                        (transaction?.details?.transaction_coupon && transaction?.details?.transaction_coupon >0) &&
                        <div className="payment-content">
                          <div className="payment-text">Coupon:</div>
                          <div className="payment-price discount">-${transaction?.details?.transaction_coupon?transaction?.details?.transaction_coupon:'0.00'}</div>
                        </div>
                      }                      
                    </div>

                    <div className="payment-footer">
                      <div className="total-text">Total Amount</div>
                      <div className="total-Price">${transaction?.details?.transaction_amount?transaction?.details?.transaction_amount:'0.00'}</div>
                    </div>
                  </div>
                </div>
              </div></> :<NothingFound/> )
              }
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
};

export default SecBillingsDetails;

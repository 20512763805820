import React, { useState, useEffect,useRef,} from 'react';
import { connect } from "react-redux";
import toastr from 'toastr';
import {stripeCardList,stripeCardDelete} from '../../actions/payment';
import {cartList,cartDelete} from '../../actions/cart';
import {Elements} from '@stripe/react-stripe-js';
//import {Elements} from 'react-stripe-elements';
import {loadStripe} from '@stripe/stripe-js';
import CvcFrom from '../../components/Checkout/CvcFrom';
import CheckoutForm from '../../components/Checkout/CheckoutForm';
import { PayPalButton } from "react-paypal-button-v2";
import Searching from '../../shared/searching';
import NothingFound from '../../shared/nothingFound';
import {storePaypalData,getApplyCoupon,totalReferralAmount,payUsingWallet,checkPhone,checkEmail} from '../../actions/payment';
import { useNavigate } from "react-router";
import { getSiteSettings,getStates,getCountry,getProfileData } from '../../actions/site';
import Autocomplete , { usePlacesWidget } from "react-google-autocomplete";
import InputMask from 'react-input-mask';
import { NavLink } from "react-router-dom";
import { googleApiData,stripePublishKey,paypalClientId } from '../../helpers';

const SecCheckout = ({cartList,cartDelete}) => {
    const navigate = useNavigate(); 
    const googleApiKey = googleApiData();
    const stripePromise = loadStripe(stripePublishKey());
    const [loading,setLoading] = useState(true);
    const [isAddCart,setIsAddCart] = useState(false);
    const [cards,setCards] = useState([]);
    const [cartInfo,setCartInfo] = useState([]);
    const [totalDataCount,setTotalDataCount] = useState(0);
    const [searchCart, setSearchCart] = useState({});
    const [total,setTotal] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);
    const [paymentType, setPaymentType] = useState('stripe');
    const [isUser,setIsUser] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [amounts,setAmounts] = useState([]);
    const [fine_amount,setFineAmount] = useState([]);
    const [isDelete,setIsDelete] = useState(false);

    const [coupon, setCoupon] = useState('');
    const [isApplyBtn, setIsApplyBtn] = useState(false);
    const [settings, setSiteSettings] = useState({});
    const [couponPrice, setCouponPrice] = useState(0);
    const [taxs,setTaxes] = useState(0);
    const [totalRef,setTotalref] = useState(0);
    const [isPayUsing,setIsPayUsing] = useState(false);
    const [walletAmount,setWalletAmount] = useState(0);
    const [isFullPayWallet,setIsFullPayWallet] = useState(false);
    const [isPay,setIsPay] = useState(false);
    const [place,setPlace] = useState({});
    const [cardId,setCardId] = useState('');

    //User Info 
    const [first_name,setFirstName] = useState('');
    const [last_name,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [address,setAddress] = useState('');
    const [city,setCity] = useState('');
    const [state,setState] = useState('');
    //const [country,setCountry] = useState('');
    const [zip,setZip] = useState('');
    const [user_info,setUserInfo] = useState({});


    const [searchState, setSearchState] = useState([]);
    const [states,setStates] = useState({});
    const [displayState,setDisplayState] =  useState('');
    // const [displayCountry,setDisplayCountry] = useState('Select Country');
    // const [countries,setCountries] = useState([]);
    // const [searchCountry,setSearchCountry] = useState([]);
    const [isUserInfo,setIsUserInfo] =  useState(false);
    const [enabledPayment,setEnabledPayment] = useState(false);

    const inputRef = useRef(null);

    //Fees Calculation 
    const [credit_card_fees,setCreditCardFees] = useState('');
    const [platform_fees,setPlatformFees] = useState('');
    const [displayComment, setDisplayComment] = useState('');
    const [displayDescription,setDisplayDescription] = useState('');
    const [isExisting,setIsExisting] = useState(false);
    const [isCreateUser,setIsCreateUser] = useState(false);
    const [isDeleteCart,setIsDeleteCart] = useState(false);
    const [isCartError,setIsCartError] =  useState(false);
    const [errorTicket,setErrorTicket] = useState([]);

    const [isDeleteCard,setIsDeleteCard] = useState(false);
    const [deleteCardId,setDeleteCardId] = useState('');
    const [totalCard,setTotalCard] =  useState(0);
    const [isHide,setIsHide] = useState(false);

    //console.log(setIsHide);

    useEffect(() => {
        // For Credit card
        let cc_fees = 0;
        if(settings?.creadit_fee){            
            if(settings?.creadit_fee_type ==='percentage'){
                cc_fees = parseFloat(total)*parseFloat(settings?.creadit_fee)/100;
            }else{
                cc_fees = settings?.creadit_fee
            }
        }
        // For Platform
        let platform_fees = 0;
        if(settings?.processing_fee){            
            if(settings?.processing_fee_type ==='percentage'){
                platform_fees = parseFloat(total)*parseFloat(settings?.processing_fee)/100;
            }else{
                platform_fees = settings?.processing_fee
            }
        }
        setCreditCardFees(cc_fees.toFixed(2));
        setPlatformFees(platform_fees.toFixed(2));
        setTotalPrice((parseFloat(total)+parseFloat(platform_fees)+parseFloat(cc_fees)+parseFloat(taxs)).toFixed(2)); 
    }, [cartInfo,settings,total,taxs]);    


    const checkPayUsing = (e) => {    
        if(e.target.checked){
            setIsPayUsing(true);
            if(total >= totalRef){
                let tot = ((parseFloat(total)+parseFloat(credit_card_fees)+parseFloat(platform_fees)+parseFloat(taxs))-parseFloat(totalRef)).toFixed(2);
                setWalletAmount(totalRef);
                setTotalPrice(tot);
                setIsFullPayWallet(false) 
            }else{
                let tot = (parseFloat(totalRef)-(parseFloat(total)+parseFloat(credit_card_fees)+parseFloat(platform_fees)+parseFloat(taxs))).toFixed(2);
                setWalletAmount((parseFloat(total)+parseFloat(credit_card_fees)+parseFloat(platform_fees)+parseFloat(taxs)));
                setTotalPrice((parseFloat(total)+parseFloat(credit_card_fees)+parseFloat(platform_fees)+parseFloat(taxs)));
                setIsFullPayWallet(true);
            }
        }else{
            setIsPayUsing(false);
            setIsFullPayWallet(false) 
            setTotalPrice((parseFloat(total)+parseFloat(credit_card_fees)+parseFloat(platform_fees)+parseFloat(taxs)).toFixed(2)); 
            setWalletAmount(0);
        }        
    }

    const handleChange = (e) => {
        const { name, value } = e.target;    
        setPaymentType(value);
    };

    const handleCardChange = (e) => {
        const { name, value } = e.target;    
        setCardId(value);
    }

    const addNewCart = () => {
        if(isAddCart){
            setIsAddCart(false)
        }else{
            setIsAddCart(true)
        }
    }

    const handleExistChange = (e) => {
        const { name, value } = e.target;  
        if(value === 'existing'){
            setIsExisting(true);
        }else{
            setIsExisting(false);
        }
    };

    useEffect(() => {
        if(cards && cards.length>0){
            setIsExisting(true)
        }else{
            setIsExisting(false)
        }
    }, [cards]);

    useEffect(() => {
        if(localStorage.getItem('access_token')){
            setIsUser(true)
        }else{
            setIsUser(false)
        }
    }, []);

    useEffect(() => {
        if(amounts && amounts.length >0){            
            let count = 0
            let cart_tickets = [];
            for (let i in amounts) {
                if (amounts[i] === 0) {
                    cart_tickets.push(cartInfo[i]?.ticket_no);
                    count++
                }                
            }
            if(count>0){
                setIsCartError(true);
                setErrorTicket(cart_tickets);
            }else{
                setIsCartError(false);
                setErrorTicket([]);
            }
        }else{
            setIsCartError(false);
            setErrorTicket([]);
        }
    }, [amounts]);
    //console.log(errorTicket);

    
    
    const onApplyCoupon = async () => {
      //  console.log(total,'total');
        setIsApplyBtn(true);
          try {
              let response = await getApplyCoupon({
                  coupon:coupon,
                  cartId:localStorage.getItem('cartId'),
                  total_price: total
              });
              if (response.status === 200) {
                  setCouponPrice(response.data.couponPrice);
                  setTotalPrice((response.data.total)); 
                  setDisplayComment(response.data.comment); 
                  setIsApplyBtn(false);                     
              } else {
                  toastr.error(response.message);
                  setCouponPrice(0);
                  setDisplayComment(''); 
                  setIsApplyBtn(false);
              }
          } catch (error) {
              toastr.error(error.message);
              setCouponPrice(0);
              setDisplayComment(''); 
              setIsApplyBtn(false);
          }
    }    

    useEffect(() => {
        // Card List
        const loadCardList = async () => {
          try {            
            const data = await stripeCardList({});  
            if(data.status == 200) {
                setCards(data.data);   
                setTotalCard(data.data.length);    
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        if(isUser){
            loadCardList();
        }               
    }, [isUser]); 
    
    // Card Delete
    const onStripeCardDelete = async () => {
        setIsDeleteCard(true);
        try {            
            const data = await stripeCardDelete({
                cardId:deleteCardId,
                total:totalCard
            });  
            if(data.status === 200) {
                closeOneModal2();
                setDeleteCardId('');
                setIsDeleteCard(false);
                setCards(data.data);
                setTotalCard(data.data.length);    
            }else{
                setIsDeleteCard(false);
                setDeleteCardId('');
                toastr.error(data.message);
                console.log(data.message);
            }
        } catch(err) {
            toastr.error(err.message);
            setIsDeleteCard(false);
            setDeleteCardId('');
        }
    }
    
    useEffect(() => {
        // Total amount Referral
        const loadTotalRefAmount = async () => {
          try {            
            const data = await totalReferralAmount({});  
            if(data.status == 200) {
                setTotalref(data.total);          
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadTotalRefAmount();       
    }, []);
    
    
    const onSubmit = async () => {
        setIsPay(true);
        try {
          let msg = await payUsingWallet({
            cartId:localStorage.getItem('cartId'),
            amount:totalPrice,
            coupon:coupon,
            couponPrice:couponPrice,
            taxs:taxs,
            reward_amount:walletAmount
          });
          if (msg.status === 200) {            
            setIsPay(false);
            toastr.success(msg.message);
            return navigate("/success?txn="+ msg?.data?.transaction_id); 
          } else {
            setIsPay(false);
            toastr.error(msg.message);
          }
        } catch (err) {
            setIsPay(false);
          toastr.error(err.message);
        }
      }
    
    useEffect(() => {
        // Cart List
        const loadCartList = async () => {
            try {            
                const data = await cartList({
                    cartId:localStorage.getItem('cartId'),
                });  
                if(data.status == 200) {
                    setCartInfo(data.data);  
                    setSearchCart(data.data);
                    setTotalPrice((data.total)); 
                    setTotal((data.total)); 
                    setTaxes(data.stateTax);
                } 
                setTotalDataCount(data.count);
                setAmounts(data.amounts);
                setFineAmount(data.fine_amount);
                setLoading(false); 
            } catch(err) {
                console.log(err.message);
                setLoading(false); 
            }
        }
        loadCartList();

        // Settings
        const loadSiteSettingsData = async () => {
            try {            
              const data = await getSiteSettings({});  
              if(data.status == 200) {
                  setSiteSettings(data.data)
              }    
            } catch(err) {
              console.log(err.message);
            }
        }
        loadSiteSettingsData();
    }, []);

    const closeOneModal = () => {
        const modal = document.getElementById('ModalCartDelete');
        modal.classList.remove('show');
        modal.removeAttribute("role");
        modal.removeAttribute("aria-modal");
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
        document.body.classList.remove('modal-open');
        document.body.style = '';       
    }

    const closeOneModal2 = () => {
        const modal = document.getElementById('ModalCardDelete');
        modal.classList.remove('show');
        modal.removeAttribute("role");
        modal.removeAttribute("aria-modal");
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
        document.body.classList.remove('modal-open');
        document.body.style = '';       
    }

    // Cart Delete
    const onCartDelete = async () => {
        setIsDelete(true)
        setIsDeleteCart(true);
        try {            
            const data = await cartDelete({
                cartId:localStorage.getItem('cartId'),
                id:currentId
            });  
            if(data.status == 200) {
                closeOneModal(); 
                setCartInfo(data.data);  
                setSearchCart(data.data);
                setTotal((data.total)); 
                setTotalPrice((data.total));
                setTaxes(data.stateTax);
                // let filteredArray = cartInfo.filter(item => item._id !== currentId);                    
                // setSearchCart(filteredArray);
                setCurrentId('');
                setIsDeleteCart(false);
                try {
                    let response = await getApplyCoupon({
                        coupon:coupon,
                        cartId:localStorage.getItem('cartId'),
                        total_price: data.total
                    });
                    if (response.status === 200) {
                        setCouponPrice(response.data.couponPrice);
                        setTotalPrice(((response.data.total)+parseFloat(data.stateTax)).toFixed(2));
                        setDisplayComment(response.data.comment); 
                    } else {
                        toastr.error(response.message);
                        setCouponPrice(0);
                        setDisplayComment(''); 
                    }
                } catch (error) {
                    setCouponPrice(0);
                    setDisplayComment(''); 
                    setIsApplyBtn(false);
                }
                toastr.success(data.message); 
            } 
            setTotalDataCount(data.count);
            setAmounts(data.amounts);
            setFineAmount(data.fine_amount);
            setIsDelete(false);
            setIsDeleteCart(false);
            setCurrentId('');
        } catch(err) {
            console.log(err.message);
            setIsDelete(false);
            setIsDeleteCart(false);
            setCurrentId('');
        }
    } 

    useEffect(() => {
        // State List
        const loadStates = async () => {
          try {            
            const data = await getStates({
              country_code : 'US'
            });  
            if(data.status == 200) {
                setStates(data.data);
                setSearchState(data.data);
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadStates();  
        
        // Country List
        // const loadCountry = async () => {
        //     try {            
        //       const data = await getCountry({});  
        //       if(data.status == 200) {
        //           setCountries(data.data);
        //           setSearchCountry(data.data);
        //       }    
        //     } catch(err) {
        //       console.log(err.message);
        //     }
        //   }
        //   loadCountry(); 
    }, []);

    const handleChangeState = (event) => {
        let searchStates = states.filter((item) => { 
            if(item.state_code){ 
                return item.state_code.toLowerCase().includes(event.target.value.toLowerCase())
            }
        })
        setSearchState(searchStates);
    }

    const getState = (id,code,name) => {
        setState(id);
        setDisplayState(code);
    }

    const hadelCreateAccount = async (e) => { 
        if(e.target.checked){ 
            setIsCreateUser(true);
        }else{
            setIsCreateUser(false);
        }
    } 

    const onCheckUser = async () => {
        setIsUserInfo(true);
        if(first_name === ''){
            toastr.error('First name field is required'); 
            setIsUserInfo(false);
        }else if(last_name === ''){
            toastr.error('Last name field is required'); 
            setIsUserInfo(false);
        }else if(email === ''){
            toastr.error('Email field is required'); 
            setIsUserInfo(false);
        }else if(phone === ''){
            toastr.error('Phone field is required'); 
            setIsUserInfo(false);
        }else if(address === ''){
            toastr.error('Address field is required'); 
            setIsUserInfo(false);
        }
        // else if(city === ''){
        //     toastr.error('City field is required'); 
        //     setIsUserInfo(false);
        // }else if(displayState === ''){
        //     toastr.error('State field is required'); 
        //     setIsUserInfo(false);
        // }else if(zip === ''){
        //     toastr.error('Zip code field is required'); 
        //     setIsUserInfo(false);
        // }
        else{    
            if(isCreateUser){
                try {            
                    const chkEmail = await checkEmail({
                      email:email
                    });  
                    if(chkEmail.status === 200) {
                        try {            
                            const chk = await checkPhone({
                              phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):''
                            });  
                            if(chk.status === 200) {
                                setIsUserInfo(false);
                                setEnabledPayment(true);
                                setUserInfo({
                                    first_name:first_name,
                                    last_name:last_name,
                                    name : first_name+' '+last_name,
                                    email:email,
                                    phone:phone,
                                    address:address,
                                    city:city,
                                    state:displayState,
                                    zip:zip,
                                    isCreateUser:isCreateUser
                                })
                            } else {
                                toastr.error(chk.message);
                                setIsUserInfo(false);
                            }   
                        } catch(err) {
                            toastr.error(err.message);
                            setIsUserInfo(false);
                        }
                    } else {
                        toastr.error(chkEmail.message);
                        setIsUserInfo(false);
                    }   
                } catch(err) {
                    toastr.error(err.message);
                    setIsUserInfo(false);
                }
            }else{
                setIsUserInfo(false);
                setEnabledPayment(true);
                setUserInfo({
                    first_name:first_name,
                    last_name:last_name,
                    name : first_name+' '+last_name,
                    email:email,
                    phone:phone,
                    address:address,
                    city:city,
                    state:displayState,
                    zip:zip,
                    isCreateUser:isCreateUser
                })
            } 
        }
    }

    // const handleChangeCountry = (event) => {
    //     let searchCountry = countries.filter((item) => { 
    //         if(item.name){ 
    //             return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    //         }
    //     })
    //     setSearchCountry(searchCountry);
    // }

    // const getCountries = (id,code,name) => {
    //     setCountry(id);
    //     setDisplayCountry(name);
    // }

    useEffect(() => {
        if(localStorage.getItem('access_token')){
            setEnabledPayment(true);
            // Profile Data 
            const loadProfileData = async () => {
                try {            
                  const data = await getProfileData({
                    id : localStorage.getItem('userId')
                  });  
                  if(data.status == 200) {
                    if(data.data?.state){
                        let index = states.findIndex((item) => item._id === data.data?.state);
                        if(index != -1){
                            setDisplayState(states[index]?.state_code);
                        }
                    }
                    // if(data.data?.country){
                    //     let index2 = countries.findIndex((item) => item._id === data.data?.country);
                    //     if(index2 != -1){
                    //         setDisplayCountry(countries[index2]?.name);
                    //     }
                    // }
                    setUserInfo({
                        first_name:data.data?.first_name,
                        last_name:data.data?.last_name,
                        name : data.data?.name,
                        email:data.data?.email,
                        phone:data.data?.phone,
                        address:data.data?.address,
                        city:data.data?.city,
                        state:displayState,
                        //country:displayCountry,
                        zip:zip,
                        isCreateUser:isCreateUser
                    })
                    
                  }else{
                    setUserInfo({});
                  }    
                } catch(err) {
                  console.log(err.message);
                  setUserInfo({});
                }
              }
            loadProfileData(); 
        }else{
            setEnabledPayment(false);
        }
    }, [localStorage.getItem('access_token')]);  

    //console.log(totalPrice);  

    

    return (
        <section className=''>
            <div className='body-layout'>
                <div className='body-content'>
                    <div className='user-search-part'>
                        <div className='title'>Cart Details</div>
                    </div>
                </div>
            </div>
            <div className='row'>
                {
                    displayComment && 
                    <div className='col-lg-12'>
                        <div className='dashboard-action'>
                        <div className="alert alert-success" role="alert">
                            {displayComment}
                        </div>
                        </div>
                    </div>
                }
                <div className='col-lg-8'>
                    <div className='driver-list-inner checkout-section-part mb-5'>
                        <div className='ticket-list'>
                            <div className='ticket-table'>
                                <div className='table-head'>
                                    <div className='table-row header-row'>
                                    <div className='table-cell cell-image'>
                                        <div className='table-title'>Ticket #</div>
                                    </div>
                                    <div className="table-cell cell-name">
                                        <div className="table-title">Plate No</div>
                                    </div>
                                    <div className='table-cell cell-licence'>
                                        <div className='table-title'>Fine</div>
                                    </div>
                                    <div className='table-cell cell-licence'>
                                        <div className='table-title'>Due</div>
                                    </div>
                                    <div className='table-cell drive-action'>
                                        <div className='table-title'>
                                            {
                                                (totalDataCount && totalDataCount >0)?
                                                <i 
                                                data-toggle="modal" 
                                                data-target="#ModalCartDelete"
                                                onClick={() => { setCurrentId('all')} }
                                                className={isDelete?"far fa-spinner fa-spin":"far fa-trash-alt"}
                                                ></i> : ''
                                            }
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className='table-body'>
                                    {
                                    totalDataCount >0 ? (
                                    searchCart.length > 0 &&
                                    searchCart.map((item, index) => (
                                    <div className='table-row' key={index}>
                                        <div className='table-cell cell-image'>
                                            <div className='driver-content'>
                                                <NavLink to={''} className='driver-list-title'>{item?.ticket_no }</NavLink>
                                            </div>
                                        </div>
                                        <div className="table-cell cell-name">{item?.plate_no}</div>
                                        <div className='table-cell cell-licence'>
                                            <div className='driver-list-title'>${(fine_amount)?fine_amount[index]:'--'}</div>
                                        </div>
                                        <div className='table-cell cell-licence due'>
                                            <div className='driver-list-title'>${(amounts)?amounts[index]:'--'}</div>
                                        </div>
                                        <div className='table-cell drive-action'>
                                            <button 
                                            className='cart-delete-btn' 
                                            data-toggle="modal" 
                                            data-target="#ModalCartDelete"
                                            onClick={() => { setCurrentId(item?._id)} }
                                            ><i className={isDelete?"far fa-spinner fa-spin":"far fa-trash-alt"}></i></button>
                                            {/* <div className='dropdown'>
                                                <i className='far fa-ellipsis-v btn-action' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'></i>
                                                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                                    <button 
                                                    className='dropdown-item' 
                                                    data-toggle="modal" 
                                                    data-target="#ModalCartDelete"
                                                    onClick={() => { setCurrentId(item?._id)} }
                                                    ><i className={isDelete?"far fa-spinner fa-spin":"far fa-trash-alt"}></i>Delete</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    ))
                                    ):(loading?<Searching/>:<NothingFound/>)
                                    } 
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* User Information  */}
                    {
                        (!localStorage.getItem('access_token') && totalDataCount>0 && !enabledPayment) &&
                        <>
                        <div className='payment-select mb-2'>
                            <div className='title'>User Info</div>
                        </div>
                        <div className='add-new-driver'>
                            <div className='form-group'>
                                <div className='driver-info add-plate'>                                    
                                    <div className='driver-info-content'>
                                        <div className='driver-info-title required'>First Name</div>
                                        <div className='driver-input'> 
                                        <input 
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter First name'
                                        value={first_name}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        /></div>
                                    </div>
                                    <div className='driver-info-content'>
                                        <div className='driver-info-title required'>Last Name</div>
                                        <div className='driver-input'> 
                                        <input 
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter Last Name'
                                        value={last_name}
                                        onChange={(e) => setLastName(e.target.value)}
                                        /></div>
                                    </div>
                                </div>
                                <div className='driver-info add-plate'>                                    
                                    <div className='driver-info-content'>
                                        <div className='driver-info-title required'>Email</div>
                                        <div className='driver-input'> 
                                        <input 
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        /></div>
                                    </div>
                                    <div className='driver-info-content'>
                                        <div className='driver-info-title required'>Phone</div>
                                        <div className='driver-input'> 
                                        <InputMask
                                        mask="(999) 999-9999"  
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter Phone'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        /></div>
                                    </div>
                                </div>
                                <div className='driver-info add-plate'>
                                    <div className='driver-info-content'>
                                        <div className='driver-info-title required'>Address</div>
                                        <div className='driver-input'> 
                                        <Autocomplete 
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter Address'
                                        value={address}
                                        ref={inputRef}
                                        apiKey={googleApiKey}
                                        onPlaceSelected={(place) => {
                                            console.log(place);                                            
                                            //setPlace(place);
                                            setAddress(place.formatted_address);   
                                            if(place && place.address_components.length>0){
                                                for (let i = 0; i < place.address_components.length; i++) {
                                                    if(place.address_components[i].types[0] === 'sublocality_level_1'){
                                                        setCity(place.address_components[i]?.long_name)
                                                    }
                                                    if(place.address_components[i].types[0] === 'administrative_area_level_1'){
                                                        setDisplayState(place.address_components[i]?.short_name)
                                                    }
                                                    if(place.address_components[i].types[0] === 'postal_code'){
                                                        setZip(place.address_components[i]?.short_name)
                                                    }
                                                }
                                            }                                         
                                            //setZip(place.address_components[4]?.short_name)
                                        }}
                                        options={{
                                            types: ['geocode'],
                                            componentRestrictions: { country: "us" },
                                        }}
                                        onChange={(e) => setAddress(e.target.value)}
                                        /></div>
                                    </div>
                                    {/* <div className='driver-info-content'>
                                        <div className='driver-info-title'>City</div>
                                        <div className='driver-input'> 
                                        <input 
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter City'
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        /></div>
                                    </div> */}
                                </div>
                                <div className='driver-info add-plate'>
                                <div className="custom-checkbox">
                                    <input 
                                    type="checkbox" 
                                    className="custom-checkbox-input"
                                    onChange={ hadelCreateAccount }
                                    />
                                    <div className="custom-checkbox-label">Monitor this vehicle & Create an account</div>
                                </div>
                                    {/* <div className='driver-info-content state'>
                                        <div className='driver-info-title'>State</div>
                                        <div className='ticket-dropdown'>
                                            <div className='dropdown'>
                                                <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayState}</button>
                                                <div className='dropdown-menu'>
                                                <div className='dropdown-search'>
                                                    <input 
                                                    type="text" 
                                                    className='form-control' 
                                                    placeholder="Search" 
                                                    id="search-state-input"
                                                    onChange={handleChangeState}
                                                    />
                                                </div>
                                                <div className='dropdown-scroll'>
                                                    {
                                                        searchState.length > 0 &&
                                                        searchState.map((item,index) => (
                                                            <button  
                                                            key={index} 
                                                            type="button" 
                                                            className='dropdown-item' 
                                                            data-value={item?._id} 
                                                            onClick={() => getState(item?._id,item?.state_code,item?.name)}                                           
                                                            >{item?.state_code} - {item?.name}</button>
                                                        ))
                                                    }                                    
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='driver-info-content'>
                                        <div className='driver-info-title'>Zip</div>
                                        <div className='driver-input'> 
                                        <input 
                                        className='input' 
                                        type="text" 
                                        placeholder='Enter Zip code'
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        /></div>
                                    </div> */}
                                    {/* <div className='driver-info-content state'>
                                        <div className='driver-info-title'>Country</div>
                                        <div className='ticket-dropdown'>
                                            <div className='dropdown'>
                                                <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayCountry}</button>
                                                <div className='dropdown-menu'>
                                                <div className='dropdown-search'>
                                                    <input 
                                                    type="text" 
                                                    className='form-control' 
                                                    placeholder="Search" 
                                                    id="search-country-input"
                                                    onChange={handleChangeCountry}
                                                    />
                                                </div>
                                                <div className='dropdown-scroll'>
                                                    {
                                                        searchCountry.length > 0 &&
                                                        searchCountry.map((item,index) => (
                                                            <button  
                                                            key={index} 
                                                            type="button" 
                                                            className='dropdown-item' 
                                                            data-value={item?._id} 
                                                            onClick={() => getCountries(item?._id,item?.iso2,item?.name)}                                           
                                                            >{item?.iso2} - {item?.name}</button>
                                                        ))
                                                    }                                    
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='driver-info add-plate'>
                                    <div className='driver-info-content search-action'>
                                        <div className='vehicle-action'>
                                            <button 
                                            type='button' 
                                            className='vehicle-btn' 
                                            onClick={() => onCheckUser() }
                                            > 
                                            <i className={(isUserInfo)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-arrow-right-to-bracket mr-2"}></i> Next</button>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </div>    
                        </>
                    }  
                    {
                        (enabledPayment && totalPrice >0 && !isCartError)?
                        <>
                        {
                            totalDataCount >0 &&  
                            <div className="payment-select" style={{ display:isFullPayWallet?'none':'' }}>
                                 <h2 className="title">Pay using</h2>
                                 <div className="radio-group">
                                     <div className="radio">
                                         <input 
                                         type="radio" 
                                         className="radio-input" 
                                         name="payment-type"
                                         value="paypal"
                                         checked={paymentType === 'paypal'}
                                         onChange={ handleChange }
                                         />
                                         <div className="radio-label">
                                             <div className="text">Pay Pal</div>
                                             <div className="icon"><i className="fa-brands fa-fw fa-paypal"></i></div>
                                         </div>
                                     </div>
                                     <div className="radio">
                                         <input 
                                         type="radio" 
                                         className="radio-input" 
                                         name="payment-type"
                                         value="stripe"
                                         checked={paymentType === 'stripe'}
                                         onChange={ handleChange }
                                         />
                                         <div className="radio-label">
                                             <div className="text">Credit Card</div>
                                             <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         }
                         {
                            totalDataCount >0 && 
                            (cards && cards.length > 0 && paymentType === 'stripe') &&
                            <div className="payment-select" style={{ display:isFullPayWallet?'none':'' }}>
                                 <h2 className="title">Card Type</h2>
                                 <div className="radio-group">
                                     <div className="radio">
                                         <input 
                                         type="radio" 
                                         className="radio-input" 
                                         name="card-type"
                                         value="existing"
                                         checked={isExisting}
                                         disabled={cards && cards.length === 0}
                                         onChange={ handleExistChange }
                                         />
                                         <div className="radio-label">
                                             <div className="text">Existing Card</div>
                                             <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                                         </div>
                                     </div>
                                     <div className="radio">
                                         <input 
                                         type="radio" 
                                         className="radio-input" 
                                         name="card-type"
                                         value="new"
                                         checked={!isExisting}
                                         onChange={ handleExistChange }
                                         />
                                         <div className="radio-label">
                                             <div className="text">New Card</div>
                                             <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         }
                         {
                            isExisting?
                             (totalDataCount > 0 && (localStorage.getItem('access_token') && paymentType === 'stripe')) && 
                             <>
                            {/* Card List */}
                            <div className="saved-cards">
                                     <div className="title">Saved Cards</div>
                                     <div className="card-list">
                                         <div className="card-item">
                                             {
                                                 cards.length > 0 &&
                                                 cards.map((item, index) =>
                                                 <div className="radio" key={index}>
                                                     <input 
                                                     type="radio" 
                                                     className="radio-input" 
                                                     name="saved-cards" 
                                                     value={item?.id}
                                                     checked={cardId === item?.id}
                                                     onChange={ handleCardChange }
                                                     />
                                                     <div className="radio-label">
                                                         <div className="label-icon">
                                                             {
                                                                (item.card?.brand && item.card?.brand === 'visa') &&
                                                                <div className="icon"><i className="fa-brands fa-fw fa-cc-visa"></i></div>
                                                             }
                                                             {
                                                                (item.card?.brand && item.card?.brand === 'amex') &&
                                                                <div className="icon"><i className="fa-brands fa-fw fa-cc-amex"></i></div>
                                                             }
                                                             {
                                                                (item.card?.brand && item.card?.brand === 'mastercard') &&
                                                                <div className="icon"><i className="fa-brands fa-fw fa-cc-mastercard"></i></div>
                                                             }
                                                         </div>
                                                         <div className="card-content">
                                                             <div className="card-number">
                                                                 <div className="data">**** **** **** {item?.card?.last4}</div>
                                                             </div>
                                                             <div className="card-details">
                                                                 <div className="expiry-date">
                                                                     <div className="card-text">Expires on</div>
                                                                     <div className="data">{item?.card?.exp_month}/{item?.card?.exp_year}</div>
                                                                 </div>
                                                                 {/* <div className="cvv-number">   
                                                                     <input type="number" className="input-style" value="" />
                                                                 </div> */}
                                                             </div>
                                                             <button 
                                                             className='btn-delete-action' 
                                                             type='button'
                                                             data-toggle="modal" 
                                                             data-target="#ModalCardDelete"
                                                             onClick={() => { setDeleteCardId(item?.id)} } 
                                                             ><i class="far fa-trash-alt"></i>
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </div>
                                                 )
                                             }
                                         </div>
                                         {
                                            cardId &&
                                            <Elements stripe={stripePromise}>
                                                <CvcFrom
                                                price={totalPrice}
                                                coupon={coupon}
                                                couponPrice={couponPrice}
                                                taxs={taxs}
                                                reward_amount={walletAmount}
                                                user_info={user_info}
                                                payment_method={cardId}
                                                cartInfo={cartInfo}
                                                setIsHide={setIsHide}
                                                />
                                            </Elements>
                                         }
                                         
                                         {/* <div className="add-card-btn">
                                             <button type="button" className="add-action" onClick={() => addNewCart() } >
                                                 <div className="icon"><i className="fa-light fa-plus"></i></div>
                                                 <div className="text">Add new card</div>
                                             </button>
                                         </div> */}
                                     </div>
                            </div>

                            {/* <div className='add-new-payment' style={{display: isAddCart ? 'block' : 'none' }}>
                                <div className="title">Add new card</div>
                                <div className='payment-card'>
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm type="2"/>
                                    </Elements>
                                </div>
                            </div> */}
                             </>
                             :''
                         }
                         {
                            !isExisting?
                             (totalDataCount >0 && (!localStorage.getItem('userId') && paymentType == 'stripe')) &&
                             <div className='payment-card' style={{ display:isFullPayWallet?'none':'' }}>
                                 <Elements stripe={stripePromise}>
                                     <CheckoutForm 
                                     type="1" 
                                     price={totalPrice}
                                     coupon={coupon}
                                     couponPrice={couponPrice}
                                     taxs={taxs}
                                     reward_amount={walletAmount}
                                     user_info={user_info}
                                     isCreateUser={isCreateUser}
                                     cartInfo={cartInfo}
                                     />
                                 </Elements>
                             </div>
                            :''
                         }
                         {
                             (paymentType === 'paypal' && !isFullPayWallet) &&
                             <PayPalButton                               
                                amount={totalPrice}
                                 //shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                 onSuccess={(details, data) => {
                                     const loadPaypalData = async () => {
                                         try {            
                                             const data1 = await storePaypalData({
                                                 cartId:localStorage.getItem('cartId'),
                                                 paymentData:data,
                                                 paymentDetails : details,
                                                 coupon:coupon,
                                                 couponPrice:couponPrice,
                                                 taxs:taxs,
                                                 reward_amount:walletAmount,
                                                 user_info:user_info,
                                                 isCreateUser:isCreateUser
                                             });  
                                             if(data1.status == 200) {
                                                 return navigate("/success?txn="+ data1?.data?.transaction_id);         
                                             }    
                                         } catch(err) {
                                             console.log(err.message);
                                         }
                                     }
                                     loadPaypalData();
                                 }}
                                 options={{
                                 clientId: paypalClientId()
                                 }}
                             />
                         }
                        </>
                        :(
                            (totalDataCount >0 && isCartError)?
                            <div className='col-lg-12'>
                                <div className='dashboard-action'>
                                    <div className="alert alert-warning" role="alert">
                                    {errorTicket} have no due amount.If you continue to next process first remove this {errorTicket.length>1?'tickets':'ticket'}
                                     {/*  Cart amount must be greater than 0 */}
                                    </div>
                                </div>
                            </div>:''
                        )                        
                    }  
                </div>
                <div className='col-lg-4'>
                    {
                        totalPrice >0 && 
                        (totalDataCount >0 && localStorage.getItem('access_token')) && 
                        <div className="coupon-code">
                            <h2 className="title">Coupon</h2>
                            <div className="coupons-input">
                                <div className="input-wrap">
                                    <input 
                                    type="text" 
                                    className="input-style" 
                                    placeholder="Apply Coupon"
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                    />
                                    <button 
                                    type="button" 
                                    className="action remove"
                                    onClick={() => onApplyCoupon() }
                                    disabled={isApplyBtn}
                                    >{(isApplyBtn)?'Proces...':"Apply"}</button>
                                </div>                          
                            </div>
                        </div>
                    }                    
                    <div className='payment-option'>
                        <div className="title">Payment Summary 
                        {
                            (totalDataCount && totalDataCount>0) ?
                            <span class="badge badge-primary ml-2">{totalDataCount}</span>
                            :''
                        }
                        </div>
                        <div className="payment-summary">
                            <div className="summary-list">
                                <div className="item">
                                    <div className="item-label">Sub total:</div>
                                    <div className="item-data">${total.toFixed(2)}</div>
                                </div>
                                {
                                    credit_card_fees >0 &&
                                    <div className="item">
                                        <div className="item-label">Credit Card Fee:</div>
                                        <div className="item-data">${credit_card_fees}</div>
                                    </div>
                                }
                                {
                                   platform_fees >0 &&  
                                   <div className="item">
                                        <div className="item-label">Platform Fee:</div>
                                        <div className="item-data">${platform_fees}</div>
                                    </div>
                                }
                                {
                                   taxs >0 &&  
                                   <div className="item">
                                        <div className="item-label">State Fee:</div>
                                        <div className="item-data">${taxs}</div>
                                    </div>
                                }
                                {
                                    (couponPrice >0 ) && 
                                    <div className="item">
                                        <div className="item-label">Discount:</div>
                                        <div className="item-data">- ${couponPrice}</div>
                                    </div>
                                }
                            </div>
                            <div className="summary-total">
                                <div className="label">Total Amount</div>
                                <div className="data">${totalPrice}</div>
                            </div>
                        </div>
                    </div>
                    {/* {
                        (localStorage.getItem('access_token') && totalRef > 0) && 
                        <div className="coupon-code">
                            <h2 className="title">Pay using Reward</h2>
                            <div className="reward-info-title">Your current reward <span>${(totalRef>0)?totalRef.toFixed(2):0}</span> </div>
                            <div className="coupons-input">
                                <div className="checkbox-group">
                                    <div className="custom-checkbox">
                                        <input 
                                        type="checkbox" 
                                        className="custom-checkbox-input" 
                                        checked={isPayUsing}
                                        onChange={checkPayUsing }
                                        />
                                        <div className="custom-checkbox-label">Defendit Reward</div>
                                    </div>
                                </div>
                                {
                                    isFullPayWallet && 
                                    <div className='submit-action' >
                                        <button type="button" className='payment-card-btn pay' disabled={isPay} onClick={onSubmit}><i className={(isPay)?'fa-regular fa-spinner fa-spin mr-2':"fa-solid fa-save mr-2"}></i> Pay ${walletAmount}</button>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    } */}
                </div>
            </div>
            {/* Delete cart Modal */}
            <div className="modal fade career-apply-modal delete-modal " id="ModalCartDelete" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content delete">
                    <div className="modal-header">
                        <h5 className="modal-title">Delete Cart</h5>
                        <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                        <i className="fal fa-fw fa-times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-text">Are you want to remove this ticket from cart, Click "Confirm" button.</div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                        Cancel
                        </button>
                        <button className="confirm-btn" disabled={isDeleteCart} onClick={onCartDelete}>
                        <i className={(isDeleteCart)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                        Confirm
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Delete card Modal */}
            <div className="modal fade career-apply-modal delete-modal " id="ModalCardDelete" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content delete">
                    <div className="modal-header">
                        <h5 className="modal-title">Card Delete</h5>
                        <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                        <i className="fal fa-fw fa-times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-text">Are you want to delete this card, Click "Confirm" button.</div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                        Cancel
                        </button>
                        <button className="confirm-btn" disabled={isDeleteCard} onClick={onStripeCardDelete}>
                        <i className={(isDeleteCard)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                        Confirm
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
      auth: state.auth
    }
}
export default connect(mapStateToProps, { cartList,cartDelete })(SecCheckout);
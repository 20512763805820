import React from 'react';

const SecTicket = ({tickets}) => {

  const dateFormat = issue_date => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d); //short
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${(mo<=9)?'0'+mo:mo}/${(da<=9)?'0'+da:da}/${ye}`;
  }

  return (
    <section className="section-tickets">
    <div className="container">
      <div className="tickets">
        <div className="ticket-inner">
          {
            tickets.length > 0 &&
            tickets.map((item, index) => 
            <div className="ticket-item" key={index}>
              <div className="ticket-start">
                <div className="ticket-id">
                  <div className="ticket-label">Ticket #</div>
                  <div className="ticket-data">{item?.summons_number}</div>
                </div>
                <div className="ticket-label">Issue Date</div>
                <div className="ticket-date">{item?.issue_date ? dateFormat(item?.issue_date) : ''}</div>
                <div className="ticket-meta">
                  <div className="meta-item">
                    <div className="meta-label">Fine</div>
                    <div className="meta-data">${item?.fine_amount}</div>
                  </div>
                  {/* <div className="meta-item">
                    <div className="meta-label">VC</div>
                    <div className="meta-data">50</div>
                  </div> */}
                  <div className="meta-item meta-item-full">
                    <div className="meta-label">Violation</div>
                    <div className="meta-data">{item?.violation}</div>
                  </div>
                </div>
              </div>
              <div className="ticket-end">
                <div className="ticket-status">
                  {
                    (item && item.amount_due == 0)?
                    <div className="status paid">Paid</div>:
                    (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount )?<div className="status pending">Pending</div>:
                    ''
                  }
                </div>
                <div className="ticket-plate">
                  <div className="plate-label">Plate No.</div>
                  <div className="plate-data">{item?.plate}</div>
                </div>
              </div>
            </div>
            )
          }
          {/* <div className="ticket-item">
            <div className="ticket-start">
              <div className="ticket-id">
                <div className="ticket-label">Ticket ID</div>
                <div className="ticket-data">#852643565</div>
              </div>
              <div className="ticket-date">20 Jun 2022</div>
              <div className="ticket-meta">
                <div className="meta-item">
                  <div className="meta-label">Fine</div>
                  <div className="meta-data">$115</div>
                </div>
                <div className="meta-item">
                  <div className="meta-label">VC</div>
                  <div className="meta-data">50</div>
                </div>
                <div className="meta-item meta-item-full">
                  <div className="meta-label">Violation</div>
                  <div className="meta-data">Crosswalk</div>
                </div>
              </div>
            </div>
            <div className="ticket-end">
              <div className="ticket-status">
                <div className="status pending">Pending</div>
              </div>
              <div className="ticket-plate">
                <div className="plate-label">Plate No.</div>
                <div className="plate-data">JDZ7126</div>
              </div>
            </div>
          </div> */}

          {/* <div className="ticket-item">
            <div className="ticket-start">
              <div className="ticket-id">
                <div className="ticket-label">Ticket ID</div>
                <div className="ticket-data">#852643565</div>
              </div>
              <div className="ticket-date">20 Jun 2022</div>
              <div className="ticket-meta">
                <div className="meta-item">
                  <div className="meta-label">Fine</div>
                  <div className="meta-data">$65</div>
                </div>
                <div className="meta-item">
                  <div className="meta-label">VC</div>
                  <div className="meta-data">74</div>
                </div>
                <div className="meta-item meta-item-full">
                  <div className="meta-label">Violation</div>
                  <div className="meta-data">Front or Back plate missing</div>
                </div>
              </div>
            </div>
            <div className="ticket-end">
              <div className="ticket-status">
                <div className="status paid">Paid</div>
              </div>
              <div className="ticket-plate">
                <div className="plate-label">Plate No.</div>
                <div className="plate-data">JDZ7126</div>
              </div>
            </div>
          </div>
          <div className="ticket-item">
            <div className="ticket-start">
              <div className="ticket-id">
                <div className="ticket-label">Ticket ID</div>
                <div className="ticket-data">#852643565</div>
              </div>
              <div className="ticket-date">20 Jun 2022</div>
              <div className="ticket-meta">
                <div className="meta-item">
                  <div className="meta-label">Fine</div>
                  <div className="meta-data">$65</div>
                </div>
                <div className="meta-item">
                  <div className="meta-label">VC</div>
                  <div className="meta-data">74</div>
                </div>
                <div className="meta-item meta-item-full">
                  <div className="meta-label">Violation</div>
                  <div className="meta-data">Front or Back plate missing</div>
                </div>
              </div>
            </div>
            <div className="ticket-end">
              <div className="ticket-status">
                <div className="status resolved">Resolved</div>
              </div>
              <div className="ticket-plate">
                <div className="plate-label">Plate No.</div>
                <div className="plate-data">JDZ7126</div>
              </div>
            </div>
          </div>
          <div className="ticket-item">
            <div className="ticket-start">
              <div className="ticket-id">
                <div className="ticket-label">Ticket ID</div>
                <div className="ticket-data">#852643565</div>
              </div>
              <div className="ticket-date">20 Jun 2022</div>
              <div className="ticket-meta">
                <div className="meta-item">
                  <div className="meta-label">Fine</div>
                  <div className="meta-data">$45</div>
                </div>
                <div className="meta-item">
                  <div className="meta-label">VC</div>
                  <div className="meta-data">21</div>
                </div>
                <div className="meta-item meta-item-full">
                  <div className="meta-label">No-Parking street cleaning</div>
                  <div className="meta-data">Crosswalk</div>
                </div>
              </div>
            </div>
            <div className="ticket-end">
              <div className="ticket-status">
                <div className="status pending">Pending</div>
              </div>
              <div className="ticket-plate">
                <div className="plate-label">Plate No.</div>
                <div className="plate-data">JDZ7126</div>
              </div>
            </div>
          </div>
          <div className="ticket-item">
            <div className="ticket-start">
              <div className="ticket-id">
                <div className="ticket-label">Ticket ID</div>
                <div className="ticket-data">#852643565</div>
              </div>
              <div className="ticket-date">20 Jun 2022</div>
              <div className="ticket-meta">
                <div className="meta-item">
                  <div className="meta-label">Fine</div>
                  <div className="meta-data">$115</div>
                </div>
                <div className="meta-item">
                  <div className="meta-label">VC</div>
                  <div className="meta-data">50</div>
                </div>
                <div className="meta-item meta-item-full">
                  <div className="meta-label">Violation</div>
                  <div className="meta-data">Crosswalk</div>
                </div>
              </div>
            </div>
            <div className="ticket-end">
              <div className="ticket-status">
                <div className="status paid">Paid</div>
              </div>
              <div className="ticket-plate">
                <div className="plate-label">Plate No.</div>
                <div className="plate-data">JDZ7126</div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
      <div className="ticket-content">
        <h2 className="ticket-title">Tickets And Status</h2>
        {/* <div className="ticket-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras hendrerit varius velit ut ultricies. Donec condimentum elit et orci posuere congue. Donec placerat nulla non purus pharetra, porta dapibus justo placerat.</div> */}
        <div className="tickets-image"><img src="assets/img/bg-parking.svg" alt=""/></div>
      </div>
    </div>
  </section>
  );
};

export default SecTicket;
import React, { useRef, useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Searching from "../../shared/searching";
import NothingFound from "../../shared/nothingFound";
import { getPlateDetails } from "../../actions/ticket";
import ImageShowModal from "../ImageShowModal";
import { updatePlate} from '../../actions/ticket';
import SecPlateOption from "../Plates/SecPlateOptions";
import {stripeCardList} from '../../actions/payment';
import toastr from 'toastr';
import { NavLink } from 'react-router-dom';

const SecPlateDetails = ({ id }) => {
  const [plate, setPlate] = useState({});
  const [apijson, setApijson] = useState({});
  const [viewPdf, setViewPdf] = useState('');
  const [viewLightBox, setViewLightBox] = useState({
    photo: null,
    isOpen: false,
  });
  const { photo, isOpen } = viewLightBox;

  const [sms_notification,setSmsNotification] = useState(false);
  const [email_notification,setEmailNotification] = useState(false);
  const [autoPay,setAutoPay] = useState(false);
  const [isEditBtn, setIsEditBtn] = useState(false);

  useEffect(() => {
    setSmsNotification(plate?.sms_notification);        
    setEmailNotification(plate?.email_notification);
    setAutoPay(plate?.auto_pay);
}, [plate]);

const switchSmsNotification =(e) => { 
  if(e.target.checked){
      setSmsNotification(true);
  }else{
      setSmsNotification(false);
  }
}

const switchEmailNotification =(e) => { 
  if(e.target.checked){
      setEmailNotification(true);
  }else{
      setEmailNotification(false);
  }
} 

const switchautoPayNotification = async (e) => { 
  if(e.target.checked){
      try {            
          const data = await stripeCardList({});  
          if(data.status == 200) {
              if(data.data && data.data.length>0){
                  setAutoPay(true);                        
              }else{
                  setAutoPay(false);
                  toastr.error('Must add atleast one card from checkout');
              }   
          }    
      } catch(err) {
          setAutoPay(false);
          console.log(err.message);
          toastr.error(err.message);
      }
  }else{
      setAutoPay(false);
  }
}

const onSubmit = async () => {
  setIsEditBtn(true);
  try {
    let msg = await updatePlate({
      id:id,
      sms_notification:sms_notification,
      email_notification:email_notification,
      auto_pay:autoPay,
      register_images:[],
      insurance_image:[],
    });
    if (msg.status === 200) {
      toastr.success(msg.message);
    } else {
      toastr.error(msg.message);
    }
    setIsEditBtn(false);
  } catch (err) {
    setIsEditBtn(false);
    toastr.error(err.message);
  }
}


  useEffect(() => {
    // Plate Details
    const loadPlateData = async () => {
      try {
        const data = await getPlateDetails({
          id: id,
        });
        if (data.status == 200) {
          setPlate(data.data);
          setApijson(data.data.api_json ? data.data.api_json[0] : []);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlateData();
  }, [id]);

  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d); //numeric
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da <= 9 ? "0" + da : da} ${mo <= 9 ? "0" + mo : mo} ${ye}`;
  };
  
  return (
    <section className="sec-plate-details">
      <div className="body-layout">
        <div className="body-content">
          {Object.keys(plate).length == 0 ? (
            plate.length == 0 ? (
              <NothingFound />
            ) : (
              <Searching />
            )
          ) : (
            <>
              <div className="user-search-part">
                <div className="title">Plate Details</div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="details-content">
                    <div className="details">
                      <div className="details-card">
                        <div className='vehicle-info'>
                          <div className='vehicle-number'>
                            {plate?.plate_no}
                          </div>
                          <div className='report-details'>
                              <NavLink to={"/edit-plate/"+ id}>
                                <button type="button" className='report-btn'><i class="far fa-edit mr-2"></i>Edit Plate</button>
                              </NavLink>
                          </div>
                        </div>
                        <div className="vehicle-name">
                          {apijson.licensePlateLookup
                            ? apijson.licensePlateLookup.name
                            : ""}
                        </div>
                        <div className="vehicle-metalist">
                          <div className="meta-item">
                            <div className="meta-label">Make</div>
                            <div className="meta-data">
                              {plate?.vehicle_make
                                ? plate?.vehicle_make
                                : "N/A"}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Model</div>
                            <div className="meta-data">
                              {plate?.vehicle_model
                                ? plate?.vehicle_model
                                : "N/A"}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Year</div>
                            <div className="meta-data">
                              {plate?.vehicle_year
                                ? plate?.vehicle_year
                                : "N/A"}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Color</div>
                            <div className="meta-data">
                              {plate?.vehicle_color
                                ? plate?.vehicle_color
                                : "N/A"}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Vehicle Type</div>
                            <div className="meta-data">
                              {plate?.vehicle_type
                                ? plate?.vehicle_type
                                : "N/A"}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Plate Expiration</div>
                            <div className="meta-data">
                              {plate?.warranty_expires_on
                                ? dateFormat(plate?.warranty_expires_on)
                                : "N/A"}
                            </div>
                          </div>
                          <div className="meta-item item-full">
                            <div className="meta-label">VIN/SN</div>
                            <div className="meta-data">
                              {plate?.vehicle_vin ? plate?.vehicle_vin : "N/A"}
                            </div>
                          </div>
                          {apijson && apijson.licensePlateLookup && (
                            <>
                              <div className="meta-item">
                                <div className="meta-label">Trim</div>
                                <div className="meta-data">
                                  {apijson.licensePlateLookup
                                    ? apijson.licensePlateLookup?.trim
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="meta-item">
                                <div className="meta-label">Engine</div>
                                <div className="meta-data">
                                  {apijson.licensePlateLookup
                                    ? apijson.licensePlateLookup?.engine
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="meta-item">
                                <div className="meta-label">Style</div>
                                <div className="meta-data">
                                  {apijson.licensePlateLookup
                                    ? apijson.licensePlateLookup?.style
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="meta-item">
                                <div className="meta-label">Transmission</div>
                                <div className="meta-data">
                                  {apijson.licensePlateLookup
                                    ? apijson.licensePlateLookup?.transmission
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="meta-item">
                                <div className="meta-label">Drive Type</div>
                                <div className="meta-data">
                                  {apijson.licensePlateLookup
                                    ? apijson.licensePlateLookup?.driveType
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="meta-item">
                                <div className="meta-label">Fuel</div>
                                <div className="meta-data">
                                  {apijson.licensePlateLookup
                                    ? apijson.licensePlateLookup?.fuel
                                    : "N/A"}
                                </div>
                              </div>
                            </>
                          )}
                          {plate && plate.inspection_expiration_date && (
                            <div className="meta-item">
                              <div className="meta-label">Expiration</div>
                              <div className="meta-data">
                                {plate?.inspection_expiration_date
                                  ? dateFormat(
                                      plate?.inspection_expiration_date
                                    )
                                  : "N/A"}
                              </div>
                            </div>
                          )}

                          {/* <div className='meta-item item-full'>
                                <div className='meta-label'>Note</div>
                                <div className='meta-data'>--</div>
                                </div> */}
                        </div>
                        {plate?.vendor_name && (
                          <>
                            <div className="details-title">
                              Purchase / Lease Information
                            </div>
                            <div className="vehicle-metalist">
                              {plate?.vendor_name && (
                                <>
                                  <div className="meta-item item-full">
                                    <div className="meta-label">Vendor</div>
                                    <div className="meta-data">
                                      {plate?.vendor_name}
                                    </div>
                                  </div>
                                </>
                              )}
                              {plate?.purchase_date && (
                                <>
                                  <div className="meta-item item-half">
                                    <div className="meta-label">
                                      Date of Acqusition
                                    </div>
                                    <div className="meta-data">
                                      {plate?.purchase_date
                                        ? dateFormat(plate?.purchase_date)
                                        : ""}
                                    </div>
                                  </div>
                                </>
                              )}
                              {plate?.purchase_price && (
                                <>
                                  <div className="meta-item item-half">
                                    <div className="meta-label">
                                      Value of Acqusition
                                    </div>
                                    <div className="meta-data">
                                      ${plate?.purchase_price}
                                    </div>
                                  </div>
                                </>
                              )}
                              {plate?.purchase_note && (
                                <>
                                  <div className="meta-item item-full">
                                    <div className="meta-label">Note</div>
                                    <div className="meta-data">
                                      {plate?.purchase_note}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}

                        {plate && plate?.vendor_Agent_name && (
                          <>
                            <div className="details-title">
                              Insurance Information
                            </div>
                            <div className="vehicle-metalist">
                              {plate?.vendor_Agent_name && (
                                <>
                                  <div className="meta-item item-full">
                                    <div className="meta-label">Vendor</div>
                                    <div className="meta-data">
                                      {plate?.vendor_Agent_name}
                                    </div>
                                  </div>
                                </>
                              )}
                              {plate?.vendor_insurance_expiratione && (
                                <>
                                  <div className="meta-item item-full">
                                    <div className="meta-label">Expiration</div>
                                    <div className="meta-data">
                                      {plate?.vendor_insurance_expiratione
                                        ? dateFormat(
                                            plate?.vendor_insurance_expiratione
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                </>
                              )}
                              {plate?.vendor_insurance_note && (
                                <>
                                  <div className="meta-item item-full">
                                    <div className="meta-label">Note</div>
                                    <div className="meta-data">
                                      {plate?.vendor_insurance_note}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {plate?.driver && (
                          <>
                            <div className="assigned-inner">
                              <div className="details-title">
                                Driver Assigned
                              </div>
                            </div>
                            <div className="assigned-drivers">
                              <div className="driver">
                                <div className="driver-image input-file cursor ">
                                  {plate.driver.driver_image && (
                                    <img
                                      src={plate.driver.driver_image}
                                      onClick={() =>
                                        setViewLightBox({
                                          photo: plate?.driver?.driver_image,
                                          isOpen: true,
                                        })
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="driver-content">
                                  <div className="driver-name">
                                    {plate.driver.name
                                      ? plate.driver.name
                                      : plate.driver.first_name +
                                        " " +
                                        plate.driver.last_name}
                                  </div>
                                  <div className="driver-meta">
                                    <div className="meta-label">
                                      License No.
                                    </div>
                                    <div className="meta-data">
                                      {plate.driver
                                        ? plate.driver.license_number
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className='driver-info pdf-content'>
                            {
                                ((plate?.ticket_galleries &&  plate?.ticket_galleries.length>0)) && 
                                <div className='driver-info-content'>
                                    <div className='driver-info-title'>Registration card</div> 
                                    <div className='img-list'>
                                    {
                                        plate?.ticket_galleries.map((item,index) => (
                                            item.type == 'registration-card' &&
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item?.extension === 'pdf' || item?.extension === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item?.original_name}
                                                    onClick={() => setViewPdf(item?.file_name)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />
                                                </>:
                                                <>
                                                <img 
                                                src={item?.file_name} 
                                                title={item?.original_name}
                                                onClick={() => setViewLightBox({ photoIndex:index, isOpen: true, photo: item?.file_name })}
                                                />
                                                </>
                                            }                                                
                                            </div>
                                        ))
                                    }
                                    
                                    </div>
                                </div>
                            }
                            {
                                ((plate?.ticket_galleries &&  plate?.ticket_galleries.length>0)) && 
                                <div className='driver-info-content'>
                                    <div className='driver-info-title'>Insurance Card</div> 
                                    <div className='img-list'>
                                    {
                                        plate?.ticket_galleries.map((item,index) => (
                                            item.type == 'insurance-card' &&
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item?.extension === 'pdf' || item?.extension === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item?.original_name}
                                                    onClick={() => setViewPdf(item?.file_name)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />
                                                </>:
                                                <>
                                                <img 
                                                src={item?.file_name} 
                                                title={item?.original_name}
                                                onClick={() => setViewLightBox({ photoIndex:index, isOpen: true, photo: item?.file_name })}
                                                />
                                                </>
                                            }                                                
                                            </div>
                                        ))
                                    }
                                    
                                    </div>
                                </div>
                            }
                           
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='add-new-driver'>
                <div className='driver-info'>
                    <div className='notifications-option'>
                      <SecPlateOption 
                        sms_notification={sms_notification} 
                        switchSmsNotification={switchSmsNotification}
                        email_notification ={email_notification}
                        switchEmailNotification={switchEmailNotification}
                        autoPay={autoPay}
                        switchautoPayNotification={switchautoPayNotification}
                      />
                    </div>
                </div>
                <div className='submit-action mt-3'>
                    <button type="button" className='btn-submit-action' disabled={isEditBtn} onClick={onSubmit}>
                    <i className={(isEditBtn)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                        Update
                    </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <ImageShowModal
          photo={photo}
          isOpen={isOpen}
          setViewLightBox={setViewLightBox}
        />
      )}
      {/* DPdf View Modal */}
      {
                viewPdf && 
                <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf('')}>
                                <i className="fal fa-fw fa-times"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                                    <p>There was an error downloading the invoice.</p>
                                </object>
                            </div>
                        </div>
                    </div>
                </div>

            }
    </section>
  );
};

export default SecPlateDetails;

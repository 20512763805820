import React,{ useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  getPageDetails,
  getBanners
 } from '../actions/site';

const DefaultFooter = ({ settings }) => {
  const [pageId,setPageId] = useState({});
  const [banner,setBanners] = useState({});
  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'about-us'
        });  
        if(data.status == 200) {
          setPageId(data.data._id)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();
  }, [ ]); 

  useEffect(() => {
    //Banners
    const loadBannersData = async () => {
        try {            
          const data = await getBanners({
            type:'page',
            type_id:pageId
          });  
          if(data.status == 200) {
              setBanners(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadBannersData();
  },[pageId]);

    return (
        <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-content">
                <div className="brand">
                  <NavLink className="logo" to="/"><img src="/assets/img/logo.png" alt=""/></NavLink>
                </div>
                {
                  banner &&
                  <div className="footer-text" dangerouslySetInnerHTML={{__html:banner[0]?.description}}>
                  </div>
                }
                
              </div>
            </div>
            <div className="col-lg-3">
            <div className="footer-content">
                <h2 className="footer-title">Company Info</h2>
                <div className="footer-links">
                  <div className="link-item"><NavLink to="/" className={isActive => "link" + (!isActive ? " active" : "")}>Home</NavLink></div>
                  <div className="link-item"><NavLink to="/about-us" className={isActive => "link" + (!isActive ? " active" : "")} >About Us</NavLink></div>
                  <div className="link-item"><NavLink to="/solutions" className={isActive => "link" + (!isActive ? " active" : "")}>Solutions</NavLink></div>
                  {/* <div className="link-item"><NavLink to="/career" className={isActive => "link" + (!isActive ? " active" : "")}>Career</NavLink></div> */}
                  <div className="link-item"><NavLink to="/contact-us" className={isActive => "link" + (!isActive ? " active" : "")}>Contact Us</NavLink></div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-content">
                <h2 className="footer-title">Contact</h2>
                <div className="footer-contact">
                  {
                      settings?.contact_no && 
                      <a className="contact-item" href={(settings && settings.contact_no)?'tel:1'+settings.contact_no:''}><i className="fa-solid fa-fw fa-phone-arrow-down-left mr-2"></i>{settings?.contact_no}</a>
                  }
                  {
                    settings?.email && 
                    <a className="contact-item" href={(settings && settings.email)?'mailto:'+settings.email:''}><i className="fa-solid fa-fw fa-envelope-circle-check mr-2"></i>{settings?.email}</a>
                  }
                  {
                    settings?.whatsapp_no && 
                    <a className="contact-item" href={(settings && settings.whatsapp_no)?'https://api.whatsapp.com/send?phone=1'+settings.whatsapp_no:''}><i className="fa-brands fa-fw fa-whatsapp mr-2"></i>{settings?.whatsapp_no}</a>
                  }
                  {
                    settings?.address && 
                    <a className="contact-item" target="_blank" href="https://www.google.com/maps/place/35-37+36th+St,+Queens,+NY+11106,+USA/@40.7547351,-73.9274013,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25f2f9b964315:0x4fd427abd900fce7!8m2!3d40.7547311!4d-73.9252126"><i className="fa-solid fa-fw fa-location-check mr-2"></i>{settings?.address}</a>
                  }                  
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-content">
                {
                  (settings?.playstore_link || settings?.appstore_link) && 
                  <h2 className="footer-title">Downloads</h2>
                }                
                <div className="footer-download">
                  {
                    settings?.playstore_link &&
                    <a className="download-item" target="_blank" href={settings?.playstore_link}>
                      <div className="icon"><img src="assets/img/google.svg" alt=""/></div>
                      <div className="title">Google Play</div>
                    </a>
                  }
                  {
                    settings?.appstore_link &&
                    <a className="download-item" target="_blank" href={settings?.appstore_link}>
                      <div className="icon"><img src="assets/img/apple.svg" alt=""/></div>
                      <div className="title">Apple Store</div>
                    </a>
                  }
                  
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="footer-bottom">
                <div className="copyright">{settings?.copyright}</div>
                <div className="other-links">
                  <NavLink to="/terms-of-service" className={isActive => "links-item" + (!isActive ? " active" : "")} >Terms of Service</NavLink>
                  <NavLink to="/privacy-policy" className={isActive => "links-item" + (!isActive ? " active" : "")} >Privacy policies</NavLink>
                </div>
                <div className="social">
                  {
                    settings?.facebook_link && 
                    <a className="social-item" target="_blank" rel="nofollow" href={settings?.facebook_link}><i className="fa-brands fa-fw fa-facebook"></i></a>
                  }
                  {
                    settings?.twitter_link &&
                    <a className="social-item" target="_blank" rel="nofollow" href={settings?.twitter_link}><i className="fa-brands fa-fw fa-twitter"></i></a>
                  }
                  {
                    settings?.instagram_link && 
                    <a className="social-item" target="_blank" rel="nofollow" href={settings?.instagram_link}><i className="fa-brands fa-fw fa-instagram"></i></a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>       
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div class="elfsight-app-62c363b7-a232-4e5d-994e-0249626dd566"></div>
      </footer>
    );
};

export default DefaultFooter;
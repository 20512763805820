import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { storeCart } from "../actions/cart";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/Checkout/CheckoutForm";
import CvcFrom from "../components/Checkout/CvcFrom";
import { PayPalButton } from "react-paypal-button-v2";
import {
  storeTlcPaypalData,
  getTransCheckTicket,
  getEmailToUser,
  checkPhone,
  checkEmail,
  stripeCardList,
  stripeCardDelete,
  getTLCApplyCoupon,
} from "../actions/payment";
import NothingFound from "../shared/nothingFound";
import Searching from "../shared/searching";
import InputMask from "react-input-mask";
import { stripePublishKey, paypalClientId } from "../helpers";

const TlcTicketDetailsModal = ({
  ticketInfo,
  settings,
  ticket_id,
  isSearchTlcTicket,
}) => {
  const navigate = useNavigate();
  const closeRef = useRef(null);
  //Paypal & stripe
  const stripePromise = loadStripe(stripePublishKey());

  const [isSattle, setIssattle] = useState(false);
  const [types, setTypes] = useState("sattle");
  const [price, setPrice] = useState(settings?.tlc_amount);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isPrecessing, setIsProcessing] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [trans, setTrans] = useState([]);

  //Fees Calculation
  const [credit_card_fees, setCreditCardFees] = useState("");
  const [platform_fees, setPlatformFees] = useState("");

  const [cards, setCards] = useState([]);
  const [isExisting, setIsExisting] = useState(false);
  const [cardId, setCardId] = useState("");
  const [isDeleteCard, setIsDeleteCard] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState("");
  const [totalCard, setTotalCard] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isReset, setReset] = useState(false);
  useEffect(() => {
    // Type Check
    let total = 0;
    if (types === "sattle") {
      total = settings?.tlc_amount;
    } else if (types === "fight") {
      total = settings?.fight_tlc_amount;
    }
    // For Credit card
    let cc_fees = 0;
    if (settings?.creadit_fee) {
      if (settings?.creadit_fee_type === "percentage") {
        cc_fees = (parseFloat(total) * parseFloat(settings?.creadit_fee)) / 100;
      } else {
        cc_fees = settings?.creadit_fee;
      }
    }
    // For Platform
    let platform_fees = 0;
    if (settings?.processing_fee) {
      if (settings?.processing_fee_type === "percentage") {
        platform_fees =
          (parseFloat(total) * parseFloat(settings?.processing_fee)) / 100;
      } else {
        platform_fees = settings?.processing_fee;
      }
    }
    setCreditCardFees(cc_fees.toFixed(2));
    setPlatformFees(platform_fees.toFixed(2));
    setPrice(
      (
        parseFloat(total) +
        parseFloat(platform_fees) +
        parseFloat(cc_fees)
      ).toFixed(2)
    );
  }, [settings, types,isReset]);

  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(d); //short
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${mo <= 9 ? "0" + mo : mo}/${da <= 9 ? "0" + da : da}/${ye}`;
  };

  const closeOneModal = () => {
    const modal = document.getElementById("ModalTlcTicketDetails");
    modal.classList.remove("show");
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("style", "display: none");
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");
    document.body.removeChild(modalBackdrops[0]);
  };

  useEffect(() => {
    if (cards && cards.length > 0) {
      setIsExisting(true);
    } else {
      setIsExisting(false);
    }
  }, [cards]);

  const handleExistChange = (e) => {
    const { name, value } = e.target;
    if (value === "existing") {
      setIsExisting(true);
    } else {
      setIsExisting(false);
    }
  };

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    setCardId(value);
  };

  useEffect(() => {
    // Card List
    const loadCardList = async () => {
      try {
        const data = await stripeCardList({});
        if (data.status == 200) {
          setCards(data.data);
          setTotalCard(data.data.length);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCardList();
  }, []);

  // Card Delete
  const onStripeCardDelete = async () => {
    setIsDeleteCard(true);
    try {
      const data = await stripeCardDelete({
        cardId: deleteCardId,
        total: totalCard,
      });
      if (data.status === 200) {
        closeOneModal2();
        setDeleteCardId("");
        setIsDeleteCard(false);
        setCards(data.data);
        setTotalCard(data.data.length);
      } else {
        setIsDeleteCard(false);
        setDeleteCardId("");
        toastr.error(data.message);
        console.log(data.message);
      }
    } catch (err) {
      toastr.error(err.message);
      setIsDeleteCard(false);
      setDeleteCardId("");
    }
  };

  const closeOneModal2 = () => {
    const modal = document.getElementById("ModalCardDelete");
    modal.classList.remove("show");
    modal.removeAttribute("role");
    modal.removeAttribute("aria-modal");
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("style", "display: none");
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");
    document.body.removeChild(modalBackdrops[0]);
    document.body.classList.remove("modal-open");
    document.body.style = "";
  };

  const onSattle = async (type) => {
    if (isSattle) {
      setIssattle(false);
    } else {
      setIssattle(true);
    }
    if (type === 1) {
      setTypes("sattle");
      setReset(!isReset);
    } else {
      setTypes("fight");
      setReset(!isReset);
    }
  };

  const onCheckEmail = async () => {
    setIsProcessing(true);
    if (!email) {
      toastr.error("Enter your email address");
      setIsUser(false);
    } else if (!phone) {
      toastr.error("Enter your phone number");
      setIsUser(false);
    } else {
      try {
        const chkEmail = await checkEmail({
          email: email,
        });
        if (chkEmail.status === 200) {
          try {
            const chk = await checkPhone({
              phone: phone
                ? phone.replace(/[(|)|_|-]/g, "").replace(/\s/g, "")
                : "",
            });
            if (chk.status === 200) {
              setIsProcessing(false);
              setIsUser(true);
            } else {
              toastr.error(chk.message);
              setIsProcessing(false);
            }
          } catch (err) {
            toastr.error(err.message);
            setIsProcessing(false);
          }
        } else {
          toastr.error(chkEmail.message);
          setIsProcessing(false);
        }
      } catch (err) {
        toastr.error(err.message);
        setIsProcessing(false);
      }
      // try {
      //     const chk = await checkPhone({
      //       phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):''
      //     });
      //     if(chk.status === 200) {
      //         setIsProcessing(true)
      //         setIsUser(true);
      //     } else {
      //         toastr.error(chk.message);
      //     }
      // } catch(err) {
      //     console.log(err.message);
      // }
    }
  };

  useEffect(() => {
    const loadTransCheckTicketData = async () => {
      try {
        const data = await getTransCheckTicket({
          ticket_id: ticket_id,
        });
        if (data.status == 200) {
          setTrans(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadTransCheckTicketData();
  }, [ticket_id]);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [localStorage.getItem("access_token")]);

  useEffect(() => {
    if (ticketInfo) {
      setEmail("");
      setPhone("");
    } else {
      setIsProcessing(false);
      setTrans([]);
      setEmail("");
      setPhone("");
    }
    if (localStorage.getItem("access_token")) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
    setIssattle(false);
  }, [ticketInfo]);

  const applyCoupon = async () => {
    try {
      let response = await getTLCApplyCoupon({
        coupon: coupon,
        isSettle: types === "sattle" ? true : false,
        total_price: price,
      });
      if (response.status === 200) {
        setPrice(response.data.total);
        setDiscount(response.data.couponPrice);
      } else {
        toastr.error(response.message);
      }
    } catch (error) {
      //console.log(error.Error,'error');
    }
  };

  return (
    <>
      <div
        className="modal fade career-apply-modal"
        id="ModalTlcTicketDetails"
        tabIndex="-1"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="header-text">
                <h5 className="modal-title" id="ModalCenterTitle">
                  Ticket Information
                </h5>
              </div>
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                onClick={() => {
                  setDiscount(0);
                  setCoupon("");
                  setReset(!isReset);
                }}
              >
                <i className="fa-light fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="details">
                <div className="details-card">
                  <div className="vehicle-name">
                    {ticketInfo?.ticket_number
                      ? ticketInfo?.ticket_number
                      : ticket_id}
                  </div>
                  <div className="vehicle-info">
                    <div className="vehicle-number">{ticketInfo?.plate}</div>
                  </div>
                  {ticketInfo ? (
                    ticketInfo &&
                    !isSattle && (
                      <div className="vehicle-metalist">
                        <div className="meta-item">
                          <div className="meta-label">Violation Date</div>
                          <div className="meta-data">
                            {ticketInfo?.violation_date
                              ? dateFormat(ticketInfo?.violation_date)
                              : ""}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">
                            Violation Location Borough
                          </div>
                          <div className="meta-data">
                            {ticketInfo?.violation_location_borough}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">City</div>
                          <div className="meta-data">
                            {ticketInfo?.violation_location_city}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Zip Code</div>
                          <div className="meta-data">
                            {ticketInfo?.violation_location_zip_code}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Violation Details</div>
                          <div className="meta-data">
                            {ticketInfo?.violation_details
                              ? ticketInfo?.violation_details
                              : ticketInfo?.violation_description}
                          </div>
                        </div>
                        <div className="meta-item item-two-third">
                          <div className="meta-label">
                            Respondent First Name
                          </div>
                          <div className="meta-data">
                            {ticketInfo?.respondent_first_name}
                          </div>
                        </div>
                        <div className="meta-item item-full">
                          <div className="meta-label">Respondent Last Name</div>
                          <div className="meta-data">
                            {ticketInfo?.respondent_last_name}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">
                            Respondent Address Borough
                          </div>
                          <div className="meta-data">
                            {ticketInfo?.respondent_address_borough}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Issueing Agency</div>
                          <div className="meta-data">
                            {ticketInfo?.issuing_agency}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Decision Date</div>
                          <div className="meta-data">
                            {ticketInfo?.decision_date
                              ? dateFormat(ticketInfo?.decision_date)
                              : ""}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Hearing Date</div>
                          <div className="meta-data">
                            {ticketInfo?.hearing_date
                              ? dateFormat(ticketInfo?.hearing_date)
                              : ""}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Paid Amount</div>
                          <div className="meta-data">
                            {ticketInfo?.paid_amount}
                          </div>
                        </div>
                        <div className="meta-item item-two-third">
                          <div className="meta-label">Penalty Imposed</div>
                          <div className="meta-data">
                            {ticketInfo?.penalty_imposed}
                          </div>
                        </div>
                        {/* <div className='meta-item'>
                                      <div className='meta-label'>Payment Amount</div>
                                      <div className='meta-data'>{ticketInfo?.payment_amount}</div>
                                      </div> */}
                        <div className="meta-item">
                          <div className="meta-label">
                            Total Violation Amount
                          </div>
                          <div className="meta-data">
                            {ticketInfo?.total_violation_amount
                              ? ticketInfo?.total_violation_amount
                              : ticketInfo?.due_amount}
                          </div>
                        </div>
                      </div>
                    )
                  ) : isSearchTlcTicket ? (
                    <Searching />
                  ) : isSattle ? (
                    ""
                  ) : (
                    ""
                  )}
                  {isSattle && (
                    <div className="container-fluid">
                      {!localStorage.getItem("access_token") && !isUser ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="control-label required">
                                Email
                              </div>
                              <div className="ticket-info">
                                <div className="ticket-input mb-3">
                                  <input
                                    className="input"
                                    type="text"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="control-label required">
                                Phone
                              </div>
                              <div className="ticket-info">
                                <div className="ticket-input mb-3">
                                  <InputMask
                                    mask="(999) 999-9999"
                                    className="input"
                                    type="text"
                                    placeholder="Enter Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="input-file">
                            <button
                              className="btn-action"
                              type="button"
                              onClick={() => onCheckEmail()}
                              disabled={isPrecessing}
                            >
                              <i
                                className={
                                  isPrecessing
                                    ? "fa-regular fa-spinner fa-spin mr-2"
                                    : "fa-regular fa-arrow-right mr-2"
                                }
                              ></i>
                              Next
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {isUser && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="payment-option">
                              <div className="title">Coupon</div>
                              <div className="payment-summary">
                                <div className="summary-list">
                                  <div className="item ticket-info">
                                    <div className="ticket-input d-flex gap-3 align-items-center">
                                      <input
                                        className="input"
                                        type="text"
                                        placeholder="Coupon"
                                        defaultValue=""
                                        value={coupon}
                                        onChange={(e) => {
                                          setCoupon(e.target.value);
                                        }}
                                        style={{
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 2px 16px",
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-success px-4 py-6 ml-4"
                                        onClick={() => applyCoupon()}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="payment-option">
                              <div className="title">Payment Summary</div>
                              <div className="payment-summary">
                                <div className="summary-list">
                                  <div className="item">
                                    <div className="item-label">Sub total:</div>
                                    {types === "sattle" ? (
                                      <div className="item-data">
                                        ${settings?.tlc_amount}
                                      </div>
                                    ) : (
                                      <div className="item-data">
                                        ${settings?.fight_tlc_amount}
                                      </div>
                                    )}
                                  </div>
                                  {credit_card_fees > 0 && (
                                    <div className="item">
                                      <div className="item-label">
                                        Credit Card Fee:
                                      </div>
                                      <div className="item-data">
                                        ${credit_card_fees}
                                      </div>
                                    </div>
                                  )}
                                  {platform_fees > 0 && (
                                    <div className="item">
                                      <div className="item-label">
                                        Platform Fee:
                                      </div>
                                      <div className="item-data">
                                        ${platform_fees}
                                      </div>
                                    </div>
                                  )}
                                   {discount > 0 && (
                                  <div className="item">
                                    <div className="item-label">Discount:</div>
                                    <div className="item-data">-${discount}</div>
                                  </div>
                                )}
                                </div>

                               
                                <div className="summary-total">
                                  <div className="label">Total Amount</div>
                                  <div className="data">${price}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            {localStorage.getItem("access_token")
                              ? cards &&
                                cards.length > 0 && (
                                  <div className="payment-select">
                                    <div className="radio-group">
                                      <div className="radio">
                                        <input
                                          type="radio"
                                          className="radio-input"
                                          name="card-type"
                                          value="existing"
                                          checked={isExisting}
                                          disabled={cards && cards.length === 0}
                                          onChange={handleExistChange}
                                        />
                                        <div className="radio-label">
                                          <div className="text">
                                            Existing Card
                                          </div>
                                          <div className="icon">
                                            <i className="fa-regular fa-fw fa-credit-card"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="radio">
                                        <input
                                          type="radio"
                                          className="radio-input"
                                          name="card-type"
                                          value="new"
                                          checked={!isExisting}
                                          onChange={handleExistChange}
                                        />
                                        <div className="radio-label">
                                          <div className="text">New Card</div>
                                          <div className="icon">
                                            <i className="fa-regular fa-fw fa-credit-card"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              : ""}
                            {isExisting &&
                            localStorage.getItem("access_token") ? (
                              <div className="saved-cards">
                                <div className="title">Saved Cards</div>
                                <div className="card-list">
                                  <div className="card-item">
                                    {cards.length > 0 &&
                                      cards.map((item, index) => (
                                        <div className="radio" key={index}>
                                          <input
                                            type="radio"
                                            className="radio-input"
                                            name="saved-cards"
                                            value={item?.id}
                                            checked={cardId === item?.id}
                                            onChange={handleCardChange}
                                          />
                                          <div className="radio-label">
                                            <div className="label-icon">
                                              {item.card?.brand &&
                                                item.card?.brand === "visa" && (
                                                  <div className="icon">
                                                    <i className="fa-brands fa-fw fa-cc-visa"></i>
                                                  </div>
                                                )}
                                              {item.card?.brand &&
                                                item.card?.brand === "amex" && (
                                                  <div className="icon">
                                                    <i className="fa-brands fa-fw fa-cc-amex"></i>
                                                  </div>
                                                )}
                                              {item.card?.brand &&
                                                item.card?.brand ===
                                                  "mastercard" && (
                                                  <div className="icon">
                                                    <i className="fa-brands fa-fw fa-cc-mastercard"></i>
                                                  </div>
                                                )}
                                            </div>
                                            <div className="card-content">
                                              <div className="card-number">
                                                <div className="data">
                                                  **** **** ****{" "}
                                                  {item?.card?.last4}
                                                </div>
                                              </div>
                                              <div className="card-details">
                                                <div className="expiry-date">
                                                  <div className="card-text">
                                                    Expires on
                                                  </div>
                                                  <div className="data">
                                                    {item?.card?.exp_month}/
                                                    {item?.card?.exp_year}
                                                  </div>
                                                </div>
                                                {/* <div className="cvv-number">   
                                                                                        <input type="number" className="input-style" value="" />
                                                                                    </div> */}
                                              </div>
                                              <button
                                                className="btn-delete-action"
                                                type="button"
                                                data-toggle="modal"
                                                data-target="#ModalCardDelete"
                                                onClick={() => {
                                                  setDeleteCardId(item?.id);
                                                }}
                                              >
                                                <i class="far fa-trash-alt"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                  {cardId && (
                                    <Elements stripe={stripePromise}>
                                      <CvcFrom
                                        type="3"
                                        price={price}
                                        ticket_id={ticket_id}
                                        payment_for="assignee"
                                        payment_type={types}
                                        email={email}
                                        phone={
                                          phone
                                            ? phone
                                                .replace(/[(|)|_|-]/g, "")
                                                .replace(/\s/g, "")
                                            : ""
                                        }
                                        isHome={true}
                                        payment_method={cardId}
                                        coupon={coupon}
                                        couponPrice={discount}
                                      />
                                    </Elements>
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {!isExisting ? (
                              <div className="payment-card">
                                <Elements stripe={stripePromise}>
                                  <CheckoutForm
                                    type="3"
                                    price={price}
                                    ticket_id={ticket_id}
                                    payment_for="assignee"
                                    payment_type={types}
                                    email={email}
                                    phone={
                                      phone
                                        ? phone
                                            .replace(/[(|)|_|-]/g, "")
                                            .replace(/\s/g, "")
                                        : ""
                                    }
                                    isHome={true}
                                    couponPrice={discount}
                                    coupon={coupon}
                                  />
                                </Elements>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-2">
                            <div className="pay-or">or</div>
                          </div>
                          <div className="col-lg-5">
                            <div className="payment-mode">
                              <PayPalButton
                                amount={price}
                                onSuccess={(details, data) => {
                                  const loadPaypalData = async () => {
                                    try {
                                      const user = await getEmailToUser({
                                        email: email,
                                        phone: phone
                                          ? phone
                                              .replace(/[(|)|_|-]/g, "")
                                              .replace(/\s/g, "")
                                          : "",
                                      });
                                      if (user.status === 200) {
                                        const data1 = await storeTlcPaypalData({
                                          ticket_no: ticket_id,
                                          paymentData: data,
                                          paymentDetails: details,
                                          payment_for: "assignee",
                                          payment_type: types,
                                          email: email,
                                          phone: phone
                                            ? phone
                                                .replace(/[(|)|_|-]/g, "")
                                                .replace(/\s/g, "")
                                            : "",
                                          userId: user.data._id,
                                          couponPrice:discount,
                                          coupon:coupon
                                        });
                                        if (data1.status == 200) {
                                          closeOneModal();
                                          return navigate(
                                            "/authorization-form/" + ticket_id
                                          );
                                        }
                                      }
                                    } catch (err) {
                                      console.log(err.message);
                                    }
                                  };
                                  loadPaypalData();
                                }}
                                options={{
                                  clientId: paypalClientId(),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isSattle && trans && trans.length === 0 ? (
              <>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => onSattle(1)}
                  >
                    <i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>
                    Settle Ticket
                  </button>
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => onSattle(2)}
                  >
                    <i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>
                    Fight Ticket
                  </button>
                </div>
              </>
            ) : !isSattle ? (
              <div className="col-lg-12">
                <div className="dashboard-action">
                  <div className="alert alert-warning" role="alert">
                    #
                    {ticketInfo?.ticket_number
                      ? ticketInfo?.ticket_number
                      : ticketInfo?.summons_number}{" "}
                    already paid
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* Delete card Modal */}
      <div
        className="modal fade career-apply-modal delete-modal "
        id="ModalCardDelete"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content delete">
            <div className="modal-header">
              <h5 className="modal-title">Card Delete</h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="close"
                className="modal-close"
              >
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-text">
                Are you want to delete this card, Click "Confirm" button.
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="cancel-btn mr-4"
                data-dismiss="modal"
                type="button"
              >
                Cancel
              </button>
              <button
                className="confirm-btn"
                disabled={isDeleteCard}
                onClick={onStripeCardDelete}
              >
                <i
                  className={
                    isDeleteCard
                      ? "fa-regular fa-spinner fa-spin mr-2"
                      : "fa-regular fa-save mr-2"
                  }
                ></i>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {})(TlcTicketDetailsModal);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SecDashboardCount from "./SecDashboardCount";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import toastr from "toastr";
import { getTicketList } from "../../actions/ticket";
import { storeCart } from "../../actions/cart";
import { v4 as uuidv4 } from "uuid";
import NothingFound from "../../shared/nothingFound";
import Searching from "../../shared/searching";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { checkDriverVehicle } from "../../actions/site";

const SecTickets = ({ settings, storeCart }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [tickets, setTickets] = useState({});
  const [ticket_ids, setTicketsIds] = useState([]);
  const [cart_id, setCartId] = useState(localStorage.getItem("cartId"));
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [status, setStatus] = useState("");
  const [defaultStatus, setDefaultStatus] = useState("Select Status");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [showTicket, setShowTicket] = useState("pending");
  const [pendingTickets, setPendingTickets] = useState(0);
  const [isProcessing,setIsProcessing] = useState(false);
  const [isAddtocart,setIsAddtocart] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(async () => {
        try {
          const data = await checkDriverVehicle();
          if (data.status == 200) {
            if (data.step == 1) {
              return navigate("/add-driver");
            } else if (data.step == 2) {
              return navigate("/add-plate");
            }
          } else {
            return navigate("/add-driver");
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);

  useEffect(() => {
    // Ticket List
    const loadTickets = async () => {
      setLoading(true);
      try {
        const data = await getTicketList({
          keyword: keyword,
          limit: limit,
          status: status,
          offset: offset,
          ticket_type: "dmv",
          payment: showTicket,
          //plate_no:params.get('plate')
        });
        if (data.status == 200) {
          setTickets(data.data);
        }
        setTotalDataCount(data.count);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };
    loadTickets();
  }, [keyword, limit, offset, status, showTicket]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
  };

  useEffect(() => {
    if (localStorage.getItem("cartId")) {
      setCartId(localStorage.getItem("cartId"));
    } else {
      localStorage.setItem("cartId", uuidv4());
      setCartId(localStorage.getItem("cartId"));
    }
  }, []);

  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d); //numeric
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${mo <= 9 ? "0" + mo : mo} ${da}, ${ye}`;
  };

  const sum = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum = sum + parseFloat(data[i]?.amount ? data[i]?.amount : 0);
    }
    return sum;
  };

  const tabSwitch = (type) => {
    setOffset(0);
    setShowTicket(type);
    setTicketsIds([]);
    setLoading(false);
    setTotalDataCount(0);
  };

  // const hadelAllTicket = async (e) => {
  //   if (e.target.checked) {
  //     let ticketIds = [];
  //     try {
  //       const data = await getTicketList({
  //         ticket_type: "dmv",
  //         payment: "pending",
  //       });
  //       if (data.status == 200) {
  //         data.data.length > 0 &&
  //           data.data.map((item) =>
  //             item && (item?.due_amount === "0" || item?.due_amount === null)
  //               ? ""
  //               : item && item.transaction && item.transaction?.payment_status && item.transaction.payment_status === "success"
  //               ? ""
  //               : item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount
  //               ? ticketIds.push({
  //                   id: item?._id,
  //                   plate_no: item?.plate_no,
  //                   ticket_id: item.summons_number,
  //                   amount: item?.due_amount,
  //                 })
  //               : ticketIds.push({
  //                   id: item?._id,
  //                   plate_no: item?.plate_no,
  //                   ticket_id: item.summons_number,
  //                   amount: item?.due_amount,
  //                 })
  //           );
  //       }
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //     setTicketsIds(ticketIds);
  //   } else {
  //     setTicketsIds([]);
  //   }
  // };

  const addToCart = async (data) => {
    try {
      let msg = await storeCart({
        userId: localStorage.getItem("userId"),
        cartId: cart_id,
        ticket_ids: [data],
      });
      if (msg.status === 200) {
        toastr.success(msg.message);
        setTicketsIds([]);
      } else {
        toastr.error(msg.message);
      }
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const onSubmit = async () => {
    setIsProcessing(true);
    let ticketIds = [];
    try {
      const data = await getTicketList({
        ticket_type: "dmv",
        payment: "pending",
      });
      if (data.status == 200) {
        data.data.length > 0 &&
          data.data.map((item) =>
            item && (item?.due_amount === "0" || item?.due_amount === null)
              ? ""
              : item && item.transaction && item.transaction?.payment_status && item.transaction.payment_status === "success"
              ? ""
              : item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount
              ? ticketIds.push({
                  id: item?._id,
                  plate_no: item?.plate_no,
                  ticket_id: item.summons_number,
                  amount: item?.due_amount,
                })
              : ticketIds.push({
                  id: item?._id,
                  plate_no: item?.plate_no,
                  ticket_id: item.summons_number,
                  amount: item?.due_amount,
                })
          );
          /// Direct add to cart
          try {
            let msg = await storeCart({
              userId: localStorage.getItem("userId"),
              cartId: cart_id,
              ticket_ids: ticketIds,
            });
            if (msg.status === 200) {
              setTicketsIds([]);
              setIsProcessing(false);
              return navigate("/checkout");
            } else {
              toastr.error(msg.message);
              setIsProcessing(false);
            }
          } catch (err) {
            toastr.error(err.message);
            setIsProcessing(false);
          }
      }
    } catch (err) {
      console.log(err.message);
      setIsProcessing(false);
    }
  };

  const onSubmitCart = async () => {
    setIsAddtocart(true);
     try {
      let msg = await storeCart({
        userId: localStorage.getItem("userId"),
        cartId: cart_id,
        ticket_ids: ticket_ids,
      });
      if (msg.status === 200) {
        setTicketsIds([]);
        setIsAddtocart(false);
        return navigate("/checkout");
      } else {
        setIsAddtocart(false);
        toastr.error(msg.message);
      }
    } catch (err) {
      setIsAddtocart(false);
      toastr.error(err.message);
    }
  }

  useEffect(() => {
    const onLoadPendingTicket = async () => {
      let ticketIds = [];
      try {
        const data = await getTicketList({
          ticket_type: "dmv",
          payment: "pending",
        });
        if (data.status == 200) {
          data.data.length > 0 &&
            data.data.map((item) =>
              item && (item?.due_amount === "0" || item?.due_amount === null)
                ? ""
                : item && item.transaction && item.transaction?.payment_status && item.transaction.payment_status === "success"
                ? ""
                : item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount
                ? ticketIds.push({
                    id: item?._id,
                    plate_no: item?.plate_no,
                    ticket_id: item.summons_number,
                    amount: item?.due_amount,
                  })
                : ticketIds.push({
                    id: item?._id,
                    plate_no: item?.plate_no,
                    ticket_id: item.summons_number,
                    amount: item?.due_amount,
                  })
            );
        }
      } catch (err) {
        console.log(err.message);
      }
      setPendingTickets(ticketIds);
    };
    onLoadPendingTicket();
  }, [tickets]);

  return (
      <div className="ticket-list">
        {
          (pendingTickets && pendingTickets.length > 0)? (
            <div className="ticket-total">
              <div className="title">you have {pendingTickets.length} unpaid tickets</div>
              <div className="amoumt-text">
                <div className="text">Total Owed</div>
                <div className="amount">${sum(pendingTickets) > 0 ? sum(pendingTickets).toFixed(2) : 0}</div>
              </div>
            </div>
          ):'' 
        }
        {(showTicket && (showTicket === "pending" || showTicket === "all") && pendingTickets && pendingTickets.length > 0) ? (
          <div className="total-pay-action">
            {/* <div className="custom-checkbox">
              <input className="custom-checkbox-input" type="checkbox" value="all" id="flexCheckChecked" onChange={hadelAllTicket} />
              <label className="custom-checkbox-label" for="flexCheckChecked">
                Pay All
              </label>
            </div> */}
            <div className="action-btn d-flex">
                <button type="button" className="btn-pay mr-2" onClick={onSubmit} disabled={isProcessing}>
                  <i className={(isProcessing)?'fa-regular fa-spinner fa-spin mr-2':"fal fa-money-check-alt mr-2"}></i><span>Pay All Now</span>
                </button>
                {
                  (ticket_ids && ticket_ids.length>0) && 
                  <button type="button" className="btn-pay" onClick={onSubmitCart} disabled={isAddtocart}>
                    <i className={(isAddtocart)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-cart-shopping mr-2"}></i><span>Add To Cart</span>
                  </button>
                }
                
            </div>
          </div>
        ):''}
        <div className="ticket-table">
          <div className="table-head">
            <div className="table-row header-row">
              <div className="table-cell cell-check">
                <div className="table-title"></div>
              </div>
              <div className="table-cell cell-plate">
                <div className="table-title">Plate</div>
              </div>
              <div className="table-cell cell-vc">
                <div className="table-title">VIN</div>
              </div>
              <div className="table-cell cell-ticketID">
                <div className="table-title">Summons No.</div>
              </div>
              <div className="table-cell cell-date">
                <div className="table-title">Issue Date</div>
              </div>
              <div className="table-cell cell-fine">
                <div className="table-title">Fine</div>
              </div>
              <div className="table-cell cell-fine">
                <div className="table-title">Due</div>
              </div>
              <div className="table-cell cell-violation">
                <div className="table-title">Violation</div>
              </div>
              <div className="table-cell cell-status">
                <div className="table-title">Status</div>
              </div>
              <div className="table-cell cell-view-btn">
                <div className="table-title">Action</div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {totalDataCount > 0 ? (
              tickets.length > 0 &&
              tickets.map((item, index) => (
                <>
                  <div className="" key={index}>
                    <div className=" ticket-result-content">
                      <div className="table-row">
                        <div className="table-cell cell-check">
                          <div className="mobile-title">
                            <div className="cell-data">
                              <div className="custom-checkbox">
                                <input type="checkbox" className="custom-checkbox-input" />
                                <div className="custom-checkbox-label"></div>
                              </div>
                            </div>
                          </div>
                          <div className="cell-data">
                            {item && (
                              <div className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox-input"
                                  value={item?._id}
                                  key={index}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setTicketsIds([
                                        ...ticket_ids,
                                        {
                                          id: item?._id,
                                          plate_no: item?.plate_no,
                                          ticket_id: item.summons_number,
                                          amount: item?.due_amount,
                                        },
                                      ]);
                                    } else {
                                      setTicketsIds(ticket_ids.filter((ticket) => ticket.ticket_id !== item.summons_number));
                                    }
                                  }}
                                  checked={ticket_ids && ticket_ids.length > 0 && ticket_ids.some((ticket) => ticket.id === item._id)}
                                  disabled={
                                    item && (item?.due_amount === "0" || item?.due_amount === null)
                                      ? true
                                      : item && item.transaction && item.transaction?.payment_status
                                      ? true
                                      : item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount
                                      ? false
                                      : false
                                  }
                                />
                                <div className="custom-checkbox-label"></div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="table-cell cell-plate">
                          <div className="mobile-title">Plate</div>
                          <div className="cell-data">
                          <NavLink to={"/plate-details/" + item?.plate_details?._id}>
                            {item?.plate_no}
                          </NavLink>
                          </div>
                        </div>
                        <div className="table-cell cell-vc">
                          <div className="mobile-title">VIN</div>
                          <div className="cell-data">
                          <NavLink to={"/plate-details/" + item?.plate_details?._id}>
                            {item.plate_details ? item.plate_details.vehicle_vin : "--"}
                          </NavLink>
                          </div>
                        </div>
                        <div className="table-cell cell-ticketID">
                          <div className="mobile-title">Ticket-ID</div>
                          <div className="cell-data">
                            <NavLink to={"/ticket/" + item?._id}>{item?.summons_number}</NavLink>
                          </div>
                        </div>
                        <div className="table-cell cell-date">
                          <div className="mobile-title">Date</div>
                          <div className="cell-data">{item?.issue_date ? dateFormat(item?.issue_date) : ""}</div>
                        </div>
                        <div className="table-cell cell-fine">
                          <div className="mobile-title">Fine</div>
                          <div className="cell-data">${item?.fine_amount}</div>
                        </div>
                        <div className="table-cell cell-fine due">
                          <div className="mobile-title">Due</div>
                          <div className="cell-data">${item?.due_amount}</div>
                        </div>
                        <div className="table-cell cell-violation">
                          <div className="mobile-title">Violation</div>
                          <div className="cell-data">{item?.violation_description ? item?.violation_description : item?.violation}</div>
                        </div>
                        <div className="table-cell cell-status">
                          <div className="mobile-title">Status</div>
                          <div className="cell-data">
                            {item && (item?.due_amount === "0") ? (
                              (item?.transaction)?
                              <NavLink to={"/transaction/" + item?.transaction?._id}>
                                <div className="status-text dismiss">Paid</div>
                              </NavLink> :  
                               <div className="status-text dismiss">Paid</div>  
                            ) : item && item.transaction && item.transaction?.payment_status && item.transaction.payment_status === "success" ? (
                              (item?.transaction)?
                              <NavLink to={"/transaction/" + item?.transaction?._id}>
                                <div className="status-text reduce">Paid</div>
                              </NavLink>:
                              <div className="status-text dismiss">Paid</div>
                            ) : item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount ? (
                              <div className="status-text color waiting">Pay Ticket</div>
                            ) : (
                              <div className="status-text color waiting">Pay Ticket</div>
                            )}
                          </div>
                        </div>
                        <div className="table-cell cell-view-btn">
                            <div className="d-flex">
                            <NavLink to={"/ticket/" + item?._id} className="btn btn-dark btn-sm mr-2">
                              <i className="fa-solid fa-eye"></i>
                            </NavLink>
                            {
                              item && (item?.due_amount === "0" || item?.due_amount === null) ? ("") :
                              item && item.transaction && item.transaction?.payment_status && item.transaction.payment_status === "success" ? ("") :
                              item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount ? (
                              <button 
                              className="btn btn-success btn-sm"  
                              onClick={() =>
                                addToCart({
                                  id: item?._id,
                                  plate_no: item?.plate_no,
                                  ticket_id: item.summons_number,
                                  amount: item?.due_amount,
                              })}>
                              <i className="fa-regular fa-cart-shopping"></i>
                              </button>
                              
                              ) : (
                              <button 
                              className="btn btn-success btn-sm"
                              onClick={() =>
                                addToCart({
                                  id: item?._id,
                                  plate_no: item?.plate_no,
                                  ticket_id: item.summons_number,
                                  amount: item?.due_amount,
                              })}>
                              <i className="fa-regular fa-cart-shopping"></i>
                              </button>
                              )}
                            </div>
                            {/* <div className="view-btn-action" data-bs-boundary="body">
                              <div className="dropdown">
                                <button type="button" className="btn-action" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="far fa-ellipsis-v"></i>
                                </button>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <NavLink to={"/ticket/" + item?._id} className="dropdown-item">
                                    <i className="fa-solid fa-eye"></i> View Details
                                  </NavLink>
                                  {item && (item?.due_amount === "0" || item?.due_amount === null) ? (
                                    ""
                                  ) : item && item.transaction && item.transaction?.payment_status && item.transaction.payment_status === "success" ? (
                                    ""
                                  ) : item && item.fine_amount + item.interest_amount + item.penalty_amount - item.reduction_amount > item.payment_amount ? (
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        addToCart({
                                          id: item?._id,
                                          plate_no: item?.plate_no,
                                          ticket_id: item.summons_number,
                                          amount: item?.due_amount,
                                        })
                                      }
                                    >
                                      <i className="fa-regular fa-cart-shopping"></i> Add to Cart
                                    </button>
                                  ) : (
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        addToCart({
                                          id: item?._id,
                                          plate_no: item?.plate_no,
                                          ticket_id: item.summons_number,
                                          amount: item?.due_amount,
                                        })
                                      }
                                    >
                                      <i className="fa-regular fa-cart-shopping"></i> Add to Cart
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : loading ? (
              <Searching />
            ) : (
              <NothingFound />
            )}
          </div>
        </div>
        {noOfPage > 1 && (
          <div className="pagination-inner">
            <nav aria-label="Page navigation example">
              <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={3} pageCount={noOfPage} previousLabel="<" renderOnZeroPageCount={null} />
            </nav>
          </div>
        )}
      </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, { storeCart })(SecTickets);

import React, { useState, useEffect } from 'react';
import SecNewsletter from '../components/AboutUs/SecNewsletter';
import SecBanner from '../components/Solutions/SecBanner';
import SecSolution from '../components/Solutions/SecSolution';
import {
  getPageDetails,
  getBanners,
  getSolutions,
  getSeo
} from '../actions/site';
import {Helmet} from "react-helmet";
const Solutions = () => {
  const [seo,setSeo] = useState({});
  const [pageId,setPageId] = useState({});
  const [banners,setBanners] = useState({});
  const [solutions, setSolutions] = useState({});
  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'solutions'
        });  
        if(data.status == 200) {
          setPageId(data.data._id)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();

    // Solutions
    const loadSolutionsData = async () => {
        try {            
          const data = await getSolutions({
            status:'active'
          });
          if(data.status == 200) {
              setSolutions(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSolutionsData();
  }, [ ]); 

  useEffect(() => {
    //Banners
    const loadBannersData = async () => {
        try {            
          const data = await getBanners({
            type:'page',
            type_id:pageId
          });  
          if(data.status == 200) {
              setBanners(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadBannersData();
  },[pageId]);
  useEffect(() => {
    //Seo
    const loadSeoData = async () => {
        try {            
          const data = await getSeo({
            type:'page',
            typeId:pageId,
          });  
          if(data.status == 200) {
              setSeo(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSeoData();
  },[pageId]);  
  return (
    <><Helmet>
    <meta charSet="utf-8" />
    <meta name="title" content={seo?.meta_title}></meta>
    <meta name="author" content={seo?.author}></meta>
    <meta name="keywords" content={seo?.keywords}></meta>
    <meta name="description" content={seo?.description}></meta>
    </Helmet>
      <SecBanner banner={banners[0]}/>
      <SecSolution solutions={solutions}/>
      <SecNewsletter/>
    </>
  );
};

export default Solutions;
import React from 'react';

const SecSearch = () => {
  return (
    <section className="ticket-result-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
          <div className="ticket-id">Ticket-ID</div>
            <div className="ticket-details-inner">
              <div className="ticket-list">
              <div className="ticket-item">
                <div className="custom-checkbox">
                  <input type="checkbox" class="custom-checkbox-input"/>
                  <div className="custom-checkbox-label ticket-result-content">
                    <div className="ticket-details">
                      <div className="price">$250.58</div>
                      <div className="violation-message">Obstructing driveway</div>
                      <div className="violation-time">at New York on March 06,2022</div>
                    </div>
                    <div className="plate-details">
                      <div className="text">Plate No.</div>
                      <div className="data">JDZ7126</div>
                    </div>
                    <div className="due-date">
                      <div className="text">Due date</div>
                      <div className="data">Jul 15, 2022</div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="ticket-item">
                <div className="custom-checkbox">
                  <input type="checkbox" class="custom-checkbox-input"/>
                  <div className="custom-checkbox-label ticket-result-content">
                    <div className="ticket-details">
                      <div className="price">$250.58</div>
                      <div className="violation-message">Obstructing driveway</div>
                      <div className="violation-time">at New York on March 06,2022</div>
                    </div>
                    <div className="plate-details">
                      <div className="text">Plate No.</div>
                      <div className="data">JDZ7126</div>
                    </div>
                    <div className="due-date">
                      <div className="text">Due date</div>
                      <div className="data">Jul 15, 2022</div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="ticket-item">
                <div className="custom-checkbox">
                  <input type="checkbox" class="custom-checkbox-input"/>
                  <div className="custom-checkbox-label ticket-result-content">
                    <div className="ticket-details">
                      <div className="price">$250.58</div>
                      <div className="violation-message">Obstructing driveway</div>
                      <div className="violation-time">at New York on March 06,2022</div>
                    </div>
                    <div className="plate-details">
                      <div className="text">Plate No.</div>
                      <div className="data">JDZ7126</div>
                    </div>
                    <div className="due-date">
                      <div className="text">Due date</div>
                      <div className="data">Jul 15, 2022</div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="ticket-item">
                <div className="custom-checkbox">
                  <input type="checkbox" class="custom-checkbox-input"/>
                  <div className="custom-checkbox-label ticket-result-content">
                    <div className="ticket-details">
                      <div className="price">$250.58</div>
                      <div className="violation-message">Obstructing driveway</div>
                      <div className="violation-time">at New York on March 06,2022</div>
                    </div>
                    <div className="plate-details">
                      <div className="text">Plate No.</div>
                      <div className="data">JDZ7126</div>
                    </div>
                    <div className="due-date">
                      <div className="text">Due date</div>
                      <div className="data">Jul 15, 2022</div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cart-section">
              <div className="title">Ticket Summary</div>
              <div className="ticket-summary">
                <div className="ticket-info">
                  <div className="text">No. of Violations</div>
                  <div className="data">4</div>
                </div>
              </div>
              <div className="total-info">
                <div className="text">Total Owed</div>
                <div className="data">$850.00</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecSearch;
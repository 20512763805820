import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import toastr from "toastr";
import { getPlateList, deletePlate } from "../../actions/ticket";
import { getStates } from "../../actions/site";
import NothingFound from "../../shared/nothingFound";
import Searching from "../../shared/searching";
import ReactPaginate from "react-paginate";
import { getDriverList } from "../../actions/driver";

const SecPlateList = () => {
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [plates, setPlates] = useState({});
  const [searchPlates, setSearchPlates] = useState({});
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [autopay, setAutopay] = useState(null);
  const [defaultAutopay, setDefaultAutopay] = useState("Auto pay");
  const [type, setType] = useState("");
  const [defaultType, setDefaultType] = useState("Select Type");
  const [searchState, setSearchState] = useState([]);
  const [displayState, setDisplayState] = useState("Select State");
  const [state, setState] = useState("");
  const [states, setStates] = useState({});
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchDriver, setSearchDriver] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [displayDriver, setDisplayDriver] = useState("Select Driver");
  const [driverId, setDriverId] = useState("");

  const [currentId, setCurrentId] = useState("");
  useEffect(() => {
    // State List
    const loadStates = async () => {
      try {
        const data = await getStates({ country_code: "US" });
        if (data.status == 200) {
          setStates(data.data);
          setSearchState(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStates();

    // Driver list
    const loadDrivers = async () => {
      try {
        const data = await getDriverList({});
        if (data.status == 200) {
          setDrivers(data.data);
          setSearchDriver(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDrivers();
  }, []);

  useEffect(() => {
    // Plate List
    const loadPlates = async () => {
      try {
        const data = await getPlateList({
          keyword: keyword,
          limit: limit,
          offset: offset,
          auto_pay: autopay,
          plate_type: type,
          vehicle_state: state,
          driver_id: driverId,
        });
        if (data.status == 200) {
          setPlates(data.data);
          setSearchPlates(data.data);
        }
        setTotalDataCount(data.count);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlates();
  }, [keyword, limit, offset, autopay, state, type, driverId]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
  };

  const handleChangeState = (event) => {
    let searchStates = states.filter((item) => {
      if (item.state_code) {
        return item.state_code.toLowerCase().includes(event.target.value.toLowerCase()) || item.name.toLowerCase().includes(event.target.value.toLowerCase());
      }
    });
    setSearchState(searchStates);
  };

  const getState = (id, code) => {
    setState(id);
    setDisplayState(code);
  };

  const handleChangeDriver = (event) => {
    let searchDriver = drivers.filter((item) => {
      if (item.name) {
        return item.name.toLowerCase().includes(event.target.value.toLowerCase());
      }
    });
    setSearchState(searchDriver);
  };

  const getDriver = (id, name) => {
    setDriverId(id);
    setDisplayDriver(name);
  };

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const closeOneModal = () => {
    const modal = document.getElementById("ModalPlateDelete");
    modal.classList.remove("show");
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("style", "display: none");
    const modalBackdrops = document.getElementsByClassName("modal-backdrop");
    document.body.removeChild(modalBackdrops[0]);
  };

  const onDelete = async (e) => {
    e.preventDefault();
    try {
      let msg = await deletePlate({
        id: currentId,
      });
      if (msg.status === 200) {
        closeOneModal();
        let filteredArray = plates.filter((item) => item._id !== currentId);
        setSearchPlates(filteredArray);
        toastr.success(msg.message);
      } else {
        toastr.error(msg.message);
      }
    } catch (err) {
      toastr.error(err.message);
    }
  };

 
  return (
    <section className="sec-plate-management sec-plate-list">
      <div className="body-layout">
        <div className="body-content">
          <div className="user-search-part">
            <div className="search-upper-part">
              <div className="title">Plates</div>
              <div className="add-vehicle-action">
                <NavLink to="/add-plate" className="add-vehicle-btn">
                  <i className="far fa-plus"></i> Add Plate
                </NavLink>
              </div>
            </div>
            <div className="search-lower-part">
              <div className="list-filter-action">
                <div className="search">
                  <button type="button" className="search-btn">
                    <i className="fa-regular fa-fw fa-magnifying-glass"></i>
                  </button>
                  <input type="text" className="search-input" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                </div>
                <div className="search-filters">
                  <div className="dropdown">
                    <button className="dropdown-toggle" type="button" data-toggle="dropdown">
                      {displayState}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-search">
                        <input type="text" className="form-control" placeholder="Search" onChange={handleChangeState} />
                      </div>
                      <div className="dropdown-scroll" id="search-state">
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={(e) => {
                            setState("");
                            setDisplayState("Select State");
                          }}
                        >
                          Select State
                        </button>
                        {searchState.length > 0 &&
                          searchState.map((item, index) => (
                            <button key={index} type="button" className="dropdown-item" data-value={item?.state_code} data-name={item?.name} onClick={() => getState(item?._id, item?.state_code)}>
                              {item?.state_code} - {item?.name}
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-filters">
                  <div className="dropdown">
                    <button className="dropdown-toggle" type="button" data-toggle="dropdown">
                      {displayDriver}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-search">
                        <input type="text" className="form-control" placeholder="Search" onChange={handleChangeDriver} />
                      </div>
                      <div className="dropdown-scroll" id="search-driver">
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={(e) => {
                            setDriverId("");
                            setDisplayDriver("Select Driver");
                          }}
                        >
                          Select Driver
                        </button>
                        {searchDriver.length > 0 &&
                          searchDriver.map((item, index) => (
                            <button key={index} type="button" className="dropdown-item" data-value={item?.id} data-name={item?.name} onClick={() => getDriver(item?._id, item?.name)}>
                              {item?.name}
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-filters">
                  <div className="dropdown">
                    <button type="button" className="dropdown-toggle vehicle-input" data-toggle="dropdown">
                      {defaultType}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-scroll">
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value=""
                          onClick={(e) => {
                            setType("");
                            setDefaultType("Select Type");
                          }}
                        >
                          Select Type
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="Commercial"
                          onClick={(e) => {
                            setType("COM");
                            setDefaultType("Commercial");
                          }}
                        >
                          Commercial
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="Personal"
                          onClick={(e) => {
                            setType("PAS");
                            setDefaultType("Personal");
                          }}
                        >
                          Personal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-filters">
                  <div className="dropdown">
                    <button type="button" className="dropdown-toggle vehicle-input" data-toggle="dropdown">
                      {defaultAutopay}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-scroll">
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value=""
                          onClick={(e) => {
                            setAutopay(null);
                            setDefaultAutopay("Auto pay");
                          }}
                        >
                          Auto pay
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="Yes"
                          onClick={(e) => {
                            setAutopay(Boolean("true"));
                            setDefaultAutopay("Yes");
                          }}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="No"
                          onClick={(e) => {
                            setAutopay(Boolean(""));
                            setDefaultAutopay("No");
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="driver-list-inner">
            <div className="ticket-list">
              <div className="ticket-table">
                <div className="table-head">
                  <div className="table-row header-row">
                    <div className="table-cell cell-image">
                      <div className="table-title">Plate Number</div>
                    </div>
                    <div className="table-cell cell-name">
                      <div className="table-title">Vin</div>
                    </div>
                    <div className="table-cell cell-licence">
                      <div className="table-title">State</div>
                    </div>
                    <div className="table-cell cell-email">
                      <div className="table-title">Type</div>
                    </div>
                    <div className="table-cell cell-action">
                      <div className="table-title">Auto pay</div>
                    </div>
                    <div className="table-cell view-details">
                      <div className="table-title">Open Tickets</div>
                    </div>
                    <div className="table-cell view-details">
                      <div className="table-title">Driver</div>
                    </div>
                    <div className="table-cell drive-action">
                      <div className="table-title">Action</div>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {totalDataCount > 0 ? (
                    searchPlates.length > 0 &&
                    searchPlates.map((item, index) => (
                      <div className="table-row" key={index}>
                        <div className="table-cell cell-image">
                          <div className="driver-content">
                            <NavLink to={"/plate-details/" + item?._id} className="driver-list-title">
                              {item?.plate_no}
                            </NavLink>
                          </div>
                        </div>
                        <div className="table-cell cell-name">
                          <div className="driver-list-title">{item?.vehicle_vin}</div>
                        </div>
                        <div className="table-cell cell-licence">
                          <div className="driver-list-title">{item.vehicle_state ? item.vehicle_state.state_code : ""}</div>
                        </div>
                        <div className="table-cell cell-email">
                          <div className="driver-list-title">{item?.plate_type}</div>
                        </div>
                        <div className="table-cell cell-action">
                          <div className="driver-list-title">{item.auto_pay === true ? "Yes" : "No"}</div>
                        </div>
                        <div className="table-cell view-details open-tickets">
                          <div className="driver-content">
                            <NavLink to={"/ticket-management?payment=pending&plate=" + item?.plate_no} className="driver-list-title">
                              {item?.total_ticket}
                            </NavLink>
                          </div>
                        </div>
                        <div className="table-cell view-details driver-name">
                          <div className="driver-list-title">{item.driver ? item.driver?.name : "--"}</div>
                        </div>
                        <div className="table-cell drive-action">
                          <div className="dropdown">
                            <i className="far fa-ellipsis-v btn-action" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <NavLink to={"/edit-plate/" + item?._id} className="dropdown-item">
                                <i className="fas fa-edit"></i> Edit
                              </NavLink>
                              <NavLink to={"/plate-details/" + item?._id} className="dropdown-item">
                                <i className="fas fa-eye"></i> View Details
                              </NavLink>
                              <a
                                href="!#"
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#ModalPlateDelete"
                                onClick={() => {
                                  setCurrentId(item?._id);
                                }}
                              >
                                <i className="far fa-trash-alt"></i>Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : loading ? (
                    <Searching />
                  ) : (
                    <NothingFound />
                  )}
                </div>
              </div>
              {noOfPage > 1 && (
                <div className="pagination-inner">
                  <nav aria-label="Page navigation example">
                    <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={3} pageCount={noOfPage} previousLabel="<" renderOnZeroPageCount={null} />
                  </nav>
                </div>
              )}
              {/* {
                  noOfPage > 1 &&
                  <div className='pagination-inner'>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        { 
                          Array(noOfPage).fill().map((_, i) => {
                           return (
                            <Fragment key={i}>
                            { 
                              (offset > 0 && i === 0) &&
                              <li className="page-item">
                                <a className="page-link" href="#" onClick={() => {setOffset(offset - limit); setCurrentPage(currentPage - 1)}}>
                                  <i className="fa-regular fa-arrow-left"></i>
                                </a>
                              </li>
                            }
                            {
                              currentPage === (i + 1) &&                              
                              <li className="page-item active">
                                <a className="page-link" href="#">{ i + 1 }</a>
                              </li>
                            }
                            {
                              currentPage !== (i + 1) &&
                              <li className="page-item">
                                <a className="page-link" href="#" onClick={() => {setOffset(limit*i); setCurrentPage(i + 1)}}> 
                                { i + 1 }</a>
                              </li>
                            }
                            { 
                              (currentPage !== noOfPage && (i + 1) === noOfPage) &&
                              <li className="page-item">
                                <a className="page-link" href="#" onClick={() => {setOffset(offset + limit); setCurrentPage(currentPage + 1)}}>
                                  <i className="fa-regular fa-arrow-right"></i>
                                </a>
                              </li>
                            }
                            </Fragment>
                           )
                          })
                        }
                      </ul>
                    </nav>
                  </div>
                } */}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade career-apply-modal delete-modal " id="ModalPlateDelete" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content delete">
            <div className="modal-header">
              <h5 className="modal-title">Delete Plate</h5>
              <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-text">Are you want to delete this Plate, Click "Confirm" button.</div>
            </div>
            <div className="modal-footer justify-content-center">
              <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                Cancel
              </button>
              <a href="#!" className="confirm-btn" onClick={onDelete}>
                Confirm
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecPlateList;

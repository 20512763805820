import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const getBillingList = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}transaction/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data, count: data.count,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTransactionDetails = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}transaction/details`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data, count: data.count,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { updatePlate,deletePlateGallery} from '../../actions/ticket';
import { convertToBase64MultipleFile } from "../../helpers/base64FileHelper"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {stripeCardList} from '../../actions/payment';
import SecPlateOption from './SecPlateOptions';


const SecPlateEdit = ({plate,id}) => {
    const allowedExts = ['jpg', 'jpeg', 'png','pdf'];
    const [isEditBtn, setIsEditBtn] = useState(false);
    const [sms_notification,setSmsNotification] = useState(false);
    const [email_notification,setEmailNotification] = useState(false);
    const [autoPay,setAutoPay] = useState(false);
    const [registerImages,setRegisterimages] = useState([]);
    const [uploadInsurance,setUploadInsurance] = useState([]);

    const [imagePreview,setImagePreview] = useState([]);
    const [searchImagePreview, setSearchImagePreview] = useState([]);
    const [currentId, setCurrentId] = useState('');
    const [viewLightBox, setViewLightBox] = useState({photoIndex: 0,isOpen: false})
    const { photoIndex, isOpen } = viewLightBox;
    const [viewPdf, setViewPdf] = useState('');

    const [ext,setExt] = useState([]);
    const [insExt,setInsExt] = useState([]);
    const [isDeleteGallery,setIsDeleteGallery] = useState(false);


    useEffect(() => {
        setSmsNotification(plate?.sms_notification);        
        setEmailNotification(plate?.email_notification);
        setAutoPay(plate?.auto_pay);
        setImagePreview((plate?.ticket_galleries)?plate?.ticket_galleries:[]);
        setSearchImagePreview((plate?.ticket_galleries)?plate?.ticket_galleries:[]);
    }, [plate]);

    
    const switchSmsNotification =(e) => { 
        if(e.target.checked){
            setSmsNotification(true);
        }else{
            setSmsNotification(false);
        }
    }

    const switchEmailNotification =(e) => { 
        if(e.target.checked){
            setEmailNotification(true);
        }else{
            setEmailNotification(false);
        }
    } 
    
    const switchautoPayNotification = async (e) => { 
        if(e.target.checked){
            try {            
                const data = await stripeCardList({});  
                if(data.status == 200) {
                    if(data.data && data.data.length>0){
                        setAutoPay(true);                        
                    }else{
                        setAutoPay(false);
                        toastr.error('Must add atleast one card from checkout');
                    }   
                }    
            } catch(err) {
                setAutoPay(false);
                console.log(err.message);
                toastr.error(err.message);
            }
        }else{
            setAutoPay(false);
        }
    }

    const onFileChange = (e) => {
        let exts=[];
        if(e.target.files.length>0){
             for (let i = 0; i < e.target.files.length; i++) {
                 if (e.target.files[i]) {
                     const ext = e.target.files[i].type.split('/')[1];
                     exts.push(ext);  
                     if(e.target.files.length == i+1){
                        setExt(exts);
                     }         
                     if (![...allowedExts].includes(ext)) {   
                     toastr.error("Please upload a valid file.");
                     } else {
                     var output = document.getElementById('registration-file-input');
                         output.src = URL.createObjectURL(e.target.files[i]);
                         output.onload = function() {
                         URL.revokeObjectURL(output.src) // free memory
                         }                   
                     }
                 }
             }
             convertToBase64MultipleFile(e.target.files, setRegisterimages);
        }
    };
 
    const onInsuranceFileChange = (e) => {
        let exts=[];
         if(e.target.files.length>0){
             for (let i = 0; i < e.target.files.length; i++) {
                 if (e.target.files[i]) {
                     const ext = e.target.files[i].type.split('/')[1];   
                     exts.push(ext);
                     if(e.target.files.length == i+1){
                        setInsExt(exts);
                     }       
                     if (![...allowedExts].includes(ext)) {   
                       toastr.error("Please upload a valid file.");
                     } else {
                       var output = document.getElementById('insurance-file-input');
                         output.src = URL.createObjectURL(e.target.files[i]);
                         output.onload = function() {
                           URL.revokeObjectURL(output.src) // free memory
                         }                   
                     }
                 }
             }
            convertToBase64MultipleFile(e.target.files, setUploadInsurance);
         }
    }

    const onSubmit = async () => {
        setIsEditBtn(true);
        try {
          let msg = await updatePlate({
            id:id,
            //user_id:localStorage.getItem('userId'),
            sms_notification:sms_notification,
            email_notification:email_notification,
            auto_pay:autoPay,
            register_images:registerImages,
            insurance_image:uploadInsurance
          });
          if (msg.status === 200) {  
            setSearchImagePreview((msg.data?.ticket_galleries)?msg.data?.ticket_galleries:[]);
            setRegisterimages([]);
            setUploadInsurance([]);
            toastr.success(msg.message);
          } else {
            toastr.error(msg.message);
          }
          setIsEditBtn(false);
        } catch (err) {
          setIsEditBtn(false);
          toastr.error(err.message);
        }
    }

    const  closeOneModal = () => {
        const modal = document.getElementById('ModalGalDelete');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
    }

    const onDelete = async (e) => { 
        e.preventDefault();   
        setIsDeleteGallery(true);
        try {
          let msg = await deletePlateGallery({  
            id : currentId
          });       
          if(msg.status === 200){ 
            setIsDeleteGallery(false);
            closeOneModal(); 
            let filteredArray = searchImagePreview.filter(item => item._id !== currentId);
            console.log(filteredArray);
            setSearchImagePreview(filteredArray);
            setCurrentId('');
            toastr.success(msg.message);
          }else{
            setIsDeleteGallery(false);
            setCurrentId('');
            toastr.error(msg.message);
          }      
        } catch(err) {
          setIsDeleteGallery(false);
          setCurrentId('');
          toastr.error(err.message);
        }
      }      

    return (
        <section className='edit-plate'>
            <div className='body-layout'>
                <div className='body-content'>
                    <div className='user-search-part'>
                        <div className='title'>Edit Plate</div>
                    </div>
                    <div className='tickets-inner'>
                        <div className='tickets-no'>{plate?.plate_no}</div>
                        <div className='tickets-state'>{(plate.vehicle_state)?plate.vehicle_state?.state_code:''}</div>
                    </div>
                    <div className='add-new-driver'>
                        <div className='driver-info'>
                            <div className='notifications-option'>
                                <SecPlateOption 
                                sms_notification={sms_notification} 
                                switchSmsNotification={switchSmsNotification}
                                email_notification ={email_notification}
                                switchEmailNotification={switchEmailNotification}
                                autoPay={autoPay}
                                switchautoPayNotification={switchautoPayNotification}
                                />
                                <div className='registration-inner'>
                                    <div className='registration-content'>
                                        <div className='notifications-header'>
                                            <div className='image-upload'>
                                                <input 
                                                type="file" 
                                                className="image-upload-input" 
                                                onChange={onFileChange}
                                                id="registration-file-input"
                                                accept="image/jpeg,image/gif,image/png,application/pdf"
                                                multiple
                                                />
                                                <div className='registration-icon'>
                                                <i className="fa-fw fa-solid fa-id-card"></i>
                                                </div>
                                                <div className='registration-text'>
                                                    Add your registration card
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='registration-content'>
                                        <div className="notifications-header">
                                            <div className="image-upload">
                                                <input 
                                                type="file" 
                                                className="image-upload-input"
                                                id="insurance-file-input"
                                                onChange={onInsuranceFileChange}
                                                accept="image/jpeg,image/gif,image/png,application/pdf"
                                                multiple
                                                />
                                                <div className='registration-icon'>
                                                <i className="fa-fw fa-solid fa-credit-card-front"></i>
                                                </div>
                                                <div className='registration-text'>
                                                  Upload Insurance Card
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='driver-info pdf-content'>
                            {
                                ((searchImagePreview &&  searchImagePreview.length>0) || (registerImages &&  registerImages.length>0)) && 
                                <div className='driver-info-content'>
                                    <div className='driver-info-title'>Registration card</div> 
                                    <div className='img-list'>
                                    {
                                        searchImagePreview.map((item,index) => (
                                            item.type == 'registration-card' &&
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item?.extension === 'pdf' || item?.extension === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item?.original_name}
                                                    onClick={() => setViewPdf(item?.file_name)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />
                                            
                                                <i 
                                                data-toggle="modal" 
                                                data-target="#ModalGalDelete"
                                                className="fal fa-times-circle" 
                                                onClick={() => {setCurrentId(item?._id)}}
                                                ></i>
                                                </>:
                                                <>
                                                <img 
                                                src={item?.file_name} 
                                                title={item?.original_name}
                                                onClick={() => setViewLightBox({ photoIndex:index, isOpen: true })}
                                                />
                                                <i 
                                                data-toggle="modal" 
                                                data-target="#ModalGalDelete"
                                                className="fal fa-times-circle" 
                                                onClick={() => {setCurrentId(item?._id)}}
                                                ></i>
                                                </>
                                            }                                                
                                            </div>
                                        ))
                                    }
                                    {
                                        registerImages.map((item,index) => (
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item &&  ext[index] === 'pdf' || ext[index] === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item}
                                                    onClick={() => setViewPdf(item)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />
                                                </>:
                                                <>
                                                <img 
                                                src={item} 
                                                title={item}
                                                />
                                                </>
                                            }                                                
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                            }
                            {
                                ((searchImagePreview &&  searchImagePreview.length>0) || (uploadInsurance &&  uploadInsurance.length>0)) && 
                                <div className='driver-info-content'>
                                    <div className='driver-info-title'>Insurance Card</div>
                                    <div className='img-list'>
                                    {
                                       searchImagePreview.map((item,index) => (
                                            item.type == 'insurance-card' &&
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item?.extension === 'pdf' || item?.extension === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item?.original_name}
                                                    onClick={() => setViewPdf(item?.file_name)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />
                                                
                                                <i 
                                                data-toggle="modal" 
                                                data-target="#ModalGalDelete"
                                                className="fal fa-times-circle" 
                                                onClick={() => {setCurrentId(item?._id)}}
                                                ></i>
                                                </>:
                                                <>
                                                <img 
                                                src={item?.file_name} 
                                                title={item?.original_name}
                                                onClick={() => setViewLightBox({photoIndex:index,isOpen: true })}
                                                />
                                                <i 
                                                data-toggle="modal" 
                                                data-target="#ModalGalDelete"
                                                className="fal fa-times-circle" 
                                                onClick={() => {setCurrentId(item?._id)}}
                                                ></i>
                                                </>
                                            }
                                                
                                            </div>
                                        )) 
                                    }
                                    {
                                        uploadInsurance.map((item,index) => (
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item &&  insExt[index] === 'pdf' || insExt[index] === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item}
                                                    onClick={() => setViewPdf(item)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />                                                
                                                </>:
                                                <>
                                                <img 
                                                src={item} 
                                                title={item}
                                                />
                                                </>
                                            }
                                                
                                            </div>
                                        )) 
                                    }
                                    </div>
                                </div>
                            }
                            {
                                isOpen && (
                                <Lightbox
                                    mainSrc={searchImagePreview[photoIndex]?.file_name}
                                    animationDisabled ={true}
                                    onCloseRequest={() => setViewLightBox({ isOpen: false })}                                    
                                />
                                )
                            } 
                        </div>
                        
                        <div className='submit-action mt-3'>
                            <button type="button" className='btn-submit-action' disabled={isEditBtn} onClick={onSubmit}>
                            <i className={(isEditBtn)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                                Update Vehicle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* DPdf View Modal */}
            {
                viewPdf && 
                <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf('')}>
                                <i className="fal fa-fw fa-times"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                                    <p>There was an error downloading the invoice.</p>
                                </object>
                            </div>
                        </div>
                    </div>
                </div>

            }

            {/* Delete Gallery Modal */}
            <div className="modal fade career-apply-modal delete-modal " id="ModalGalDelete" tabIndex="-1" role="dialog" data-backdrop="static"
            data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content delete">
                <div className="modal-header">
                    <h5 className="modal-title">Time to Go?</h5>
                    <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                    <i className="fal fa-fw fa-times"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-text">Are you want to delete this item, Click "Confirm" button.</div>
                </div>
                <div className="modal-footer justify-content-center">
                    <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                    Cancel
                    </button>
                    <button className="confirm-btn" disabled={isDeleteGallery} onClick={onDelete}>
                    <i className={(isDeleteGallery)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-save mr-2"}></i>
                    Confirm
                    </button>
                </div>
                </div>
            </div>
            </div>
        </section>
    );
};

export default SecPlateEdit;
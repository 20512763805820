import React, { useState, useEffect } from 'react';


const SecPlateOption = ({
    plate,id,
    sms_notification,
    switchSmsNotification = () => {},
    email_notification,
    switchEmailNotification =()=>{},
    autoPay,
    switchautoPayNotification = ()=>{}
}) => {    
    return (
    <div className='notifications-inner'>
        <div className='driver-notifications'>
            <div className='notifications-title'>Email Notifications</div>
            <div className='notifications-sub-title'>Get email notifications about tickets.</div>
                <div className='pricing-toggle'>
                <h6 className='toggle-text'>Off</h6> 
                <label className='switch'>
                <input 
                type="checkbox"
                checked={sms_notification}
                onChange={switchSmsNotification}
                />
                <span className='slider round'></span>
                </label>
                <h6 className='toggle-text'>On</h6>
            </div>
        </div>
        <div className='driver-notifications'>
            <div className='notifications-title'>SMS Notifications</div>
            <div className='notifications-sub-title'>Get text notifications about tickets.</div>
                <div className='pricing-toggle'>
                <h6 className='toggle-text'>Off</h6> 
                <label className='switch'>
                <input 
                type="checkbox"
                checked={email_notification}
                onChange={switchEmailNotification }
                />
                <span className='slider round'></span>
                </label>
                <h6 className='toggle-text'>On</h6>
            </div>
        </div>
        <div className='driver-notifications'>
            <div className='notifications-title'>Auto Pay DMV Tickets</div>
            <div className='notifications-sub-title'>Auto Pay DMV Tickets (Automatically pay any new tickets received)</div>
                <div className='pricing-toggle'>
                <h6 className='toggle-text'>Off</h6> 
                <label className='switch'>
                <input 
                type="checkbox"
                checked={autoPay}
                onChange={switchautoPayNotification }
                />
                <span className='slider round'></span>
                </label>
                <h6 className='toggle-text'>On</h6>
            </div>
        </div>
    </div>
    )
}

export default SecPlateOption;
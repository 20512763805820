import React, { useRef, useState, useEffect } from "react";
import "react-image-lightbox/style.css";
import Searching from "../../shared/searching";
import NothingFound from "../../shared/nothingFound";
import { getTicketDetailsGlobal } from "../../actions/ticket";

const SecTicketDetailsGlobal = ({ type, ticket_id }) => {
  const [ticket, setTicket] = useState({});
  const [types, setType] = useState(null);
  const [status, setStatus] = useState(100);
  useEffect(() => {
    const loadTicketData = async () => {
      try {
        const data = await getTicketDetailsGlobal({
          type, ticket_id
        });
        if (data.status == 200) {
          setTicket(data.data[0]);
          setType(data.type);
          
        }
        setStatus(data.status);
      } catch (err) {
        console.log(err.message);
      }
    };
    loadTicketData();
  }, [type, ticket_id]);
  const dateFormat = date => {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${(da<=9)?'0'+da:da} ${(mo<=9)?'0'+mo:mo} ${ye}`;
}
  return (
    <section className="sec-plate-details">
      <div className="body-layout">
        <div className="body-content">
          <div className="user-search-part">
            <div className="title">Ticket Details</div>
          </div>
          <div className="row">
            {status == 100 || status == 400 ? (
              status == 400 ? (
                <NothingFound />
              ) : (
                <Searching />
              )
            ) : (
              <div className="col-lg-12">
                <div className="details-content">
                  <div className="details">
                    <div className="details-card">
                      <div className="vehicle-name">
                      </div>
                      <div className="vehicle-info">
                        <div className="vehicle-number"></div>
                        <div className="report-details">
                        </div>
                      </div>
                      {types==="dmv" ?
                       <div className="vehicle-metalist">
                        <div className="meta-item">
                          <div className="meta-label">Plate No</div>
                          <div className="meta-data">
                            {ticket?.plate||ticket?.plate_no}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">License Type</div>
                          <div className="meta-data">
                          {ticket?.license_type}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Summons Number</div>
                          <div className="meta-data">
                          {ticket?.summons_number}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Issue Date</div>
                          <div className="meta-data">
                          {ticket?.issue_date}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">State</div>
                          <div className="meta-data">
                          {ticket?.state}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Fine Amount</div>
                          <div className="meta-data">
                          {ticket?.fine_amount}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Penalty Amount</div>
                          <div className="meta-data">
                          {ticket?.penalty_amount}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Interest Amount</div>
                          <div className="meta-data">
                          {ticket?.interest_amount}
                          </div>
                        </div>

                        <div className="meta-item">
                          <div className="meta-label">Reduction Amount</div>
                          <div className="meta-data">
                          {ticket?.reduction_amount}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Payment Amount</div>
                          <div className="meta-data">
                          {ticket?.payment_amount}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Amount Due</div>
                          <div className="meta-data">
                          {ticket?.amount_due}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Issuing Agency</div>
                          <div className="meta-data">
                          {ticket?.issuing_agency}
                          </div>
                        </div>
                        <div className="meta-item">
                          <div className="meta-label">Violation</div>
                          <div className="meta-data">
                          {ticket?.violation}
                          </div>
                        </div>
                       </div>
                      : types==="tlc"?
                        <div className="vehicle-metalist">
                          <div className="meta-item">
                            <div className="meta-label">Ticket Number</div>
                            <div className="meta-data">
                            {ticket?.ticket_number}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Date</div>
                            <div className="meta-data">
                            {ticket?.violation_date ? dateFormat(ticket?.violation_date) : ''}
                            </div>
                          </div>
                          
                          <div className="meta-item">
                            <div className="meta-label">Violation Location House</div>
                            <div className="meta-data">
                            {ticket?.violation_location_house}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Location Block No</div>
                            <div className="meta-data">
                            {ticket?.violation_location_block_no}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Location Lot No</div>
                            <div className="meta-data">
                            {ticket?.violation_location_lot_no}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Location Borough</div>
                            <div className="meta-data">
                            {ticket?.violation_location_borough}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Location Street Name</div>
                            <div className="meta-data">
                            {ticket?.violation_location_street_name}
                            </div>
                          </div>

                          <div className="meta-item">
                            <div className="meta-label">Violation Location City</div>
                            <div className="meta-data">
                            {ticket?.violation_location_city}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Location State Name</div>
                            <div className="meta-data">
                            {ticket?.violation_location_state_name}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Violation Location Zip Code</div>
                            <div className="meta-data">
                            {ticket?.violation_location_zip_code}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Total Violation Amount</div>
                            <div className="meta-data">
                            {ticket?.total_violation_amount}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Balance Due</div>
                            <div className="meta-data">
                            {ticket?.balance_due}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Hearing Status</div>
                            <div className="meta-data">
                            {ticket?.hearing_status}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Compliance Status</div>
                            <div className="meta-data">
                            {ticket?.compliance_status}
                            </div>
                          </div>
                          <div className="meta-item">
                            <div className="meta-label">Issuing Agency</div>
                            <div className="meta-data">
                            {ticket?.issuing_agency}
                            </div>
                          </div>
                        </div>
                      :""
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecTicketDetailsGlobal;

import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { 
    storeTicket,
    getSearchVehicle
  } from '../../actions/ticket';
import { convertToBase64MultipleFile } from "../../helpers/base64FileHelper"
import Lightbox from 'react-image-lightbox';
import ImageShowModal from '../ImageShowModal';
import {stripeCardList} from '../../actions/payment';
import { useNavigate } from "react-router";
import NothingFound from '../../shared/nothingFound';
import Searching from '../../shared/searching';
import { allowedExtensions } from '../../helpers';

const SecPlateAdd = ({states,drivers}) => {   
    const navigate = useNavigate();  
    const allowedExts = allowedExtensions();
    const [singleReg,setSingleReg] = useState('');
    const [isSearchBtn, setIsSearchBtn] = useState(false);

    const [vehicle_type, setVehicleType] = useState('COM');
    const [plate_no, setPlateNo] = useState('');
    const [state, setState] = useState('');
    const [driver_id, setDriverId] = useState('');
    const [sms_notification,setSmsNotification] = useState(false);
    const [email_notification,setEmailNotification] = useState(false);
    const [autoPay,setAutoPay] = useState(false);
    const [registerImages,setRegisterimages] = useState([]);
    const [uploadInsurance,setUploadInsurance] = useState([]);

    const [searchRegisterImages,setSearchRegisterimages] = useState([]);
    const [searchUploadInsurance,setSearchUploadInsurance] = useState([]);
    
    const [searchState, setSearchState] = useState([]);
    const [searchDriver, setSearchDriver] = useState([]);
    const [displayDriver,setDisplayDriver] = useState('Select Driver');
    const [displayState,setDisplayState] =  useState('Select State');
    const [vehicleInfo,setVehicleInfo] = useState({});
    const [isAddPlate,setIsAddPlate] = useState(false);

    const [viewPdf, setViewPdf] = useState('');
    const [viewLightBox, setViewLightBox] = useState({photo: null,isOpen: false})
    const { photo, isOpen } = viewLightBox;
    const [currentId, setCurrentId] = useState('');
    const [ext,setExt] = useState([]);
    const [insExt,setInsExt] = useState([]);
    

    const handleChangeState = (event) => {
        let searchStates = states.filter((item) => { 
            if(item.state_code){ 
                return item.state_code.toLowerCase().includes(event.target.value.toLowerCase()) || item.name.toLowerCase().includes(event.target.value.toLowerCase())
            }
        })
        setSearchState(searchStates);
    }

    const handleChangeDriver = (event) => {
        let searchDrivers = drivers.filter((item) => {  
            if(item.name){
                return item.name.toLowerCase().includes(event.target.value.toLowerCase())
            }          
        })
        setSearchDriver(searchDrivers);
    }

    const getDriver = (id,name) => {
        setDriverId(id);
        setDisplayDriver(name);
    }

    const getState = (id,code) => {
        setState(id);
        setDisplayState(code);
    }
    
    useEffect(() => {
        if(states.length){
            setSearchState(states);
        }
        if(drivers.length){
            setSearchDriver(drivers);
            getDriver(drivers[0]?._id,drivers[0]?.name)
        }
    }, [states.length,drivers.length]);

    // useEffect(() => {
    //     if(registerImages){
    //         setSearchRegisterimages(registerImages);
    //     }
    //     console.log(searchRegisterImages);
    //     if(uploadInsurance){
    //         setSearchUploadInsurance(uploadInsurance);
    //     }
    // },[registerImages,uploadInsurance]);
    
    
    
    const onSearchVehicle = async () => {
        setVehicleInfo({});
        setIsSearchBtn(true);
        try {
            let response = await getSearchVehicle({
                license_type : vehicle_type,
                plate_no:plate_no.toUpperCase().replace(/ /g,''),
                state:(displayState != 'Select State')?displayState:''
            });
            if (response.status === 200) {
                setVehicleInfo(response.data);
                setIsSearchBtn(false);
            }else {
                toastr.error(response.message);
                setIsSearchBtn(false);
                
            }
        } catch (error) {
            toastr.error(error.message);
            setIsSearchBtn(false);
        }
        
    }

    //console.log(vehicleInfo);
    

    const handleChange = (e) => {
        const { name, value } = e.target;    
        setVehicleType(value);
    };

    const switchSmsNotification =(e) => { 
        if(e.target.checked){
            setSmsNotification(true);
        }else{
            setSmsNotification(false);
        }
    }

    const switchEmailNotification = (e) => { 
        if(e.target.checked){
            setEmailNotification(true);
        }else{
            setEmailNotification(false);
        }
    } 
    
    const switchautoPayNotification = async (e) => { 
        if(e.target.checked){
            try {            
                const data = await stripeCardList({});  
                if(data.status == 200) { 
                    if(data.data && data.data.length>0){
                        setAutoPay(true);                        
                    }else{
                        setAutoPay(false);
                        toastr.error('Must add atleast one card from checkout');
                    }   
                }    
            } catch(err) {
                setAutoPay(false);
                console.log(err.message);
                toastr.error(err.message);
            }
        }else{
            setAutoPay(false);
        }
    }

    const onFileChange = (e) => {
       let exts=[];
       if(e.target.files.length>0){
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]) {
                    const ext = e.target.files[i].type.split('/')[1];  
                    exts.push(ext);
                    if(e.target.files.length == i+1){
                        setExt(exts);
                    }        
                    if (![...allowedExts].includes(ext)) {   
                    toastr.error("Please upload a valid file.");
                    } else {
                    var output = document.getElementById('registration-file-input');
                        output.src = URL.createObjectURL(e.target.files[i]);
                        output.onload = function() {
                        URL.revokeObjectURL(output.src) // free memory
                        }                   
                    }
                }
            }
            convertToBase64MultipleFile(e.target.files, setRegisterimages);
       }
    };    

    const onInsuranceFileChange = (e) => {
        let exts=[];
        if(e.target.files.length>0){
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i]) {
                    const ext = e.target.files[i].type.split('/')[1]; 
                    exts.push(ext);
                    if(e.target.files.length == i+1){
                        setInsExt(exts);
                    }           
                    if (![...allowedExts].includes(ext)) {   
                      toastr.error("Please upload a valid file.");
                    } else {
                      var output = document.getElementById('insurance-file-input');
                        output.src = URL.createObjectURL(e.target.files[i]);
                        output.onload = function() {
                          URL.revokeObjectURL(output.src) // free memory
                        }                   
                    }
                }
            }
            convertToBase64MultipleFile(e.target.files, setUploadInsurance);
        }
    }

    const  closeOneModal = () => {
        const modal = document.getElementById('ModalVehicleDetails');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
    }
    

    const onSubmit = async () => {
        setIsAddPlate(true);
        try {
          let msg = await storeTicket({
            plate_type: vehicle_type,        
            plate_no: plate_no.toUpperCase(),
            state: state,
            driver_id: driver_id,
            sms_notification:sms_notification,
            email_notification:email_notification,
            auto_pay:autoPay,
            register_images:registerImages,
            insurance_image:uploadInsurance,
            display_state:displayState,
            year:vehicleInfo.licensePlateLookup?.year,
            make:vehicleInfo.licensePlateLookup?.make,
            model:vehicleInfo.licensePlateLookup?.model,
            vehicle_color:(vehicleInfo && vehicleInfo.licensePlateLookup)?vehicleInfo.licensePlateLookup.color?.name:'',
            vin:vehicleInfo.licensePlateLookup?.vin,
            api_json:vehicleInfo        
          });
          if (msg.status === 200) {
            setVehicleType('');        
            setPlateNo('');
            setState('');
            setDriverId('');
            setSmsNotification(false);
            setEmailNotification(false);
            setAutoPay(false);
            setDisplayState('');
            closeOneModal();
            setIsAddPlate(false);
            toastr.success(msg.message);
            return navigate("/plates");
          } else {
            setIsAddPlate(false);
            toastr.error(msg.message);
          }
        } catch (err) {
          setIsAddPlate(false);
          toastr.error(err.message);
        }
    }
    
    
    
    return (
        <>
        <section className='add-plate'>
        <div className='body-layout'>
        <div className='body-content'>
          <div className='user-search-part'>
            <div className='title'>Add Plate</div>
          </div>
          <div className='add-new-driver'>
          <div className='commercial-inner'>
          <div className="radio-group">
            <div className="custom-radio">
                <input 
                type="radio" 
                className="custom-radio-input" 
                name="country-type"
                value='COM'
                checked
                onChange={ handleChange }
                />
                <div className="custom-radio-label">Commercial</div>
            </div>
            <div className="custom-radio">
                <input 
                type="radio" 
                className="custom-radio-input"
                name="country-type" 
                value='PAS'
                onChange={ handleChange }
                />
                <div className="custom-radio-label">Personal</div>
            </div>
        </div>
          </div>
            <form className='form-content'>
              <div className='form-group'>
                <div className='driver-info add-plate'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>Plate Number</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    placeholder='Enter Plate no'
                    value={plate_no}
                    onChange={(e) => setPlateNo(e.target.value)}
                    /></div>
                  </div>
                  <div className='driver-info-content state'>
                    <div className='driver-info-title required'>State</div>
                            <div className='ticket-dropdown'>
                            <div className='dropdown'>
                                <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayState}</button>
                                <div className='dropdown-menu'>
                                <div className='dropdown-search'>
                                    <input 
                                    type="text" 
                                    className='form-control' 
                                    placeholder="Search" 
                                    id="search-state-input"
                                    //value={stateKey}
                                    onChange={handleChangeState}
                                    />
                                </div>
                                <div className='dropdown-scroll'>
                                    {
                                        searchState.length > 0 &&
                                        searchState.map((item,index) => (
                                            <button  
                                            key={index} 
                                            type="button" 
                                            className='dropdown-item' 
                                            data-value={item?._id} 
                                            onClick={() => getState(item?._id,item?.state_code,item?.name)}                                           
                                            >{item?.state_code} - {item?.name}</button>
                                        ))
                                    }                                    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='driver-info-content search-action'>
                        <div className='vehicle-action'>
                            <a 
                            href="" 
                            type='button' 
                            className='vehicle-btn' 
                            onClick={() => onSearchVehicle() }
                            data-toggle="modal" 
                            data-target="#ModalVehicleDetails"
                            > 
                            <i className={(isSearchBtn)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-search mr-2"}></i> Search Vehicle</a>
                        </div>
                    </div>
                </div>
                {/* <div className='driver-info'>
                    <div className='driver-info-content'>
                    <div className='driver-info-title'>Driver</div>
                            <div className='ticket-dropdown'>
                            <div className='dropdown'>
                                <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayDriver}</button>
                                <div className='dropdown-menu'>
                                <div className='dropdown-search'>
                                    <input 
                                    type="text" 
                                    className='form-control' 
                                    placeholder="Search" 
                                    id="search-state-input"
                                    onChange={handleChangeDriver}
                                    />
                                </div>
                                <div className='dropdown-scroll'>
                                    {
                                        searchDriver.length>0 &&  
                                        searchDriver.map((item,index) => (
                                            <button 
                                            type="button" 
                                            className='dropdown-item' 
                                            data-value={item?._id} 
                                            onClick={() => getDriver(item?._id,item?.name)}                                       
                                            >{item?.name}</button>
                                        ))
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='driver-info'>
                  <div className='notifications-option'>
                        <div className='notifications-inner'>
                        <div className='driver-notifications'>
                            <div className='notifications-title'>SMS Notifications</div>
                                <div className='pricing-toggle'>
                                <h6 className='toggle-text'>No</h6> 
                                <label className='switch'>
                                <input 
                                type="checkbox"
                                checked={sms_notification}
                                onChange={switchSmsNotification }
                                />
                                <span className='slider round'></span>
                                </label>
                                <h6 className='toggle-text'>Yes</h6>
                            </div>
                        </div>
                        <div className='driver-notifications'>
                            <div className='notifications-title'>Email Notifications</div>
                                <div className='pricing-toggle'>
                                <h6 className='toggle-text'>No</h6> 
                                <label className='switch'>
                                <input 
                                type="checkbox"
                                checked={email_notification}
                                onChange={switchEmailNotification }
                                />
                                <span className='slider round'></span>
                                </label>
                                <h6 className='toggle-text'>Yes</h6>
                            </div>
                        </div>
                        <div className='driver-notifications'>
                            <div className='notifications-title'>Auto Pay DMV Tickets</div>
                                <div className='pricing-toggle'>
                                <h6 className='toggle-text'>No</h6> 
                                <label className='switch'>
                                <input 
                                type="checkbox"
                                checked={autoPay}
                                onChange={switchautoPayNotification }
                                />
                                <span className='slider round'></span>
                                </label>
                                <h6 className='toggle-text'>Yes</h6>
                            </div>
                        </div>
                        </div>
                        <div className='registration-inner'>
                            <div className='registration-content'>
                                <div className='notifications-header'>
                                    <div className='image-upload'>
                                        <input 
                                        type="file" 
                                        className="image-upload-input"
                                        onChange={onFileChange}
                                        id="registration-file-input"
                                        multiple
                                         />
                                        <div className='registration-icon'>
                                        <i class="fa-fw fa-solid fa-id-card"></i>
                                        </div>
                                        <div className='registration-text'>
                                            Add your registration card
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='registration-content'>
                                <div className="notifications-header">
                                    <div className="image-upload">
                                        <input 
                                        type="file" 
                                        className="image-upload-input"
                                        id="insurance-file-input"
                                        onChange={onInsuranceFileChange}
                                        multiple
                                        />
                                        <div className='registration-icon'>
                                            <i class="fa-fw fa-solid fa-id-card-clip"></i>
                                        </div>
                                        <div className='registration-text'>
                                            Upload Insurance Card
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
              </div>              
              {/* <div className='submit-action'>
                <button type="button" className='btn-submit-action' onClick={onSubmit}>Save</button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </section>
    {
        (plate_no && state) && 
        <>
        <div className="modal fade career-apply-modal" id="ModalVehicleDetails" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <div className="header-text">
                    <h5 className="modal-title" id="ModalCenterTitle">Vehicle Information</h5>
                </div>
                <button type="button" className="modal-close" data-dismiss="modal"><i
                    className="fa-light fa-fw fa-times"></i></button>
            </div>
            <div className="modal-body">
                <div className='details'>
                    {
                        (vehicleInfo && Object.keys(vehicleInfo).length>0) ?(
                        <div className='details-card'>
                            <div className='vehicle-name'>{vehicleInfo.licensePlateLookup?.name}</div>
                            <div className='vehicle-info'>
                                <div className='vehicle-number'>{vehicleInfo?.query}</div>
                            </div>
                            {
                                (vehicleInfo && vehicleInfo.licensePlateLookup) &&
                                <div className='vehicle-metalist'>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Make</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.make}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Model</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.model}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Year</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.year}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Color</div>
                                    <div className='meta-data'>{(vehicleInfo && vehicleInfo.licensePlateLookup)?vehicleInfo.licensePlateLookup.color?.name:''}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Drive Type</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.driveType}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Engine</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.engine}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Fuel</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.fuel}</div>
                                    </div>
                                    <div className='meta-item item-full'>
                                    <div className='meta-label'>VIN/SN</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.vin}</div>
                                    </div>                       
                                    <div className='meta-item'>
                                    <div className='meta-label'>Style</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.style}</div>
                                    </div>
                                    <div className='meta-item item-two-third'>
                                    <div className='meta-label'>Transmission</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.transmission}</div>
                                    </div>
                                    <div className='meta-item'>
                                    <div className='meta-label'>Trim</div>
                                    <div className='meta-data'>{vehicleInfo.licensePlateLookup?.trim}</div>
                                    </div>
                                    {
                                    vehicleInfo.licensePlateLookup.image.imageFound &&
                                    <div className='assigned-drivers'>
                                        <div className='driver'>
                                            <div className='driver-image'><img src={vehicleInfo.licensePlateLookup.image?.url} alt="" /></div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            }
                        </div>
                        ):((isSearchBtn)?<Searching/>:'')
                    }                    
                    {/* Add Others */}                    
                    <div className='driver-info'>
                        <div className='driver-info-content'>
                        <div className='driver-info-title required'>Driver</div>
                                <div className='ticket-dropdown'>
                                <div className='dropdown'>
                                    <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayDriver}</button>
                                    <div className='dropdown-menu'>
                                    <div className='dropdown-search'>
                                        <input 
                                        type="text" 
                                        className='form-control' 
                                        placeholder="Search" 
                                        id="search-state-input"
                                        onChange={handleChangeDriver}
                                        />
                                    </div>
                                    <div className='dropdown-scroll'>
                                        {
                                            searchDriver.length>0 &&  
                                            searchDriver.map((item,index) => (
                                                <button
                                                key={index} 
                                                type="button" 
                                                className='dropdown-item' 
                                                data-value={item?._id} 
                                                onClick={() => getDriver(item?._id,item?.name)}                                       
                                                >{item?.name}</button>
                                            ))
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='driver-info'>
                        <div className='notifications-option'>
                            <div className='notifications-inner'>
                            <div className='driver-notifications'>
                                <div className='notifications-title'>SMS Notifications</div>
                                    <div className='pricing-toggle'>
                                    <h6 className='toggle-text'>No</h6> 
                                    <label className='switch'>
                                    <input 
                                    type="checkbox"
                                    checked={sms_notification}
                                    onChange={switchSmsNotification }
                                    />
                                    <span className='slider round'></span>
                                    </label>
                                    <h6 className='toggle-text'>Yes</h6>
                                </div>
                            </div>
                            <div className='driver-notifications'>
                                <div className='notifications-title'>Email Notifications</div>
                                    <div className='pricing-toggle'>
                                    <h6 className='toggle-text'>No</h6> 
                                    <label className='switch'>
                                    <input 
                                    type="checkbox"
                                    checked={email_notification}
                                    onChange={switchEmailNotification }
                                    />
                                    <span className='slider round'></span>
                                    </label>
                                    <h6 className='toggle-text'>Yes</h6>
                                </div>
                            </div>
                            <div className='driver-notifications'>
                                <div className='notifications-title'>Auto Pay DMV Tickets</div>
                                    <div className='pricing-toggle'>
                                    <h6 className='toggle-text'>No</h6> 
                                    <label className='switch'>
                                    <input 
                                    type="checkbox"
                                    checked={autoPay}
                                    onChange={switchautoPayNotification }
                                    />
                                    <span className='slider round'></span>
                                    </label>
                                    <h6 className='toggle-text'>Yes</h6>
                                </div>
                            </div>
                            </div>
                            <div className='registration-inner'>
                                <div className='registration-content'>
                                    <div className='notifications-header'>
                                        <div className='image-upload'>
                                            <input 
                                            type="file" 
                                            className="image-upload-input"
                                            onChange={onFileChange}
                                            id="registration-file-input"
                                            accept="image/jpeg,image/gif,image/png,application/pdf"
                                            multiple
                                            />
                                            <div className='registration-icon'>
                                            <i className="fa-fw fa-solid fa-id-card"></i>
                                            </div>
                                            <div className='registration-text'>
                                                Add your registration card
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='registration-content'>
                                    <div className="notifications-header">
                                        <div className="image-upload">
                                            <input 
                                            type="file" 
                                            className="image-upload-input"
                                            id="insurance-file-input"
                                            onChange={onInsuranceFileChange}
                                            accept="image/jpeg,image/gif,image/png,application/pdf"
                                            multiple
                                            />
                                            <div className='registration-icon'>
                                                <i className="fa-fw fa-solid fa-id-card-clip"></i>
                                            </div>
                                            <div className='registration-text'>
                                                Upload Insurance Card
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='driver-info pdf-content'>
                        {
                            (registerImages &&  registerImages.length>0) && 
                            <div className='driver-info-content'>
                                <div className='driver-info-title'>Registration card</div> 
                                <div className='img-list'>
                                {
                                    registerImages.map((item,index) => (
                                        // <div className='col-md-4'>
                                            <div className="img input-file cursor" key ={index}> 
                                            {
                                                (item &&  ext[index] === 'pdf' || ext[index] === 'PDF')?
                                                <>
                                                <img
                                                    src='/assets/img/PDF_file_icon.svg.png'
                                                    title={item}
                                                    onClick={() => setViewPdf(item)}
                                                    data-toggle="modal" 
                                                    data-target="#pdfModal"
                                                />
                                                {/* <i 
                                                data-toggle="modal" 
                                                data-target="#ModalGalDelete"
                                                className="fal fa-times-circle" 
                                                onClick={() => {setCurrentId(item?._id)}}
                                                ></i> */}
                                                </>:
                                                <>
                                                <img 
                                                src={item} 
                                                title={item}
                                                onClick={() => setViewLightBox({ photo:item, isOpen: true })}
                                                />
                                                {/* <i 
                                                data-toggle="modal" 
                                                data-target="#ModalGalDelete"
                                                className="fal fa-times-circle" 
                                                onClick={() => {setCurrentId(item?._id)}}
                                                ></i> */}
                                                </>
                                            }                                                
                                            </div>

                                            
                                        // </div>
                                    ))
                                }
                                </div>
                            </div>
                        }
                        {
                            (uploadInsurance &&  uploadInsurance.length>0) && 
                            <div className='driver-info-content'>
                                <div className='driver-info-title'>Insurance Card</div>
                                <div className='img-list'>
                                {
                                    uploadInsurance.map((item,index) => (
                                        <div className="img input-file cursor" key ={index}> 
                                        {
                                            (item &&  insExt[index] === 'pdf' || insExt[index] === 'PDF')?
                                            <>
                                            <img
                                                src='/assets/img/PDF_file_icon.svg.png'
                                                title={item}
                                                onClick={() => setViewPdf(item)}
                                                data-toggle="modal" 
                                                data-target="#pdfModal"
                                            />
                                            
                                            {/* <i 
                                            data-toggle="modal" 
                                            data-target="#ModalGalDelete"
                                            className="fal fa-times-circle" 
                                            onClick={() => {setCurrentId(item?._id)}}
                                            ></i> */}
                                            </>:
                                            <>
                                            <img 
                                            src={item} 
                                            title={item}
                                            onClick={() => setViewLightBox({photo:item,isOpen: true })}
                                            />
                                            {/* <i 
                                            data-toggle="modal" 
                                            data-target="#ModalGalDelete"
                                            className="fal fa-times-circle" 
                                            onClick={() => {setCurrentId(item?._id)}}
                                            ></i> */}
                                            </>
                                        }
                                            
                                        </div>
                                    )) 
                                }
                                </div>
                            </div>
                        }                            
                    </div>
                    <div className='submit-action mt-3'>
                        <button type="button" className='btn-submit-action' disabled={isAddPlate} onClick={onSubmit}>
                        <i className={(isAddPlate)?'fa-regular fa-spinner fa-spin mr-2':"fa-solid fa-save mr-2"}></i> Add Vehicle
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        {/* { isOpen && (<ImageShowModal photo={photo} isOpen={isOpen} setViewLightBox={setViewLightBox}/>)} */}
        {
            viewPdf && 
            <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf('')}>
                            <i className="fal fa-fw fa-times"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                                <p>There was an error downloading the invoice.</p>
                            </object>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    }
    
    </>
    );
};

export default SecPlateAdd;
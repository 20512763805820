import React, { useState, useEffect } from 'react';
import SecAddDriverManagement from '../components/DriverManagement/SecAddDriverManagement';
import UserSidepanel from '../shared/userSidepanel';
import { 
    getStates,
    getCities,
    checkDriverVehicle
} from '../actions/site';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
const AddDriver = () => {   
   const dispatch=useDispatch();
   const navigate = useNavigate();
    const [states,setStates] = useState({});
    const [cities,setCities] = useState({});
    useEffect(() => {
        // State List
        const loadStates = async () => {
          try {            
            const data = await getStates({
              country_code : "US"
            });  
            if(data.status == 200) {
                setStates(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadStates();

        const loadCities = async () => {
            try {            
              const data = await getCities({});  
              if(data.status == 200) {
                setCities(data.data)
              }    
            } catch(err) {
              console.log(err.message);
            }
          }
          loadCities();
    }, []);
    useEffect(() => {
      // Plate List
      if(localStorage.getItem('access_token')){
      dispatch(  async () => {
        try {            
          const data = await checkDriverVehicle();
          if(data.status == 200) {
            if(data.step==1){
              return navigate("/add-driver");
            }else if(data.step==2){
              return navigate("/add-plate");
            }
           
          }else{
            return navigate("/add-driver");
          }
          
        } catch(err) {
          console.log(err.message);
        }
      })
      }
     
    }, [dispatch,window.location.pathname]); 
    return (
        <section className='sec-ticket login-part-pages'>
            <div className='container'>
            <div className="row user-panel-menus">
          <div className="left-sidebar">
                        <UserSidepanel/>
                    </div>
                    <div className='right-sidebar'>
                        <SecAddDriverManagement states={states} cities={cities} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AddDriver;
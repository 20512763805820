
import React, {useEffect } from 'react';
import { useNavigate } from "react-router";
import Redirecting  from '../shared/redirecting';

import { loginas } from '../actions/login';


const Loginas = () => {
    localStorage.removeItem('access_token');
    const navigate = useNavigate();
    const CryptoJS = require("crypto-js");

    //Decryption
    function cryptoDecrypt(data) {
        var cripData  = CryptoJS.AES.decrypt(data, '123');
        return cripData.toString(CryptoJS.enc.Utf8);
    }

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    let userId = cryptoDecrypt(params.get('ui'));
    let email = cryptoDecrypt(params.get('el'));
    let referredCode = cryptoDecrypt(params.get('rc'));
    let password = cryptoDecrypt(params.get('uc'));

useEffect(() => {
    const loginasInfo = async () => {
      try {            
        const data = await loginas({
          id: userId,
          email: email,
          referred_code: referredCode,
          password: password,
        });  
        if(data.status == 200) {
            navigate("/dashboard");
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loginasInfo();
}, [userId, email, referredCode, password]); 



  return (
    <>
        <Redirecting/>
    </>
  );
};

export default Loginas;
import React from 'react';
import SecCheckout from '../components/Checkout/SecCheckout';
import UserSidepanel from '../shared/userSidepanel';
const Checkout = () => {
    return (
        <section className='sec-ticket checkout-page login-part-pages'>
        <div className='container'>
            <div className='row user-panel-menus'>
                {
                    localStorage.getItem('access_token') && 
                    <div className='left-sidebar'>
                        <UserSidepanel/>
                    </div>
                }
                
        <div className={localStorage.getItem('access_token')?' right-sidebar':' col-xl-12'}>
            <SecCheckout/>
        </div>
      </div>
        </div>
    </section>
    );
};

export default Checkout;
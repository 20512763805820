import React, {useState, useEffect,useRef} from 'react';
import Searching from '../../shared/searching';
import toastr from 'toastr';
import {
  updateProfile, getState, getCountry
} from '../../actions/site';
import {
  updateEmailPhone,verifyEmailPhone,
  resendProfileOtp
} from '../../actions/profile'
import DatePicker  from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import { convertToBase64 } from "../../helpers/base64FileHelper"
import InputMask from 'react-input-mask';
import Autocomplete , { usePlacesWidget } from "react-google-autocomplete";
import { googleApiData } from '../../helpers';

const SecProfile = ({userprofile,isloading}) => {
  const allowedExts = ["jpg", "jpeg", "png"];
  const googleApiKey = googleApiData();
  const [isSearchBtn, setIsSearchBtn] = useState(false);
  const [stateList, setStateList] = useState({});
  const [searchState, setSearchState] = useState([]);
  const [displayState,setDisplayState] =  useState('Select State');

  const [countryList, setCountryList] = useState({});
  const [searchCountry, setSearchCountry] = useState([]);
  const [displayCountry,setDisplayCountry] =  useState('Select Country');
  const [active,setActive] = useState('');
  const [type,setType] = useState(1);
  const [otp,setOtp] = useState("");
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState('');
  const [showImage, setShowImage] = useState('');
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [searchPhoneCode, setSearchPhoneCode] = useState([]);
  const [displayPhoneCode,setDisplayPhoneCode] =  useState('Phone code');
  const [phoneCode,setPhoneCode] = useState('');
  const [dob, setExpireDate] = useState(null);
  const [tlcLicence, setTLCLicence] = useState('');
  const [dwvLicence, setDWVLicence] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZIPCode] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [error, setError] = useState("");
  
  const [isProfileEnabled,setIsProfileEnabled] = useState(false);
  const [isEmailEnabled,setIsEmailEnabled] = useState(false);
  const [isPhoneEnabled,setIsPhoneEnabled] = useState(false);
  const [isOtpEnabled,setIsOtpEnabled] =  useState(false);
  const [countDown,setCountDown] = useState('(00:00)');
  const [isResentOtp,setIsResentOtp] =  useState(false);
  const [isEnabledResentOtp,setIsEnabledResentOtp] =  useState(false);

  const [emailVerified,setEmailVerified] = useState('');
  const [phoneVerified,setPhoneVerified] = useState('');

  const inputRef = useRef(null);

  const onImgChange = (e) => {
       if (e.target.files[0]) {
       const ext = e.target.files[0].type.split("/")[1];
 
       if (![...allowedExts].includes(ext)) {
           console.log("Please upload a valid image")
       } else {
           var output = document.getElementById("image1");
           output.src = URL.createObjectURL(e.target.files[0]);
           output.onload = function () {
           URL.revokeObjectURL(output.src); // free memory
           };
           convertToBase64(e.target.files[0], setImage);
       }
       }
   };
  useEffect(() => {
    // State List   
    const loadStateData = async () => {
        try {            
          const stateData = await getState({
            country_code:"US"
          });  
          if(stateData.status == 200) {
            setStateList(stateData.data)
            setSearchState(stateData.data);
          }    
        } catch(err) {
          console.log(err.message);
        }
      }
      loadStateData(); 
  }, [ ]);

  useEffect(() => {
    // State List   
    const loadCountryData = async () => {
        try {            
          const countryData = await getCountry({});  
          if(countryData.status == 200) {
            setCountryList(countryData.data)
            setSearchCountry(countryData.data);
          }    
        } catch(err) {
          console.log(err.message);
        }
      }
      loadCountryData(); 
  }, [ ]);
  const  closeOneModal = () => {
    const modal = document.getElementById('ModalEmail');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
    setUpdatedEmail('')
    setUpdatedPhone('')
 
  }
  useEffect(() => {
    setName(userprofile.name)
    setPhone(userprofile.phone)
    setEmail(userprofile.email)
    setTLCLicence(userprofile.tlc_licence)
    setDWVLicence(userprofile.dwv_licence)
    setAddress(userprofile.address)
    setCity(userprofile.city)
    setShowImage(userprofile.image)
    setState(userprofile?.state_details?._id)
    setDisplayState(userprofile?.state_details?.name)
    setDisplayCountry(userprofile?.country_details?.name)
    setCountry(userprofile.country)
    setZIPCode(userprofile.zip_code)
    setReferralCode(userprofile.referred_code)
    setExpireDate(new Date(userprofile.dob).getTime());
    setEmailVerified(userprofile?.email_verified);
    setPhoneVerified(userprofile?.phone_verified)
}, [userprofile]);

const onSubmit = async () => {
  setIsProfileEnabled(true);
  try {
    let msg = await updateProfile({
      id : localStorage.getItem('userId'),
      name: name,
      phone: (phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
      email:email,
      tlc_licence:tlcLicence,
      dwv_licence:dwvLicence,
      address:address,
      city:city,
      image,
      state:state,
      country:country,
      zip_code:zipCode,
      old_password : oldPassword,
      newPassword : newPassword,
      confirmPassword : rePassword,
      dob : dob,
    });

    if (msg.status === 200) {
      setName(msg.data?.name);
      setPhone(msg.data?.phone)
      setEmail(msg.data?.email)
      setTLCLicence(msg.data?.tlc_licence)
      setDWVLicence(msg.data?.dwv_licence)
      setAddress(msg.data?.address)
      setShowImage(msg.data?.image)
      setCity(msg.data?.city)
      setState(msg.data?.state)
      setCountry(msg.data?.country)
      setZIPCode(msg.data?.zip_code)
      setReferralCode(msg.data?.referral_code)
      setExpireDate(new Date(msg.data?.dob).getTime());
      setOldPassword('')
      setNewPassword('')
      setRePassword('')
      setIsProfileEnabled(false);
      toastr.success(msg.message);
    } else {
      setIsProfileEnabled(false);
      toastr.error(msg.message);
    }
  } catch (err) {
    setIsProfileEnabled(false);
    toastr.error(err.message);
  }
}

  const onSubmitUpdateEmail = async (e) => { 
    setError("");
    e.preventDefault();   
    setIsEmailEnabled(true);
    try {      
        let msg = await  updateEmailPhone({  
          type : type,
          email : updatedEmail,          
        });
        if(msg.status === 200){ 
          setIsEmailEnabled(false);     
          toastr.success(msg.message);
          setActive('otp');
          setType(type)    
          otpCounter();      
        }else{
          setIsEmailEnabled(false);
          toastr.error(msg.message);
        } 
    } catch(err) {
      setIsEmailEnabled(false);
      toastr.error(err.message);
    }
  }

  const onSubmitUpdatePhone = async (e) => { 
    setError("");
    e.preventDefault();   
    setIsPhoneEnabled(true);
    try {
        let msg = await  updateEmailPhone({  
          type : type,
          phone : (updatedPhone)?updatedPhone.replace(/[(|)|-]/g, "").replace(/\s/g,''):'',
          phone_code :phoneCode ,
        }); 
        if(msg.status === 200){    
          setIsPhoneEnabled(false);
          toastr.success(msg.message);
          setActive('otp');
          setType(type)
          otpCounter();
        }else{
          setIsPhoneEnabled(false);
          toastr.error(msg.message);
        } 
    } catch(err) {
      setIsPhoneEnabled(false);
      toastr.error(err.message);
    }
  }
  const onVerifyotp = async (e) => {
    setError("");
    e.preventDefault(); 
    setIsOtpEnabled(true);
    try {
        let msg = await  verifyEmailPhone({  
          type : type,
          otp : otp,
        });         
        if(msg.status === 200){ 
          setEmail(msg.data.email);     
          setPhone(msg.data.phone);     
          setOtp("");     
          setUpdatedPhone("");
          setIsOtpEnabled(false);
          toastr.success(msg.message);
          setEmailVerified(msg.data?.email_verified);
          setPhoneVerified(msg.data?.phone_verified);
          closeOneModal();
        }else{
          setIsOtpEnabled(false);
          toastr.error(msg.message);
        }     
       
    } catch(err) {
      setIsOtpEnabled(false);
      toastr.error(err.message);
    }
  }
  

  const handleChangeState = (event) => {
    let searchStates = stateList.filter((item) => { 
        if(item.state_code){ 
            return item.state_code.toLowerCase().includes(event.target.value.toLowerCase())
        }
    })
    setSearchState(searchStates);
  }
  const handleChangeCountry = (event) => {
    let searchCountrys = countryList.filter((item) => { 
        if(item.name){ 
            return item.name.toLowerCase().includes(event.target.value.toLowerCase())
        }
    })
    setSearchCountry(searchCountrys);
  }
  const handleChangePhoneCode = (event) => {
    let searchPhoneCodes = countryList.filter((item) => { 
        if(item.phone_code){ 
            return item.phone_code.toLowerCase().includes(event.target.value.toLowerCase())
        }
    })
    setSearchPhoneCode(searchPhoneCodes);
  }
  const years = range(1970, getYear(new Date('2050')) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const  inputDate = (e) => {
    setExpireDate(e);
    //alert(dConvert(e));
    //  setUsers({
    //   ...userDetails, date_of_birth: dConvert(e)
    //   })
  };

  const resendOtpFun = async (type) => {
    setIsEnabledResentOtp(true);
    if(type === 1){
      try {
        let msg = await resendProfileOtp({ 
          email : updatedEmail,
          type:type,
        });
        if(msg.status === 200){ 
          otpCounter();
          setIsEnabledResentOtp(false);
          toastr.success(msg.message);
        }else{
          setIsEnabledResentOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsEnabledResentOtp(false);
        toastr.error(err.message);
      }
    }else{
      try {
        let msg = await resendProfileOtp({ 
          phone:(updatedPhone)?updatedPhone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
          type:type,
        });
        if(msg.status === 200){ 
          otpCounter();
          setIsEnabledResentOtp(false);
          toastr.success(msg.message);
        }else{
          setIsEnabledResentOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsEnabledResentOtp(false);
        toastr.error(err.message);
      }
    }
  }

  const otpCounter = () => {
    setIsResentOtp(true);
    const count_time5 = add_minutes(new Date(), 2).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const count_time20 = new Date(count_time5).getTime();
    const x20 = setInterval(function() {
      const timezone20 = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const now20 = new Date(timezone20).getTime();
      const distance20 = count_time20 - now20;
      const days20 = Math.floor(distance20 / (1000 * 60 * 60 * 24));
      const hours20 = Math.floor((distance20 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes20 = Math.floor((distance20 % (1000 * 60 * 60)) / (1000 * 60));
      const seconds20 = Math.floor((distance20 % (1000 * 60)) / 1000);
      setCountDown('('+ ('0'+minutes20).slice(-2) + ":" + ('0'+seconds20).slice(-2) +')');
      if (distance20 < 0) {
          clearInterval(x20);
          setCountDown('(00:00)');
          setIsResentOtp(false);
      }
    }, 1000);
  } 
  
  const add_minutes = (dt2, minutes2) => {
    return new Date(dt2.getTime() + minutes2*60000);
  }

  return (
    <section>
    <div className='body-layout'>
    <div className='body-content'>
      <div className='user-search-part'>
        <div className='title'>Profile</div>
      </div>
      {
        isloading?(<Searching/>):( <div className='add-new-driver'>
        <div className='profile-inner'>
          {/*<div className='myaccount-profile-img'><label className='' for="profilePicture">
              <div className='myaccount-profile-img-warp'>
                <img alt="" src="assets/img/users/2.jpg" />
              </div>
                <input className='' id="profilePicture" type="file" />
                <div className='upload-icon'><i className="fal fa-camera"></i></div>
            </label>
            </div>*/}
           <form className='form-content'>
            <div className='profile-content'>
              <div className='form-group'>
                <div className='profile-title'>Image</div>
                <div className='driver-info'>
                 <div className="profile-image-edit">
                  <input 
                  name="image" 
                  type="file" 
                  id="file-input" 
                  className="upload-file" 
                  onChange ={onImgChange} 
                  accept="image/*"/>
                  <label className="file-input-label" for="file-input" >
                    <div className="no-image">
                      <img id="image1" alt="dp" src={showImage} />
                      </div>
                      <div className="icon">
                        <i className="fa-light fa-pencil"></i>
                        </div>
                  </label>
                 </div>
                </div>
                <div className='profile-title'>Personal Information</div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>Full Name</div>
                    <div className='driver-input'>
                      <input className='input' type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                       />
                    </div>
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title position-relative'>Phone Number &nbsp;
                    {
                      phone ?
                      (phoneVerified && phoneVerified === 1)?<span class="badge badge-success">Verified</span>:<span class="badge badge-danger">Not Verified</span>:''
                    }
                      <div className="icon-edit-email">
                        <button type="button" className="edit-btn"  onClick={(e)=>{setType(2);setActive("update-phone")}} data-toggle="modal" data-target="#ModalEmail"><i className="fas fa-edit"> </i></button>
                      </div>
                    </div>
                   
                    <div className='driver-input'>
                      <input className='input' type="text"
                        value={phone}  
                        readOnly="readonly" 
                      />
                    </div>
                 </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title position-relative'>Email Address &nbsp;
                    {
                      email ?
                      (emailVerified && emailVerified === 1)?<span class="badge badge-success">Verified</span>:<span class="badge badge-danger">Not Verified</span>:''
                    }
                    <div className="icon-edit-email"><button type="button"  onClick={(e)=>{setType(1);setActive("update-email")}} className="edit-btn" data-toggle="modal" data-target="#ModalEmail" ><i className="fas fa-edit"> </i></button>  </div></div>
                    <div className='driver-input'>
                      <input 
                      className='input' 
                      type="email" 
                      value={email}                       
                      readOnly="readonly" 
                      />
                    </div>
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Date of Birth</div>
                    <div className='driver-input'>
                      {/* <input className='input' type="date" value="22-09-1998" /> */}
                      <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={dob}
                      onChange={inputDate}
                    />
                    </div>
                 </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>TLC Licence</div>
                    <div className='driver-input'>
                      <input className='input' type="text"
                      value={tlcLicence} 
                      onChange={(e) => setTLCLicence(e.target.value)} 
                      />
                    </div>
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>DMV License</div>
                    <div className='driver-input'>
                      <input className='input' type="text" 
                      value={dwvLicence}
                      onChange={(e) => setDWVLicence(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                     <div className='driver-info-title required'>Address</div>
                    <div className='driver-input'>
                      <Autocomplete 
                      className='input' 
                      type="text" 
                      value={address}
                      ref={inputRef}
                      apiKey={ googleApiKey }
                      onPlaceSelected={(place) => {
                        setAddress(place.formatted_address);   
                        if(place && place.address_components.length>0){
                            for (let i = 0; i < place.address_components.length; i++) {
                                if(place.address_components[i].types[0] === 'sublocality_level_1'){
                                    setCity(place.address_components[i]?.long_name)
                                }
                                if(place.address_components[i].types[0] === 'administrative_area_level_1'){
                                    setDisplayState(place.address_components[i]?.short_name)
                                    let index = stateList.findIndex((item) => item.name === place.address_components[i]?.short_name);
                                    if(index != -1){
                                      setState(stateList[index]?._id);
                                    }
                                }
                                if(place.address_components[i].types[0] === 'postal_code'){
                                  setZIPCode(place.address_components[i]?.short_name)
                                }
                            }
                        }
                    }}
                    options={{
                      types: ['geocode'],
                      componentRestrictions: { country: "us" },
                    }}                      
                    onChange={(e) => setAddress(e.target.value)} 
                    />
                    </div>
                  </div>
                  <div className='driver-info-content'>
                      <div className='driver-info-title required'>Country</div>
                      <div className='ticket-dropdown'>
                        <div className='dropdown'>
                          <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayCountry}</button>
                          <div className='dropdown-menu'>
                            <div className='dropdown-search'>
                              <input type="text" className='form-control' placeholder="Search" onChange={handleChangeCountry} id="search-state-input"/>
                            </div>
                            <div className="dropdown-scroll">
                            {
                                  searchCountry.length > 0 &&
                                  searchCountry.map((item,index) => (
                                    <button 
                                    key={index}
                                    type="button" 
                                    className="dropdown-item" 
                                    data-value={item?.name}  
                                    data-name={item?.name}
                                    onClick={() => {setCountry(item?._id); setDisplayCountry(item?.name  )}} 
                                    >{item?.name}</button>
                                  ))
                                }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  {/* <div className='driver-info-content'>
                     <div className='driver-info-title'>City</div>
                    <div className='driver-input'>
                      <input className='input' type="text" 
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div> */}
                 
                </div>
                <div className='driver-info'>
                   {/* <div className='driver-info-content'>
                      <div className='driver-info-title'>State</div>
                      <div className='ticket-dropdown'>
                        <div className='dropdown'>
                          <button type="button" className='dropdown-toggle vehicle-input' data-toggle="dropdown">{displayState}</button>
                          <div className='dropdown-menu'>
                            <div className='dropdown-search'>
                              <input type="text" className='form-control' placeholder="Search"   onChange={handleChangeState} id="search-state-input"/>
                            </div>
                            <div className='dropdown-scroll'>
                                        {
                                  searchState.length > 0 &&
                                  searchState.map((item,index) => (
                                    <button 
                                    key={index}
                                    type="button" 
                                    className="dropdown-item" 
                                    data-value={item?.state_code}  
                                    data-name={item?.name}
                                    onClick={() => {setState(item?._id); setDisplayState(item?.name  )}} 
                                    >{item?.state_code} - {item?.name}</button>
                                  ))
                                }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    
                </div>

                {/* <div className='driver-info'>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Zip</div>
                    <div className='driver-input'>
                      <input className='input' type="text" 
                        value={zipCode}
                        onChange={(e) => setZIPCode(e.target.value)}
                      /> 
                    </div>
                  </div>
                    <div className='driver-info-content'>
                    <div className='driver-info-title'>Referral Code</div>
                    <div className='driver-input'>
                      <input className='input' type="text" 
                        value={referralCode}
                       readOnly="readonly" 
                      />
                    </div>
                </div>
                </div> */}
              </div>
            </div>
            <div className='profile-content'>
              <div className='form-group'>
                <div className='profile-title change'>Change Password</div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Old Password</div>
                    <div className='driver-input'>
                      <input className='input' type="password" 
                        onChange={(e) => setOldPassword(e.target.value)}  
                      />
                    </div>
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>New Password</div>
                   <div className='driver-input'>
                    <input className='input' type="password" 
                      onChange={(e) => setNewPassword(e.target.value)}  
                    />
                   </div>
                 </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Re Enter Password</div>
                   <div className='driver-input'>
                    <input className='input' type="password"
                      onChange={(e) => setRePassword(e.target.value)}  
                    />
                   </div>
                 </div>
                </div>
                <div className='submit-action'>
                  <button type="button" className='btn-submit-action' onClick={onSubmit} disabled={isProfileEnabled}><i className={(isProfileEnabled)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-save mr-2'}></i >Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>)
      }
     
    </div>
  </div>
   <div className="modal fade career-apply-modal profile-edit-form" id="ModalEmail" tabIndex="-1" data-backdrop="static"
    data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="modal-close" data-dismiss="modal"><i
                        className="fa-light fa-fw fa-times"></i></button>
                </div>
                <div className="modal-body">                    
                  <div className="authentication">
                    <div className="auth-content">
                      <div className={`signin-content ${active=='update-email'?'active' : ''}`}>
                        <h2 className="auth-title">Email</h2>
                        <form className="form-content">
                          <div className="form-group">
                            <div className="input-style">
                              <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                              <input 
                              className="input" 
                              type="text" 
                              placeholder="Email Address"
                              value={updatedEmail}
                              onChange={(e) => setUpdatedEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-action">
                            <button type="button" className="btn-action" onClick={onSubmitUpdateEmail} disabled={isEmailEnabled}><i className={(isEmailEnabled)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i>Update</button>
                          </div>
                        </form>
                      </div>
                      <div className={`signin-content ${active=='otp'?'active' : ''}`}>
                        <h2 className="auth-title">Verification Code</h2>
                        <div className="auth-text"></div>
                        <form className="form-content">
                          <div className="form-group">
                            <div className="input-style">
                              <div className="input-icon"><i className="fa-regular fa-fw fa-key"></i></div>
                              <InputMask 
                              mask="999999" 
                              className="input"  
                              type="text" 
                              placeholder={`Enter ${(type === 1)?'Email':'SMS'} Verification Code`}
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)} />
                            </div>
                            <span dangerouslySetInnerHTML={{__html:countDown}}></span> 
                          </div>
                          <div className="form-action">
                            <button type="button" className="btn-action mr-2" onClick={onVerifyotp} disabled={isOtpEnabled}><i className={(isOtpEnabled)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-key mr-2'}></i>Verify</button>
                            <button type="button" className="btn-action" onClick={() => resendOtpFun(type)} disabled={isResentOtp}><i className={(isEnabledResentOtp)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-clock mr-2"}></i>Resend OTP</button>
                          </div>
                        </form>
                      </div>
                       <div className={`signin-content ${active == 'update-phone'?'active' : ''}`}>
                        <h2 className="auth-title">Phone</h2>
                        <div className="auth-text"></div>
                        <form className="form-content">
                          <div className="phone form-row">
                            {/* <div className="form-group col-md-5">
                            <div className='dropdown state-dropdown'>
                              <button className="dropdown-toggle vehicle-input" type="button" data-toggle="dropdown">{displayPhoneCode}</button>
                                <div className="dropdown-menu">
                                  <div className="dropdown-search">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Search" 
                                    onChange={handleChangePhoneCode}
                                    />
                                  </div>
                                  <div className="dropdown-scroll" id="search-state">
                                          {
                                      searchPhoneCode.length > 0 &&
                                      searchPhoneCode.map((item,index) => (
                                        <button 
                                        key={index}
                                        type="button" 
                                        className="dropdown-item" 
                                        data-value={item?.phone_code}  
                                        data-name={item?.phone_code}
                                        onClick={() => {setPhoneCode(item?.phone_code); setDisplayPhoneCode(item?.phone_code)}} 
                                        >{item?.phone_code} </button>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="form-group col-md-12">
                              <div className="input-style">
                                <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                                <InputMask
                                mask="(999) 999-9999"  
                                className="input" 
                                type="text" 
                                placeholder="Phone"

                                onChange={(e) => setUpdatedPhone(e.target.value)}/>
                              </div>
                            </div>
                          </div>
                          <div className="form-action">
                            <button type="button" className="btn-action" onClick={onSubmitUpdatePhone} disabled={isPhoneEnabled}><i className={(isPhoneEnabled)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-user-plus mr-2'}></i >Update</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                
                    {/* <div className="modal-footer justify-content-center">
                        <button 
                        type="button"
                        className="btn-action"
                        onClick={updateEmail}
                        disabled={isSearchBtn}
                        ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Update
                        </button>
                    </div> */}
            </div>
        </div>
    </div>
    </section>
  );

};

export default SecProfile;
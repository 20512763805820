import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import {useStripe, useElements,CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from 'react-stripe-elements';
import {
  stripeSetupIntents,
  stripeCustomer,
  stripeCardAdd,  
  stripeChargeCreate,
  storeTlcStripeData,
  storeInvoiceStripeData,
  getEmailToUser
} from '../../actions/payment';
import { useNavigate } from "react-router";

const CheckoutForm=({
  type,
  price,
  ticket_id,
  payment_for,
  invoice_no,
  coupon,
  couponPrice,
  taxs,
  reward_amount,
  payment_type,
  email,
  user_info,
  isHome,
  phone,
  isCreateUser,
  cartInfo}) => { 
  const navigate = useNavigate(); 
  const [cardData,setCardData] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [isPayment,setIsPayment] = useState(false);

  const [clientSecret,setClientSecret] = useState('');
  const [setupIntents,setSetupIntents] = useState('');
  const [customerId,setCustomerId] = useState(''); 

  const handleSubmit = (stripe, elements) => async () => {
    setIsPayment(true);
    const cardElement = elements.getElement(CardElement); 
    if(localStorage.getItem('access_token') && !invoice_no){
      try {                     
          const data = await stripeSetupIntents({
              price: price,
              cartId:localStorage.getItem('cartId'),
          });  
          if (data.status === 200) {
              setClientSecret(data.data.client_secret);
              const cardSetup = await stripe.confirmCardSetup(data.data.client_secret, {
                  payment_method: {
                    card: cardElement,
                    billing_details: {
                      name: user_info?.name,
                    },
                  },
              });
              if(cardSetup.errors){
                setIsPayment(false);
                toastr.error(cardSetup.errors);
              }else if(cardSetup.error){
                setIsPayment(false);
                toastr.error(cardSetup.error.message);
              }else{
                setSetupIntents(cardSetup.setupIntent.payment_method);
                try {            
                    const cust = await stripeCustomer({
                        payment_method:cardSetup.setupIntent.payment_method
                    });  
                    if (cust.status === 200) {
                        setCustomerId(cust.data.id); 
                    } else {
                        setIsPayment(false);
                        toastr.error(data.message);
                    }    
                } catch(err) {
                  setIsPayment(false);
                  toastr.error(err.message);
                }
              }
          } else {
              setIsPayment(false);
              toastr.error(data.message);
          }    
      } catch(err) {
        setIsPayment(false);
        toastr.error(err.message);
      }
    }else{      
      const {error, data} = await stripe.createToken(cardElement);
      if (error) {
        console.log('[error]', error);
        setIsPayment(false);
      } else {
        const cardElement = elements.getElement(CardElement);
        let token  = await stripe.createToken(cardElement);
        setCardData(token.token.id);
      }
    }
      
    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    // });   
    // if (error) {
    //   console.log('[error]', error);
    //   setIsPayment(false);
    // } else {
    //   console.log('Payment Method',paymentMethod);
    //   console.log('error',error);
    // } 
    // const payload = await stripe.confirmCardPayment(stripePromise, {
    //   payment_method: {
    //     card: cardElement,
    //   },
    // });
    // if (payload.error) {
    //   console.log(`Payment failed ${payload.error.message}`);
    // } else {
    //   console.log(payload);
    // }
    
  };


  // const handleSubmit = (stripe, elements) => async () => {
  //   setIsPayment(true);
  //   const cardElement = elements.getElement(CardElement);
  //   const {error, data} = await stripe.createToken(cardElement);
  //   if (error) {
  //     console.log('[error]', error);
  //     setIsPayment(false);
  //   } else {
  //     const cardElement = elements.getElement(CardElement);
  //     let token  = await stripe.createToken(cardElement);
  //     setCardData(token.token.id);
  //   }
  // };
  

  useEffect(() => {
    // Card Add
    const loadCardAdd = async () => {
        try {            
            const data = await stripeCardAdd({
                userId:localStorage.getItem('userId'),
                cardId:cardData,
            });  
            if (data.status === 200) {
                toastr.success(data.message);
                setCardData(''); 
            } else {
                toastr.error(data.message);
            }    
        } catch(err) {
          toastr.error(err.message);
        }
    }
    // if(type == 2 && cardData){
    //   loadCardAdd();
    // }

    // Charge create
    const loadChargeCreate = async () => {
      if(isCreateUser){
        const user = await getEmailToUser({
            email:user_info?.email,
            phone:user_info?.phone,
            first_name:user_info?.first_name,
            last_name:user_info?.last_name,
            name : user_info?.first_name+' '+user_info?.last_name,
            address:user_info?.address
        })
        if(user.status === 200){
          try {            
            const data = await stripeChargeCreate({
                userId:(user?.data?._id)?user?.data?._id:localStorage.getItem('userId'),
                cartId:localStorage.getItem('cartId'),                
                customerId:customerId,
                payment_method:setupIntents,
                token:cardData,
                price: price,
                coupon:coupon,
                couponPrice:couponPrice,
                taxs:taxs,
                reward_amount:reward_amount,
                user_info:user_info,
                cartInfo:cartInfo
            });  
            if (data.status === 200) {
                setCardData(''); 
                setIsPayment(false)
                return navigate("/success?txn="+ data?.data?.transaction_id);
            } else {
                toastr.error(data.message);
                setIsPayment(false)
            }    
          } catch(err) {
            toastr.error(err.message);
            setIsPayment(false)
          }
        }
      }else{
        try {            
            const data = await stripeChargeCreate({
                userId:localStorage.getItem('userId'),
                cartId:localStorage.getItem('cartId'),                
                customerId:customerId,
                payment_method:setupIntents,
                token:cardData,
                price: price,
                coupon:coupon,
                couponPrice:couponPrice,
                taxs:taxs,
                reward_amount:reward_amount,
                user_info:user_info,
                cartInfo:cartInfo
            });  
            if (data.status === 200) {
                setCardData(''); 
                setIsPayment(false)
                return navigate("/success?txn="+ data?.data?.transaction_id);
            } else {
                toastr.error(data.message);
                setIsPayment(false)
            }    
        } catch(err) {
          toastr.error(err.message);
          setIsPayment(false)
        }
      } 
    }
    if((cardData || customerId) && !ticket_id && !invoice_no){
      loadChargeCreate();
    }
  }, [cardData,customerId]); 


  const  closeOneModal = () => {
      const modal = document.getElementById('ModalTicketDetails');
      modal.classList.remove('show');
      modal.setAttribute('aria-hidden', 'true');
      modal.setAttribute('style', 'display: none');
      const modalBackdrops = document.getElementsByClassName('modal-backdrop');
      document.body.removeChild(modalBackdrops[0]);
  }

  const  closeOneModal2 = () => {
    const modal = document.getElementById('ModalTLCPaymentDetails');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
  }

  const  closeOneModal3 = () => {
    const modal = document.getElementById('ModalTlcTicketDetails');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
} 


  useEffect(() => {
    // tlc Charge create
    const loadTlcStripe = async () => {
        try {  
            if(email) {
              const user = await getEmailToUser({
                  email:email,
                  phone:phone
              })
              if(user.status === 200){
                const data = await storeTlcStripeData({
                    price: price,
                    token:cardData,
                    ticket_no:ticket_id,
                    payment_for:payment_for,
                    payment_type:payment_type,
                    email:email,
                    userId : user.data._id,
                    user_info:user_info
                });  
                if (data.status === 200) {
                    //toastr.success(data.message);
                    setCardData(''); 
                    setIsPayment(false)
                    if(payment_for ==='assignee'){
                      if(email || isHome){
                        closeOneModal3();
                      }else{
                        //closeOneModal();
                      }                      
                      return navigate("/authorization-form/"+ticket_id);                 
                    }
                    if(payment_for ==='reduce'){
                      //closeOneModal2();
                      return navigate("/success?txn="+ data?.data?.transaction_id);
                    }
                } else {
                    toastr.error(data.message);
                    setIsPayment(false)
                }
              }
            }else{
              const data = await storeTlcStripeData({
                  price: price,
                  token:cardData,
                  customerId:customerId,
                  payment_method:setupIntents,
                  ticket_no:ticket_id,
                  payment_for:payment_for,
                  payment_type:payment_type,
                  email:email,
                  user_info:user_info
              });  
              if (data.status === 200) {
                  //toastr.success(data.message);
                  setCardData(''); 
                  setIsPayment(false)
                  if(payment_for ==='assignee'){
                    if(email || isHome){
                      closeOneModal3();
                    }else{
                      //closeOneModal();
                    }
                    return navigate("/authorization-form/"+ticket_id);                 
                  }
                  if(payment_for ==='reduce'){
                    //closeOneModal2();
                    return navigate("/success?txn="+ data?.data?.transaction_id);
                  }
              } else {
                  toastr.error(data.message);
                  setIsPayment(false)
              }
            }   
        } catch(err) {
          toastr.error(err.message);
          setIsPayment(false)
        }
    }
    if((cardData || customerId) && ticket_id){
      loadTlcStripe();
    }
  }, [cardData,ticket_id,payment_for,customerId]); 

  useEffect(() => {
    // tlc Charge create
    const loadInvoiceStripe = async () => {
        try {            
            const data = await storeInvoiceStripeData({
                token:cardData,
                invoice_no:invoice_no,
                payment_for:payment_for
            });  
            if (data.status === 200) {
                //toastr.success(data.message);
                setCardData(''); 
                if(payment_for ==='assignee'){
                  //closeOneModal();
                  closeOneModal3();
                }
                if(payment_for ==='reduce'){
                  //closeOneModal2();
                }
                
                setIsPayment(false)
                return navigate("/success?txn="+ data?.data?.transaction_id);
            } else {
                toastr.error(data.message);
                setIsPayment(false)
            }    
        } catch(err) {
          toastr.error(err.message);
          setIsPayment(false)
        }
    }
    if(cardData && invoice_no){
      loadInvoiceStripe();
    }
  }, [cardData,invoice_no]);   

  return (
    <>
      <CardElement />
      <button 
      onClick={handleSubmit(stripe, elements)} 
      className='payment-card-btn pay' 
      disabled={isPayment}
      ><i className={(isPayment)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i>Submit</button>
    </>
  );
  
};

export default CheckoutForm;
import React from 'react';
import SecSearch from '../components/TicketSearch/SecSearch';

const TicketSearch = () => {
  return (
    <>
      <SecSearch/>
    </>
  );
};

export default TicketSearch;
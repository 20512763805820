import React from 'react';
import { Route, Routes, BrowserRouter,Navigate } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import FreeAuthRoute from "./routes/FreeAuthRoute";
import NoAuthRoute from "./routes/NoAuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";


import DefaultLayout from './layouts/defaultLayout';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Career from './pages/Career';
import Solutions from './pages/Solutions';
import Cart from './pages/Cart';
import TicketSearch from './pages/TicketSearch';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Signup from './pages/Signup';

import Dashboard from './pages/Dashboard';
import TicketManagement from './pages/TicketManagement';
import DriverManagement from './pages/DriverManagement';
import AddDriver from './pages/AddDriver';
import EditDriver from './pages/EditDriver';
import DriverDetails from './pages/DriverDetails';
import UserProfile from './pages/UserProfile';
import Billing from './pages/Billing';
import BillingDetails from './pages/BillingDetails';
import Plates from './pages/Plates';
import AddPlate from './pages/AddPlate';
import PlateDetails from './pages/PlateDetails';
import PlateDetailsGlobal from './pages/PlateDetailsGlobal';
import TicketDetailsGlobal from './pages/TicketDetailsGlobal';

import SubmitTicket from './pages/SubmitTicket';
import Checkout from './pages/Checkout';
import EditPlate from './pages/EditPlate';
import Referral from './pages/Referral';
import PayInvoice from './pages/PayInvoice';
import Success from './pages/PaymentSuccess';
import ReferralDetails from './pages/ReferralDetails';
import Loginas from './pages/Loginas';
import AuthorizationForm from './pages/AuthorizationForm';
import TicketDetails from './pages/TicketDetails';
import Notifications from './pages/Notifications';
import Tickets from './pages/Tickets';
import TlcTickets from './pages/TlcTickets';

import { SOCKET_URL } from "./config/host";
import { io } from "socket.io-client";
const socket = io(SOCKET_URL);

const CreateRoutes = () => {
    return (
        <BrowserRouter>
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<DefaultLayout socket={socket}/>}>                
                    <Route 
                    path='/' 
                    exact 
                    element={
                        <Home />
                    } />
                </Route>
                <Route path='/about-us' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/about-us' 
                    exact 
                    element={
                        <AboutUs />
                    } />
                </Route>
                <Route path='/contact-us' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/contact-us' 
                    exact 
                    element={
                        <ContactUs />                    
                    } />
                </Route>
                <Route path='/career' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/career' 
                    exact 
                    element={
                      <Career />                  
                } />
                </Route>
                <Route path='/vehicle/:state/:plate_no' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/vehicle/:state/:plate_no' 
                    exact 
                    element={
                      <PlateDetailsGlobal />                  
                } />
                </Route>
                <Route path='/ticket/:type/:ticket_id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/ticket/:type/:ticket_id' 
                    exact 
                    element={
                      <TicketDetailsGlobal />                  
                } />
                </Route>
                <Route path='/solutions' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/solutions' 
                    exact 
                    element={
                        <Solutions />                    
                    } />
                </Route>                
                <Route path='/cart' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/cart' 
                    exact 
                    element={
                        <Cart />                    
                    } />
                </Route>

                <Route path='/ticket-search' element={<DefaultLayout socket={socket}/>}>
                    <Route path='/ticket-search' exact element={<TicketSearch />} />
                </Route>
                <Route path='/terms-of-service' element={<DefaultLayout socket={socket}/>}>
                    <Route path='/terms-of-service' exact element={<TermsOfService/>} />
                </Route>
                <Route path='/privacy-policy' element={<DefaultLayout socket={socket}/>}>
                    <Route path='/privacy-policy' exact element={<PrivacyPolicy/>} />
                </Route>

                <Route path='/signup' element={<DefaultLayout socket={socket}/>}>                
                    <Route 
                    path='/signup' 
                    exact 
                    element={
                        <NoAuthRoute>
                            <Signup />
                        </NoAuthRoute>
                        
                    } />
                </Route>
                <Route path='/loginas-user' element={<DefaultLayout socket={socket}/>}>                
                    <Route 
                    path='/loginas-user' 
                    exact 
                    element={
                        <Loginas />
                    } />
                </Route>
                <Route path='/dashboard' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/dashboard' 
                    exact 
                    element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>} />
                </Route>
                <Route path='/tickets' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/tickets' 
                    exact 
                    element={
                    <ProtectedRoute>
                        <Tickets />
                    </ProtectedRoute>} />
                </Route>
                <Route path='/authorization-form/:summons_number' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/authorization-form/:summons_number' 
                    exact 
                    element={
                        <AuthorizationForm />
                        // <ProtectedRoute>
                        //     <AuthorizationForm />
                        // </ProtectedRoute>
                    } />
                </Route>
                <Route path='/ticket-management' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/ticket-management' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <TicketManagement />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/tlc-tickets' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/tlc-tickets' 
                    exact 
                    element={
                    <ProtectedRoute>
                        <TlcTickets />
                    </ProtectedRoute>} />
                </Route>
                <Route path='/ticket/:id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/ticket/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <TicketDetails />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/submit-ticket' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/submit-ticket' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <SubmitTicket />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/driver-management' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/driver-management' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <DriverManagement />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/add-driver' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/add-driver' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <AddDriver />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/edit-driver/:id' element={<DefaultLayout socket={socket} />}>
                    <Route 
                    path='/edit-driver/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <EditDriver />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/driver-details/:id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/driver-details/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <DriverDetails />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/plates' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/plates' 
                    exact 
                    element={
                        <ProtectedRoute>
                        <Plates />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/add-plate' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/add-plate' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <AddPlate />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/edit-plate/:id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/edit-plate/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <EditPlate />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/plate-details/:id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/plate-details/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <PlateDetails />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/billings' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/billings' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <Billing />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/transaction/:id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/transaction/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <BillingDetails />
                        </ProtectedRoute>
                    } />
                </Route>
               
                <Route path='/referral' element={<DefaultLayout socket={socket}/>}>
                <Route 
                    path='/referral' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <Referral />
                        </ProtectedRoute>} 
                    />
                </Route>
                
                <Route path='/referral-details/:id' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/referral-details/:id' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <ReferralDetails />
                        </ProtectedRoute>
                    } />
                </Route>

                <Route path='/user-profile' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/user-profile' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <UserProfile />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/invoice-payment/:invoice' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/invoice-payment/:invoice' 
                    exact 
                    element={
                        <PayInvoice />
                    } />
                </Route>
                <Route path='/success' element={<DefaultLayout socket={socket}/>}>                
                    <Route 
                    path='/success' 
                    exact 
                    element={
                        <Success />
                    } />
                </Route>
                <Route path='/notifications' element={<DefaultLayout socket={socket}/>}>
                    <Route 
                    path='/notifications' 
                    exact 
                    element={
                        <ProtectedRoute>
                            <Notifications socket={socket} />
                        </ProtectedRoute>
                    } />
                </Route>
                <Route path='/checkout' element={<DefaultLayout socket={socket}/>}>
                    <Route path='/checkout' exact element={<Checkout />} />
                </Route>
                <Route path='*' exact element={<Navigate  replace to="/" />} />
            </Routes>
        </ScrollToTop>
    </BrowserRouter>
    );
};

export default CreateRoutes;

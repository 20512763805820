import React, { useState, useEffect } from 'react';
import SecInvoice from '../components/Invoice/SecInvoice';
import { useParams } from 'react-router';
import {checkInvoice} from '../actions/payment';
import toastr from 'toastr';
import { useNavigate } from "react-router";

const PayInvoice = () => {
    const navigate = useNavigate(); 
    const { invoice } = useParams();
    const [invoiceData,setInvoiceData] = useState({});
    useEffect(() => {
        // Invoice Data
        const loadInvoiceData = async () => {
            try {            
                const data = await checkInvoice({
                    invoice_no:invoice
                });  
                if (data.status === 200) {
                    setInvoiceData(data.data);
                    if(data.data?.payment_status != 'pending'){
                        return navigate("/");
                    }
                } else {                    
                    toastr.error(data.message);
                }    
            } catch(err) {
              toastr.error(err.message);
              return navigate("/");
            }
        }
        loadInvoiceData();
    }, [invoice]); 
    
    return (
        <section className='sec-ticket'>
        <div className='container'>
            <div className='row'>                
                <div className='col-lg-12 col-xl-12'>
                    <SecInvoice 
                    invoice={invoiceData}
                    invoice_no={invoice}
                    />
                </div>
            </div>
        </div>
    </section>
    );
};

export default PayInvoice;
export function googleApiData() {
    const googleApi = 'AIzaSyB34rU6Zrqh0u8M7jZy4HWtAWrCmbW-Zwg';
    return googleApi;
}

export function allowedExtensions(){
    const ext = ['jpg', 'jpeg', 'png','pdf']
    return ext;
}

export function stripePublishKey() {
    //Sandbox
   // const stripeKey = 'pk_test_ilEQkE6sFjLYM6a56yotX2kr';
    //Live
    const stripeKey = 'pk_live_eQhJ43HOWqoP6HDiZfA2EcTR';
    return stripeKey;
}

export function paypalClientId(){
    //Sandbox
   // const clientid = 'AYqTRaxfiRXspial7Xiy3EBEkQ4WeqOp4aE7Z3em8f-tJ08EuT3Hb7Gz5TO1SH1_0Sq4HUzAkorIUcaD';
    // Live
    const clientid = 'AY1J4ZQbwyCEEH3rHcTL6t1fHiYNWPmmP4WPEOppz9ycM8vOvWgqSqcPi0cBgeX845sqPb7pp4xPPrRi';
    return clientid;
}
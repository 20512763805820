import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useParams } from 'react-router';
import toastr from 'toastr';
import { useNavigate } from "react-router";
import { NavLink } from 'react-router-dom';
import { getTransactionDetails } from '../actions/billing';
import Searching from '../shared/searching';
import InputMask from 'react-input-mask';
import { sendOtp,resendOtp,verifyLogin,verifyotp } from '../actions/login';

const PaymentSuccess = ({verifyLogin}) => {
  const navigate = useNavigate();
  const { invoice } = useParams();
  const parems = new URLSearchParams(window.location.search).get("txn");
  const [transaction, setTransaction] = useState({});
  const [count, setCount] = useState();
  const [isLoading,setIsLoading] = useState(false);
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [otp,setOtp] = useState('');
  const [isOtp,setIsOtp] = useState(false);
  const [type,setType] = useState(2);
  const [countDown,setCountDown] = useState('(00:00)');
  const [isResentOtp,setIsResentOtp] =  useState(false);
  const [enabledOtp,setEnabledOtp] = useState(false);
  const [isEnabledResentOtp,setIsEnabledResentOtp] =  useState(false);
  const [goToDashboard,setGoToDashboard] = useState(false);

  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d); //numeric
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${mo <= 9 ? "0" + mo : mo} ${da}, ${ye}`;
  };

  useEffect(() => {
    // Transaction List
    const loadTransaction = async () => {
      setIsLoading(true);
      try {
        const data = await getTransactionDetails({
          id: parems
        });
        if (data.status == 200) {
          setTransaction(data.data)
          setCount(data.count)
          setEmail(data?.data?.details?.user_info?.email);
          setPhone(data?.data?.details?.user_info?.phone);
          setIsLoading(false);
        } else {
          console.log(data.message);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err.message);
        setIsLoading(false);
      }
    }
    loadTransaction();
  }, [parems]);

  const otpCounter = () => {
    setIsResentOtp(true);
    const count_time5 = add_minutes(new Date(), 0.5).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const count_time20 = new Date(count_time5).getTime();
    const x20 = setInterval(function() {
      const timezone20 = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const now20 = new Date(timezone20).getTime();
      const distance20 = count_time20 - now20;
      const days20 = Math.floor(distance20 / (1000 * 60 * 60 * 24));
      const hours20 = Math.floor((distance20 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes20 = Math.floor((distance20 % (1000 * 60 * 60)) / (1000 * 60));
      const seconds20 = Math.floor((distance20 % (1000 * 60)) / 1000);
      setCountDown('('+ ('0'+minutes20).slice(-2) + ":" + ('0'+seconds20).slice(-2) +')');
      if (distance20 < 0) {
          clearInterval(x20);
          setCountDown('(00:00)');
          setIsResentOtp(false);
      }
    }, 1000);
  } 
  
  const add_minutes = (dt2, minutes2) => {
    return new Date(dt2.getTime() + minutes2*60000);
  }

  const onOtp = async (type) => {
    setEnabledOtp(true);
    if(type === 1){
      try {
        let msg = await sendOtp({ 
          email:email,
          type:type,
        });
        if(msg.status === 200){ 
          setIsOtp(true);
          otpCounter();
          setEnabledOtp(false);
          toastr.success(msg.message);
        }else{
          setIsOtp(false);
          setEnabledOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsOtp(false);
        setEnabledOtp(false);
        toastr.error(err.message);
      }
    }else{
      try {
        let msg = await sendOtp({ 
          phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
          type:type,
        });
        if(msg.status === 200){
          setIsOtp(true);
          otpCounter();
          setEnabledOtp(false);
          toastr.success(msg.message);
        }else{
          setEnabledOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setEnabledOtp(false);
        toastr.error(err.message);
      }
    }
  }

  const resendOtpFun = async (type) => {
    setIsEnabledResentOtp(true);
    if(type === 1){
      try {
        let msg = await resendOtp({ 
          email : email,
          type:type,
        });
        if(msg.status === 200){ 
          otpCounter();
          setIsEnabledResentOtp(false);
          toastr.success(msg.message);
        }else{
          setIsEnabledResentOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsEnabledResentOtp(false);
        toastr.error(err.message);
      }
    }else{
      try {
        let msg = await resendOtp({ 
          phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
          type:type,
        });
        if(msg.status === 200){ 
          otpCounter();
          setIsEnabledResentOtp(false);
          toastr.success(msg.message);
        }else{
          setIsEnabledResentOtp(false);
          toastr.error(msg.message);
        }      
      } catch(err) {
        setIsEnabledResentOtp(false);
        toastr.error(err.message);
      }
    }
  }

  const onSubmit = async (e) => { 
    e.preventDefault(); 
    if(type && type === 1){
      setGoToDashboard(true);
      try {
        let msg = await  verifyotp({  
          email : email,
          otp : otp,
          type : type
        }); 
        if(msg.status === 200){ 
          try {
            let msg = await verifyLogin({  
              email : email,
              type:type,
            });
            if(msg.status === 200 && !msg.data){      
              toastr.success(msg.message);
              return navigate("/dashboard");
            }else{
              toastr.error(msg.message);
              setGoToDashboard(false); 
            }      
          } catch(err) {
            toastr.error(err.message);
            setGoToDashboard(false); 
          }
        }else{
          toastr.error(msg.message);
          setGoToDashboard(false); 
        }      
      } catch(err) {
        toastr.error(err.message);
        setGoToDashboard(false); 
      }
    }else{
      setGoToDashboard(true); 
        try {
          let msg = await  verifyotp({ 
            phone : (phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
            otp : otp,
            type : type
          }); 
          if(msg.status === 200){ 
            try {
              let msg = await verifyLogin({ 
                phone:(phone)?phone.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):'',
                type:type,
              });
          
              if(msg.status === 200 && !msg.data){      
                toastr.success(msg.message);
                return navigate("/dashboard");
              }else{
                toastr.error(msg.message);
                setGoToDashboard(false); 
              }      
            } catch(err) {
              toastr.error(err.message);
              setGoToDashboard(false); 
            }
          }else{
            toastr.error(msg.message);
            setGoToDashboard(false); 
          }      
        } catch(err) {
          toastr.error(err.message);
          setGoToDashboard(false); 
        }
    }
  }


  return (
    <section className="success-section">
      <div className="success-message">
        <div className="success-image">
          <img src='assets/img/success.png'></img>
        </div>
        <div className="success-text">
          <h6 className="text">Your payment is success</h6>
        </div>
        <div className="details-inner">
          {
            transaction?.tickets?.length > 0 ?
            <div className="details-body-content">
              <div className="details-content">
                <div className="details-ticket-content">
                  <div className="details-ticket-title">Payment Method</div>
                  <div className="details-ticket-sub-title">
                  {(transaction?.details?.transaction_payment_mode && transaction?.details?.transaction_payment_mode === 'stripe')?'Credit Card':transaction?.details?.transaction_payment_mode}
                  </div>
                </div>
                <div className="details-ticket-date">
                  <div className="date-title">Total Amount</div>
                  <div className="date-sub-title">{(transaction?.details?.transaction_amount)?'$'+transaction?.details?.transaction_amount:'N/A'}</div>
                </div>
              </div>
            </div>:''
          }
          {
            transaction?.tickets?.length > 0 ?
            transaction?.tickets?.map((item, index) => (
              <div className="details-body-content" key={index}>
                <div className="details-content">
                  <div className="details-ticket-content">
                    <div className="details-ticket-title">
                      Ticket Number
                    </div>
                    <div className="details-ticket-sub-title">
                      {item?.ticket_number}
                    </div>
                    <div className="details-ticket-text">
                      {item?.ticket_name}
                    </div>
                  </div>
                  <div className="details-ticket-date">
                    <div className="date-title">Issue Date</div>
                    <div className="date-sub-title">{item?.issue_date ? dateFormat(item?.issue_date) : "N/A"}</div>
                  </div>
                </div>
                <div className="details-footer">
                  <div className="plate-inner">
                    {
                      (item?.ticket_details?.ticket_type && item?.ticket_details?.ticket_type === 'tlc') ?
                        <div className="plate-content">
                          <div className="plate-item">
                            <div className="plate-title">Violation Amount</div>
                            <div className="plate-sub-title">{(item?.ticket_details?.total_violation_amount) ? '$' + item?.ticket_details?.total_violation_amount : "N/A"}</div>
                          </div>
                        </div>
                        :
                        <>
                          <div className="plate-content">                            
                            <div className="plate-item">
                              <div className="plate-title">Fine</div>
                              <div className="plate-sub-title">{(item?.ticket_details?.fine_amount) ? '$' + item?.ticket_details?.fine_amount : "N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Interest.</div>
                              <div className="plate-sub-title">{(item?.ticket_details?.interest_amount) ? '$' + item?.ticket_details?.interest_amount : "N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Penalty</div>
                              <div className="plate-sub-title">{(item?.ticket_details?.penalty_amount) ? '$' + item?.ticket_details?.penalty_amount : "N/A"}</div>
                            </div>
                          </div>
                          <div className="plate-content">                            
                            <div className="plate-item">
                              <div className="plate-title">Reduction</div>
                              <div className="plate-sub-title">{(item?.ticket_details?.reduction_amount) ? '$' + item?.ticket_details?.reduction_amount : "N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Plate No.</div>
                              <div className="plate-sub-title"> {(item?.plate_no) ? item?.plate_no : 'N/A'}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">State</div>
                              <div className="plate-sub-title">{(item?.state) ? item?.state : 'N/A'}</div>
                            </div>
                          </div>
                          <div className="plate-content">                            
                            <div className="plate-item">
                              <div className="plate-title">Vehicle Year</div>
                              <div className="plate-sub-title">{(item?.vehicle_year) ?  item?.vehicle_year : "N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Vehicle Make.</div>
                              <div className="plate-sub-title"> {(item?.vehicle_make) ?  item?.vehicle_make : "N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Vehicle Model</div>
                              <div className="plate-sub-title">{(item?.vehicle_model) ?  item?.vehicle_model : "N/A"}</div>
                            </div>
                          </div>
                          <div className="plate-content">                            
                            <div className="plate-item">
                              <div className="plate-title">Vehicle VIN</div>
                              <div className="plate-sub-title">{(item?.vehicle_vin) ?  item?.vehicle_vin : "N/A"}</div>
                            </div>
                            <div className="plate-item">
                              <div className="plate-title">Vehicle Color</div>
                              <div className="plate-sub-title">{(item?.vehicle_color) ?  item?.vehicle_color : "N/A"}</div>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                  <div className="details-price cancel">${item.amount ? item.amount : "0.00"}</div>
                </div>
              </div>
            ))
            :(isLoading?<Searching/>:'')
          }
          {
            (transaction?.details?.user_info && transaction?.details?.user_info?.isCreateUser)?
            <form className="form-content">
              <div className="commercial-inner login-type">
                <div className="radio-group">
                  <div className="custom-radio">
                    <input 
                    type="radio" 
                    className={`custom-radio-input ` } 
                    onChange={(e) => {
                      setType(1);
                      setIsOtp(false);
                      setOtp('')
                    }} 
                    checked={type === 1?'checked' : ''}
                    name="type"
                    value="1"/>
                    <div className="custom-radio-label">For Email</div>
                  </div>
                  <div className="custom-radio">
                    <input 
                    type="radio" 
                    className={`custom-radio-input `}  
                    checked={type === 2?'checked' : ''}
                    onChange={(e) =>{ 
                      setType(2);
                      setIsOtp(false);
                      setOtp('')
                    }} 
                    name="type" 
                    value="2"                    
                    />
                    <div className="custom-radio-label">For Phone</div>
                  </div>
                </div>
              </div>
                {
                  (type ===1 && !isOtp)?(
                    <>
                    <div className={`form-group ${type=== 1?'' : 'd-none'}`}>
                      <div className="input-style">
                        <div className="input-icon"><i className="fa-regular fa-fw fa-envelope"></i></div>
                        <input 
                        className="input" 
                        type="text" 
                        placeholder="Email Address"
                        autoComplete='off'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly
                        />
                      </div>
                    </div>
                    </>
                  ):(
                    (type === 1 && isOtp)?
                    <>
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                      <InputMask
                      mask="999999" 
                      className="input" 
                      placeholder={`Enter ${(type === 1)?'Email':'SMS'} Verification Code`}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <div className="timer" dangerouslySetInnerHTML={{__html:countDown}}></div>                      
                    </>:''
                  )
                }
                <div className={`form-group ${type=== 2?'' : 'd-none'}`}>
                  {
                    (type === 2 && !isOtp)?
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                      <InputMask
                      mask="(999) 999-9999" 
                      className="input" 
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      readOnly
                      />
                    </div>
                    :
                    <>
                    <div className="input-style">
                      <div className="input-icon"><i className="fa-regular fa fa-mobile"></i></div>
                      <InputMask
                      mask="999999" 
                      type="text"
                      className="input" 
                      placeholder={`Enter ${(type === 1)?'Email':'SMS'} Verification Code`}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <div className="timer" dangerouslySetInnerHTML={{__html:countDown}}></div>
                    </>
                  }
                </div>
                <div className="form-action">
                  {
                    isOtp?
                    <>
                     <button type="button" className="btn-action" onClick={onSubmit} disabled={goToDashboard}><i className={(goToDashboard)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i>Go to Dashboard</button>
                    <button type="button" className="btn-action" onClick={() => resendOtpFun(type)} disabled={isResentOtp}><i className={(isEnabledResentOtp)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-clock mr-2"}></i>Resend OTP</button>
                    </>
                    :
                    <>
                    <button type="button" className="btn-action" onClick={() => onOtp(type)} disabled={enabledOtp}><i className={(enabledOtp)?'fa-regular fa-spinner fa-spin mr-2':'fa-regular fa-arrow-right-to-bracket mr-2'}></i>Verify</button>
                    </>
                  }
                </div>
              </form>
            :''
          }
        </div>
        <div className="back-home-action">
          <NavLink to="/" className="back">Go To Home</NavLink>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, { verifyLogin })(PaymentSuccess);
import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const updateEmailPhone = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}update-email-phone`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, type: data.type,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const verifyEmailPhone = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}verify-email-phone`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const resendProfileOtp = async body =>  {
  try {
    const res = await fetchData('POST', `${BASE_URL}resend-profile-otp`, body);
    const resData = await res.json();  
    if(resData.status === 200) {
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Searching from '../../shared/searching';
import NothingFound from '../../shared/nothingFound';
import { 
    getTicketDetails,
    getTicketAssignStatusDetails
} from '../../actions/ticket';
import ImageShowModal from "../ImageShowModal";
import { v4 as uuidv4 } from "uuid";
import toastr from "toastr";
import { storeCart } from "../../actions/cart";
import { NavLink } from 'react-router-dom';

const SecTicketDetails = ({id,storeCart}) => {
    const [ticket,setTicket] = useState({});
    const [apijson,setApijson] = useState({});
    const [assignStatus,setAssignStatus] = useState([]);
    const [viewLightBox, setViewLightBox] = useState({ photo: null, isOpen: false });
    const { photo, isOpen } = viewLightBox;
    const [viewPdf, setViewPdf] = useState("");
    const [isAddToCart,setIsAddToCart] = useState("");
    const [cart_id, setCartId] = useState(localStorage.getItem("cartId"));
    const [ticket_ids, setTicketsIds] = useState([]);

    useEffect(() => {
        // Ticket Details
        const loadPlateData = async () => {
          try {            
            const data = await getTicketDetails({
              id:id
            });  
            if(data.status == 200) {
              setTicket(data.data)
              setApijson((data.data.api_json)?data.data.api_json[0]:[])
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadPlateData(); 
    }, [id]); 

    useEffect(() => {
        // Ticket Assign Status Details
        const loadPlateData = async () => {
          try {            
            const data = await getTicketAssignStatusDetails({
                ticket_id:id,
                status_for:'user'
            });  
            if(data.status == 200) {
             setAssignStatus(data.data)
            }    
          } catch(err) {
            console.log(err.message);
          }
        }
        loadPlateData(); 
    }, [id]); 

    const dateFormat = issue_date => {
        let d = new Date(issue_date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${da} ${mo}, ${ye}`;
    }  

    const validURL = str => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const downloadFile = url => {
        document.getElementById('my_iframe').src = url;
    }

    useEffect(() => {
        if (localStorage.getItem("cartId")) {
          setCartId(localStorage.getItem("cartId"));
        } else {
          localStorage.setItem("cartId", uuidv4());
          setCartId(localStorage.getItem("cartId"));
        }
    }, []);

    const addToCart = async (data) => {
        try {
          let msg = await storeCart({
            userId: localStorage.getItem("userId"),
            cartId: cart_id,
            ticket_ids: [data],
          });
          if (msg.status === 200) {
            toastr.success(msg.message);
            setTicketsIds([]);
          } else {
            toastr.error(msg.message);
          }
        } catch (err) {
          toastr.error(err.message);
        }
    }
        
    return (
        <section className='sec-plate-details'>
            <div className='body-layout'>
                <div className='body-content'>
                    <div className='user-search-part'>
                        <div className='title'>Ticket: {(ticket?.summons_number)?ticket?.summons_number:''}</div>
                    </div>
                    <div className='row'>
                        {Object.keys(ticket).length == 0 ?
                        (
                            ticket.length==0?<NothingFound/> :<Searching/> 
                        ):
                        (
                        <div className='col-lg-12'>
                            <div className='details-content'>                        
                                <div className='details'>
                                    <div className='details-card'>
                                    {/* <div className='vehicle-name'>{ticket?.summons_number}</div> */}
                                    {
                                       ticket?.plate_no &&
                                       <div className='vehicle-info'>
                                            <div className='vehicle-number'>Plate: 
                                                <NavLink to={"/plate-details/"+ ticket?.plate_details?._id}>{ticket?.plate_no}</NavLink>
                                            </div>
                                            {
                                                ticket && ticket?.due_amount === "0" ? '' : 
                                                ticket && ticket.transaction && ticket.transaction?.payment_status && ticket.transaction.payment_status === "success" ? '' : 
                                                ticket && ticket.fine_amount + ticket.interest_amount + ticket.penalty_amount - ticket.reduction_amount > ticket.payment_amount ? (
                                                <div className='report-details'>
                                                    <button 
                                                    type="button" 
                                                    className='report-btn' 
                                                    onClick={() => addToCart({
                                                        id: ticket?._id,
                                                        plate_no: ticket?.plate_no,
                                                        ticket_id: ticket.summons_number,
                                                        amount: ticket?.due_amount,
                                                    })}
                                                    >
                                                        <i class="far fa-money-check-alt mr-2"></i>
                                                        Add to Cart
                                                    </button>
                                                </div>
                                                ):(
                                                    <div className='report-details'>
                                                        <button 
                                                        type="button" 
                                                        className='report-btn'
                                                        onClick={() => addToCart({
                                                            id: ticket?._id,
                                                            plate_no: ticket?.plate_no,
                                                            ticket_id: ticket.summons_number,
                                                            amount: ticket?.due_amount,
                                                        })}
                                                        >
                                                            <i class="far fa-money-check-alt mr-2"></i>
                                                            Add to Cart
                                                        </button>
                                                    </div>
                                                ) 
                                            }                                           
                                        </div>
                                    }                                    
                                    {
                                        (ticket && ticket.ticket_type === 'dmv') && 
                                        <div className='vehicle-metalist'>
                                            <div className='meta-item'>
                                            <div className='meta-label'>State</div>
                                            <div className='meta-data'>{(ticket?.state)?ticket?.state:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>License Type</div>
                                            <div className='meta-data'>{(ticket?.license_type)?ticket?.license_type:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Issue Date</div>
                                            <div className='meta-data'>{ticket?.issue_date ? dateFormat(ticket?.issue_date) : 'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Violation Time</div>
                                            <div className='meta-data'>{(ticket?.violation_time)?ticket?.violation_time:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Violation</div>
                                            <div className='meta-data'>{(ticket?.violation)?ticket?.violation:(ticket?.violation_description)?ticket?.violation_description:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Fine Amount</div>
                                            <div className='meta-data'>{(ticket?.fine_amount)?'$'+ticket?.fine_amount:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Penalty Amount</div>
                                            <div className='meta-data'>{(ticket?.penalty_amount)?'$'+ticket?.penalty_amount:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Interest Amount</div>
                                            <div className='meta-data'>{(ticket?.interest_amount)?'$'+ticket?.interest_amount:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Reduction Amount</div>
                                            <div className='meta-data'>{(ticket?.reduction_amount)?'$'+ticket?.reduction_amount:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Payment Amount</div>
                                            <div className='meta-data'>{(ticket?.payment_amount)?'$'+ticket?.payment_amount:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Amount Due</div>
                                            {
                                                ticket.is_trans &&
                                                <div className='meta-data'>$0</div>
                                            }
                                            {
                                                !ticket.is_trans &&
                                                <div className='meta-data'>{(ticket?.amount_due)?'$'+ticket?.amount_due:(ticket?.due_amount)?'$'+ticket?.due_amount:'N/A'}</div>
                                            }
                                            </div>
                                            {
                                               ticket?.transaction && 
                                               <div className='meta-item'>
                                               <div className='meta-label'>Defendit Payment Status</div>
                                               <div className='meta-data'>
                                               <NavLink to={"/transaction/" + ticket?.transaction?._id}>
                                                {(ticket.transaction?.payment_status)?<span class="badge badge-success">{ticket.transaction?.payment_status}</span>:''}
                                                </NavLink>
                                                </div>
                                               </div>

                                            }
                                           
                                        </div>
                                    } 
                                    {
                                        (ticket && ticket.ticket_type === 'tlc') && 
                                        <div className='vehicle-metalist'>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Violation Date</div>
                                            <div className='meta-data'>{ticket?.violation_date ? dateFormat(ticket?.violation_date) : 'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                            <div className='meta-label'>Violation Location Borough</div>
                                            <div className='meta-data'>{(ticket?.violation_location_borough)?ticket?.violation_location_borough:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>City</div>
                                                <div className='meta-data'>{(ticket?.violation_location_city)?ticket?.violation_location_city:'N?A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Zip Code</div>
                                                <div className='meta-data'>{(ticket?.violation_location_zip_code)?ticket?.violation_location_zip_code:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Violation Details</div>
                                                <div className='meta-data'>{(ticket?.violation_details)?ticket?.violation_details:(ticket?.violation_description)?ticket?.violation_description:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Respondent First Name</div>
                                                <div className='meta-data'>{(ticket?.respondent_first_name)?ticket?.respondent_first_name:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Respondent Last Name</div>
                                                <div className='meta-data'>{(ticket?.respondent_last_name)?ticket?.respondent_last_name:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Respondent Address Borough</div>
                                                <div className='meta-data'>{(ticket?.respondent_address_borough)?ticket?.respondent_address_borough:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Issueing Agency</div>
                                                <div className='meta-data'>{(ticket?.issuing_agency)?ticket?.issuing_agency:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Decision Date</div>
                                                <div className='meta-data'>{ticket?.decision_date ? dateFormat(ticket?.decision_date) : 'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Hearing Date</div>
                                                <div className='meta-data'>{ticket?.hearing_date ? dateFormat(ticket?.hearing_date) : 'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Paid Amount</div>
                                                <div className='meta-data'>{(ticket?.paid_amount)?'$'+ticket?.paid_amount:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Penalty Imposed</div>
                                                <div className='meta-data'>{(ticket?.penalty_imposed)?'$'+ticket?.penalty_imposed:'N/A'}</div>
                                            </div>
                                            <div className='meta-item'>
                                                <div className='meta-label'>Total Violation Amount</div>
                                                <div className='meta-data'>{(ticket?.total_violation_amount)?'$'+ticket?.total_violation_amount:(ticket?.due_amount)?'$'+ticket?.due_amount:'N/A'}</div>
                                            </div>                                            
                                        </div>
                                    }                                   
                                    {
                                        ticket?.transaction && 
                                        <>
                                        <div className='details-title'>Payment Details</div>
                                        <div className='vehicle-metalist'>
                                            {
                                                ticket?.ticket_details &&
                                                <div className='meta-item item-half'>
                                                <div className='meta-label'>Ticket Number</div>
                                                <div className='meta-data'>
                                                <NavLink to={"/transaction/" + ticket?.transaction?._id}>
                                                    {ticket?.ticket_details}
                                                </NavLink>
                                                </div>
                                                </div>
                                            }
                                            {
                                                ticket.transaction?.amount &&
                                                <>
                                                <div className='meta-item item-half'>
                                                <div className='meta-label'>Payment Amount</div>
                                                <div className='meta-data'>${ticket.transaction?.amount}</div>
                                                </div>
                                                </>
                                            }
                                            {
                                                ticket.transaction?.transaction_date &&
                                                <>
                                                <div className='meta-item item-half'>
                                                <div className='meta-label'>Payment Date</div>
                                                <div className='meta-data'>{ticket.transaction?.transaction_date ? dateFormat(ticket.transaction?.transaction_date) : ''}</div>
                                                </div>
                                                </>
                                            }
                                            {
                                                ticket.transaction?.payment_mode &&
                                                <div className='meta-item item-half'>
                                                    <div className='meta-label'>Payment Mode</div>
                                                    <div className='meta-data'>{ticket.transaction?.payment_mode}</div>
                                                </div>
                                            }
                                            {/* {
                                                ticket.transaction?.payment_status &&
                                                <div className='meta-item item-half'>
                                                    <div className='meta-label'>Payment Status</div>
                                                    <div className='meta-data'>{ticket.transaction?.payment_status}</div>
                                                </div>
                                            } */}
                                        </div>
                                        </>
                                    }
                                    {
                                        (ticket && ticket.ticket_type === 'dmv') && 
                                        <div className='vehicle-metalist'>
                                            <div className='meta-item full'>
                                                {
                                                    (ticket?.summons_image && ticket?.summons_image !=null && validURL(ticket?.summons_image)) &&
                                                    <div className='assigned-drivers mb-3 mt-3'>
                                                        <div className='ticket'>
                                                            <div className='driver-image'>
                                                            <iframe 
                                                            src={ticket?.summons_image} 
                                                            >
                                                            </iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        (ticket && ticket.ticket_type === 'tlc')? 
                                        (assignStatus && assignStatus.length>0) &&
                                        <>
                                        <div className="driver-info pdf-content">
                                            <div className='details-title'>Documents</div>
                                            {
                                                assignStatus.map((item, index) => (
                                                    (item && item.ticket_galleries && item.ticket_galleries.length>0) &&
                                                    <>
                                                    <div className='status-label' key={index}>{item?.ticket_status?.name}</div>
                                                    <div className="driver-info-content">
                                                        <div className="img-list"> 
                                                            {
                                                                item.ticket_galleries.map((item2, index2) => (
                                                                    (item2 && (item2.extension === 'pdf' || item2.extension === 'PDF'))?
                                                                    <div className="img" key={index2}>
                                                                        <img 
                                                                        src="/assets/img/PDF_file_icon.svg.png" data-toggle="modal" 
                                                                        data-target="#pdfModal" 
                                                                        onClick={() => setViewPdf(item2.file_name)} 
                                                                        />
                                                                    </div>:
                                                                    (item2 && (item2.extension === 'docx' || item2.extension === 'doc'))?
                                                                    <div className="img" key={index2}>
                                                                        <img 
                                                                        src="/assets/img/doc.png" 
                                                                        //data-toggle="modal" 
                                                                        //data-target="#pdfModal" 
                                                                        onClick={() => downloadFile(item2.file_name)} 
                                                                        />
                                                                    </div>:
                                                                    <div className="img" key={index2}>
                                                                        <img 
                                                                        src={item2.file_name} 
                                                                        onClick={() => setViewLightBox({ photo: item2.file_name, isOpen: true })}
                                                                        />
                                                                    </div>
                                                                ))
                                                            }                                                             
                                                        </div>
                                                    </div>
                                                    </>
                                                ))
                                            }
                                            
                                        </div>
                                        </>                                        
                                        :''
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    
                </div>
            </div>
            {
                viewPdf && (
                <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf("")}>
                            <i className="fal fa-fw fa-times"></i>
                        </button>
                        </div>
                        <div className="modal-body">
                        <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                            <p>There was an error downloading the invoice.</p>
                        </object>
                        </div>
                    </div>
                    </div>
                </div>
            )}
            { isOpen && (<ImageShowModal photo={photo} isOpen={isOpen} setViewLightBox={setViewLightBox}/>)}
            <iframe id="my_iframe" style={{display:'none'}}></iframe>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
};
export default connect(mapStateToProps, { storeCart })(SecTicketDetails);
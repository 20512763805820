import React, { useState, useEffect } from 'react';
import SecBillingsDetails from '../components/Billings/SecBillingsDetails';
import { NavLink } from 'react-router-dom';
import UserSidepanel from '../shared/userSidepanel';
import { useParams } from 'react-router';
import toastr from 'toastr';
import { 
    getTransactionDetails
} from '../actions/billing';
import { useNavigate } from "react-router";
const BillingDetails = () => {   
    const { id } = useParams();
    const [transaction,setTransaction] = useState({});
    const [count,setCount] = useState();
    const [isloading,setIsloading] = useState(true);
    const navigate = useNavigate();
   
    useEffect(() => {
        // State List
        const loadTransaction = async () => {
          try {            
            const data = await getTransactionDetails({
                id:id
            });  
            if(data.status == 200) {
                setTransaction(data.data)
                setCount(data.count)
                setIsloading(false)
            }else{
                toastr.error(data.message);
                navigate('/billings');
            }    
          } catch(err) {
            toastr.error(err.message);
            navigate('/billings');
          }
        }
        loadTransaction();        
    }, [id,count]);
    return (
        <section className='sec-ticket login-part-pages'>
            <div className='container'>
            <div className="row user-panel-menus">
          <div className="left-sidebar">
                        <UserSidepanel/>
                    </div>
                    <div className='right-sidebar'>
                        <SecBillingsDetails  id={id} count={count} isloading={isloading} transaction={transaction} />
                       
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BillingDetails;
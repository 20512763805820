import { NOTIFICATION_COUNT } from "../actions/type";

const notificationReducer = (state = {}, action) => {
  switch(action.type) {
    case NOTIFICATION_COUNT:
      return { status: true, count: action.payload };    
    default:
      return state;
  }
}

export default notificationReducer;
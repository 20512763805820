// Live
const BASE_URL = 'https://api.defendit.io/api/';
  FRONT_URL = 'https://defendit.io/';
  SOCKET_URL = 'https://api.defendit.io';
export default BASE_URL;
export  var FRONT_URL;
export  var SOCKET_URL;

// Staging
// const BASE_URL = 'https://api.staging.defendit.io/api/';
//   FRONT_URL = 'https://staging.defendit.io/';
//   SOCKET_URL = 'https://api.staging.defendit.io';
// export default BASE_URL;
// export  var FRONT_URL;
// export  var SOCKET_URL;
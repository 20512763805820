import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import SecNotification from '../components/Notification/SecNotification';
import UserSidepanel from '../shared/userSidepanel';
import { checkDriverVehicle } from '../actions/site';
import { getUser } from '../actions/login';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getSiteSettings } from '../actions/site';
const Notification = ({socket, getUser}) => {

  socket.on('notificationAllListResponse', data => {
    setNotifications(data.list);
  });

  socket.on('notificationAddedResponse', data => {
    for (let index = 0; index < data.list.length; index++) {
      const element = data.list[index];
      // eslint-disable-next-line eqeqeq
      if(users._id && element.reciver_id == users._id){
        setNotifications([ element, ...notifications]);
        socket.emit('notificationRead', {
          notification_id: element._id,
          user_id: users._id
        })
      }
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState('');
  const [notifications, setNotifications] = useState([]);

  const [siteSettings, setSiteSettings] = useState({});

  useEffect(() => {    
      const loadSiteSettingsData = async () => {
        try {            
          const data = await getSiteSettings({});  
          if(data.status == 200) {
              setSiteSettings(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
      }
      loadSiteSettingsData();        
    }, [ ]);  

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      dispatch(async () => {
        try {
          const data = await checkDriverVehicle();
          if (data.status == 200) {
            if (data.step == 1) {
              return navigate("/add-driver");
            } else if (data.step == 2) {
              return navigate("/add-plate");
            }

          } else {
            return navigate("/add-driver");
          }

        } catch (err) {
          console.log(err.message);
        }
      })
    }

  }, [dispatch, window.location.pathname]);

  useEffect(() => {
    const ac = new AbortController();
    const loadUserData = async () => {
      try {
        const data = await getUser(ac.signal);
        if (data.status === 200) {
          setUsers(data.data)
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    loadUserData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('access_token')]);

  useEffect(() => {
    if (users) {
      socket.emit('notificationAllList', {
        user_id: users._id,
        limit: 100
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  return (
    <section className='sec-ticket notification-page login-part-pages'>
      <div className='container'>
      <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className='right-sidebar'>
            <SecNotification notifications={notifications} settings={siteSettings} />
            {/* <SecAddDriverManagement/> */}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, { getUser })(Notification);
import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";


export const getPlateDetails = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}vehicle/global-details`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      return { message: resData.message, status:resData.status };
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


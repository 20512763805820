import React, { useState, useEffect } from "react";
import SecPlateAdd from "../components/Plates/SecPlateAdd";
import UserSidepanel from "../shared/userSidepanel";
import { getStates, checkDriverVehicle } from "../actions/site";
import { getDriverList } from "../actions/driver";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
const AddPlate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [states, setStates] = useState({});
  const [drivers, setDrivers] = useState({});
  useEffect(() => {
    // State List
    const loadStates = async () => {
      try {
        const data = await getStates({
          country_code: "US",
        });
        if (data.status == 200) {
          setStates(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStates();

    // Driver List
    const loadDrivers = async () => {
      try {
        const data = await getDriverList({});
        if (data.status == 200) {
          setDrivers(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDrivers();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(async () => {
        try {
          const data = await checkDriverVehicle();
          if (data.status == 200) {
            if (data.step == 1) {
              return navigate("/add-driver");
            } else if (data.step == 2) {
              return navigate("/add-plate");
            }
          } else {
            return navigate("/add-driver");
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);
  return (
    <section className="sec-panel login-part-pages">
      <div className="container">
        <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className="right-sidebar">
            <SecPlateAdd states={states} drivers={drivers} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddPlate;

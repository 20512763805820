import React, { useState, useEffect } from 'react';
import 'react-image-lightbox/style.css';
import SecDashboardCount from './SecDashboardCount';
import SecTlcTickets from './SecTlcTickets';
import SecTickets from './SecTickets';
import { NavLink } from 'react-router-dom';
import { getDashboardData } from '../../actions/ticket';

const SecDashboard = ({settings}) => { 
    const [dashboard, setDashboard] = useState({});
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        // Dashboard 
        const loadDashboardData = async () => {
            try {            
              const data = await getDashboardData({
                ticket_type:'tlc',
                limit:limit,
                offset:offset
              });  
              if(data.status == 200) {
                setDashboard(data.data)              
                setCount(data.count);
              } 
            } catch(err) {
              console.log(err.message);
            }
          }
        loadDashboardData();      
    },[limit, offset]); 

    return (
        <section className='sec-dashboard'>
            <div className='body-layout'>
                <div className='body-content'>
                    <div className='user-search-part'>
                        <div className='title'>Dashboard</div>
                    </div>
                    <SecDashboardCount/>
                    {/* <div className='row'>
                      <div className='col-lg-12'>
                          <div className='dashboard-action'>
                          <NavLink to={"/dashboard"} className="driving-btn active">TLC Tickets</NavLink> 
                          <NavLink to={"/tickets"} className="driving-btn">DMV Tickets</NavLink> 
                          </div>
                      </div>
                    </div> */}
                    {
                        (count && count>0)?
                        <>
                        <div className='user-search-part header-part-title'>
                            <div className='title'>TLC Tickets</div>
                        </div>
                        <SecTlcTickets settings={settings}/>
                        </>:''
                    }
                    

                    <div className='user-search-part header-part-title'>
                        <div className='title'>DMV Tickets</div>
                    </div>
                    <SecTickets settings={settings}/>
                </div>
            </div>            
        </section>
    );
};

export default SecDashboard;
import React, { useRef, useState, useEffect } from 'react';
import { connect } from "react-redux";
import toastr from 'toastr';
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { storeCart } from '../actions/cart';
import { importTicket, checkVehicle } from '../actions/ticket';
import NothingFound from '../shared/nothingFound';
import Searching from '../shared/searching';

const PlateDetailsModal = ({ plateInfo, plate_no, state, displayState, storeCart, isSearchPlate }) => {
  const navigate = useNavigate();
  const closeRef = useRef(null);
  const [ticket_ids, setTicketsIds] = useState([]);
  const [cart_id, setCartId] = useState(localStorage.getItem('cartId'));
  const [isEnabled, setIsEnabled] = useState(false);
  const [vehicle_type, setVehicleType] = useState('commercial');
  const [pendingTickets, setPendingTickets] = useState(0);
  const [searchPlate, setSearchPlate] = useState([]);
  const [isProcessing,setIsProcessing] = useState(false);

  useEffect(() => {
    setSearchPlate(plateInfo)
  }, [plateInfo]);

  const handleChangePlate = (event) => {
    let searchPlate = plateInfo.filter((item) => { 
        if(item.summons_number){ 
            return item.summons_number.includes(event.target.value)
        }
    })
    setSearchPlate(searchPlate);
  }

  const dateFormat = issue_date => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${(mo <= 9) ? '0' + mo : mo} ${da}, ${ye}`;
  }

  const onAddTocart = async () => {
    try {
      let vehicle = await checkVehicle({
        user_id: localStorage.getItem('userId'),
        plate_no: plate_no,
        state: displayState,
      });
      if (vehicle.status === 200) {
        let msg = await importTicket({
          user_id: localStorage.getItem('userId'),
          plates: [plate_no],
          ticket_type: 'dmv',
          fine_amount: plateInfo?.fine_amount
        });
        if (msg.status === 200) {
          setIsEnabled(true)
          toastr.success(msg.message);
        } else {
          toastr.error(msg.message);
        }
      } else {
        toastr.error(vehicle.message);
      }
    } catch (err) {
      toastr.error(err.message);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('cartId')) {
      setCartId(localStorage.getItem('cartId'))
    } else {
      localStorage.setItem('cartId', uuidv4());
      setCartId(localStorage.getItem('cartId'))
    }
  }, []);

  useEffect(() => {
    let ticketIds = [];
    let paidTickets = [];
    let pendingTickets = [];
    plateInfo.length > 0 &&
      plateInfo.map(item => (
        (item && (item?.amount_due == '0' || item?.amount_due === null)) ? paidTickets.push(item) :
          (item && (item?.is_trans == true)) ? paidTickets.push(item) :
            (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ?(
              ticketIds.push({
                id: item?._id,
                plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                ticket_id: item.summons_number,
                amount: item?.amount_due,
              }),
              pendingTickets.push(item) ) :(
              ticketIds.push({
                id: item?._id,
                plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                ticket_id: item.summons_number,
                amount: item?.amount_due,
              }),
              pendingTickets.push(item)
              )
      ))
    setPendingTickets(ticketIds);
    setSearchPlate([...pendingTickets,...paidTickets]);
  }, [plateInfo]);

  const closeOneModal = () => {
    const modal = document.getElementById('ModalPlateDetails');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
  }

  useEffect(() => {
    const insertCart = async () => {
      try {
        let msg = await storeCart({
          userId: localStorage.getItem('userId'),
          cartId: cart_id,
          ticket_ids: ticket_ids
        });
        if (msg.status === 200) {
          setTicketsIds([]);
          closeOneModal();
          return navigate("/checkout");
        } else {
          toastr.error(msg.message);
        }
      } catch (err) {
        toastr.error(err.message);
      }
    }
    if (isEnabled) {
      insertCart();
    }
  }, [isEnabled]);

  const addToCart = async (data) => {
    try {
      let msg = await storeCart({
        userId: localStorage.getItem("userId"),
        cartId: cart_id,
        ticket_ids: [data],
      });
      if (msg.status === 200) {
        toastr.success(msg.message);
        setTicketsIds([]);
      } else {
        toastr.error(msg.message);
      }
    } catch (err) {
      toastr.error(err.message);
    }
  }

  const hadelAllTicket = (e) => {
    if (e.target.checked) {
      let ticketIds = [];
      plateInfo.length > 0 &&
        plateInfo.map(item => (
          (item && (item?.amount_due == '0' || item?.amount_due === null)) ? '' :
            (item && (item?.is_trans == true)) ? '' :
              (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ?
                ticketIds.push({
                  id: item?._id,
                  plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                  ticket_id: item.summons_number,
                  amount: item?.amount_due,
                }) :
                ticketIds.push({
                  id: item?._id,
                  plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                  ticket_id: item.summons_number,
                  amount: item?.amount_due,
                })
        ))
      setTicketsIds(ticketIds);
    } else {
      setTicketsIds([]);
    }
  }

  const sum = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum = sum + parseFloat((data[i]?.amount) ? data[i]?.amount : 0);
    }
    return sum;
  };

  const onSubmit = async () => {
    setIsEnabled(false);
    setIsProcessing(true);    
    try {     
      let ticketIds = [];
      plateInfo.length > 0 &&
        plateInfo.map(item => (
          (item && (item?.amount_due == '0' || item?.amount_due === null)) ? '' :
            (item && (item?.is_trans == true)) ? '' :
              (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ?
                ticketIds.push({
                  id: item?._id,
                  plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                  ticket_id: item.summons_number,
                  amount: item?.amount_due,
                }) :
                ticketIds.push({
                  id: item?._id,
                  plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                  ticket_id: item.summons_number,
                  amount: item?.amount_due,
                })
        ))
        //console.log(ticketIds);
        setTicketsIds(ticketIds);
        /// Direct add to cart
        try {
          let vehicle = await checkVehicle({
            user_id: localStorage.getItem('userId'),
            plate_no: plate_no,
            state: displayState,
          });
          if (vehicle.status === 200) {
            let msg = await importTicket({
              user_id: localStorage.getItem('userId'),
              plates: [plate_no],
              ticket_type: 'dmv',
              fine_amount: plateInfo?.fine_amount
            });
            if (msg.status === 200) {
              setIsEnabled(true);
              setIsProcessing(false);
              toastr.success(msg.message);
            } else {
              toastr.error(msg.message);
              setIsProcessing(false);
            }
          } else {
            toastr.error(vehicle.message);
            setIsProcessing(false);
          }
        } catch (err) {
          toastr.error(err.message);
          setIsProcessing(false);
        }
    } catch (err) {
      console.log(err.message);
      setIsProcessing(false);
    }
    
  };

  return (
    <div className="modal fade career-apply-modal ticket-serach-modal-part" id="ModalPlateDetails" tabIndex="-1" data-backdrop="static"
      data-keyboard="false">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="header-text">
              <h5 className="modal-title" id="ModalCenterTitle">Plate Information</h5>
            </div>
            <button type="button" className="modal-close" data-dismiss="modal"><i
              className="fa-light fa-fw fa-times"></i></button>
          </div>
          <div className="modal-body">
            <div className='ticket-list'>
              {
                (pendingTickets && pendingTickets.length > 0) &&
                <div className='ticket-total'>
                  <div className='title'>you have {pendingTickets.length} unpaid tickets</div>
                  <div className='amoumt-text'>
                    <div className='text'>Total Owed</div>
                    <div className='amount'>${sum(pendingTickets).toFixed(2)}</div>
                  </div>
                </div>
              }
              <div className='modal-search'>
              <div class="search management">
                <button type="button" class="search-btn"><i class="fa-regular fa-fw fa-magnifying-glass"></i></button>
                <input 
                type="text" 
                class="search-input"
                placeholder='Search by ticket id'
                onChange={handleChangePlate}/>
              </div>
              <div className='total-pay-action'>
              <div className='action-btn'>
                  {
                    (searchPlate && searchPlate.length>0 && pendingTickets && pendingTickets.length > 0) &&
                    <div className="justify-content-center">
                      <button
                        type="button"
                        className="btn-pay"
                        onClick={onSubmit}
                        disabled={isProcessing}
                      ><i className={(isProcessing)?'fa-regular fa-spinner fa-spin mr-2':"far fa-money-check-alt mr-2"}></i>Add & Pay Now
                      </button>
                    </div>
                  }
                </div>
                {/* {
                  (searchPlate && searchPlate.length>0) &&
                  <div class="custom-checkbox">
                    <input
                      class="custom-checkbox-input"
                      type="checkbox"
                      value="all"
                      id="flexCheckChecked"
                      onChange={hadelAllTicket}
                    />
                    <label class="custom-checkbox-label" for="flexCheckChecked">Pay All</label>
                  </div>
                }                */}
              </div>
              </div>
             

              <div className='ticket-table plate-table'>
                <div className='table-head'>
                  <div className='table-row header-row'>
                    <div className='table-cell cell-check'>
                      <div className='table-title'></div>
                    </div>
                    <div className='table-cell cell-plate'>
                      <div className='table-title'>Plate</div>
                    </div>
                    {/* <div className='table-cell cell-vc'>
                            <div className='table-title'>VIN</div>
                        </div> */}
                    <div className='table-cell cell-ticketID'>
                      <div className='table-title'>Ticket-ID</div>
                    </div>
                    <div className='table-cell cell-date'>
                      <div className='table-title'>Date</div>
                    </div>
                    <div className='table-cell cell-fine'>
                      <div className='table-title'>Fine</div>
                    </div>
                    <div className='table-cell cell-violation'>
                      <div className='table-title'>Violation</div>
                    </div>
                    <div className='table-cell cell-status'>
                      <div className='table-title'>Status</div>
                    </div>
                    <div className='table-cell cell-action'>
                      <div className='table-title'></div>
                    </div>
                  </div>
                </div>
                <div className='table-body'>
                  {
                    (plateInfo && plateInfo.length > 0) ? (
                      searchPlate.length > 0 &&
                      searchPlate.map((item, index) => (
                        <>
                          <div className={"custom-checkbox " + ((item && (item?.amount_due === '0' || item?.amount_due === null)) ? 'disabled' : (item && (item?.is_trans == true)) ? 'disabled' : (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ? '' : '')} key={index}>
                            {
                              (item && item.amount_due > '0') &&
                              <input
                                type="checkbox"
                                className="custom-checkbox-input"
                                value={item?._id}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setTicketsIds([
                                      ...ticket_ids,
                                      {
                                        id: item?._id,
                                        plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                                        ticket_id: item.summons_number,
                                        amount: item?.amount_due,
                                      },
                                    ]);
                                  } else {
                                    setTicketsIds(
                                      ticket_ids.filter((ticket) => ticket.ticket_id !== item?.summons_number),
                                    );
                                  }
                                }}
                                disabled={(item && (item?.amount_due === '0' || item?.amount_due === null)) ? true : (item && (item?.is_trans == true)) ? true : (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ? false : false}
                              />
                            }
                            <div className="custom-checkbox-label ticket-result-content">
                              <div className="table-row">
                                <div className="table-cell cell-check">
                                  <div className="mobile-title"></div>
                                  <div className="cell-data"></div>
                                </div>
                                <div className='table-cell cell-plate'>
                                  <div className='mobile-title'>Plate</div>
                                  <div className='cell-data'>{item?.plate}</div>
                                </div>
                                {/* <div className='table-cell cell-vc'>
                                    <div className='mobile-title'>VIN</div>
                                    <div className='cell-data'>60</div>
                                </div> */}
                                <div className='table-cell cell-ticketID'>
                                  <div className='mobile-title'>Ticket-ID</div>
                                  <div className='cell-data'>{item?.summons_number}</div>
                                </div>
                                <div className='table-cell cell-date'>
                                  <div className='mobile-title'>Date</div>
                                  <div className='cell-data'>{item?.issue_date ? dateFormat(item?.issue_date) : ''}</div>
                                </div>
                                <div className='table-cell cell-fine'>
                                  <div className='mobile-title'>Fine</div>
                                  <div className='cell-data'>${item?.fine_amount}</div>
                                </div>
                                <div className='table-cell cell-violation'>
                                  <div className='mobile-title'>Violation</div>
                                  <div className='cell-data'>{(item?.violation_description) ? item?.violation_description : item?.violation}</div>
                                </div>
                                <div className='table-cell cell-status'>
                                  <div className='mobile-title'>Status</div>
                                  <div className='cell-data'>
                                    {
                                      (item && (item?.amount_due == '0' || item?.amount_due === null)) ?
                                        <div className="status-text dismiss">Paid</div> :
                                        (item && (item?.is_trans == true)) ?
                                          <div className="status-text dismiss">Paid</div> :
                                          (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ?
                                            <div className="status-text waiting">Pay Ticket</div> :
                                            <div className="status-text color waiting">Pay Ticket</div>
                                    }
                                  </div>
                                </div>
                                <div className='table-cell cell-action'>
                                  <div className='mobile-title'>Action</div>
                                  {
                                    (item && (item?.amount_due === '0' || item?.amount_due === null)) ? '' :
                                      (item && (item?.is_trans === true)) ? '' :
                                        (item && ((item.fine_amount + item.interest_amount + item.penalty_amount) - item.reduction_amount) > item.payment_amount) ?
                                          <i className="fa-regular fa-cart-shopping" onClick={() => addToCart({
                                            id: item?._id,
                                            plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                                            ticket_id: item.summons_number,
                                            amount: item?.amount_due,
                                          })}></i> :
                                          <i className="fa-regular fa-cart-shopping" onClick={() => addToCart({
                                            id: item?._id,
                                            plate_no: (item?.plate_no) ? item?.plate_no : item?.plate,
                                            ticket_id: item.summons_number,
                                            amount: item?.amount_due,
                                          })}></i>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : ((isSearchPlate) ? <Searching /> : <NothingFound />)
                  }
                </div>
              </div>
            </div>

          </div>
          {
            (ticket_ids && ticket_ids.length > 0) &&
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn-action"
                onClick={onAddTocart}
              ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Add & Pay Now
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}
export default connect(mapStateToProps, { storeCart })(PlateDetailsModal);
import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const getSiteSettings = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}site-setting/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getWhyus = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}whyus/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getSolutions = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}solutions/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getClients = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}client/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getBanners = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}banner/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}
export const getSeo = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}seo/details`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getSteps = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}how_it_work/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getTickets = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}ticket/recent`, body, signal);
    const data = await res.json();
    if(data.data.length >0) {
      
      return { status:200,message: 'Ticket Data Successfully fetch', data: data.data };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getPageDetails = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}page/details`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const manageContact = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}contact/manage`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getStates = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}state/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getCities = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}city/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

//Abhik
export const getJobs = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}job/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const jobApply = async (body, signal = null)  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}job/add-job-apply`, body,signal);
    const resData = await res.json();
    if(resData.status === 200) {
      return { message: resData.message, data: resData.data,status:resData.status };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

// Susanta

//Get User Profile Data
export const getProfileData = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}profile-details`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}
//Get User Profile Data
export const checkDriverVehicle = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}check-plate-driver`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, step: data.step,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

//Update User Profile Data
export const updateProfile = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}profile-update`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

//Get Sate list
export const getState = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}state/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

//Get Sate list
export const getCountry = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}country/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}






import React ,{ useEffect } from 'react';
import SecTicketDetailsGlobal from '../components/TicketManagement/SecTicketDetailsGlobal';
import { useParams } from 'react-router';
const TicketDetailsGlobal = () => {
  const { type,ticket_id } = useParams();
     console.log(type,ticket_id )
    return (
        <section className='sec-panel'>
        <div className='container'>
          <div className='row'>
        
            <div className=' col-xl-12'>
                <SecTicketDetailsGlobal type={type} ticket_id={ticket_id} />
            </div>
          </div>
        </div>
    </section>
    );
};

export default TicketDetailsGlobal;
import React, { useState, useEffect } from 'react';
import SecNewsletter from '../components/AboutUs/SecNewsletter';
import SecBanner from '../components/ContactUs/SecBanner';
import SecContact from '../components/ContactUs/SecContact';
import { 
  getPageDetails,
  getBanners,
  getSiteSettings,
  getSeo
 } from '../actions/site';
 import {Helmet} from "react-helmet";
const ContactUs = () => {
  const [seo,setSeo] = useState({});
  const [pageId,setPageId] = useState({});
  const [page,setPages] = useState({});
  const [banners,setBanners] = useState({});
  const [siteSettings, setSiteSettings] = useState({});

  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'contact-us'
        });  
        if(data.status == 200) {
          setPageId(data.data._id)
          setPages(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();
  }, [ ]); 

  useEffect(() => {
    //Banners
    const loadBannersData = async () => {
        try {            
          const data = await getBanners({
            type:'page',
            type_id:pageId
          });  
          if(data.status == 200) {
              setBanners(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadBannersData();
  },[pageId]);

  useEffect(() => {    
    const loadSiteSettingsData = async () => {
      try {            
        const data = await getSiteSettings({});  
        if(data.status == 200) {
            setSiteSettings(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadSiteSettingsData();        
  }, [ ]); 
  useEffect(() => {
    //Seo
    const loadSeoData = async () => {
        try {            
          const data = await getSeo({
            type:'page',
            typeId:pageId,
          });  
          if(data.status == 200) {
              setSeo(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSeoData();
  },[pageId]);
  return (
    <><Helmet>
    <meta charSet="utf-8" />
    <meta name="title" content={seo?.meta_title}></meta>
    <meta name="author" content={seo?.author}></meta>
    <meta name="keywords" content={seo?.keywords}></meta>
    <meta name="description" content={seo?.description}></meta>
    </Helmet>
      <SecBanner banner={banners[0]}/>
      <SecContact page={page} settings={siteSettings}/>
      <SecNewsletter/>
    </>
  );
};

export default ContactUs;
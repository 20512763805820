import React, { useRef, useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Searching from "../../shared/searching";
import NothingFound from "../../shared/nothingFound";
import ImageShowModal from "../ImageShowModal";
import { NavLink } from 'react-router-dom';

const SecDriverDetails = ({ id, driver }) => {
  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d); //numeric
    let da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
    return `${da <= 9 ? "0" + da : da} ${mo <= 9 ? "0" + mo : mo} ${ye}`;
  };
  const [viewLightBox, setViewLightBox] = useState({
    photo: null,
    isOpen: false,
  });
  const { photo, isOpen } = viewLightBox;

  return (
    <section className="sec-plate-details">
      <div className="body-layout">
        <div className="body-content">
          
          {Object.keys(driver).length == 0 ? (
            driver.length == 0 ? (
              <NothingFound />
            ) : (
              <Searching />
            )
          ) : (<><div className="user-search-part">
          <div className="title">Driver Details </div>
        </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="details-content">
                  <div className="details">
                    <div className="details-card">
                      <div className='vehicle-info'>
                        <div className='vehicle-number'>
                        {driver?.name}
                        </div>
                        <div className='report-details'>
                            <NavLink to={"/edit-driver/"+ id}>
                              <button type="button" className='report-btn'><i class="far fa-edit mr-2"></i>Edit Driver</button>
                            </NavLink>
                        </div>
                      </div>
                      <div className="assigned-inner">
                        <div className="details-title">DMV Information</div>
                      </div>
                      {
                        <div className="assigned-drivers">
                          <div className="driver">
                            <div className="driver-image input-file cursor">
                              <img
                                src={driver?.dmv_license_image}
                                alt=""
                                onClick={() =>
                                  setViewLightBox({
                                    photo: driver?.dmv_license_image,
                                    isOpen: true,
                                  })
                                }
                              />
                            </div>
                            <div className="driver-content">
                              <div className="driver-meta">
                                <div className="meta-label">
                                  {" "}
                                  DMV License No.
                                </div>
                                <div className="meta-data">
                                  {driver?.license_number
                                    ? driver?.license_number
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="driver-meta">
                                <div className="meta-label">
                                  TLC License Expiration Date
                                </div>
                                <div className="meta-data">
                                  {driver?.license_expire_date
                                    ? dateFormat(driver.license_expire_date)
                                    : "N/A"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="assigned-inner">
                        <div className="details-title">TLC Information</div>
                      </div>
                      {
                        <div className="assigned-drivers">
                          <div className="driver">
                            <div className="driver-image input-file cursor">
                              <img
                                src={driver?.tlc_license_image}
                                onClick={() =>
                                  setViewLightBox({
                                    photo: driver?.tlc_license_image,
                                    isOpen: true,
                                  })
                                }
                                alt=""
                              />
                            </div>
                            <div className="driver-content">
                              <div className="driver-meta">
                                <div className="meta-label">
                                  {" "}
                                  TLC License No.
                                </div>
                                <div className="meta-data">
                                  {driver?.tlc_license
                                    ? driver?.tlc_license
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="driver-meta">
                                <div className="meta-label">
                                  TLC Course Date
                                </div>
                                <div className="meta-data">
                                  {driver?.tlc_course_date
                                    ? dateFormat(driver.tlc_course_date)
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="driver-meta">
                                <div className="meta-label">
                                  TLC License Expiration Date
                                </div>
                                <div className="meta-data">
                                  {driver?.tlc_license_exp_date
                                    ? dateFormat(driver.tlc_license_exp_date)
                                    : "N/A"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="details-title">Personal Information</div>
                      <div className="vehicle-metalist">
                        <div className="meta-item item-half">
                          <div className="meta-label">Name</div>
                          <div className="meta-data">
                            {driver?.name
                              ? driver?.name
                              : "N/A"}
                          </div>
                        </div>
                        <div className="meta-item item-half">
                          <div className="meta-label">Email</div>
                          <div className="meta-data">
                            {driver?.email_id
                              ? driver.email_id
                              : "N/A"}
                          </div>
                        </div>
                        <div className="meta-item item-half">
                          <div className="meta-label">Mobile</div>
                          <div className="meta-data">
                            {driver?.mobile_number
                              ? driver?.mobile_number
                              : "N/A"}
                          </div>
                        </div>
                        <div className="meta-item item-half">
                          <div className="meta-label">Date of Birth</div>
                          <div className="meta-data">
                            {driver?.date_of_birth
                              ? dateFormat(driver.date_of_birth)
                              : "N/A"}
                          </div>
                        </div>
                        <div className="meta-item item-half">
                          <div className="meta-label">Apartment NO. </div>
                          <div className="meta-data">
                            {driver?.apartment ? driver?.apartment : "N/A"}
                          </div>
                        </div>
                        <div className="meta-item item-half">
                          <div className="meta-label">Address </div>
                          <div className="meta-data">
                            {driver?.address ? driver?.address : "N/A"}
                          </div>
                        </div>
                      </div>

                      <div className="details-title">
                        Defensive Driving Course
                      </div>
                      <div className="vehicle-metalist">
                        <div className="meta-item item-half">
                          <div className="meta-label">Driving Course Date</div>
                          <div className="meta-data">
                            {driver?.driving_course_date
                              ? dateFormat(driver?.driving_course_date)
                              : "N/A"}
                          </div>
                        </div>
                        <div className="meta-item item-half">
                          <div className="meta-label">Expiration Date</div>
                          <div className="meta-data">
                            {driver?.date_taken_expire_date
                              ? dateFormat(driver?.date_taken_expire_date)
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="details-title">Drug Test Information</div>
                      <div className="vehicle-metalist">
                        <div className="meta-item item-half">
                          <div className="meta-label">Driving Test Date</div>
                          <div className="meta-data">
                            {driver?.drag_test_date
                              ? dateFormat(driver?.drag_test_date)
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="assigned-inner">
                        <div className="details-title">
                          Insurance Information
                        </div>
                      </div>
                      {
                        <div className="assigned-drivers">
                          <div className="driver">
                            <div className="driver-image input-file cursor">
                              <img
                                src={driver?.insurance_image}
                                onClick={() =>
                                  setViewLightBox({
                                    photo: driver?.insurance_image,
                                    isOpen: true,
                                  })
                                }
                                alt=""
                              />
                            </div>
                            <div className="driver-content">
                              <div className="driver-meta">
                                <div className="meta-label">Company Name</div>
                                <div className="meta-data">
                                  {driver?.company_name
                                    ? driver.company_name
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="driver-meta">
                                <div className="meta-label">
                                  Insurance Issue Date
                                </div>
                                <div className="meta-data">
                                  {driver?.insurance_issue_date
                                    ? dateFormat(driver.insurance_issue_date)
                                    : "N/A"}
                                </div>
                              </div>
                              <div className="driver-meta">
                                <div className="meta-label">
                                  Insurance Expiration Date
                                </div>
                                <div className="meta-data">
                                  {driver?.insurance_expire_date
                                    ? dateFormat(driver.insurance_expire_date)
                                    : "N/A"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div></>
          )}
        </div>
      </div>
      {isOpen && (
        <ImageShowModal
          photo={photo}
          isOpen={isOpen}
          setViewLightBox={setViewLightBox}
        />
      )}
    </section>
  );
};

export default SecDriverDetails;

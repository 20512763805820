import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import {
  manageContact
} from '../../actions/site';

const SecContact = ({ page, settings }) => {

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const onSubmit = async () => {
    try {
      let msg = await manageContact({
        type: 'contact',
        name: name,
        phone: phone,
        email: email,
        message: message
      });
      if (msg.status === 200) {
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
        toastr.success(msg.message);
      } else {
        toastr.error(msg.message);
      }
    } catch (err) {
      toastr.error(err.message);
    }
  }

  return (
    <section className="contact-section">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-5 p-0">
            {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6044.653515290774!2d-73.927173!3d40.7548374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25f2f9b964315%3A0x4fd427abd900fce7!2s35-37%2036th%20St%2C%20Queens%2C%20NY%2011106%2C%20USA!5e0!3m2!1sen!2sin!4v1656673079374!5m2!1sen!2sin"
            width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col-xl-7 col-lg-12 col-md-8 mx-0 mx-md-auto mx-lg-0 ">
                <div className="contact-inner">
                  <div className="title">Get In Touch</div>
                  <div className="description" dangerouslySetInnerHTML={{ __html: page?.description }}></div>
                  <div className="form-inner">
                    <form action='#'>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          placeholder="Your message"
                          rows="3"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-action">
                        <button type="button" className="btn-action" onClick={onSubmit}><i className="fas fa-fw mr-2 fa-paper-plane"></i>Send
                          message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 d-flex">
                <div className="contact-list">
                  {
                    settings?.address &&
                    <div className="contact-info">
                      <div className="contact-icon">
                        <i className="far fa-fw fa-map-marker-alt"></i>
                      </div>
                      <div className="contact-content">
                        <div className="sub-text">Our Location</div>
                        <a className="contact-item" target="_blank" href="https://goo.gl/maps/KosUg7N8q79Fq1VT7">{settings?.address}</a>
                      </div>
                    </div>
                  }
                  {
                    settings?.email &&
                    <div className="contact-info">
                      <div className="contact-icon">
                        <i className="far fa-fw fa-envelope"></i>
                      </div>
                      <div className="contact-content">
                        <div className="sub-text">E-mail us at</div>
                        <a className="contact-item" href={(settings && settings.email) ? 'mailto:' + settings.email : ''}>{settings?.email}</a>
                      </div>
                    </div>
                  }
                  {
                    settings?.contact_no &&
                    <div className="contact-info">
                      <div className="contact-icon">
                        <i className="far fa-fw fa-phone-alt"></i>
                      </div>
                      <div className="contact-content">
                        <div className="sub-text">Contact us</div>
                        <a className="contact-item" href={(settings && settings.contact_no) ? 'tel:1' + settings.contact_no : ''}>{settings?.contact_no}</a>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
};

export default SecContact;
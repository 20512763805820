import React, { useRef,useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import DatePicker  from 'react-datepicker';
import { getMonth, getYear,parse  } from 'date-fns';
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import toastr from 'toastr';
import { 
  updateDriver
} from '../../actions/driver';
import { dConvert } from '../../helpers/dateconvert';
import { convertToBase64 } from "../../helpers/base64FileHelper"
import { NavLink } from 'react-router-dom';
import ImageShowModal from '../ImageShowModal';
import InputMask from 'react-input-mask';
import Autocomplete , { usePlacesWidget } from "react-google-autocomplete";
import { googleApiData,allowedExtensions } from '../../helpers';

const SecEditDriverManagement = ({states,cities,id,driver}) => {
  const googleApiKey = googleApiData();
  const allowedExts = allowedExtensions();
  const [first_name, setFirstName] = useState(''); 
  const [last_name, setLastName] = useState('');   
  const [email, setEmail] = useState('');
  const [dmvlicense, setDmvLicense] = useState('');
  const [license_expire_date, setExpireDate] = useState('');
  const [dmvimage,setDMVImage] = useState('');  
  const [isCommercial,setIsCommercial] = useState(false);
  const [tlcLicense,setTLCLicense] = useState('');
  const [tlc_license_exp_date,setTlcLicenceExpDate] = useState('');
  const [tlcimage,setTLCImage] = useState('');
  const [mobile,setMobile] = useState('');
  const [dob,setDOB] = useState('');
  const [address,setAddress] = useState('');
  const [apartment,setAppartment] = useState('');
  const [city,setCity] = useState('');
  const [state,setState] = useState('');
  const [zip,setZip] = useState('');
  const [viewLightBox, setViewLightBox] = useState({photo: null,isOpen: false})
  const { photo, isOpen } = viewLightBox;

  const [isDefensiveDrivingCourse,setIsDefensiveDrivingCourse] = useState(false);
  const [dateTakenExpDate,setDateTakenExpDate] = useState('');
  const [driving_course_date, setDrivingCourse] = useState(''); 

  const [isDrugTest,setIsDrugTest] = useState(false);
  const [drugTestDateTaken, setDrugTestDateTaken] = useState('');

  const [isTlcCourse,setIsTlcCourse] = useState(false);
  const [tlc_course_date, setTlcCourseDate] = useState('');
  
  const [isInsurance,setIsInsurance] = useState(false);
  const [company_name,setCompanyName] = useState('');
  const [insurance_issue_date,setInsuranceIssueDate] = useState('');
  const [insurance_expire_date,setInsuranceExpireDate] = useState('');
  const [insuranceimage,setInsuranceImage] = useState('');   
  const [isEditDriver,setIsEditDriver] = useState(false);

  const [showInsImage,setShowInsImage] = useState('');
  const [showTlcImage,setShowTlcImage] = useState('');
  const [showDmvImage,setShowDmvImage] = useState('');
  
  const [dmvImageExt,setDmvImageExt] = useState('');
  const [tlcImageExt,setTlcImageExt] = useState('');
  const [insuranceImageExt,setInsuranceImageExt] = useState('');
  const [viewPdf, setViewPdf] = useState('')

  const inputRef = useRef(null);
  const [displayState,setDisplayState] =  useState('Select State');
  const [isMobileUpdate,setIsMobileUpdate] =  useState(false);


  useEffect(() => {       
    setFirstName(driver?.first_name);
    setLastName(driver?.last_name);          
    setEmail(driver?.email_id);
    setDmvLicense(driver?.license_number);
    setExpireDate((driver && driver.license_expire_date)?new Date(driver?.license_expire_date).getTime():'');
    setIsCommercial(driver?.is_commercial);
    setTLCLicense(driver?.tlc_license);
    setTlcLicenceExpDate((driver && driver.tlc_license_exp_date)?new Date(driver?.tlc_license_exp_date).getTime():'');
    setMobile(driver?.mobile_number);
    setDOB((driver && driver.date_of_birth)?new Date(driver?.date_of_birth).getTime():'');
    setAddress(driver?.address);
    setAppartment(driver?.apartment);
    setCity(driver.city?driver.city:'');
    setState(driver.state?driver.state._id:'');
    setZip(driver?.ZIP_code);
    setIsDefensiveDrivingCourse(driver?.is_defensive_driving_course);
    setDateTakenExpDate((driver?.date_taken_expire_date)?new Date(driver?.date_taken_expire_date).getTime():'');
    setDrivingCourse((driver && driver.driving_course_date)?new Date(driver?.driving_course_date).getTime():'');
    setIsDrugTest(driver?.is_drag_test);
    setDrugTestDateTaken((driver && driver.drag_test_date)?new Date(driver?.drag_test_date).getTime():'');
    setIsTlcCourse(driver?.is_tlc_cource);
    setTlcCourseDate((driver && driver.tlc_course_date)?new Date(driver?.tlc_course_date).getTime():'');
    setIsInsurance(driver?.is_insurance);
    setCompanyName(driver?.company_name);
    setInsuranceIssueDate((driver && driver.insurance_issue_date)?new Date(driver?.insurance_issue_date).getTime():'');
    setInsuranceExpireDate((driver && driver.insurance_expire_date)?new Date(driver?.insurance_expire_date).getTime():'');
    setShowInsImage(driver?.insurance_image);
    setShowTlcImage(driver?.tlc_license_image);
    setShowDmvImage(driver?.dmv_license_image);
    setIsMobileUpdate(false);
    if(driver?.dmv_license_image){
      setDmvImageExt(get_url_extension(driver?.dmv_license_image));
    }
    if(driver?.tlc_license_image){
      setTlcImageExt(get_url_extension(driver?.tlc_license_image))
    }
    if(driver?.insurance_image){
      setInsuranceImageExt(get_url_extension(driver?.insurance_image))
    }
}, [driver]);

function get_url_extension( url ) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

useEffect(() => {
  if(dmvimage){
    setShowDmvImage(dmvimage);
  }
  if(tlcimage){
    setShowTlcImage(tlcimage);
  }
  if(insuranceimage){
    setShowInsImage(insuranceimage);
  }
}, [dmvimage,tlcimage,insuranceimage]);
  
  const years = range(1970, getYear(new Date('2050')) + 1, 1);
  const years2 = range(1970, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const  inputDate = (e) => { 
    setExpireDate(e);
  };

  const inputDOBDate =(e) =>{
    setDOB(e);
  }

  const courseExpDate = (e) => {
    setTlcLicenceExpDate(e);
  };

  const inputDrivingCourseDate = (e) => {
    setDrivingCourse(e);
  };

  const dateTakenExpireDate = (e) => {
    setDateTakenExpDate(e);
  };

  const insuranceIssueDate = (e) => {
    setInsuranceIssueDate(e);
  };

  const insuranceExpireDate = (e) => {
    setInsuranceExpireDate(e);
  };

  const handleDrugTestDateTaken = (e) => {
    setDrugTestDateTaken(e)
  }

  const handleTlcCourseDate = (e) =>{
    setTlcCourseDate(e);
  }

  const checkCommercial =(e) => {    
    if(e.target.checked){
        setIsCommercial(true);
    }else{
        setIsCommercial(false);
        setTLCLicense('');
        courseExpDate('');
        setTLCImage('');
        setShowTlcImage('');
    }
  }

  const checkedInsurance = (e) => {
    if(e.target.checked){
        setIsInsurance(true);
    }else{
        setIsInsurance(false);
        setCompanyName('');
        setInsuranceIssueDate('');
        setInsuranceExpireDate('');
        setInsuranceImage('');
        setShowInsImage('');
       
    }
  }

  const checkDefensiveDrivingCourse = (e) => {
    if(e.target.checked){
        setIsDefensiveDrivingCourse(true);
    }else{
        setIsDefensiveDrivingCourse(false);
        setDrivingCourse('');
        setDateTakenExpDate('');
    }
  }

  const checkedDrugTest = (e) => {
    if(e.target.checked){
      setIsDrugTest(true);
    }else{
      setIsDrugTest(false);
      setDrugTestDateTaken('');
    }
  }

  const checkedTlcCourse = (e) => {
    if(e.target.checked){
      setIsTlcCourse(true);
    }else{
      setIsTlcCourse(false);
      setTlcCourseDate('');
    }
  }

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];
      setDmvImageExt(ext);
      if (![...allowedExts].includes(ext)) {   
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById('file-input');
          output.src = URL.createObjectURL(e.target.files[0]);
          output.onload = function() {
            URL.revokeObjectURL(output.src) // free memory
          }
        convertToBase64(e.target.files[0], setDMVImage);
  
      }
    }
  };

  const onTlcFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];
      setTlcImageExt(ext);
      if (![...allowedExts].includes(ext)) {   
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById('tlc-file-input');
          output.src = URL.createObjectURL(e.target.files[0]);
          output.onload = function() {
            URL.revokeObjectURL(output.src) // free memory
          }
        convertToBase64(e.target.files[0], setTLCImage);
  
      }
    }
  };

  const onInsranceCard = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split('/')[1];
      setInsuranceImageExt(ext);
      if (![...allowedExts].includes(ext)) {   
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById('insurance-file-input');
          output.src = URL.createObjectURL(e.target.files[0]);
          output.onload = function() {
            URL.revokeObjectURL(output.src) // free memory
          }
        convertToBase64(e.target.files[0], setInsuranceImage);
      }
    }
  }

  const onSubmit = async () => {    
    setIsEditDriver(true);
    try {
      let msg = await updateDriver({
        id:id,
        first_name:first_name,
        last_name : last_name,
        name: first_name+' '+last_name,        
        email_id: email,
        license_number: dmvlicense,
        license_expire_date: license_expire_date,
        dmv_license_image : dmvimage,
        is_commercial : isCommercial,
        tlc_license:tlcLicense,
        tlc_license_exp_date :tlc_license_exp_date,
        tlc_license_image : tlcimage,        
        mobile_number:(isMobileUpdate)?mobile.replace(/[(|)|_|-]/g, "").replace(/\s/g,''):mobile,
        date_of_birth:dob,
        address:address,
        apartment:apartment,
        state:state,
        city:city,
        ZIP_code:zip,
        is_defensive_driving_course : isDefensiveDrivingCourse,
        date_taken_expire_date: dateTakenExpDate,
        driving_course_date: driving_course_date,
        is_drag_test : isDrugTest,
        drag_test_date : drugTestDateTaken,
        is_tlc_cource : isTlcCourse,
        tlc_course_date : tlc_course_date,
        is_insurance: isInsurance,
        company_name : company_name,
        insurance_issue_date : insurance_issue_date,
        insurance_expire_date: insurance_expire_date,
        insurance_image: insuranceimage,
      });
      if (msg.status === 200) {   
        setIsEditDriver(false);     
        toastr.success(msg.message);
      } else {
        setIsEditDriver(false);
        toastr.error(msg.message);
      }
    } catch (err) {
      setIsEditDriver(false);
      toastr.error(err.message);
    }
  }

    return (
        <section>
        <div className='body-layout'>
        <div className='body-content'>
          <div className='user-search-part'>
            <div className='title'>Edit Driver</div>
          </div>
          <div className='add-new-driver'>
            <form className='form-content'>
              <div className='form-group'>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>First Name</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    value={first_name}
                    placeholder='Enter First Name'
                    onChange={(e) => setFirstName(e.target.value)}
                    /></div>
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>Last Name</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    value={last_name}
                    placeholder='Enter Last Name'
                    onChange={(e) => setLastName(e.target.value)}
                    /></div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>Email</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="email" 
                    value={email}
                    placeholder='Enter Email'
                    onChange={(e) => setEmail(e.target.value)}
                    /></div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>DMV License Number</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    placeholder='Enter DMV License No.'
                    value={dmvlicense}
                    onChange={(e) => setDmvLicense(e.target.value)} 
                    /></div>
                    
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title required'>Expiration Date</div>
                    <div className='driver-input'>
                    <DatePicker 
                    className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={license_expire_date}
                      onChange={inputDate}
                    />
                    {/* <input 
                    className='input' 
                    type="text" 
                    placeholder='Choose Expiration Date'
                    value={license_expire_date}
                    onChange={(e) => setExpireDate(e.target.value)} 
                     /> */}
                     </div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>DMV License Image</div>
                      <div className='driver-option'>
                        <div className='registration-content'>
                          <div className='notifications-header'>
                              <div className='image-upload'>
                              <input 
                              name="dmv_license_image"  
                              onChange={onFileChange}  
                              type="file" 
                              id="file-input" 
                              className="image-upload-input feedback-input" 
                              accept="image/jpeg,image/gif,image/png,application/pdf"
                              />
                              <label className='file-input-label' htmlFor="file-input">
                                <div className='registration-icon'>
                                  <i className="fa-fw fa-solid fa-id-card"></i>
                                </div>
                                <div className='registration-text'>
                                  Upload File
                                </div>
                              </label>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className='driver-info-content'>
                    {
                     showDmvImage &&
                      <>
                      <div className='driver-info-title'>DMV Image Preview</div>
                      <div className="input-file cursor">
                        {
                          (dmvImageExt && (dmvImageExt === 'pdf' || dmvImageExt === 'PDF'))?
                          <img
                              src='/assets/img/PDF_file_icon.svg.png'
                              title={showDmvImage}
                              onClick={() => setViewPdf(showDmvImage)}
                              data-toggle="modal" 
                              data-target="#pdfModal"
                          />:
                          <img 
                           src={showDmvImage}   
                           onClick={() => setViewLightBox({ photo:showDmvImage, isOpen: true })}
                           className="img-thumbnail" 
                           alt="Cinque Terre"
                           />
                        }
                           
                      </div>
                      </>
                    }
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className="checkbox-group">
                      <div className="custom-checkbox">
                        <input 
                        type="checkbox" 
                        className="custom-checkbox-input"
                        checked={isCommercial}
                        onChange={checkCommercial }
                         />
                        <div className="custom-checkbox-label">Commercial License/TLC</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='driver-info'>
                    <div className='driver-info-content' style={{display: isCommercial ? '' : 'none' }}>
                      <div className='driver-info-title'>TLC License #</div>
                        <div className='driver-input'> 
                        <input 
                        className='input' 
                        type="text" 
                        placeholder='Enter Drug Test'
                        value={tlcLicense}
                        onChange={(e) => setTLCLicense(e.target.value)}
                        /> </div>
                    </div>
                    <div className='driver-info-content' style={{display: isCommercial ? '' : 'none' }}>
                    <div className='driver-info-title'>Expiration Date</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={tlc_license_exp_date}
                      onChange={courseExpDate}
                    />
                    </div>
                  </div>                 
                </div>     
                <div className='driver-info'>
                  <div className='driver-info-content' style={{display: isCommercial ? '' : 'none' }}>
                      <div className='driver-info-title'>TLC License Image</div>
                      <div className='driver-option'>
                      <div className='registration-content'>
                      <div className='notifications-header'>
                          <div className='image-upload'>
                          <input 
                          name="tlc_license_image"  
                          onChange={onTlcFileChange}  
                          type="file" 
                          id="tlc-file-input" 
                          className="image-upload-input feedback-input" 
                          accept="image/jpeg,image/gif,image/png,application/pdf"
                          />
                          <label className='file-input-label' htmlFor="tlc-file-input">
                            <div className='registration-icon'>
                              <i className="fa-fw fa-solid fa-id-card"></i>
                            </div>
                            <div className='registration-text'>Upload File</div>
                          </label>
                              
                          </div>
                      </div>
                  </div>
                  </div>
                  </div>
                  <div className='driver-info-content' style={{display: isCommercial ? '' : 'none' }}>
                    {
                      showTlcImage &&
                      <>
                      <div className='driver-info-title'>TLC Image Preview</div>
                      <div className="input-file cursor">
                      {
                        (tlcImageExt && (tlcImageExt === 'pdf' || tlcImageExt === 'PDF'))?
                        <img
                            src='/assets/img/PDF_file_icon.svg.png'
                            title={showTlcImage}
                            onClick={() => setViewPdf(showTlcImage)}
                            data-toggle="modal" 
                            data-target="#pdfModal"
                        />:
                        <img 
                        src={showTlcImage}  
                        onClick={() => setViewLightBox({ photo:showTlcImage, isOpen: true })}  
                        className="img-thumbnail" 
                        alt="Cinque Terre"
                        />
                      }
                      </div>
                      </>
                    }
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Mobile</div>
                    <div className='driver-input'> 
                    <InputMask
                    mask="(999) 999-9999" 
                    className='input' 
                    type="text" 
                    placeholder='Enter Mobile'
                    value={mobile} 
                    onChange={e => {setMobile(e.target.value);setIsMobileUpdate(true)} }
                    /> </div>
                  </div>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Date of Birth</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years2.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={dob}
                      onChange={inputDOBDate}
                    />
                    </div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Address</div>
                    <div className='driver-input'> 
                    <Autocomplete 
                    className='input' 
                    type="text" 
                    placeholder='Enter Address'
                    value={address} 
                    ref={inputRef}
                    apiKey={googleApiKey}
                    onPlaceSelected={(place) => {
                        console.log(place); 
                        setAddress(place.formatted_address);   
                        if(place && place.address_components.length>0){
                            for (let i = 0; i < place.address_components.length; i++) {
                                if(place.address_components[i].types[0] === 'sublocality_level_1'){
                                    setCity(place.address_components[i]?.long_name)
                                }
                                if(place.address_components[i].types[0] === 'administrative_area_level_1'){
                                    setDisplayState(place.address_components[i]?.short_name)
                                    let index = states.findIndex((item) => item.name === place.address_components[i]?.short_name);
                                    if(index != -1){
                                      setState(states[index]?._id);
                                    }
                                }
                                if(place.address_components[i].types[0] === 'postal_code'){
                                    setZip(place.address_components[i]?.short_name)
                                }
                            }
                        }
                    }}
                    options={{
                      types: ['geocode'],
                      componentRestrictions: { country: "us" },
                    }}
                    onChange={(e) => setAddress(e.target.value)}
                    /> </div>
                  </div>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Apt #</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    placeholder='Enter Apt #'
                    value={apartment} 
                    onChange={(e) => setAppartment(e.target.value)}
                    /> 
                    </div>
                  </div>
                </div>
                {/* <div className='driver-info'> 
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>State</div>
                    <div className='driver-input'> 
                    <select 
                    className='input' 
                    placeholder='Enter State'
                    onChange={(e) => setState(e.target.value)}
                    >
                    {
                      states.length > 0 &&
                      states.map((item,index) => (
                        <option key={index} value={item._id} selected={item._id == state}>
                          {item.name}
                        </option>
                      ))
                    }
                    </select> </div>
                  </div>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>City</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    placeholder='Enter City'
                    value={city} 
                    onChange={(e) => setCity(e.target.value)}
                    /> 
                    </div>
                  </div>
                </div> */}
                {/* <div className='driver-info'>                  
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Zip</div>
                    <div className='driver-input'> 
                    <input 
                    className='input' 
                    type="text" 
                    placeholder='Enter Zip'
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    /> </div>
                  </div>
                </div> */}
                <div className='driver-info'>
                  <div className='driver-info-content'>
                    <div className="checkbox-group">
                      <div className="custom-checkbox">
                        <input 
                        type="checkbox" 
                        className="custom-checkbox-input" 
                        checked={isDefensiveDrivingCourse}
                        onChange={ checkDefensiveDrivingCourse }
                         />
                        <div className="custom-checkbox-label">Defensive Driving Course</div>
                      </div>
                    </div>
                  </div>
                </div> 
                <div className='driver-info' style={{display: isDefensiveDrivingCourse ? '' : 'none' }}>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Driving Course Date</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={driving_course_date}
                      onChange={inputDrivingCourseDate}
                    />
                    </div>
                  </div>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Expiration Date</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={dateTakenExpDate}
                      onChange={dateTakenExpireDate}
                    />
                    </div>
                  </div>  
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                      <div className="checkbox-group">
                      <div className="custom-checkbox">
                          <input 
                          type="checkbox" 
                          className="custom-checkbox-input" 
                          checked={ isDrugTest }
                          onChange={ checkedDrugTest }
                          />
                          <div className="custom-checkbox-label">Drug Test</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='driver-info' style={{display: isDrugTest ? '' : 'none' }}>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Drug Test Date</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={drugTestDateTaken}
                      onChange={ handleDrugTestDateTaken }
                    />
                    </div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                      <div className="checkbox-group">
                      <div className="custom-checkbox">
                          <input 
                          type="checkbox" 
                          className="custom-checkbox-input" 
                          checked={ isTlcCourse }
                          onChange={ checkedTlcCourse }
                          />
                          <div className="custom-checkbox-label">TLC Course</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='driver-info' style={{display: isTlcCourse ? '' : 'none' }}>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>TLC course Date</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={tlc_course_date}
                      onChange={ handleTlcCourseDate }
                    />
                    </div>
                  </div>
                </div>
                <div className='driver-info'>
                  <div className='driver-info-content'>
                      <div className="checkbox-group">
                      <div className="custom-checkbox">
                          <input 
                          type="checkbox" 
                          className="custom-checkbox-input" 
                          checked={isInsurance}
                          onChange={checkedInsurance }
                          />
                          <div className="custom-checkbox-label">Insurance Information</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='driver-info' style={{display: isInsurance ? '' : 'none' }}>
                 <div className='driver-info-content'>
                     <div className='driver-info-title'>Show Company</div>
                    <div className='driver-input'> 
                    <input 
                    className='input'
                     type="text" 
                     placeholder='Enter Company Name'
                     value={company_name}
                     onChange={(e) => setCompanyName(e.target.value)} 
                     /> 
                     </div>
                  </div>
                  <div className='driver-info-content'>
                     <div className='driver-info-title'>Insurance issue Date</div>
                    <div className='driver-input'> 
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={insurance_issue_date}
                      onChange={insuranceIssueDate}
                    />
                     </div>
                  </div>
                </div>
                <div className='driver-info' style={{display: isInsurance ? '' : 'none' }}>
                  <div className='driver-info-content'>
                    <div className='driver-info-title'>Expiration Date</div>
                    <div className='driver-input'>
                    <DatePicker className='input'
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button className='arrow-btn' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select className='year-arrow left'
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select className='year-arrow'
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button className='arrow-btn' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={insurance_expire_date}
                      onChange={insuranceExpireDate}
                    />
                     </div>
                  </div>
                  <div className='driver-info-content' style={{display: isInsurance ? '' : 'none' }}>
                    <div className='driver-info-title'>Upload Insurance Card</div>
                      <div className='registration-content'>
                        <div className='notifications-header'>
                          <div className='image-upload'>
                              <input 
                                name="insurance_license_image"  
                                onChange={onInsranceCard}  
                                type="file" 
                                id="insurance-file-input" 
                                className="image-upload-input feedback-input"
                                accept="image/jpeg,image/gif,image/png,application/pdf"
                              />
                            <label className='file-input-label' htmlFor="insurance-file-input">
                              <div className='registration-icon'>
                                <i className="fa-regular fa-upload mr-2"></i>
                              </div>
                              <div className='registration-text'>
                                  Upload File
                              </div>
                            </label>
                        </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div className='driver-info' style={{display: isInsurance ? '' : 'none' }}>
                  <div className='driver-info-content'>
                    {
                      showInsImage &&
                      <>
                      <div className='driver-info-title'>Insurance Image Preview</div>
                      <div className="input-file cursor">
                        {
                          (insuranceImageExt && (insuranceImageExt === 'pdf' || insuranceImageExt === 'PDF'))?
                          <img
                              src='/assets/img/PDF_file_icon.svg.png'
                              title={showInsImage}
                              onClick={() => setViewPdf(showInsImage)}
                              data-toggle="modal" 
                              data-target="#pdfModal"
                          />:
                          <img 
                          src={showInsImage}
                          onClick={() => setViewLightBox({ photo:showInsImage, isOpen: true })} className="img-thumbnail"
                          alt="Cinque Terre"
                          />
                        }  
                      </div>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className='submit-action'>
                <button type="button" className='btn-submit-action' disabled={isEditDriver} onClick={onSubmit}><i className={(isEditDriver)?'fa-regular fa-spinner fa-spin mr-2':"fa-solid fa-save mr-2"}></i> Submit</button>
                <NavLink  to={'/driver-management'} type="button" className='btn-cancel-action'>Cancel</NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
          viewPdf && 
          <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf('')}>
                          <i className="fal fa-fw fa-times"></i>
                          </button>
                      </div>
                      <div className="modal-body">
                          <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                              <p>There was an error downloading the invoice.</p>
                          </object>
                      </div>
                  </div>
              </div>
          </div>
      }
      {   isOpen && (<ImageShowModal photo={photo} isOpen={isOpen} setViewLightBox={setViewLightBox}/>)}
        </section>
    );
};

export default SecEditDriverManagement;
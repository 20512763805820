import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';

const NoAuthRoute = ({ auth, children }) => {
    
  if(localStorage.getItem('access_token')) {
    return <Navigate to="/" />
  }
  return children;
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(NoAuthRoute);
import React, { useState, useEffect } from 'react';
import UserSidepanel from '../shared/userSidepanel';
import SecTlcTickets from '../components/Dashboard/SecTlcTickets';
//import SecAddNewTicket from '../components/TicketManagement/SecAddNewTicket';
import { checkDriverVehicle } from '../actions/site';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

const TicketManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const onCheckTicket = async () => {
    try {
      const data = await checkDriverVehicle();
      if (data.status == 200) {
        if (data.step == 1) {
          return navigate("/add-driver");
        } else if (data.step == 2) {
          return navigate("/add-plate");
        }
      } else {
        return navigate("/add-driver");
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  // useEffect(() => {
  //   if (localStorage.getItem('access_token')) {
  //     dispatch(async () => {
  //       try {
  //         const data = await checkDriverVehicle();
  //         if (data.status == 200) {
  //           if (data.step == 1) {
  //             return navigate("/add-driver");
  //           } else if (data.step == 2) {
  //             return navigate("/add-plate");
  //           }
  //         } else {
  //           return navigate("/add-driver");
  //         }
  //       } catch (err) {
  //         console.log(err.message);
  //       }
  //     })
  //   }

  // }, [dispatch, window.location.pathname]);

  return (
    <section className='sec-ticket login-part-pages'>
      <div className='container'>
      <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className='right-sidebar'>
            <section className='sec-dashboard tlc-management'>
              <div className='body-layout'>
                <div className='body-content'>
                  <div className='user-search-part'>
                  <div className='search-upper-part'>
                  <div className='title'>Tickets</div>
                  <div className="ticket-submit-box">
                        <div className="submit-box-item" onClick={() => onCheckTicket() }>
                          <NavLink to="/submit-ticket?type=dmv" className="submit-btn">
                            Submit Parking Tickets
                          </NavLink>
                        </div>
                        <div className="submit-box-item">
                          <NavLink to="/submit-ticket?type=tlc" className="submit-btn traffic">
                            Submit TLC Summons
                          </NavLink>
                        </div>
                      </div>
                  </div>
                    
                    <div className='tlc-tickets-management'>
                      <div className="search management">
                        <button type="button" className="search-btn">
                          <i className="fa-regular fa-fw fa-magnifying-glass"></i>
                        </button>
                        <input
                          type="text"
                          className="search-input"
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                        />
                      </div>
                     
                    </div>

                  </div>
                  <SecTlcTickets keyword={keyword} />
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
    </section>
  );
};

export default TicketManagement;
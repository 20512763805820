import React, {CSSProperties} from 'react';

const SecServices = ({solutions}) => {

  return (
    <section className="section-services">
    <div className="services-background"><img src="assets/img/bg-service.svg" alt=""/></div>
    <div className="container">
      <div className="service-content">
        <h2 className="service-title">Transportation Solution</h2>
        {/* <div className="service-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras hendrerit varius velit ut ultricies. Donec condimentum elit et orci posuere congue. Donec placerat nulla non purus pharetra, porta dapibus justo placerat.</div> */}
      </div>
      <div className="service-infographics">
        <div className="service-circle">
          {
            solutions.length > 0 &&
            solutions.map((item, index) =>
            <div className="service-item" style={{'--i': index}} key={index}>
              <div className="service-inner">
                <div className="service-icon"><i className={item?.icon}></i></div>
                <div className="service-title">{item?.name}</div>
              </div>
            </div>
            )
          }
          
          {/* <div className="service-item" style={{'--i': 1}}>
            <div className="service-inner">
              <div className="service-icon"><i className="icon-traffic-lights"></i></div>
              <div className="service-title">Red Light Violation</div>
            </div>
          </div>
          <div className="service-item" style={{'--i': 2}}>
            <div className="service-inner">
              <div className="service-icon"><i className="icon-bus-lane-camera"></i></div>
              <div className="service-title">Bus Lane Camera Violation</div>
            </div>
          </div>
          <div className="service-item" style={{'--i': 3}}>
            <div className="service-inner">
              <div className="service-icon"><i className="icon-speed-camera"></i></div>
              <div className="service-title">Speed Camera Violation</div>
            </div>
          </div>
          <div className="service-item" style={{'--i': 4}}>
            <div className="service-inner">
              <div className="service-icon"><i className="icon-summons"></i></div>
              <div className="service-title">TLC Summons</div>
            </div>
          </div> */}

          <div className="service-center">
            <a className="logo" href="index.html"><img src="assets/img/logo.png" alt=""/></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default SecServices;
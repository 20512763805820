import React from 'react';
import SecReferral from '../components/Referral/SecReferral';
import UserSidepanel from '../shared/userSidepanel';

const Referral = () => {
  console.log('hii');
    return (
        <section className='sec-panel login-part-pages'>
          <div className='container'>
          <div className='row user-panel-menus'>
      <div className='left-sidebar'>
                  <UserSidepanel/>
              </div>
              <div className=' right-sidebar'>
                  <SecReferral/>
              </div>
            </div>
          </div>
        </section>
    );
};

export default Referral;
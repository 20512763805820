import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import NothingFound from "../../shared/nothingFound";
import Searching from "../../shared/searching";
import { getBillingList } from "../../actions/billing";
import ReactPaginate from "react-paginate";

const SecBillings = () => {
  const dateFormat = (issue_date) => {
    let d = new Date(issue_date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d); //numeric
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${mo <= 9 ? "0" + mo : mo} ${da}, ${ye}`;
  };
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [mode, setMode] = useState("");
  const [defaultMode, setDefaultMode] = useState("Payment mode");
  const [status, setStatus] = useState("");
  const [defaultStatus, setDefaultStatus] = useState("Payment status");
  const [offset, setOffset] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(10);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [billings, setBillings] = useState({});
  useEffect(() => {
    // Billing List
    const loadBillings = async () => {
      try {
        const data = await getBillingList({
          keyword: keyword,
          payment_mode: mode,
          payment_status: status,
          user_id: localStorage.getItem("userId"),
          sortByField: "created_at",
          sortByValue: "DESC",
          limit: limit,
          offset: offset,
        });
        if (data.status == 200) {
          setBillings(data.data);
          setCount(data.count);
          setTotalDataCount(data.count);
        } else {
          setCount(0);
          setTotalDataCount(0);
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    loadBillings();
  }, [keyword, limit, offset, mode, status]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  return (
    <section className="sec-billings sec-plate-management">
      <div className="body-layout">
        <div className="body-content">
          <div className="user-search-part">
            <div className="title">Billings</div>
            <div className="search-lower-part">
              <div className="list-filter-action">
                <div className="search">
                  <button type="button" className="search-btn">
                    <i className="fa-regular fa-fw fa-magnifying-glass"></i>
                  </button>
                  <input
                    type="text"
                    className="search-input"
                    value={keyword}
                    onChange={(e) =>
                      setKeyword(e.target.value.replace(/[^\w\s]/gi, ""))
                    }
                  />
                </div>
                <div className="search-filters">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle vehicle-input"
                      data-toggle="dropdown"
                    >
                      {defaultMode}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-scroll">
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value=""
                          onClick={(e) => {
                            setMode("");
                            setDefaultMode("Payment mode");
                          }}
                        >
                          Payment mode
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="stripe"
                          onClick={(e) => {
                            setMode("stripe");
                            setDefaultMode("Credit Card");
                          }}
                        >
                          Credit Card
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="paypal"
                          onClick={(e) => {
                            setMode("paypal");
                            setDefaultMode("Paypal");
                          }}
                        >
                          Paypal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-filters">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle vehicle-input"
                      data-toggle="dropdown"
                    >
                      {defaultStatus}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-scroll">
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value=""
                          onClick={(e) => {
                            setStatus("");
                            setDefaultStatus("Payment status");
                          }}
                        >
                          Payment status
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="pending"
                          onClick={(e) => {
                            setStatus("pending");
                            setDefaultStatus("Pending");
                          }}
                        >
                          Pending
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="success"
                          onClick={(e) => {
                            setStatus("success");
                            setDefaultStatus("Success");
                          }}
                        >
                          Success
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          data-value="cancel"
                          onClick={(e) => {
                            setStatus("cancel");
                            setDefaultStatus("Cancel");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="billings-inner">
            {totalDataCount > 0 ? (
              billings.length > 0 &&
              billings.map((item, index) => (
                <div className="billings-item" key={index}>
                  <div className="billings-option">
                    <div className="billings-hading">
                      <div className="billings-catagory">
                        <div className="billings-id">
                          {
                            (item?.invoice_no && item?.payment_status === "pending")?
                            <NavLink
                              to={"/invoice-payment/" + item?.invoice_no}
                              className="billings-title"
                            >
                              Pay Now
                            </NavLink>:''
                          }
                          
                          {/* <div className='billings-sub-title'>{item?.payment_transaction}</div> */}
                        </div>
                      </div>
                      <div className="billings-status">
                        <div className={`status-title ${(item?.payment_status && item?.payment_status === 'pending')?'un-paid':item?.payment_status}`}>
                          {item?.payment_status}
                        </div>
                      </div>
                    </div>
                    <NavLink
                      to={"/transaction/" + item?._id}
                      className="billings-content"
                    >
                      <div className="billings-description">
                        {item?.payment_type === "signup_referrel" ? (
                          <div className="billings-id">
                            <div className="billings-title ticket">&nbsp;</div>
                            <div className="billings-sub-title ticket-no">
                              for referral signup
                            </div>
                          </div>
                        ) : item?.invoice_no ? (
                          <>
                            <div className="billings-id">
                              <div className="billings-title ticket">
                                Invoice Number
                              </div>
                              <div className="billings-sub-title ticket-no">
                                {item?.invoice_no}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="billings-id">
                              <div className="billings-title ticket">
                                Ticket Number
                              </div>
                              <div className="billings-sub-title ticket-no">
                                {item.ticket_details}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="billings-payment">
                          <div className="payment-mode">Payment Mode</div>
                          <div className="payment-sub-mode">
                            {item?.payment_mode &&
                            item?.payment_mode === "stripe"
                              ? "Credit Card"
                              : item?.payment_mode}
                          </div>
                        </div>
                        <div className="billings-date">
                          <div className="payment-mode">Date</div>
                          <div className="payment-sub-mode">
                            {item?.transaction_date
                              ? dateFormat(item.transaction_date)
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="billings-price">
                        ${item?.amount.toFixed(2)}
                      </div>
                    </NavLink>
                  </div>
                </div>
              ))
            ) : loading ? (
              <Searching />
            ) : (
              <NothingFound />
            )}
          </div>
          {noOfPage > 1 && (
            <div className="pagination-inner">
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={noOfPage}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
              </nav>
            </div>
          )}
          {/* {
                          noOfPage > 1 &&
                          <div className='pagination-inner'>
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                { 
                                  Array(noOfPage).fill().map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                    { 
                                      (offset > 0 && i === 0) &&
                                      <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => {setOffset(offset - limit); setCurrentPage(currentPage - 1)}}>
                                          <i className="fa-regular fa-arrow-left"></i>
                                        </a>
                                      </li>
                                    }
                                    {
                                      currentPage === (i + 1) &&                              
                                      <li className="page-item active">
                                        <a className="page-link" href="#">{ i + 1 }</a>
                                      </li>
                                    }
                                    {
                                      currentPage !== (i + 1) &&
                                      <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => {setOffset(limit*i); setCurrentPage(i + 1)}}> 
                                        { i + 1 }</a>
                                      </li>
                                    }
                                    { 
                                      (currentPage !== noOfPage && (i + 1) === noOfPage) &&
                                      <li className="page-item">
                                        <a className="page-link" href="#" onClick={() => {setOffset(offset + limit); setCurrentPage(currentPage + 1)}}>
                                          <i className="fa-regular fa-arrow-right"></i>
                                        </a>
                                      </li>
                                    }
                                    </Fragment>
                                  )
                                  })
                                }
                              </ul>
                            </nav>
                          </div>
                        } */}
        </div>
      </div>
    </section>
  );
};

export default SecBillings;

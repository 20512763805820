import React,{ useRef, useState } from 'react';
import ReactPlayer from 'react-player'
//import video from '../../Video/video.mp4';

const SecStep = ({steps,settings}) => {
  // const vidRef = useRef(null);
  // const [videoIcon,setVideoIcon] = useState('fa-solid fa-fw fa-pause');
  // let VideoStatus = true;
  // const handlePlayVideo = () => {
  //   if(VideoStatus){
  //     vidRef.current.pause();
  //     VideoStatus = false;
  //     setVideoIcon('fa-solid fa-fw fa-play');
  //   }else{
  //     vidRef.current.play();
  //     VideoStatus = true;
  //     setVideoIcon('fa-solid fa-fw fa-pause');
  //   }
  // }

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (    
    <section className="step-section">
    <div className="container"> 
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-8">
            <div className="step-video">
              {
                settings?.video && 
                <div className="myVideo">
                  <ReactPlayer
                    playing={true}
                    //loop={true}
                    playsinline={true}
                    controls={true}
                    muted={true}
                    onReady={onLoadedData}
                    className='react-player'
                    url={settings?.video}
                    width='100%'
                    height='100%'
                  />
                {/* <video 
                  autoPlay
                  ref={vidRef}
                  width='100%'
                  height='100%'>
                  <source src={settings?.video} type="video/mp4" />
                </video>
                <div className="video-action">
                  <button type="button" className="btn-action" onClick={handlePlayVideo}>
                    <i className={videoIcon}></i>
                  </button>
                </div> */}
              </div>
              }
            </div>
          </div>
          <div className="col-md-8 col-lg-4 offset-md-2 offset-lg-0">
            <div className="steps">
            {
              steps.length > 0 &&
              steps.map((item, index) => 
              <div className="steps-item" key={index}>
                <div className="step-icon"><i className={item?.icon}></i></div>
                <div className="step-content">
                  <div className="step-number">Step 0{index+1}</div>
                  <h2 className="step-title">{item?.name}</h2>
                  <div className="step-text">{item?.description}</div>
                </div>
              </div>
              )
            } 
            </div>
          </div>
      </div>
    </div>
</section>
  );
};

export default SecStep;
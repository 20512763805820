import React, { useState, useEffect } from "react";
import SecTickets from "../components/Dashboard/SecTickets";
import UserSidepanel from "../shared/userSidepanel";
import { getSiteSettings, checkDriverVehicle } from "../actions/site";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
const Tickets = () => {
  const [siteSettings, setSiteSettings] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Settings
    const loadSiteSettingsData = async () => {
      try {
        const data = await getSiteSettings({});
        if (data.status == 200) {
          setSiteSettings(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadSiteSettingsData();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispatch(async () => {
        try {
          const data = await checkDriverVehicle();
          if (data.status == 200) {
            if (data.step == 1) {
              return navigate("/add-driver");
            } else if (data.step == 2) {
              return navigate("/add-plate");
            }
          } else {
            return navigate("/add-driver");
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);

  return (
    <section className="sec-ticket login-part-pages">
      <div className="container">
        <div className="row user-panel-menus">
          <div className="left-sidebar">
            <UserSidepanel />
          </div>
          <div className="right-sidebar">
            <SecTickets settings={siteSettings} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tickets;

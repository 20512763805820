import React from 'react';

const Hero = ({banner}) => {
  return (
    <section className="pages-banner">
    <div className="container">
        <div className="row justify-content-between">
            <div className="col-md-5">
                <h2 className="page-title">{banner?.title}</h2>
                <div className="page-banner-content" dangerouslySetInnerHTML={{__html:banner?.description}}></div>
            </div>
            <div className="col-md-5">
                <div className="pages-banner-img">
                {
                    banner?.image && 
                    <img src={banner?.image} alt="" />
                }
                </div>
            </div>
        </div>
    </div>
</section>
  );
};

export default Hero;
export const fetchData = async (method, host, body, signal = null, customHeaders = {}) => {
  return (
    await fetch(host, {
      method,
      headers: new Headers({
        // 'mode':'no-cors',
        //'Access-Control-Allow-Origin': '*',
        'Content-Type': "application/json",
        'Accept': 'application/json',
        ...customHeaders,
        authorization: 'Bearer '+localStorage.getItem('access_token')
      }),
      body: (method !== 'GET') ? JSON.stringify(body) : null,
      signal: signal ? signal : null
    })
  )
}
import React, { useState, useEffect } from 'react';
import TicketDetailsModal from '../../components/TicketDetailsModal';
import PlateDetailsModal from '../../components/PlateDetailsModal';
import TlcTicketDetailsModal from '../../components/TlcTicketDetailsModal';
import toastr from 'toastr';
import { 
  getSearchTicket
 } from '../../actions/ticket';

const SecSearch = ({states,settings}) => {
  const [plate_no, setPlateNo] = useState('');
  const [ticket_id, setTicketId] = useState(''); 
  const [state, setState] = useState('');
  const [searchState, setSearchState] = useState([]);
  const [displayState,setDisplayState] =  useState('');
  const [plateInfo,setPlateInfo] = useState({});
  const [ticketInfo,setTicketInfo] = useState({});
  const [searchBy,setSearchBy] = useState('plate');
  const [isSearchPlate,setIsSearchPlate] = useState(false);
  const [isSearchTicket,setIsSearchTicket] = useState(false);
  const [isSearchTlcTicket,setIsSearchTlcTicket] = useState(false);

  const [tlc_ticket_id,setTlcTicketId] = useState('');
  const [tlcTicketInfo,setTlcTicketInfo] = useState('');
  
  const handleChangeState = (event) => {
    let searchStates = states.filter((item) => { 
        if(item.state_code){ 
            return item.state_code.toLowerCase().includes(event.target.value.toLowerCase()) || item.name.toLowerCase().includes(event.target.value.toLowerCase())
        }
    })
    setSearchState(searchStates);
  }

  const getState = (id,code) => {
    setState(id);
    setDisplayState(code);
  }

  const onSearchBy = type => {
    setSearchBy(type);
    if(type === 'plate'){
      setTicketId('');
      setTlcTicketId('');
    }else if(type === 'ticket'){
      setTlcTicketId('');
      setPlateNo('');
      setState('');
      setDisplayState('');
    }else{
      setTicketId('');
      setPlateNo('');
      setState('');
      setDisplayState('');
    }
  }

  const onSearchTicketByPlate = async () => {
    setPlateInfo({});
    setIsSearchPlate(true);
    try {
        let response = await getSearchTicket({
            search_by:searchBy,
            plate_no:plate_no.toUpperCase().replace(/ /g,''),
            state:displayState,
            type : 'dmv',
        });
        if (response.status === 200) {
            setPlateInfo(response.data);
            setTicketId('');
            setTlcTicketId('');
            setIsSearchPlate(false);
        }else {
            toastr.error(response.message);
            setIsSearchPlate(false);
        }
    } catch (error) {
        toastr.error(error.message);
        setIsSearchPlate(false);
    }
  }

  const onSearchTicketByNumber = async () => {
    setTicketInfo({});
    setIsSearchTicket(true);
    try {
        let response = await getSearchTicket({
            search_by:searchBy,
            ticket_id:ticket_id,
            type : 'dmv',
        });
        if (response.status === 200) {
            setTicketInfo(response.data[0]);
            setTlcTicketId('');
            setPlateNo('');
            setState('');
            setDisplayState('Select State');
            setIsSearchTicket(false);
        }else {
            toastr.error(response.message);
            setIsSearchTicket(false);
        }
    } catch (error) {
        toastr.error(error.message);
        setIsSearchTicket(false);
    }
  }

  const onSearchTlcTicketByNumber = async () => {
    setTlcTicketInfo({});
    setIsSearchTlcTicket(true);
    try {
        let response = await getSearchTicket({
            search_by:searchBy,
            ticket_id:tlc_ticket_id,
            type : 'tlc',
        });
        if (response.status === 200) {
            setTlcTicketInfo(response.data[0]);
            setTicketId('');
            setPlateNo('');
            setState('');
            setDisplayState('Select State');
            setIsSearchTlcTicket(false);
        }else {
            toastr.error(response.message);
            setIsSearchTlcTicket(false);
        }
    } catch (error) {
        toastr.error(error.message);
        setIsSearchTlcTicket(false);
    }
  }

  useEffect(() => {
    if(states.length){
        setSearchState(states);
    }
  }, [states.length]); 
  
  

  return (
    <>
    <section className="section-search" id="searchDiv">
    <div className="container">
      <div className="row d-flex align-items-center">
        {/* <div className="overlay-section">
            Coming Soon
        </div> */}
        <div className="col-lg-6">
          <div className="search">
            <h2 className="search-title">Pay Your Parking Tickets Right Now</h2>
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="plate-tab" data-toggle="tab" href="#plate" role="tab" aria-controls="plate" aria-selected="true" onClick={() => onSearchBy('plate') }>Search by Plate</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="summons-tab" data-toggle="tab" href="#summons" role="tab" aria-controls="summons" aria-selected="false" onClick={() => onSearchBy('ticket') }>Search by Parking</a>
              </li>
              <li className="nav-item">
              <a className="nav-link" id="summons-tab" data-toggle="tab" href="#tlc-summons" role="tab" aria-controls="summons" aria-selected="false" onClick={() => onSearchBy('tlc_ticket') }>Search by TLC</a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="plate" role="tabpanel" aria-labelledby="plate-tab">
                <div className="search-plate-content">
                  <form className="form-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="control-label required">License Plate</div>
                          <input 
                          type="text" 
                          className="form-control" 
                          placeholder="License Plate Number"
                          value={plate_no}
                          onChange={(e) => setPlateNo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="control-label required">State Registered</div>
                          <div className="dropdown state-dropdown">
                            <button className="dropdown-toggle" type="button" data-toggle="dropdown">{(displayState)?displayState:'Select State'}</button>
                            <div className="dropdown-menu">
                              <div className="dropdown-search">
                                <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Search" 
                                onChange={handleChangeState}
                                />
                              </div>
                              <div className="dropdown-scroll" id="search-state">
                                {
                                  searchState.length > 0 &&
                                  searchState.map((item,index) => (
                                    <button 
                                    key={index}
                                    type="button" 
                                    className="dropdown-item" 
                                    data-value={item?.state_code}  
                                    data-name={item?.name}
                                    onClick={() => getState(item?._id,item?.state_code)} 
                                    >{item?.state_code} - {item?.name}</button>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <button 
                          className="btn-action" 
                          type="button" 
                          onClick={() => onSearchTicketByPlate() } 
                          data-toggle="modal" 
                          data-target="#ModalPlateDetails"
                          ><i className={(isSearchPlate)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-search mr-2"}></i> Search Ticket</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="tab-pane fade" id="summons" role="tabpanel" aria-labelledby="summons-tab">
                <div className="summon-content">
                  <form className="form-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="control-label required">Summons Number</div>
                          <input 
                          type="text" 
                          className="form-control" 
                          placeholder="Summons Number"
                          value={ticket_id}
                          onChange={(e) => setTicketId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <button 
                          className="btn-action" 
                          type="button" 
                          onClick={() => onSearchTicketByNumber() } 
                          data-toggle="modal" 
                          data-target="#ModalTicketDetails"
                          ><i className={(isSearchTicket)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-search mr-2"}></i> Search Ticket</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="tab-pane fade" id="tlc-summons" role="tabpanel" aria-labelledby="summons-tab">
                <div className="summon-content">
                  <form className="form-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="control-label required">Ticket Number</div>
                          <input 
                          type="text" 
                          className="form-control" 
                          placeholder="Ticket Number"
                          value={tlc_ticket_id}
                          onChange={(e) => setTlcTicketId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <button 
                          className="btn-action" 
                          type="button" 
                          onClick={() => onSearchTlcTicketByNumber() } 
                          data-toggle="modal" 
                          data-target="#ModalTlcTicketDetails"
                          ><i className={(isSearchTlcTicket)?'fa-regular fa-spinner fa-spin mr-2':"fa-regular fa-search mr-2"}></i> Search Ticket</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="search">
            <div className="search-image"><img src="assets/img/bg-payment.svg" alt=""/></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {
    (searchBy === 'ticket' && ticket_id) &&
    <TicketDetailsModal 
    ticketInfo={ticketInfo}
    isSearchTicket={isSearchTicket}
    />
  }
  {
    (searchBy === 'tlc_ticket' && tlc_ticket_id) &&
    <TlcTicketDetailsModal 
    ticketInfo={tlcTicketInfo} 
    settings={settings}
    isSearchTlcTicket={isSearchTlcTicket}
    ticket_id = {tlc_ticket_id}
    />
  }
  {
    (searchBy === 'plate' && plate_no && state) &&
    <PlateDetailsModal 
    plateInfo={plateInfo} 
    isSearchPlate={isSearchPlate}
    plate_no={plate_no} 
    state={state}
    displayState={displayState}
    />
  }
  </>
  );
};

export default SecSearch;

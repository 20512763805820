import React, { useState, useEffect } from 'react';
import {
  getPageDetails,
  getSeo,
} from '../actions/site';
import {Helmet} from "react-helmet";
const PrivacyPolicy = () => {
  const [pageId,setPageId] = useState({});
  const [page,setPages] = useState({});
  const [seo,setSeo] = useState({});
  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'privacy-policies'
        });  
        if(data.status == 200) {
          setPages(data.data)
          setPageId(data.data._id)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();
  },[]);
  useEffect(() => {
    //Seo
    const loadSeoData = async () => {
        try {            
          const data = await getSeo({
            type:'page',
            typeId:pageId,
          });  
          if(data.status == 200) {
              setSeo(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadSeoData();
  },[pageId]); 
  return (<>
    <Helmet>
            <meta charSet="utf-8" />
            <meta name="title" content={seo?.meta_title}></meta>
            <meta name="author" content={seo?.author}></meta>
            <meta name="keywords" content={seo?.keywords}></meta>
            <meta name="description" content={seo?.description}></meta>
            </Helmet>
    <section className="privacy-section">
      <div className="container">
        <div className="title-part">
          <h2 className="title">{page?page.title:'Privacy Policy'}</h2>
        </div>
        <div className="row">
          <div className="col-md-12" dangerouslySetInnerHTML={{__html:page?.description}}>
            {/* <h6 className="privacy-sub-head">Last Updated: February 02, 2015</h6>
            <div className="content-part">
            <p>
            This Privacy Policy explains how information about you is collected, used and disclosed by Defendit, Inc. (“Defendit”) in connection with mobile app and website operated and located at <a href="https://defendit.io" target="_blank">https://defendit.io</a> and any related Defendit websites, mobile sites or apps that link to this Privacy Policy (collectively the “Site”).
            </p>
            <h6>
              <b>Collection of your Personal Information</b>
            </h6>
            <p>
            Defendit may collect personally identifiable information, such as your name, email, address and phone number. If you purchase Defendit’s services, we also collect billing and credit card information. This information is used to complete the purchase transaction. We may gather additional personal or non-personal information in the future.
            </p>
            <p>
            Information about your computer hardware and software and mobile device may be automatically collected by Defendit. This information can include: your IP address, browser type, device identifiers, mobile network information, domain names, access times and referring website addresses. This information is used for purposes such as the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Defendit website.
            </p>
            <p>
            We may also obtain information from other sources and combine that with information we collect through our Sites. For example, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, account information and friends lists, in accordance with the authorization procedures determined by such social media site.
            </p>
            <p>
            Defendit encourages you to review the privacy statements of websites you choose to link to from Defendit so that you can understand how those websites collect, use and share your information. Defendit is not responsible for the privacy statements or other content on websites outside of the Defendit website.
            </p>
            <h6>
              <b>Use of your Personal Information</b>
            </h6>
            <p>
            Defendit collects and uses your personal information for the purposes described in this Policy or elsewhere on the Site. For example, Defendit may use personal information it collects:
            </p>
          
              <ul>
                <li>to operate and facilitate your use of the Site and deliver the services you have requested;</li>
                <li>to respond to your communications and requests, provide customer service, request feedback, and otherwise contact you about your use of Defendit;
                </li>
                <li>to inform you of other products or services available from Defendit and its affiliates and to otherwise provide you with news and information about events, activities, offers, promotions, products, and services we think will be of interest to you;</li>
                <li>conduct research about your opinion of current services or of potential new services that may be offered; and
                </li>
                <li>to monitor and analyze Site usage and trends and to keep track of the websites, pages and activities of our users visit within Defendit, in order to determine what Defendit services are the most popular in order to deliver customized content and advertising within Defendit.
                </li>
              </ul>
            <h6>
              <b>Sharing of Your Personal Information</b>
            </h6>
            <p>
            Defendit does not share the personal information collected through the Site with third parties, except as described in this Policy. For example, Defendit may share personal information with consultants, and other service providers who need access to such information to carry out their work for us, such as to help perform statistical analysis, send you email or postal mail, provide customer support, or process payments. All such third parties are authorized to use your personal information only to provide these services to you and to Defendit.
            </p>
            <p>
            In addition, Defendit may share personal information as follows:
            </p>
            <ul>
              <li>when you give Defendit your consent to do so, including if Defendit notifies you that the information you provide will be shared in a particular manner and you provide such information;
              </li>
              <li>if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Defendit or the site; (b) protect and defend the rights or property of Defendit; or, © act under exigent circumstances to protect the personal safety of users of Defendit, or the public; and
              </li>
              <li>in connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation where personal information may be disclosed or transferred as one of the business assets of Defendit.</li>

            </ul>
            <p>
            Defendit may also share information about you and your purchases in aggregated or anonymized form that does not directly identify you.
            </p>
            <h6><b>Social Sharing</b></h6>
            <p>
            The Site may offer social sharing features and other integrated tools (such as the Facebook “Like” button), which let you share actions you take on our Site with other media, and vice versa. The use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.
            </p>
            <h6>
              <b>Use of Cookies</b>
            </h6>
            <p>
            The Defendit website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
            </p>
            <p>
            One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page.  This simplifies the process of recording your personal information, such as billing addresses, and so on. When you return to the same Defendit website, the information you previously provided can be retrieved, so you can easily use the Defendit features that you customized.
            </p>
            <p>
            You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. Removing or rejecting browser cookies does not necessarily affect third party flash cookies which may be used in connection with Defendit. To delete or disable flash cookies please visit<a href="https://www.adobe.com/devnet-docs/acrobatetk/index.html" target="_blank">www.adobe.com/products/flashplayer/security</a>  for more information. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Defendit services or websites you visit.
            </p>
            <h6><b>Security of your Personal Information</b></h6>
            <p>
            Defendit takes reasonable measures to protect your personal information from unauthorized access, use or disclosure.
            </p>
            <h6><b>Opt-Out & Unsubscribe</b></h6>
            <p>
            We give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving promotional communications from Defendit by contacting us here: support@defendit.com.
            </p>
            <h6><b>Changes to this Statement</b></h6>
            <p>Defendit will occasionally update this Statement of Privacy to reflect company and customer feedback. Defendit encourages you to periodically review this Statement to be informed of how Defendit is protecting your information.</p>
            <h6><b>Contact Information</b></h6>
            <p>
            Defendit welcomes your questions or comments regarding this Statement of Privacy. If you believe that Defendit has not adhered to this Statement, please contact Defendit at:
            </p>
            <div className="details">
              <h4><b>Defendit, Inc. </b></h4>
              <h6>36-13 32nd Street, </h6>
              <h6>Astoria NY 11106</h6>
              <h6>Email Address: info@defendit.io</h6>
            </div>
             
            </div> */}

          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default PrivacyPolicy;
import React from 'react';
import { connect } from "react-redux";

const SecNotification = ({notifications, settings}) => {
    //console.log(notifications);

    const dateFormat = issue_date => {
    return new Intl.DateTimeFormat('en',{
            //weekday: 'long',
            month: 'short',//long
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h23',
            hour12: true
        }
        ).format(new Date(issue_date));
    }

    return (
        <>
        <div className="dropdown-title">Notifications</div>
        <div className='notification-list'>
            <div className='notification-list-item'>
            {
                (notifications && notifications.length>0) &&
                notifications.map((item, index) => (
                <div className={`notification-item ${(item.read && item.read === 1)?'read':'unread'}`} key={index} >
                    <div className='notification-image'>
                    <img 
                    src={(item.sender_details)?item.sender_details.image:settings?.logo}
                    alt=""/>
                    </div>
                    <div className='cart-content'>
                        <div className='text'>{item.message}</div>
                        <div className='sub-text'>{(item.sender_details)?item.sender_details.name:settings?.title} . {item?.created_at ? dateFormat(item?.created_at) : ''}</div>
                    </div>
                </div>
                ))
            }   
            </div>
        </div>
        </>
    )
};

const mapStateToProps = state => {
    return {
      auth: state.auth,
      cart : state.cart,
      site: state.site
    }
}
export default connect(mapStateToProps, { })(SecNotification);
import React from 'react';
import SecReferralDetails from '../components/Referral/SecReferralDetails';
import UserSidepanel from '../shared/userSidepanel';
import { useParams } from 'react-router';

const Referral = () => {
    const { id } = useParams();
    return (
    <section className='sec-panel login-part-pages'>
        <div className='container'>
        <div className="row user-panel-menus">
          <div className="left-sidebar">
                    <UserSidepanel/>
                </div>
                <div className="right-sidebar">
                    <SecReferralDetails id={id} />
                </div>
            </div>
        </div>
    </section>
    );
};

export default Referral;
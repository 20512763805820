import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import {stripeCardList} from '../../actions/payment';
import {cartList,cartDelete} from '../../actions/cart';
import {Elements} from '@stripe/react-stripe-js';
//import {Elements} from 'react-stripe-elements';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../../components/Checkout/CheckoutForm';
import { PayPalButton } from "react-paypal-button-v2";
import {storeInvoicePaypalData} from '../../actions/payment';
import { getSiteSettings } from '../../actions/site';
import { useNavigate } from "react-router";
import { stripePublishKey,paypalClientId } from '../../helpers';


const SecInvoice = ({invoice,invoice_no}) => {
    const navigate = useNavigate(); 
    const stripePromise = loadStripe(stripePublishKey());
    const [loading,setLoading] = useState(true);
    const [isAddCart,setIsAddCart] = useState(false);
    const [totalPrice,setTotalPrice] = useState(0);
    const [paymentType, setPaymentType] = useState('stripe');
    const [isUser,setIsUser] = useState('');

    const [settings, setSiteSettings] = useState({});
    const [credit_card_fees,setCreditCardFees] = useState('');
    const [platform_fees,setPlatformFees] = useState('');

    //console.log(invoice);
    

    const handleChange = (e) => {
        const { name, value } = e.target;    
        setPaymentType(value);
    };

    useEffect(() => {
        // For Credit card
        let cc_fees = 0;
        if(settings?.creadit_fee){            
            if(settings?.creadit_fee_type ==='percentage'){
                cc_fees = parseFloat(invoice?.amount)*parseFloat(settings?.creadit_fee)/100;
            }else{
                cc_fees = settings?.creadit_fee
            }
        }
        // For Platform
        let platform_fees = 0;
        if(settings?.processing_fee){            
            if(settings?.processing_fee_type ==='percentage'){
                platform_fees = parseFloat(invoice?.amount)*parseFloat(settings?.processing_fee)/100;
            }else{
                platform_fees = settings?.processing_fee
            }
        }
        setCreditCardFees(cc_fees.toFixed(2));
        setPlatformFees(platform_fees.toFixed(2));
        setTotalPrice((parseFloat(invoice?.amount)+parseFloat(platform_fees)+parseFloat(cc_fees)).toFixed(2)); 
    }, [settings]); 

    useEffect(() => {
        if(localStorage.getItem('access_token')){
            setIsUser(localStorage.getItem('access_token'))
        }else{
            setIsUser('')
        }

         // Settings
         const loadSiteSettingsData = async () => {
            try {            
              const data = await getSiteSettings({});  
              if(data.status == 200) {
                  setSiteSettings(data.data)
              }    
            } catch(err) {
              console.log(err.message);
            }
        }
        loadSiteSettingsData();
    }, []);     

    return (
        <section className=''>
            <div className='body-layout'>
                <div className='body-content'>
                    <div className='user-search-part'>
                        <div className='title'>Pay Invoice</div>                        
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-8'>
                    
                    <div className="payment-select">
                        <h2 className="title">Pay using</h2>
                        <div className="radio-group">
                            <div className="radio">
                                <input 
                                type="radio" 
                                className="radio-input" 
                                name="payment-type"
                                value="paypal"
                                checked={paymentType == 'paypal'}
                                onChange={ handleChange }
                                />
                                <div className="radio-label">
                                    <div className="text">Pay Pal</div>
                                    <div className="icon"><i className="fa-brands fa-fw fa-paypal"></i></div>
                                </div>
                            </div>
                            <div className="radio">
                                <input 
                                type="radio" 
                                className="radio-input" 
                                name="payment-type"
                                value="stripe"
                                checked={paymentType == 'stripe'}
                                onChange={ handleChange }
                                />
                                <div className="radio-label">
                                    <div className="text">Credit Card</div>
                                    <div className="icon"><i className="fa-regular fa-fw fa-credit-card"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {
                        (localStorage.getItem('access_token') && paymentType == 'stripe') && 
                        <>
                        <div className='add-new-payment' style={{display: isAddCart ? 'block' : 'none' }}>
                            <div className="title">Add new card</div>
                            <div className='payment-card'>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm 
                                    type="2" 
                                    price={invoice?.amount}
                                    invoice_no={invoice_no}
                                    />
                                </Elements>
                            </div>
                        </div>
                        </>
                    } */}
                    {
                        (paymentType == 'stripe') &&
                        <div className='payment-card'>
                            <Elements stripe={stripePromise}>
                                <CheckoutForm 
                                type="1" 
                                price={invoice?.amount}
                                invoice_no={invoice_no}
                                />
                            </Elements>
                        </div>
                    }
                    {
                        (paymentType === 'paypal') &&
                        <PayPalButton
                            amount={invoice?.amount}
                            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                            onSuccess={(details, data) => {
                                const loadPaypalData = async () => {
                                    try {            
                                        const data1 = await storeInvoicePaypalData({
                                            invoice_no:invoice_no,
                                            paymentData:data,
                                            paymentDetails : details,
                                        });  
                                        if(data1.status == 200) {
                                            return navigate("/success?txn="+ data1?.data?.transaction_id);      
                                        }    
                                    } catch(err) {
                                        console.log(err.message);
                                    }
                                }
                                loadPaypalData();
                            }}
                            options={{
                            clientId: paypalClientId()
                            }}
                        />
                    }
                </div>
                <div className='col-lg-4'>
                    {/* <div className="coupon-code">
                        <h2 className="title">Coupon</h2>
                        <div className="coupons-input">
                            <div className="input-wrap">
                                <input type="text" className="input-style" placeholder="Apply Coupon" />
                                <button type="button" className="action remove">Apply</button>
                            </div>
                        </div>
                    </div> */}
                    <div className='payment-option'>
                        <div className="title">Payment Summary</div>
                        <div className="payment-summary">
                            <div className="summary-list">
                                <div className="item">
                                    <div className="item-label">Price:</div>
                                    <div className="item-data">${invoice?.amount}</div>
                                </div>
                                {
                                    credit_card_fees >0 &&
                                    <div className="item">
                                        <div className="item-label">Credit Card Fee:</div>
                                        <div className="item-data">${credit_card_fees}</div>
                                    </div>
                                }
                                {
                                   platform_fees >0 &&  
                                   <div className="item">
                                        <div className="item-label">Platform Fee:</div>
                                        <div className="item-data">${platform_fees}</div>
                                    </div>
                                }
                            </div>
                            <div className="summary-total">
                                <div className="label">Total Amount</div>
                                <div className="data">${totalPrice}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    );
};

export default SecInvoice;
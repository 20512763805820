import React, { useState, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import { storeAuthorizationFrom } from "../../actions/ticket";
import toastr from "toastr";
import { useNavigate } from "react-router";
import InputMask from "react-input-mask";
import { convertToBase64, convertToBase64MultipleFile } from "../../helpers/base64FileHelper";
import Lightbox from "react-image-lightbox";
import ImageShowModal from "../ImageShowModal";
import { allowedExtensions } from '../../helpers';

const SecAuthorizationForm = ({ summons_number }) => {
  const allowedExts = allowedExtensions();
  const allowedExts2 = ["jpg", "jpeg", "png"];
  const [uploadImages, setUploadImages] = useState([]);
  const [searchUploadImages, setSearchUploadImages] = useState([]);
  const [ext, setExt] = useState([]);
  const [viewPdf, setViewPdf] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [viewLightBox, setViewLightBox] = useState({ photo: null, isOpen: false });
  const { photo, isOpen } = viewLightBox;

  const navigate = useNavigate();
  const [state, setState] = useState({ trimmedDataURL: null });
  const [sigPad, setSignpad] = useState({});

  const clear = () => {
    sigPad.clear();
    setState({ trimmedDataURL: null });
  };

  const trim = () => {
    if(sigPad.isEmpty()){
        sigPad.clear();
        setState({trimmedDataURL: null});
    }else{
        setState({trimmedDataURL: sigPad.getTrimmedCanvas()
            .toDataURL('image/png')})
    }
  }

  let { trimmedDataURL } = state;
  let array = [{ summons: "", license: "" }];
  const [name, setName] = useState("");
  const [attorney_to, setAttorneyTo] = useState("");
  const [respondent_print_name, setRespondentPrintName] = useState("");
  const [respondent_date, setRespondentDate] = useState("");
  const [attorney_name, setAttorneyName] = useState("");
  const [respondent_name, setRespondentName] = useState("");
  const [attorney_print_name, setAttorneyPrintName] = useState("");
  const [attorney_date, setAttorneyDate] = useState("");
  //const [tempArray, setTempArray] = useState();
  const [mainArray, setMainArray] = useState(array);

  const [frontDmv, setFrontDmv] = useState("");
  const [backDmv, setBackDmv] = useState("");
  const [frontTlc, setFrontTlc] = useState("");
  const [backTlc, setBackTlc] = useState("");

  useEffect(() => {
    if (currentId) {
      uploadImages.splice(currentId, 1);
      setSearchUploadImages(uploadImages);
      setCurrentId("");
    }
  }, [currentId]);

  useEffect(() => {
    if (uploadImages.length > 0) {
      setSearchUploadImages(uploadImages);
    }
  }, [uploadImages]);

  const onSubmit = async () => {
    try {
      let msg = await storeAuthorizationFrom({
        ticket_number: summons_number,
        respondent_signature: trimmedDataURL,
        name: name,
        attorney_to: attorney_to,
        summons: mainArray,
        respondent_print_name: respondent_print_name,
        respondent_date: respondent_date,
        attorney_date: attorney_date,
        attorney_name: attorney_name,
        respondent_name: respondent_name,
        attorney_print_name: attorney_print_name,
        upload_doc: uploadImages,
        front_dmv_license: frontDmv,
        back_dmv_license: backDmv,
        front_tlc_license: frontTlc,
        back_tlc_license: backTlc,
      });
      if (msg.status === 200) {
        toastr.success(msg.message);
        return navigate("/dashboard");
      } else {
        toastr.error(msg.message);
      }
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const addRows = async () => {
    if (mainArray.length <= 5) {
      setMainArray([...mainArray, { summons: "", license: "" }]);
    }
  };

  const removeRows = async (index) => {
    const arr = mainArray.filter((item, key) => key !== index);
    setMainArray(arr);
  };

  const updateSummons = (index) => (event) => {
    const newData = [...mainArray];
    newData[index].summons = event.target.value;
    setMainArray(newData);
  };

  const updateLicense = (index) => (event) => {
    const newData = [...mainArray];
    newData[index].license = event.target.value;
    setMainArray(newData);
  };

  const onFileChange = (e) => {
    let exts = [];
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i]) {
          const ext = e.target.files[i].type.split("/")[1];
          exts.push(ext);
          if (e.target.files.length == i + 1) {
            setExt(exts);
          }
          if (![...allowedExts].includes(ext)) {
            toastr.error("Please upload a valid file.");
          } else {
            var output = document.getElementById("doc-file-input");
            output.src = URL.createObjectURL(e.target.files[i]);
            output.onload = function () {
              URL.revokeObjectURL(output.src); // free memory
            };
          }
        }
      }
      convertToBase64MultipleFile(e.target.files, setUploadImages);
    }
  };

  const onFrontDMvFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts2].includes(ext)) {
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("front-dmv-license-file-input");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setFrontDmv);
      }
    }
  };

  const onBackDMvFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts2].includes(ext)) {
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("back-dmv-license-file-input");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setBackDmv);
      }
    }
  };

  const onFrontTlcFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts2].includes(ext)) {
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("front-tlc-license-file-input");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setFrontTlc);
      }
    }
  };

  const onBackTlcFileChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts2].includes(ext)) {
        toastr.error("Please upload a valid file.");
      } else {
        // getBase64(e.target.files[0]);
        var output = document.getElementById("back-tlc-license-file-input");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setBackTlc);
      }
    }
  };

  // var signatures = {
  //     JVBERi0: "application/pdf",
  //     R0lGODdh: "image/gif",
  //     R0lGODlh: "image/gif",
  //     iVBORw0KGgo: "image/png",
  //     "/9j/": "image/jpg"
  //   };

  // const detectMimeType = async (b64) => {
  //     for (var s in signatures) {
  //       if (b64.indexOf(s) === 0) {
  //         return signatures[s];
  //       }
  //     }
  // }

  return (
    <>
      <div className="authorization-form">
        {/* <div className='authorization-heading'>
            <div className='authorization-image'>
                <img src="/assets/img/300px-Nyctlc_logo.webp.png"/>
            </div>
            <div className='authorization-address'>
                <div className='text'>Aloysee Heredia Jarmoszuk, Commissioner Mohammed Akinlolu, <span>Asst. Commissione</span> </div>
                <div className='text'>Prosecution Unit<br></br>
                                    31-00 47th Avenue, 3rd Floor<br></br>
                                    Long Island City, NY 11101<br></br>
                                    +1-718-391-5584
                </div>
            </div>
        </div>
        <div className="card border-dark">
            <div className="card-header bg-warning border-dark">&nbsp;&nbsp;</div>
            <div className="card-body">
                <p className="card-text">In order to have an attorney or representative settle a summons with TLC on your behalf, you and the attorney or representative must complete this form. Please fill in all fields and retain a copy of the completed form for your records. TLC does not endorse or qualify any attorneys or representatives.</p>
            </div>
        </div>
        <div className='settlment-header'>Settlment Authorization Form</div> 
        <div className="card border-dark mb-3">
            <div className="card-body">
                <h5 className="card-title text-center certification">Certification of respondent</h5>
                <div className="card-text">
                    I,&nbsp;&nbsp; 
                    <input 
                    type="text" 
                    className="input-fild"
                    value={name}
                    onChange={(e) => setName(e.target.value)} 
                    />, certify that I am the respondent and/or owner or principal of the respondent entity named in the TLC summons(es) listed below. I hereby authorize &nbsp;&nbsp;
                     <input 
                    type="text" 
                    className="input-fild"
                    value={attorney_to}
                    onChange={(e) => setAttorneyTo(e.target.value)}  
                    />, an attorney or representative, to enter into a settlement agreement with TLC on my behalf in resolution of the summons(es) listed below. I further certify that the attorney or representative has informed me of all of the following:
                    <div className='row mt-3'>
                        <ol>
                            <li>The attorney or representative is not an employee of TLC</li>
                            <li>I have a right to a hearing and am not required to settle my summons(es) with TLC.</li>
                            <li>I may appear at TLC to settle my summons(es) without an attorney or representative.</li>
                            <li>I will be responsible for the payment of any fines as listed on the settlement agreement(s).</li>
                            <li>All TLC settlement agreements include a guilty plea that will count toward the calculation of future penalties.</li>
                        </ol>
                    </div>
                    <div className='form-group mb-5'>
                        {
                            (mainArray && mainArray.length > 0) &&
                            mainArray.map((item, index) => (
                            <div className='row' key={index}>
                                <div className='col-sm-5'>
                                    <div className='driver-info-title'>Summons #</div>
                                    <div className='driver-input'> 
                                    <input 
                                    className='form-control summons mb-2' 
                                    type="text" 
                                    value={mainArray[index].summons}
                                    onChange={updateSummons(index)}
                                    /></div>
                                </div>
                                <div className='col-sm-5'>
                                    <div className='driver-info-title'>License #</div>
                                    <div className='driver-input mb-2'> 
                                    <input 
                                    className='form-control summons' 
                                    type="text" 
                                    value={mainArray[index].license}
                                    onChange={updateLicense(index)}
                                    /></div>
                                </div>
                                <div className='col-sm-2'>
                                    <div className='driver-info-title'>&nbsp;</div>
                                    <div className='driver-input'>
                                    {
                                        (index && index != 0)?<button type="button" className="close-btn btn-sm" onClick={() => removeRows(index)}><i className="fa-regular fa-times"></i></button>:
                                        <button type="button" className="add-btn btn-sm" onClick={() => addRows()}><i className="fa-regular fa-plus"></i></button>
                                    }
                                    </div>
                                </div> 
                            </div>
                            ))
                        }                        
                    </div>

                    <div className='form-group'>
                        <div className='row'>
                            <div className='col-sm-4 date'> 
                            
                            </div>
                            <div className='col-sm-4 date'>                                
                                <div className='driver-input mb-2'> 
                                <input 
                                className='form-control input-fild' 
                                type="text" 
                                value={respondent_print_name}
                                onChange={(e) => setRespondentPrintName(e.target.value)}
                                /></div>
                                <div className='driver-info-title'>Print Name</div>
                            </div>
                            <div className='col-sm-4 date'>                                
                                <div className='driver-input mb-2'> 
                                <InputMask 
                                mask="99/99/9999" 
                                maskChar={null} 
                                value={respondent_date}
                                className="input-fild fild"
                                onChange={(e) => setRespondentDate(e.target.value)} 
                                 />
                                </div>
                                <div className='driver-info-title'>Date</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card border-dark">
            <div className="card-body">
                <h5 className="card-title text-center certification">Certification of Attorney/Representative</h5>
                <div className="card-text">
                    I,&nbsp;&nbsp; 
                    <input 
                    type="text" 
                    className="input-fild" 
                    value={attorney_name}
                    onChange={(e) => setAttorneyName(e.target.value)}
                    />, hereby certify that I have been authorized by &nbsp;&nbsp;
                     <input 
                    type="text" 
                    className="input-fild" 
                    value={respondent_name}
                    onChange={(e) => setRespondentName(e.target.value)}
                    />, the respondent and/or owner or principal of the respondent entity named in the above TLC summons(es), to enter into a settlement agreement with TLC in resolution of the above summons(es). I also acknowledge that I am aware of and have explained all of the terms of the settlement agreement(s) to the above-named respondent.
                    
                    <p>Furthermore, I have been warned that any willful false statements made on or in connection with this form are punishable by fine or imprisonment, or both, under New York Penal Law Section 175.30. I declare that all statements made of my knowledge are true, and all statements made based on information and belief are believed to be true.</p>

                    <div className='form-group mt-5'>
                        <div className='row'>
                            <div className='col-sm-4 date'>                                
                                <div className='driver-input'> 
                                <input 
                                className='form-control input-fild mb-2' 
                                type="text" 
                                readOnly
                                /></div> 
                                <div className='driver-info-title'>Respondent Signature</div>                  
                            </div>
                            <div className='col-sm-4 date'>                                
                                <div className='driver-input mb-2'> 
                                <input 
                                className='form-control input-fild' 
                                type="text" 
                                value={attorney_print_name}
                                onChange={(e) => setAttorneyPrintName(e.target.value)}
                                /></div>
                                <div className='driver-info-title'>Print Name</div>
                            </div>
                            <div className='col-sm-4 date'>                                
                                <div className='driver-input mb-2'> 
                                <InputMask 
                                mask="99/99/9999" 
                                maskChar={null} 
                                className="input-fild fild"
                                value={attorney_date}
                                onChange={(e) => setAttorneyDate(e.target.value)} 
                                 />
                                </div>
                                <div className='driver-info-title'>Date</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="settlment-header">User can upload images of documents or take pictures of "Supporting Documents"</div>
        <div className="driver-info">
          <div className="notifications-option">
            <div className="registration-inner">
              <div className="registration-content">
                <div className="notifications-header">
                  <div className="image-upload">
                    <input type="file" className="image-upload-input" onChange={onFrontDMvFileChange} id="front-dmv-license-file-input" accept="image/*" />
                    <div className="registration-icon">
                      <i className="fa-fw fa-solid fa-id-card"></i>
                    </div>
                    <div className="registration-text">Front of DMV License</div>
                  </div>
                </div>
              </div>

              <div className="registration-content">
                <div className="notifications-header">
                  <div className="image-upload">
                    <input type="file" className="image-upload-input" onChange={onBackDMvFileChange} id="back-dmv-license-file-input" accept="image/*" />
                    <div className="registration-icon">
                      <i className="fa-fw fa-solid fa-id-card"></i>
                    </div>
                    <div className="registration-text">Back of DMV License</div>
                  </div>
                </div>
              </div>
              <div className="registration-content">
                <div className="notifications-header">
                  <div className="image-upload">
                    <input type="file" className="image-upload-input" onChange={onFrontTlcFileChange} id="front-tlc-license-file-input" accept="image/*" />
                    <div className="registration-icon">
                      <i className="fa-fw fa-solid fa-id-card"></i>
                    </div>
                    <div className="registration-text">Front of TLC License</div>
                  </div>
                </div>
              </div>
              <div className="registration-content">
                <div className="notifications-header">
                  <div className="image-upload">
                    <input type="file" className="image-upload-input" onChange={onBackTlcFileChange} id="back-tlc-license-file-input" accept="image/*" />
                    <div className="registration-icon">
                      <i className="fa-fw fa-solid fa-id-card"></i>
                    </div>
                    <div className="registration-text">Back of TLC License</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="driver-info pdf-content">
          <div className="notifications-option">
            <div className="registration-inner">
              {frontDmv && (
                <div className="driver-info-content">
                  <div className="driver-info-title">Front of DMV License Preview</div>
                  <div className="img-list">
                  <i
                          data-toggle="modal"
                          data-target="#ModalGalDelete"
                          className="fal fa-times-circle"
                          onClick={() => {
                            setFrontDmv("");
                          }}
                        ></i>
                    <div className="img">
                      <>
                        <img src={frontDmv} onClick={() => setViewLightBox({ photoIndex: frontDmv, isOpen: true })} />
                        
                      </>
                    </div>
                  </div>
                </div>
              )}

              {backDmv && (
                <div className="driver-info-content">
                  <div className="driver-info-title">Back of DMV License Preview</div>
                  <div className="img-list">
                  <i
                          data-toggle="modal"
                          data-target="#ModalGalDelete"
                          className="fal fa-times-circle"
                          onClick={() => {
                            setBackDmv("");
                          }}
                        ></i>
                    <div className="img">
                      <>
                        <img src={backDmv} onClick={() => setViewLightBox({ photoIndex: backDmv, isOpen: true })} />
                        
                      </>
                    </div>
                  </div>
                </div>
              )}

              {frontTlc && (
                <div className="driver-info-content">
                  <div className="driver-info-title">Front of TLC License Preview</div>
                  <div className="img-list">
                    <i
                      data-toggle="modal"
                      data-target="#ModalGalDelete"
                      className="fal fa-times-circle"
                      onClick={() => {
                        setFrontTlc("");
                      }}
                    ></i>
                    <div className="img">
                      <>
                        <img src={frontTlc} onClick={() => setViewLightBox({ photoIndex: frontTlc, isOpen: true })} />
                      </>
                    </div>
                  </div>
                </div>
              )}

              {backTlc && (
                <div className="driver-info-content">
                  <div className="driver-info-title">Back of TLC License Preview</div>
                  <div className="img-list">
                  <i
                          data-toggle="modal"
                          data-target="#ModalGalDelete"
                          className="fal fa-times-circle"
                          onClick={() => {
                            setBackTlc("");
                          }}
                        ></i>
                    <div className="img">
                      <>
                        <img src={backTlc} onClick={() => setViewLightBox({ photoIndex: backTlc, isOpen: true })} />
                       
                      </>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="driver-info">
          <div className="notifications-option">
            <div className="registration-inner">
              <div className="registration-content">
                <div className="notifications-header">
                  <div className="image-upload">
                    <input type="file" className="image-upload-input" onChange={onFileChange} id="doc-file-input" multiple />
                    <div className="registration-icon">
                      <i className="fa-fw fa-solid fa-id-card"></i>
                    </div>
                    <div className="registration-text">others documents</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="driver-info pdf-content">
          {searchUploadImages.length > 0 && (
            <div className="driver-info-content">
              <div className="driver-info-title">Image Preview</div>
              <div className="img-list">
                {searchUploadImages.map((item, index) => (
                  <div className="img" key={index}>
                    {(item && ext[index] === "pdf") || ext[index] === "PDF" ? (
                      <>
                        <img src="/assets/img/PDF_file_icon.svg.png" title={item} onClick={() => setViewPdf(item)} data-toggle="modal" data-target="#pdfModal" />
                        {/* <i 
                                className="fal fa-times-circle" 
                                onClick={() => {setCurrentId(index)}}
                                ></i> */}
                      </>
                    ) : (
                      <>
                        <img
                          src={item}
                          //onClick={() => setViewLightBox({ photoIndex:index, isOpen: true })}
                        />
                        {/* <i 
                                className="fal fa-times-circle" 
                                onClick={() => {setCurrentId(index)}}
                                ></i> */}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {viewPdf && (
          <div className="modal fade career-apply-modal pdf-modal " id="pdfModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" data-dismiss="modal" aria-label="close" className="modal-close" onClick={() => setViewPdf("")}>
                    <i className="fal fa-fw fa-times"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <object width="100%" height="100%" data={viewPdf} type="application/pdf">
                    <p>There was an error downloading the invoice.</p>
                  </object>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="settlment-header">Respondent Signature</div>
        <div className="signature-canvas mb-3">
          <SignaturePad
            penColor="green"
            canvasProps={{
              width: 800,
              height: 200,
              className: "sigCanvas",
            }}
            ref={(ref) => {
              setSignpad(ref);
            }}
          />
          <div className="submit-action">
            <button type="button" className="btn clear-btn btn-sm" onClick={clear}>
              {" "}
              Clear{" "}
            </button>
            <button type="button" className="btn trim-btn btn-sm" onClick={trim}>
              {" "}
              Next
            </button>
          </div>
          <div className="settlment-inner">
            {trimmedDataURL ? (
              <>
                <div className="settlment-title">Signature Preview</div>
                <img className="img-thumbnail" src={trimmedDataURL} />
              </>
            ) : null}
          </div>
        </div>
        <div className="justify-content-center">
          <button type="button" className="btn btn-success" onClick={onSubmit}>
            <i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Submit
          </button>
        </div>
      </div>
      {/* { isOpen && (<ImageShowModal photo={photo} isOpen={isOpen} setViewLightBox={setViewLightBox}/>)} */}
    </>
  );
};
export default SecAuthorizationForm;


import React, { useState, useEffect } from 'react';
import SecBanner from '../components/Auth/SecBanner';
import SecSignup from '../components/Auth/SecSignup';

import {
  getPageDetails,
  getBanners,

} from '../actions/site';
const Signup = () => {


  const [pageId,setPageId] = useState();
  const [pages,setPages] = useState({});
  const [banners,setBanners] = useState({});
  
  useEffect(() => {
    // Page
    const loadPageData = async () => {
      try {            
        const data = await getPageDetails({
          slug:'signup'
        });  
        if(data.status == 200) {
         
          setPageId(data.data._id)
          setPages(data.data)
        }    
      } catch(err) {
        console.log(err.message);
      }
    }
    loadPageData();
  }, [ ]); 
 
  useEffect(() => {
    //Banners
    const loadBannersData = async () => {
        try {            
          const data = await getBanners({
            type:'page',
            type_id:pageId
          });  
          if(data.status == 200) {
              setBanners(data.data)
          }    
        } catch(err) {
          console.log(err.message);
        }
    }
    loadBannersData();
  },[pageId]);
  return (
    <>
      <SecBanner banner={banners[0]}/>
      <SecSignup   pages={pages} />
    </>
  );
};

export default Signup;
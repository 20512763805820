let base64String = "";

export const convertToBase64 = (file, setImage) => {
  if(file instanceof File && file.name) {
    const reader = new FileReader();
    
    reader.onload = () => {
      base64String = reader.result;
      const imageBase64Stringsep = base64String;
      setImage(imageBase64Stringsep);
    }

    reader.readAsDataURL(file);
  }
}

export const convertToBase64MultipleFile = (files, setImage) => {
  let images=[];
  if(files.length>0){    
    for (let i = 0; i < files.length; i++) {
      if(files[i] instanceof File && files[i].name) {
        const reader = new FileReader();        
        reader.onload = () => {
          base64String = reader.result;
          const imageBase64Stringsep = base64String;
          images.push(imageBase64Stringsep);
          if(files.length == i+1){
            setImage(images);
          }
        }
        reader.readAsDataURL(files[i]);
      }
    }
    // console.log('final images', images)
    // setImage(images);
  }
  
}
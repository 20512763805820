import React from 'react';

const SecFeature = ({mission}) => {
  return (
    <section className="feature-section">
    <div className="container">
        <div className="feature-part">
            <div className="feature-list">
                {
                    mission.length > 0 &&
                    mission.map((item, index) => 
                    <div className="feature-box" key={index}>
                        <div className="icon">
                            <i className={item?.icon}></i>
                        </div>
                        <div className="feature-content">
                            <div className="title">{item?.name}</div>
                            <div className="description" dangerouslySetInnerHTML={{__html:item?.description}}></div>
                        </div>
                    </div>
                    )
                }                
            </div>
        </div>
        <div className="feature-descriptions-img">
            <img src="assets/img/hero-banner.svg" alt='' />
        </div>
    </div>

</section>

  );
};

export default SecFeature;
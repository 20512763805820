import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUser } from "../actions/login";
import AuthenticationModal from "./AuthenticationModal";
import LogoutModal from "../components/LogoutModal";
import SecNotification from "../components/Notification/SecNotification";
import UserSidepanel from "./userSidepanel";
import {cartList} from '../actions/cart';

const DefaultHeader = ({ socket, settings, getUser, cart,cartList }) => {
  const location = useLocation();

  socket.on("notificationListResponse", (data) => {
    setNotifications(data.list);
    setUnreadNotifications(data.unreadCount);
  });

  socket.on("notificationAddedResponse", (data) => {
    for (let index = 0; index < data.list.length; index++) {
      const element = data.list[index];
      // eslint-disable-next-line eqeqeq
      if (users._id && element.reciver_id == users._id) {
        setNotifications([element, ...notifications]);
        // eslint-disable-next-line eqeqeq
        if (notificationButtonEl.current.getAttribute("aria-expanded") == "true") {
          socket.emit("notificationRead", {
            notification_id: element._id,
            user_id: users._id,
          });
        } else {
          if (location.pathname !== "/notifications") {
            setUnreadNotifications(data.unreadCount);
          }
        }
      }
    }
  });

  socket.on("notificationReadResponse", (data) => {
    // eslint-disable-next-line eqeqeq
    if (users._id && data.data.user_id == users._id) {
      setUnreadNotifications(data.unreadCount);
    }
  });

  const notificationButtonEl = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  var [navigationShow, setNavigationShow] = useState(false);
  var [sidenavigationShow, setSidenavigationShow] = useState(false);
  const [users, setUsers] = useState("");
  const [modalActive, setModalActive] = useState(false);

  const [isOpen, setIsOpen] = useState("");
  const parems = new URLSearchParams(window.location.search).get("payment");
  const [activeMenu,setActiveMenu] = useState(false);
  // const location = useLocation();
  const pathname = location.pathname;
  const segment = pathname.substring(pathname.lastIndexOf("/") + 1);
  useEffect(() => {
    setIsOpen(segment);
  }, [segment]);

  useEffect(() => {
    if(modalActive){
      setTimeout(setModalActive(false), 5000)
    }
  }, [modalActive]);

  useEffect(() => {
    if(parems){
      setActiveMenu(true)
    }else if(segment === 'tlc-tickets'){
      setActiveMenu(true)
    }
  }, [parems]);

  const openMenu = () => {
    if(activeMenu){
      setActiveMenu(false)
    }else{
      setActiveMenu(true)
    }
  }

  useEffect(() => {
    const ac = new AbortController();
    const loadUserData = async () => {
      try {
        const data = await getUser(ac.signal);
        if (data.status === 200) {
          setUsers(data.data);
          setNavigationShow(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token")]);

  useEffect(() => {
    if (users) {
      socket.emit("notificationList", {
        user_id: users._id,
      });
    }
  }, [users]);

  useEffect(() => {
        // Cart List
        const loadCartList = async () => {
            try {            
                const data = await cartList({
                    cartId:localStorage.getItem('cartId'),
                });  
                if(data.status == 200) {
                   
                } 
            } catch(err) {
                console.log(err.message);
            }
        }
        loadCartList();
  }, [segment]);

  const notificationButtonClick = () => {
    socket.emit("notificationRead", {
      notification_id: "",
      user_id: users._id,
    });
    setUnreadNotifications(0);
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="header-strip">
            <div className="contact">
              {settings?.contact_no && (
                <a className="contact-item" href={settings && settings.contact_no ? "tel:1" + settings.contact_no : ""}>
                  <i className="fa-solid fa-fw fa-phone-arrow-down-left mr-2"></i>
                  <span>{settings?.contact_no}</span>
                </a>
              )}
              {settings?.email && (
                <a className="contact-item" href={settings && settings.email ? "mailto:" + settings.email : ""}>
                  <i className="fa-solid fa-fw fa-envelope-circle-check mr-2"></i>
                  <span>{settings?.email}</span>
                </a>
              )}
              {settings?.whatsapp_no && (
                <a className="contact-item" href={settings && settings.whatsapp_no ? "https://api.whatsapp.com/send?phone=1" + settings.whatsapp_no : ""}>
                  <i className="fa-brands fa-fw fa-whatsapp mr-2"></i>
                  <span>{settings?.whatsapp_no}</span>
                </a>
              )}
            </div>
            <div className="social">
              {settings?.facebook_link && (
                <a className="social-item" target="_blank" rel="nofollow noreferrer" href={settings?.facebook_link}>
                  <i className="fa-brands fa-fw fa-facebook"></i>
                </a>
              )}
              {settings?.twitter_link && (
                <a className="social-item" target="_blank" rel="nofollow noreferrer" href={settings?.twitter_link}>
                  <i className="fa-brands fa-fw fa-twitter"></i>
                </a>
              )}
              {settings?.instagram_link && (
                <a className="social-item" target="_blank" rel="nofollow noreferrer" href={settings?.instagram_link}>
                  <i className="fa-brands fa-fw fa-instagram"></i>
                </a>
              )}
            </div>
          </div>
          <div className="header-content">
            <div className="brand">
              <NavLink className="logo" to="/">
                <img src="/assets/img/logo.png" alt="" />
              </NavLink>
            </div>
          

            {/* {
          localStorage.getItem('access_token') && 
          <div className='side-action'>
              <a href='#' className='side-action' onClick={() => setSidenavigationShow(!sidenavigationShow)}><i className="fas fa-user-circle"></i></a>
          </div>
        } */}
            {/* {
          localStorage.getItem('access_token') && 
          <div className='side-action'>
              <a href='#' className='side-action' onClick={() => setSidenavigationShow(!sidenavigationShow)}><i className="fas fa-user-circle"></i></a>
          </div>
        } */}
            <div className={`navigation${navigationShow ? " show" : ""}`}>
              <div className="navigation-wrap">
                <div className="navigation-menu">
                  <div className="menu-item" onClick={() => setNavigationShow(false)}>
                    <NavLink to="/" className={(isActive) => "menu-link" + (!isActive ? " active" : "")}>
                      Home
                    </NavLink>
                  </div>
                  <div className="menu-item" onClick={() => setNavigationShow(false)}>
                    <NavLink to="/about-us" className={(isActive) => "menu-link" + (!isActive ? " active" : "")}>
                      About Us
                    </NavLink>
                  </div>
                  <div className="menu-item" onClick={() => setNavigationShow(false)}>
                    <NavLink to="/solutions" className={(isActive) => "menu-link" + (!isActive ? " active" : "")}>
                      Solutions
                    </NavLink>
                  </div>
                  <div className="menu-item" onClick={() => setNavigationShow(false)}>
                    <NavLink to="/career" className={(isActive) => "menu-link" + (!isActive ? " active" : "")}>
                      Career
                    </NavLink>
                  </div>
                  <div className="menu-item" onClick={() => setNavigationShow(false)}>
                    <NavLink to="/contact-us" className={(isActive) => "menu-link" + (!isActive ? " active" : "")}>
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                {/* <div className="cart-action">
              <NavLink to="/cart" className={isActive => "menu-link" + (!isActive ? " active" : "")}><i className="fa-solid fa-fw fa-cart-shopping"></i></NavLink>
            </div> */}
              </div>
            </div>
            {!localStorage.getItem("access_token") ? (
              <>
              <div className="authentication desktop">
                <button className="btn-login" type="button" data-toggle="modal" data-target="#authenticationModal" onClick={() => setModalActive(true)}>
                  Sign / Signup
                </button>
              </div>
              <div className="authentication mobile">
                <button className="btn-login" type="button" data-toggle="modal" data-target="#authenticationModal" onClick={() => setModalActive(true)}>
                <i class="fa-solid fa-circle-user"></i>
                </button>
              </div>
              </>
              
            ) : (
              <div className="authentication">
                <div className='side-action'>
                  <a href='#' className='side-action' onClick={() => setSidenavigationShow(!sidenavigationShow)}>
                    <div className="user-profile">
                      {users.image && (
                        <div className="profile-img">
                          <img src={users?.image} alt="" />
                        </div>
                      )}
                      {/* <div className="profile-text">{users?.name}</div> */}
                    </div>
                  </a>
                </div>
                <div className="dropdown">
                  <button type="button" onClick={() => setSidenavigationShow(!sidenavigationShow)} className="dropdown-toggle vehicle-input" data-toggle="dropdown">
                    <div className="user-profile">
                      {users.image && (
                        <div className="profile-img">
                          <img src={users?.image} alt="" />
                        </div>
                      )}
                      <div className="profile-text">{users?.name}</div>
                    </div>
                  </button>
                  <div className="dropdown-menu">
                    <div className="dropdown-scroll">
                      <Link to="/dashboard" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-fw fa-regular fa-tachometer-alt-fast"></i>
                          </div>
                          <div className="text">Dashboard</div>
                        </div>
                      </Link>
                      <Link to="/ticket-management" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-fw fa-regular fa-ticket"></i>
                          </div>
                          <div className="text">Tickets</div>
                        </div>
                      </Link>
                      <Link to="/driver-management" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-fw fa-regular fa-user-vneck"></i>
                          </div>
                          <div className="text">Drivers</div>
                        </div>
                      </Link>
                      <Link to="/plates" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-fw fa-regular fa-cars"></i>
                          </div>
                          <div className="text">Plates</div>
                        </div>
                      </Link>
                      <Link to="/billings" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-fw fa-regular fa-file-invoice-dollar"></i>
                          </div>
                          <div className="text">Billings</div>
                        </div>
                      </Link>
                      <Link to="/referral" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-fw fa-regular fa-user-group"></i>
                          </div>
                          <div className="text">Referral</div>
                        </div>
                      </Link>
                      <Link to="/user-profile" className="dropdown-item">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <div className="text">Profile</div>
                        </div>
                      </Link>
                      <button type="button" className="dropdown-item" data-value="" data-toggle="modal" data-target="#logoutModal">
                        <div className="profile-menu" onClick={() => setNavigationShow(false)}>
                          <div className="icon">
                            <i className="fa-regular fa-arrow-right-from-bracket"></i>
                          </div>
                          <div className="text">Logout</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className={`mobile-view sidenavigation${sidenavigationShow ? " show" : ""}`}>
                  <div className="side-panel">
                    <div className="side-menu">
                      <NavLink to="/dashboard" className={isOpen && (isOpen === "dashboard" || isOpen === "tickets") ? "menu-link active" : "menu-link"} onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Dashboard">
                          <i className="fa-regular fa-fw fa-tachometer-alt-fast"></i>
                        </div>
                        <div className="link-text">Dashboard</div>
                      </NavLink>

                      <div className="ticket-menus">
                        <a href='#' className={isOpen && ((isOpen === "ticket-management" && (parems === 'all' || parems === 'pending' || parems === 'paid')) || isOpen === "submit-ticket" || isOpen === "tlc-tickets") ? "menu-link active" : "menu-link"} onClick={() => openMenu()}>
                          <div className="link-icon" title="Ticket Management">
                            <i className="fa-regular fa-fw fa-ticket"></i>
                          </div>
                          <div className="link-text">Ticket Management</div>
                        </a>
                        {/* to="/ticket-management?payment=all" */}
                        <div className={`other-menus ${(activeMenu) ? 'active' : ''}`}>
                          <NavLink to="/tlc-tickets" className={`sub-menu ${isOpen && isOpen === "tlc-tickets" ? "selected" : ""}`} onClick={() => setSidenavigationShow(false)}>
                            <div className="link-icon" title="Ticket Management">
                              <i className="fal fa-clipboard-list-check mr-2"></i>
                            </div>
                            <div className="link-text">TLC</div>
                          </NavLink>
                          <NavLink to="/ticket-management?payment=pending" className={`sub-menu ${isOpen && (isOpen === "ticket-management" && parems === 'pending') ? "selected" : ""}`} onClick={() => setSidenavigationShow(false)}>
                            <div className="link-icon" title="Ticket Management">
                              <i className="fal fa-clipboard-list-check mr-2"></i>
                            </div>
                            <div className="link-text">DMV (Pending)</div>
                          </NavLink>
                          <NavLink to="/ticket-management?payment=paid" className={`sub-menu ${isOpen && (isOpen === "ticket-management" && parems === 'paid') ? "selected" : ""}`} onClick={() => setSidenavigationShow(false)}>
                            <div className="link-icon" title="Ticket Management">
                              <i className="fal fa-clipboard-list-check mr-2"></i>
                            </div>
                            <div className="link-text">DMV (Paid)</div>
                          </NavLink>
                          <NavLink to="/ticket-management?payment=all" className={`sub-menu ${isOpen && (isOpen === "ticket-management" && parems === 'all') ? "selected" : ""}`} onClick={() => setSidenavigationShow(false)}>
                            <div className="link-icon" title="Ticket Management">
                              <i className="fal fa-clipboard-list-check mr-2"></i>
                            </div>
                            <div className="link-text">DMV (All)</div>
                          </NavLink>
                        </div>
                      </div>
                      
                      <NavLink to="/driver-management" className={isOpen && (isOpen === "driver-management" || isOpen === "add-driver") ? "menu-link active" : "menu-link"} onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Driver Management">
                          <i className="fa-regular fa-fw fa-user-vneck"></i>
                        </div>
                        <div className="link-text">Driver Management</div>
                      </NavLink>
                      <NavLink to="/plates" className={isOpen && (isOpen === "plates" || isOpen === "add-plate") ? "menu-link active" : "menu-link"} onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Plate Management">
                          <i className="fa-regular fa-fw fa-cars"></i>
                        </div>
                        <div className="link-text">Plate Management</div>
                      </NavLink>
                      <NavLink to="/billings" className={isOpen && isOpen === "billings" ? "menu-link active" : "menu-link"} onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Billings">
                          <i className="fa-regular fa-fw fa-file-invoice-dollar"></i>
                        </div>
                        <div className="link-text">Billings</div>
                      </NavLink>
                      <NavLink to="/referral" className={isOpen && isOpen === "referral" ? "menu-link active" : "menu-link"} onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Billings">
                          <i className="fa-regular fa-fw fa-user-group"></i>
                        </div>
                        <div className="link-text">Referral</div>
                      </NavLink>
                      <NavLink to="/user-profile" className={isOpen && isOpen === "user-profile" ? "menu-link active" : "menu-link"} onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Profile">
                          <i className="fa-regular fa-circle-user"></i>
                        </div>
                        <div
                          className="link-text"
                          onClick={() => {
                            setIsOpen("user-profile");
                          }}
                        >
                          Profile
                        </div>
                      </NavLink>
                      <a href="#!" data-toggle="modal" data-target="#logoutModal" className="menu-link" onClick={() => setSidenavigationShow(false)}>
                        <div className="link-icon" title="Log Out">
                          <i className="fa-regular fa-arrow-right-from-bracket"></i>
                        </div>
                        <div className="link-text">Log out</div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={`side-overlay${sidenavigationShow ? " show" : ""}`} onClick={() => setSidenavigationShow(!sidenavigationShow)}></div>

              </div>
            )}
            <div className="cart-inner">
              <div className="cart-action">
                <NavLink to="/checkout" className="cart-btn">
                  <i className="fa-sharp fa-solid fa-cart-shopping"></i>
                  {cart && cart.count > 0 ? <span className="badge badge-icon">{cart.count}</span> : ""}
                </NavLink>
              </div>
              {localStorage.getItem("access_token") && (
                <div className="notifications-dropdown">
                  <div className="dropdown">
                    <button ref={notificationButtonEl} onClick={(e) => notificationButtonClick()} type="button" className="dropdown-toggle notifications-btn" data-toggle="dropdown">
                      <i className="fa-sharp fa-solid fa-bell"></i>
                      {unreadNotifications > 0 ? <span className="badge badge-icon">{unreadNotifications > 99 ? "99+" : unreadNotifications}</span> : ""}
                    </button>
                    <div className="dropdown-menu">
                      <SecNotification notifications={notifications} settings={settings} />
                      {notifications.length > 0 && (
                        <NavLink type="button" className="see-btn" to="/notifications">
                          <div className="text">See All</div>
                        </NavLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="responsive-action">
              <a href="#!" className="btn-menu" onClick={() => setNavigationShow(!navigationShow)}>
                <i className="far fa-fw fa-bars"></i>
              </a>
            </div>
            <div className={`menu-overlay${navigationShow ? " show" : ""}`} onClick={() => setNavigationShow(!navigationShow)}></div>
          </div>
        </div>
      </header>
      <div className="header-placeholder"></div>
      {/* {
    (localStorage.getItem('access_token') && sidenavigationShow) &&
    <UserSidepanel/>
  } */}
      {/* Login Modal */}
      <LogoutModal />
      <AuthenticationModal modalActive={modalActive} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cart: state.cart,
  };
};

export default connect(mapStateToProps, { getUser,cartList })(DefaultHeader);

import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";
import { CART_LIST,CART_DELETE} from "./type";

export const storeCart = body => async dispatch  => {
    try {
      const res = await fetchData('POST', `${BASE_URL}cart/add`, body);
      const resData = await res.json();
      if(resData.status === 200) {
        dispatch({ type: CART_LIST, payload: resData.count});
        return { 
          message: resData.message, 
          data: resData.data,
          status:resData.status,
          count:resData.count,
        };
      } else {
        throw new Error(resData.message);
      }
    } catch(err) {
      throw new Error(err.message);
    }
}

export const cartList = body => async dispatch  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}cart/list`, body);
    const resData = await res.json();
    if(resData.status === 200) {
      dispatch({ type: CART_LIST, payload: resData.count});
      return { 
        message: resData.message, 
        data: resData.data,
        status:resData.status,
        total : resData.total,
        count:resData.count,
        amounts:resData.amounts,
        fine_amount:resData.fine_amount,
        stateTax:resData.stateTax.reduce((partialSum, a) => partialSum + a, 0)
      };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const cartDelete = body => async dispatch  => {
  try {
    const res = await fetchData('POST', `${BASE_URL}cart/delete`, body);
    const resData = await res.json();
    if(resData.status === 200) {
      dispatch({ type: CART_LIST, payload: resData.count});
      return { 
        message: resData.message, 
        data: resData.data,
        status:resData.status,
        total : resData.total,
        count:resData.count,
        amounts:resData.amounts,
        fine_amount:resData.fine_amount,
        stateTax:resData.stateTax.reduce((partialSum, a) => partialSum + a, 0)
      };
    } else {
      throw new Error(resData.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}


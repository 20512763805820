import React, { useRef,useState, useEffect } from 'react';
import toastr from 'toastr';
import {saveReferral} from '../actions/referral';

const ReferredModal = ({setReferredCode}) => {

    const [referred_code, setReferredCoded] = useState();
    const [isSearchBtn, setIsSearchBtn] = useState(false);
    const  closeOneModal = () => {
        const modal = document.getElementById('ModalReferredDetails');
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        document.body.removeChild(modalBackdrops[0]);
    }
        const updateReferredCode = async () => {
          setIsSearchBtn(true)
            try {
            
                let msg = await saveReferral({  
                referredCode: referred_code
                });
                if (msg.status === 200) {
                  toastr.success(msg.message);
                  setReferredCoded(); 
                  setReferredCode(msg.data.referred_code);
              
                  closeOneModal();
                  setIsSearchBtn(false)
                } else {
                toastr.error(msg.message);
                setIsSearchBtn(false)
                }
            
            } catch (err) {
              setIsSearchBtn(false)
                toastr.error(err.message);
            }
        }

    return (
    <div className="modal fade career-apply-modal" id="ModalReferredDetails" tabIndex="-1" data-backdrop="static"
    data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="header-text">
                        <h5 className="modal-title" id="ModalCenterTitle">Referred Information</h5>
                    </div>
                    <button type="button" className="modal-close" data-dismiss="modal"><i
                        className="fa-light fa-fw fa-times"></i></button>
                </div>
                <div className="modal-body">                    
                    <div className='details'>
                        <div className='details-card'>
                        <div className='driver-info-content'>
                    <div className='driver-info-title'>Referred Code</div>
                    <div className='driver-input'>
                      <input className='form-control input' type="text"
                        value={referred_code}
                        onChange={(e) => setReferredCoded(e.target.value)}
                      />
                    </div>
                 </div>
                            
                        </div>
                    </div>
                </div>
                
                    <div className="modal-footer justify-content-center">
                        <button 
                        type="button"
                        className="btn-action"
                        onClick={updateReferredCode}
                        disabled={isSearchBtn}
                        ><i className="fa-regular fa-arrow-right-to-bracket mr-2"></i>Update
                        </button>
                    </div>
            </div>
        </div>
    </div>
    )
}

export default ReferredModal;
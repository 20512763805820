import React from 'react';

const SecWeDo = ({page}) => {
    //console.log(page);
  return (
    <section className="what-we-do-section">
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-md-5">
                <div className="image-inner">
                    {
                        page?.image && 
                        <img src={page?.image} alt='' />
                    }
                </div>
            </div>
            <div className="col-md-6">
                <div className="title">{page?.subtitle}</div>
                {/* <div className="sub-title">DMV Tickets And TLC Tickets</div> */}
                <div className="content" dangerouslySetInnerHTML={{__html:page?.description}}></div>
            </div>
        </div>
    </div>
</section>
  );
};

export default SecWeDo;
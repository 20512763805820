import { fetchData } from "../async/fetch";
import BASE_URL from "../config/host";

export const getReferralList = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}transaction/user-referral/list`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status ,total:data.total ,count:data.count};
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const saveReferral = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}change-referred-code`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { message: data.message, data: data.data,status:data.status ,count:data.count};
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

export const getReferralDetails = async (body, signal = null) => {
  try {
    const res = await fetchData('POST', `${BASE_URL}transaction/user-referral/details`, body, signal);
    const data = await res.json();
    if(data.status === 200) {
      return { 
        message: data.message, 
        data: data.data,
        status:data.status,
        count:data.count
      };
    } else {
      throw new Error(data.message);
    }
  } catch(err) {
    throw new Error(err.message);
  }
}

import React, { useState, useEffect,Fragment } from 'react';
import NothingFound from '../../shared/nothingFound';
import Searching from '../../shared/searching';
import ReactPaginate from 'react-paginate';
import { getReferralDetails } from '../../actions/referral';

const SecReferralDetails = ({id}) => {
    const [loading,setLoading] = useState(true);
    const [keyword,setKeyword] = useState('');
    const [offset,setOffset] = useState(0);
    const [totalDataCount,setTotalDataCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [noOfPage, setNoOfPage] = useState(0);
    const [details, setDetails] = useState({});
    const dateFormat = date => {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d); //numeric
        let da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);
        return `${(mo<=9)?'0'+mo:mo} ${(da<=9)?'0'+da:da},${ye}`;
    }
    const handlePageClick = (event) => {
        const newOffset = (event.selected * limit) % totalDataCount;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setOffset(newOffset);
    };
    useEffect(() => {
        const loadReferral = async () => {
          try {            
            const data = await getReferralDetails({
                keyword:keyword,
                id:id,
                sortByField:'created_at',
                sortByValue:'DESC',
                limit:limit,
                offset:offset
            });  
            if(data.status == 200) {
              setDetails(data.data);
              setTotalDataCount(data.count); 
            }  else{
              setTotalDataCount(0);  
            }
            setLoading(false); 
          } catch(err) {
            console.log(err.message);
          }
        }
        loadReferral();
    }, [keyword, offset]); 
    return ( 
        <section className='sec-billings sec-plate-management'>
                <div className='body-layout'>
                    <div className='body-content'>
                      <div className='user-search-part'>
                          <div className='title'>Referral Details</div>
                          <div className='submit-new-ticket'>                         
                            <div className='search'>
                                <button type="button" className='search-btn'><i className="fa-regular fa-fw fa-magnifying-glass"></i></button>
                                <input type="text" className='search-input' value={keyword} onChange={(e) => setKeyword(e.target.value)}  />
                            </div>                        
                          </div>
                      </div>
                        <div className='billings-inner'>
                          {totalDataCount >0 ?(
                            details.length > 0 &&
                            details.map((item, index) => (
                            <div className='billings-item' key={index}>
                                <div className='billings-hading'>
                                    <div className='billings-catagory'>
                                        <div className='billings-id'>
                                            <div className='billings-title'>Transaction ID</div>
                                            <div className='billings-sub-title'>{item?.payment_transaction}</div>
                                        </div>
                                    </div>
                                    <div className='billings-status'>
                                        <div className={"status-title " + item?.payment_status}>{item?.payment_status}</div>
                                    </div>
                                </div>
                                <div className='billings-content'>
                                    <div className='billings-description'>
                                    <div className='billings-id'>
                                            <div className='billings-title ticket'>Ticket Number</div>
                                            <div className='billings-sub-title ticket-no'>{item?.trans_details && item?.trans_details.length>0 && item?.trans_details.map(res => res.ticket_id).join(', ')}</div>
                                        </div>
                                        <div className='billings-payment'>
                                            <div className='payment-mode'>Payment Mode</div>
                                            <div className='payment-sub-mode'>{item?.payment_mode}</div>
                                        </div>
                                        <div className='billings-date'>
                                            <div className='payment-mode'>Date</div>
                                            <div className='payment-sub-mode'>{item?.created_at?dateFormat(item.created_at):""}</div>
                                        </div>
                                    </div>
                                    <div className='billings-price'>
                                        ${item?.referred_amount.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            ))
                            ):(loading?<Searching/>:<NothingFound/>)
                         }
                        </div>
                        {
                          noOfPage > 1 &&
                          <div className='pagination-inner'>
                            <nav aria-label="Page navigation example">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={noOfPage}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                              />
                            </nav>
                        </div>
                        }
                    </div>    
                </div>
        </section>
    );
};
export default SecReferralDetails;
import React, { useState, useEffect, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import { 
  getDriverList,
  deleteDriver
} from '../../actions/driver';
import NothingFound from '../../shared/nothingFound';
import Searching from '../../shared/searching';
import ReactPaginate from 'react-paginate';

const SecDriverManagement = () => {
  const [loading,setLoading] = useState(true);
  const [keyword,setKeyword] = useState('');
  const [drivers, setDrivers] = useState({});
  const [searchdDrivers, setSearchDrivers] = useState({});
  const [totalDataCount,setTotalDataCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentId, setCurrentId] = useState('');
  
  useEffect(() => {
      // Driver List
      const loadDrivers = async () => {
        try {            
          const data = await getDriverList({
              keyword:keyword,
              user_id:localStorage.getItem('userId'),
              limit:limit,
              offset:offset
          });  
          if(data.status == 200) {
            setDrivers(data.data)
            setSearchDrivers(data.data)
          }    
          setTotalDataCount(data.count);
          setLoading(false);
        } catch(err) {
          console.log(err.message);
        }
      }
      loadDrivers();
  }, [keyword,limit, offset]); 

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit))
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  const  closeOneModal = () => {
    const modal = document.getElementById('ModalDriverDelete');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
  }

  const onDelete = async (e) => { 
    e.preventDefault();   
    try {
      let msg = await deleteDriver({  
        id : currentId
      });       
      if(msg.status === 200){ 
        closeOneModal(); 
        let filteredArray = drivers.filter(item => item._id !== currentId);
        setSearchDrivers(filteredArray);
        toastr.success(msg.message);
      }else{
        toastr.error(msg.message);
      }      
    } catch(err) {
      toastr.error(err.message);
    }
  }

    return (
        <section>
        <div className='body-layout'>
        <div className='body-content'>
          <div className='user-search-part'>
          <div className="search-upper-part">
          <div className='title'>Drivers</div>
          <div className='add-vehicle-action'>
                <NavLink to="/add-driver" className='add-vehicle-btn'><i className="far fa-plus"></i> Add Driver</NavLink>
            </div>
          </div>
           
            <div className='submit-new-ticket'>
              <div className='search'>
                <button type="button" className='search-btn'><i className="fa-regular fa-fw fa-magnifying-glass"></i></button>
                <input 
                type="text" 
                className='search-input' 
                value={keyword} 
                onChange={(e) => setKeyword(e.target.value)} 
                />
              </div>
              
            </div>
          </div>
          <div className='driver-list-inner'>
            <div className='ticket-list'>
              <div className='ticket-table driver-management'>
                  <div className='table-head'>
                    <div className='table-row header-row'>
                      <div className='table-cell cell-image'>
                        <div className='table-title'>Name</div>
                      </div>
                      <div className='table-cell cell-email'>
                        <div className='table-title'>Email</div>
                      </div>
                        <div className="table-cell cell-name">
                            <div className="table-title">DMV License #</div>
                        </div>
                      <div className='table-cell cell-licence'>
                        <div className='table-title'>Commercial Licence #</div>
                      </div>
                      
                      <div className='table-cell drive-action'>
                        <div className='table-title'>Action</div>
                      </div>
                    </div>
                  </div>
                  <div className='table-body'>
                    {
                      totalDataCount >0 ? (
                      searchdDrivers.length > 0 &&
                      searchdDrivers.map((item, index) => (
                      <div className='table-row' key={index}>
                        <div className='table-cell cell-image'>
                          <div className='driver-content'>
                            <NavLink to={'/driver-details/'+item?._id} className='driver-list-title'>
                            {
                            item?.name ?
                            item.name:
                            item.first_name+' '+item.last_name
                            }
                            </NavLink>
                          </div>
                        </div>
                        <div className='table-cell cell-email'>
                          <div className='driver-list-title'>{item?.email_id}</div>
                        </div>
                          <div className="table-cell cell-name">{item?.license_number}</div>
                    
                        <div className='table-cell cell-licence'>
                          <div className='driver-list-title'>{(item.tlc_license)?item.tlc_license:'--'}</div>
                        </div>
                        
                        <div className='table-cell drive-action'>
                          <div className='dropdown'>
                            <i className='far fa-ellipsis-v btn-action' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'></i>
                            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                <NavLink to={'/edit-driver/'+item?._id} className='dropdown-item'><i className="fas fa-edit"></i> Edit</NavLink>
                                <NavLink to={'/driver-details/'+item?._id} className="dropdown-item"><i className="fas fa-eye"></i> View Details</NavLink>

                                <a href="!#" className='dropdown-item' data-toggle="modal" 
                                data-target="#ModalDriverDelete" onClick={() => {setCurrentId(item?._id)}}><i className="far fa-trash-alt"></i>Delete</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      ))
                      ):(loading?<Searching/>:<NothingFound/>)
                    } 
                  </div>
              </div>
              {
                noOfPage > 1 &&
                <div className='pagination-inner'>
                    <nav aria-label="Page navigation example">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={noOfPage}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                      />
                    </nav>
                </div>
              }
              
              {/* {
                noOfPage > 1 &&
                <div className='pagination-inner'>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                    { 
                      Array(noOfPage).fill().map((_, i) => {
                        return (
                        <Fragment key={i}>
                        { 
                          (offset > 0 && i === 0) &&
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={() => {setOffset(offset - limit); setCurrentPage(currentPage - 1)}}>
                              <i className="fa-regular fa-arrow-left"></i>
                            </a>
                          </li>
                        }
                        {
                          currentPage === (i + 1) &&                              
                          <li className="page-item active">
                            <a className="page-link" href="#">{ i + 1 }</a>
                          </li>
                        }
                        {
                          currentPage !== (i + 1) &&
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={() => {setOffset(limit*i); setCurrentPage(i + 1)}}> 
                            { i + 1 }</a>
                          </li>
                        }
                        { 
                          (currentPage !== noOfPage && (i + 1) === noOfPage) &&
                          <li className="page-item">
                            <a className="page-link" href="#" onClick={() => {setOffset(offset + limit); setCurrentPage(currentPage + 1)}}>
                              <i className="fa-regular fa-arrow-right"></i>
                            </a>
                          </li>
                        }
                        </Fragment>
                        )
                      })
                    }
                    </ul>
                  </nav>
                </div>
              } */}
          </div>
          </div>
        </div>
      </div>
      
      <div className="modal fade career-apply-modal delete-modal " id="ModalDriverDelete" tabIndex="-1" role="dialog" data-backdrop="static"
        data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div className="modal-content delete">
            <div className="modal-header">
              <h5 className="modal-title">Delete Driver</h5>
              <button type="button" data-dismiss="modal" aria-label="close" className="modal-close">
                <i className="fal fa-fw fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-text">You are trying to delete the driver, click "Confirm" below.</div>
            </div>
            <div className="modal-footer justify-content-center">
              <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
                Cancel
              </button>
              <a href="#!" className="confirm-btn" onClick={onDelete}>
                Confirm
              </a>
            </div>
          </div>
        </div>
      </div>
      </section>
    );
};

export default SecDriverManagement;
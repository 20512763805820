import React, { useState, useEffect } from 'react';
import SecTicketDetails from '../components/TicketManagement/SecTicketDetails';
import UserSidepanel from '../shared/userSidepanel';
import { useParams } from 'react-router';
import {getTicketDetails} from '../actions/ticket';
import {checkDriverVehicle} from '../actions/site';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import toastr from 'toastr';

const TicketDetails = () => {

  const { id } = useParams();
  const dispatch=useDispatch();
  const [ticket,setTicket] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const loadTicket = async () => {
      try {            
        const data = await getTicketDetails({
            id:id
        });  
        if(data.status == 200) {
          setTicket(data.data)
        }else{
            toastr.error(data.message);
            navigate('/ticket-management');
        }    
      } catch(err) {
        toastr.error(err.message);
        navigate('/ticket-management');
      }
    }
    loadTicket();        
}, [id]);

useEffect(() => {
  if(localStorage.getItem('access_token')){
  dispatch(  async () => {
    try {            
      const data = await checkDriverVehicle();
      if(data.status == 200) {
        if(data.step==1){
          return navigate("/add-driver");
        }else if(data.step==2){
          return navigate("/add-plate");
        }
       
      }else{
        return navigate("/add-driver");
      }
      
    } catch(err) {
      console.log(err.message);
    }
  })
  }
 
}, [dispatch,window.location.pathname]); 

    return (
        <section className='sec-panel login-part-pages'>
        <div className='container'>
        <div className="row user-panel-menus">
          <div className="left-sidebar">
                <UserSidepanel/>
            </div>
            <div className="right-sidebar">
                <SecTicketDetails id={id} ticket={ticket} />
            </div>
          </div>
        </div>
    </section>
    );
};

export default TicketDetails;
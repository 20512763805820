import React ,{ useEffect } from 'react';
import SecPlateDetailsGlobal from '../components/PlateDetails/SecPlateDetailsGlobal';
import { useParams } from 'react-router';
const PlateDetailsGlobal = () => {
  const { state,plate_no } = useParams();
     console.log(state,plate_no )
    return (
        <section className='sec-panel login-part-pages'>
        <div className='container'>
          <div className='row'>
        
            <div className=' col-xl-12'>
                <SecPlateDetailsGlobal state={state} plate_no={plate_no} />
            </div>
          </div>
        </div>
    </section>
    );
};

export default PlateDetailsGlobal;
import React from 'react';

const SecClient = ({clients}) => {
  return (
    <section className="section-clients">
    <div className="container">
      <div className="client-content">
        <div className="client-info">
          <h2 className="client-title">Our Clients</h2>
          <div className="client-text">Clients we have worked with</div>
        </div>
        <div className="clients">
          <div className="client-list">
            {
              clients.length >0 && 
              clients.map((item, index) => 
              <div className="client-item" key={index}>
                <img src={item?.image} alt=""/>
              </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default SecClient;
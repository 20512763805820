import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/login';

const LogoutModal = ({ logout }) => {
  const closeRef = useRef(null);

  return (
    <div className="modal logoutModal log-out delete-modal fade" id="logoutModal" tabIndex="-1" data-backdrop="static" data-keyboard="false" >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content delete">
          <div className="modal-header">
            <h5 className="modal-title">Time to Go?</h5>
            <button type="button" data-dismiss="modal" aria-label="close" className="modal-close"
              ref={closeRef}
            >
              <i className="fal fa-fw fa-times"></i>
            </button>
          </div>

          <div className="modal-body">
            <div className="modal-text">When you are ready to end your current session, click "Logout" below.</div>
          </div>

          <div className="modal-footer justify-content-center">
            <button className="cancel-btn mr-4" data-dismiss="modal" type="button">
              Cancel
            </button>

            <a href="#!" className="confirm-btn"
              onClick={(e) => { e.preventDefault(); logout(); }}
            >
              Confirm
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { logout })(LogoutModal);